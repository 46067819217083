import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import axios from '../services/requests/interceptors';
import ForgotPasswordFormSchema from "../forms/forgotPasswordForm";
import ForgotPasswordCodeFormSchema from "../forms/forgotPasswordCodeForm";
import { AppState } from '../redux/stores';
import { FormError } from "@pilarterapeutico/types";
import { setMessage } from "../redux/messagesReducer";
import { RequestHandler } from "../services/contracts";
import { sendForgotCode, resetPassword } from "../services/requests/login";
import { ForgotFormType, ForgotCodeFormType } from "../types/LoginDataType";
import { Button, Input, IntegerInput, Loading, Message, Row, Title, Translate, i18n } from "@pilarterapeutico/components";
import { Login, Banner, Sidebar, Logo, Ilustration, Content, Text, Label, SimpleLink } from "./styles/Login.styles";

const ForgotPassword = () => {

    const [dataEmail, setDataEmail] = useState<string>("");
    const [dataCode, setDataCode] = useState<string>("");
    const [dataPassword, setDataPassword] = useState<string>("");
    const [dataPasswordConfirm, setDataPasswordConfirm] = useState<string>("");
    const [isAskingCode, setIsAskingCode] = useState<boolean>(false);
    const [dataErrors, setDataErrors] = useState<FormError[]>([]);

    const token = useSelector<AppState>(s => s.auth.accessToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateEmail = (data:ForgotFormType) => {
        try {
            ForgotPasswordFormSchema().validateSync(data, {abortEarly:false});
            return true;
        } catch (e:any) {
            if (e.inner && Array.isArray(e.inner)) {
                setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
            }
            return false;
        }
    };

    const validateCode = (data:ForgotCodeFormType) => {
        try {
            ForgotPasswordCodeFormSchema().validateSync(data, {abortEarly:false});
            return true;
        } catch (e:any) {
            if (e.inner && Array.isArray(e.inner)) {
                setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
            }
            return false;
        }
    };

    const getFistError = useMemo(() => {
        return (key:string) => dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
    }, [dataErrors]);

    const handleSubmitFormEmail = async () => {
        const data:ForgotFormType = {
            email: dataEmail
        };
    
        setDataErrors([]);
    
        const isValidForm = validateEmail(data);
        if (!isValidForm) {
            return;
        }
    
        let response:RequestHandler<void> = await sendForgotCode(data);
    
        if (response.error) { 
            if (response.type === 'form') {
                setDataErrors((response.error ?? []) as FormError[]);
            } else {
                dispatch(setMessage({message: response.error ?? '', type: "error"}));
            }
        } else {
            setIsAskingCode(true);
        }
    }

    const handleSubmitFormReset = async () => {
        const data:ForgotCodeFormType = {
            email: dataEmail,
            code: dataCode,
            password: dataPassword,
            password_confirm: dataPasswordConfirm
        };
    
        setDataErrors([]);
    
        const isValidForm = validateCode(data);
        if (!isValidForm) {
            return;
        }
    
        let response:RequestHandler<void> = await resetPassword(data);
    
        if (response.error) { 
            if (response.type === 'form') {
                setDataErrors((response.error ?? []) as FormError[]);
            } else {
                setDataCode('');
                setDataPassword('');
                setDataPasswordConfirm('');
                dispatch(setMessage({message: response.error ?? '', type: "error"}));
            }
        } else {
            dispatch(setMessage({message: i18n("forgot.success"), type: "success"}));
            navigate('/login');
        }
    }

    const handleSubmitForm = async () => {
        if (isAskingCode) {
            handleSubmitFormReset();
        } else {
            handleSubmitFormEmail();
        }
    }

    const handleSubmitOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            (event.target as HTMLInputElement).blur();
            handleSubmitForm();
        }
    }

    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token, navigate]);

    return <Login>
        <Banner>
            <Logo className="register">
                <Link to="https://pilarterapeutico.com.br">
                    <img src="/img/logo.svg" alt="" width="100%" />
                </Link>
            </Logo>
            <Ilustration src="/img/banner.svg" />
        </Banner>
        <Sidebar>
            <Content>
                <Title className="uppercase">
                    <Translate path="forgot.title"/>
                </Title>
                <Text>
                    <Translate path="forgot.message"/>
                </Text>
                {isAskingCode ? <>
                    <Text style={{marginTop: 0}}>
                        <Translate path="forgot.askCode"/>
                    </Text>
                    <Label>
                        <Translate path="forgot.code"/>
                    </Label>
                    <IntegerInput
                        uid="login.code"
                        value={dataCode} 
                        formError={getFistError('email')}
                        onChange={(e)=>{setDataCode((e.target as HTMLInputElement).value)}} 
                    />
                    <Row align="center" justify="stretch">
                        <Label>
                            <Translate path="config.security.newPass"/>
                        </Label>
                        <Label>
                            <Translate path="config.security.confirmPass"/>
                        </Label>
                    </Row>
                    <Row align="center" justify="stretch">
                        <Input 
                            uid="login.password"
                            value={dataPassword} 
                            type="password"
                            formError={getFistError('password')}
                            onChange={(e)=>{setDataPassword((e.target as HTMLInputElement).value)}} 
                            />
                        <Input 
                            uid="login.password_confirm"
                            value={dataPasswordConfirm} 
                            type="password"
                            formError={getFistError('password_confirm')}
                            onChange={(e)=>{setDataPasswordConfirm((e.target as HTMLInputElement).value)}} 
                            onKeyDown={(e)=>handleSubmitOnEnter(e)}
                            />
                    </Row>
                    <Text>
                        <Translate path="forgot.cancelation"/>
                    </Text>
                </> : <>
                    <Text style={{marginTop:0}}>
                        <Translate path="forgot.askMail"/>
                    </Text>
                    <Label>
                        <Translate path="login.email"/>
                    </Label>
                    <Input 
                        uid="login.email"
                        value={dataEmail} 
                        type="email"
                        formError={getFistError('email')}
                        onChange={(e)=>{setDataEmail((e.target as HTMLInputElement).value)}} 
                        onKeyDown={(e)=>handleSubmitOnEnter(e)}
                        />
                </>}
                <Row justify="space-between">
                    <SimpleLink>
                        <Link to="/login">
                            <Translate path="register.back"/>
                        </Link>
                    </SimpleLink>
                    <div></div>
                    <Button 
                        size={7}
                        color="var(--theme-color-primary)"
                        onClick={()=>{handleSubmitForm()}}>
                        <Translate path="login.enter"/>
                    </Button>
                </Row>
            </Content>
        </Sidebar>
        <Loading />
        <Message />
    </Login>
}

export default ForgotPassword;
