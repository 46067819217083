import { useEffect, useMemo, useState } from "react";
import { mdiCheck } from "@mdi/js";
import { useDispatch } from "react-redux";

import PatientFormSchema from "../forms/patienForm";
import { getImage } from "../services/requests/statics";
import { FormError } from "../services/contracts";
import { setMessage } from "../redux/messagesReducer";
import { refreshProfile } from "../redux/authReducer";
import { ProfileFormType } from "../types/ProfileDataType";
import { getProfileData, updateProfile } from "../services/requests/profile";
import { DateFromISOToTimeAt, DateFromViewToUTC, DateStrFromUTCToView } from "../util/dates";
import { Row, Column, Input, DateInput, Checkbox, Button, Label, ProfilePicture, Translate, i18n, PhoneInput, RowOrColumn } from "@pilarterapeutico/components";
import * as Tags from './styles/ConfigProfileForm.styles';

const ConfigProfile = () => {
    const [dataName, setDataName] = useState<string>("");
    const [dataSurname, setDataSurname] = useState<string>("");
    const [dataBirthdate, setDataBirthdate] = useState<string>("");
    const [dataEmail, setDataEmail] = useState<string>("");
    const [dataCreatedAt, setDataCreatedAt] = useState<string>("");
    const [dataPhone, setDataPhone] = useState<string>("");
    const [dataIsWhatsapp, setDataIsWhatsapp] = useState<boolean>(false);
    const [dataPhotoFile, setDataPhotoFile] = useState<File>();
    const [dataPhotoPreview, setDataPhotoPreview] = useState<string>();
    const [dataErrors, setDataErrors] = useState<FormError[]>([]);

    const dispatch = useDispatch();

    /*TODO: FIX*/
    const validateAll = (data:ProfileFormType) => {
        try {
            PatientFormSchema().validateSync(data, {abortEarly:false});
            return true;
        } catch (e:any) {
            if (e.inner && Array.isArray(e.inner)) {
                setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
            }
            return false;
        }
    };

    const handleSave = async () => {
        const data:ProfileFormType = {
            name: dataName,
            surname: dataSurname,
            birthdate: DateFromViewToUTC(dataBirthdate),
            phone: dataPhone,
            is_whatsapp: dataIsWhatsapp,
            photo: dataPhotoFile ?? (dataPhotoPreview ? 'keep' : 'none')
        };
    
        setDataErrors([]);
    
        const isValidForm = validateAll(data);
        if (!isValidForm) {
            return;
        }
    
        let response = await updateProfile(data);
    
        if (response.error) { 
            if (response.type === 'form') {
                setDataErrors((response.error ?? []) as FormError[]);
            } else {
                dispatch(setMessage({message: response.error ?? '', type: "error"}));
            }
        } else {
            dispatch(refreshProfile({name: dataName, photo: response.data?.photo}));
            dispatch(setMessage({message: i18n("config.savedProfile"), type: "success"}));
        }
    }

    const getFistError = useMemo(() => {
        return (key:string) => dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
    }, [dataErrors]);
    
    useEffect(()=>{
        let update = true;

        (async () => {
            let req = await getProfileData();
        
            if(!req || !update) {
                return;
            }
        
            setDataName(req.data?.name ?? '');
            setDataSurname(req.data?.surname ?? '');
            setDataBirthdate(DateStrFromUTCToView(req.data?.birthdate ?? '', false));
            setDataEmail(req.data?.email ?? '');
            setDataCreatedAt(DateFromISOToTimeAt(req.data?.created_at) ?? '');
            setDataPhone(req.data?.phone ?? '');
            setDataPhotoFile(undefined);
            setDataIsWhatsapp(req.data?.is_whatsapp ?? false);
            
            let reqPhoto = "";
            if (req.data?.photo) {
                reqPhoto = await getImage("photo/" + req.data?.photo);
            }
        
            if(reqPhoto && update) {
                setDataPhotoPreview(reqPhoto);
            }
        })();
    
        return () => {update = false;};
    }, []);

    return <>
        <div style={{padding:'1rem'}}>
            <RowOrColumn rowAlign="flex-start" columnAlign="stretch">
                <Tags.Photo>
                    <ProfilePicture uid="patiente.photo" value={dataPhotoPreview} onChange={(val:File)=>{setDataPhotoFile(val); setDataPhotoPreview(undefined)}} />
                </Tags.Photo>
                <Column style={{flex: 1,paddingRight: '0.625rem'}}>
                    <RowOrColumn columnAlign="stretch">
                        <Tags.Field>
                            <Label>
                                <Translate path="patientform.label.email"/>
                            </Label>
                            <Tags.Value>{dataEmail}</Tags.Value>
                        </Tags.Field>
                        <Tags.Field>
                            <Label>
                                <Translate path="config.created_at" />
                            </Label>
                            <Tags.Value>{dataCreatedAt}</Tags.Value>
                        </Tags.Field>
                    </RowOrColumn>
                    <Row>
                        <Tags.Field>
                            <Label>
                                <Translate path="patientform.label.name"/>
                            </Label>
                            <Input 
                                uid='patientform.name'
                                value={dataName} 
                                formError={getFistError('name')}
                                onChange={(e)=>{setDataName((e.target as HTMLInputElement).value)}} 
                            />
                        </Tags.Field>
                        <Tags.Field>
                            <Label>
                                <Translate path="patientform.label.surname"/>
                            </Label>
                            <Input 
                                uid='patientform.surname'
                                value={dataSurname} 
                                formError={getFistError('surname')}
                                onChange={(e)=>{setDataSurname((e.target as HTMLInputElement).value)}} 
                            />
                        </Tags.Field>
                    </Row>
                    <RowOrColumn rowAlign="flex-end" columnAlign="stretch">
                        <Tags.Field>
                            <Label>
                                <Translate path="patientform.label.birthdate"/>
                            </Label>
                            <DateInput 
                                uid='patientform.birthdate'
                                value={dataBirthdate} 
                                formError={getFistError('birthdate')}
                                onChange={(e)=>{setDataBirthdate((e.target as HTMLInputElement).value)}} 
                            />
                        </Tags.Field>
                        <Tags.Field style={{flexDirection:"row", alignItems:"flex-end"}}>
                            <Column style={{flex: 1}} align="flex-start">
                                <Label>
                                    <Translate path="patientform.label.phone"/>
                                </Label>
                                <PhoneInput
                                    uid='patientform.phone'
                                    formError={getFistError('phone')}
                                    value={dataPhone} 
                                    onChange={(e)=>{setDataPhone((e.target as HTMLInputElement).value)}} 
                                />
                            </Column>
                            <Checkbox 
                                style={{flex: 1, marginLeft:"1rem"}}
                                uid='patientform.isWhatsapp'
                                label={i18n("patientform.label.isWhatsapp")}
                                checked={dataIsWhatsapp} 
                                onCheck={(val:boolean)=>{setDataIsWhatsapp(val)}} 
                            />
                        </Tags.Field>
                    </RowOrColumn>
                </Column>
            </RowOrColumn>
        </div>
        <Tags.ButtonBar>
            <Button size={6} icon={mdiCheck} onClick={()=>{handleSave()}} color="#090" title={i18n("save")} />
        </Tags.ButtonBar>
    </>;
}

export default ConfigProfile;
