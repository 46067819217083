import { useEffect, useMemo, useRef, useState } from "react";
import { DndProvider } from 'react-dnd'
import { useSelector } from "react-redux";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

import useMobileDetect from '../hooks/useMobile';
import { AppState } from "../redux/stores";
import { LimitState } from './styles/Home.styles';
import { Button, PageContent, GridTime, Translate as T, i18n, Title, TitleBar, RowOrColumn, Row } from "@pilarterapeutico/components";
import { DateIntervalString } from "@pilarterapeutico/util";
import { CurrentPlanDataType } from "../types/PlansDataTypes";
import { getCalendar, moveCalendar } from "../services/requests/calendar";
import { AppointmentsListItemsType } from "../services/contracts/appointments";
import { iconCalendar2Weeks, iconCalendarMonth, iconCalendarWeek } from "../forms/icons";
import * as Tags from './styles/Calendar.styles';

type ViewModeType = 'WEEK' | '2WEEK' | 'MONTH';

const savedModeView = localStorage.getItem('config.calendarViewMode');
const defaultModeView = savedModeView === 'MONTH' ? 'MONTH' : savedModeView === '2WEEK' ? '2WEEK' : 'WEEK';

const Calendar = () => {

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [viewMode, setViewMode] = useState<ViewModeType>(defaultModeView);
    const [dataAppointments, setDataAppointments] = useState<AppointmentsListItemsType[]>([]);

    const currentPlan = useSelector<AppState, CurrentPlanDataType|undefined>(s => s.auth.plan);

    const refDate = useRef<Date>(new Date());
    const update = useRef<boolean>(true);
    const refSkipFirst = useRef<boolean>(false);
    const isMobile = useMobileDetect();

    const changeInvervalDate = (amount:number, period: 'm'|'d'|'w') => {
        let dt1 = new Date();
        let dt2 = new Date();
        let dayOfWeek = 0;

        if (period !== 'm') {
            refDate.current.setDate(refDate.current.getDate()+amount);
            dayOfWeek = refDate.current.getDay();
            dt1 = new Date(refDate.current);
            dt2 = new Date(refDate.current);

            dt1.setDate(refDate.current.getDate()-dayOfWeek);
            dt2.setDate(refDate.current.getDate()+(period === 'w' ? 13 : 6)-dayOfWeek);
        } else {
            refDate.current.setMonth(refDate.current.getMonth()+amount);
            dt1 = new Date(refDate.current);
            dt2 = new Date(refDate.current);

            dt1.setMonth(refDate.current.getMonth());
            dt1.setDate(1);

            dt2.setDate(1);
            dt2.setMonth(refDate.current.getMonth() + 1);
            dt2.setDate(0);
            
            let dayOfWeekMonthStart = dt1.getDay();
            let dayOfWeekMonthEnd = dt2.getDay();

            dt1.setDate(dt1.getDate() - dayOfWeekMonthStart);
            dt2.setDate(dt2.getDate() + (6-dayOfWeekMonthEnd));
        }

        setStartDate(dt1);
        setEndDate(dt2);
    };

    const updateCalendarData = async (start:Date, end:Date) => {
        let req = await getCalendar(start, end);

        if (update.current) {
            setDataAppointments(req.data?.items ?? []);
        }
    };

    const handlePrevInterval = () => {
        if (viewMode === 'MONTH') {
            changeInvervalDate(-1,'m');
        } else if (viewMode === '2WEEK') {
            changeInvervalDate(-7,'w');
        } else {
            changeInvervalDate(-7,'d');
        }
    };

    const handleNextInterval = () => {
        if (viewMode === 'MONTH') {
            changeInvervalDate(1,'m');
        } else if (viewMode === '2WEEK') {
            changeInvervalDate(7,'w');
        } else {
            changeInvervalDate(7,'d');
        }
    };

    const handleMoveCalendar = async (params:any, item: AppointmentsListItemsType) => { //(item:AppointmentsListItemsType) => {
        setDataAppointments(list => list.map(a => a.id !== item.id ? a : {...item, date:params.move_to}));
        await moveCalendar(params);
    };

    const handleViewMode = (mode:ViewModeType) => {
        setViewMode(mode);
        localStorage.setItem('config.calendarViewMode', mode);
    };

    const viewRange = useMemo(() => {
        switch(viewMode) {
            case 'MONTH':
                return i18n(`dates.monthLong.${refDate.current.getMonth() + 1}`) + ' ' + refDate.current.getFullYear();
            case '2WEEK':
            case 'WEEK':
                return DateIntervalString(startDate, endDate);
        }
    }, [startDate, endDate, viewMode]);

    useEffect(() => {
        const savedModeView = localStorage.getItem('config.calendarViewMode');
        const defaultModeView = savedModeView === 'MONTH' ? 'MONTH' : savedModeView === '2WEEK' ? '2WEEK' : 'WEEK';
        setViewMode(defaultModeView);
    }, []);

    useEffect(() => {
        if (viewMode === 'MONTH') {
            changeInvervalDate(0,'m');
        } else if (viewMode === '2WEEK') {
            changeInvervalDate(0,'w');
        } else {
            changeInvervalDate(0,'d');
        }
    }, [viewMode]);

    useEffect(() => {
        if (startDate.getTime() && endDate.getTime() && refSkipFirst.current) {
            updateCalendarData(startDate, endDate);
        }

        return () => {refSkipFirst.current = true}
    }, [startDate, endDate]);

    useEffect(() => {
        if (viewMode === 'MONTH') {
            changeInvervalDate(0,'m');
        } else if (viewMode === '2WEEK') {
            changeInvervalDate(0,'w');
        } else {
            changeInvervalDate(0,'d');
        }

        return () => {update.current = false;};
    }, []);

    const renderCalendar = useMemo(()=>{
        return <GridTime onMoveItem={handleMoveCalendar} appointments={dataAppointments} start={startDate} end={endDate} starttime={420} endtime={1320} viewmode={viewMode} refDate={refDate.current} />;
    }, [startDate, dataAppointments, refDate.current]);

    return (<PageContent>
        <TitleBar>
            <RowOrColumn rowJustify="stretch">
                <Row style={{flex:1}} justify="space-between">
                    <Title>
                        <T path="calendar.title" />
                    </Title>
                    <Tags.ViewModeRow>
                        <div style={{marginRight:"2rem", textAlign:"center"}}>{viewRange}</div>
                        <Button border={viewMode !== 'WEEK'} tooltip={i18n("calendar.viewModeWeek")} color="var(--theme-color-primary)" icon={iconCalendarWeek} onClick={()=>handleViewMode('WEEK')} />
                        <Button border={viewMode !== '2WEEK'} tooltip={i18n("calendar.viewMode2Week")} color="var(--theme-color-primary)" icon={iconCalendar2Weeks} onClick={()=>handleViewMode('2WEEK')} />
                        <Button border={viewMode !== 'MONTH'} tooltip={i18n("calendar.viewModeMonth")} color="var(--theme-color-primary)" icon={iconCalendarMonth} onClick={()=>handleViewMode('MONTH')} />
                    </Tags.ViewModeRow>
                </Row>
                <Row style={{width: "auto"}}>
                    <Button color="var(--theme-color-primary)" icon={mdiChevronLeft} onClick={handlePrevInterval} tooltip={i18n("calendar.viewPrev")} />
                    <Button color="var(--theme-color-primary)" icon={mdiChevronRight} onClick={handleNextInterval} tooltip={i18n("calendar.viewNext")} />
                </Row>
            </RowOrColumn>
        </TitleBar>
        {[0,undefined].indexOf(currentPlan?.events) < 0 ? <LimitState>{i18n("calendar.limit").replace("$1", String(currentPlan?.events))}</LimitState> : null}
        <DndProvider backend={isMobile.isMobile() ? TouchBackend : HTML5Backend} options={isMobile.isMobile() ? {delayTouchStart:500} : {}}>
            <Tags.GridTime>
                <Tags.GridTimeContent>
                    {renderCalendar}
                </Tags.GridTimeContent>
            </Tags.GridTime>
        </DndProvider>
    </PageContent>)
}

export default Calendar;
