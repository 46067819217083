import URLs from '../../urls';
import store from '../../redux/stores';
import axios from "./interceptors";
import { RequestHandler } from '../contracts';
import { startLoading, stopLoading} from '../../redux/loadingReducer';
import { GetAppointmentsResponse, GetBirthdateResponse, GetPaymentsResponse } from '../contracts/home';

export const getBirthdates = async (): Promise<RequestHandler<GetBirthdateResponse>> => {
    const result = new RequestHandler<GetBirthdateResponse>();
    try {
        store.dispatch(startLoading({key:'home.getBirthdates'}));
        const res = await axios.get(URLs.home.birthdates)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'home.getBirthdates'}));
    }
    return result;
}

export const getAppointments = async (): Promise<RequestHandler<GetAppointmentsResponse>> => {
    const result = new RequestHandler<GetAppointmentsResponse>();
    try {
        store.dispatch(startLoading({key:'home.getAppointments'}));
        const res = await axios.get(URLs.home.appointments)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'home.getAppointments'}));
    }
    return result;
}

export const getPayments = async (): Promise<RequestHandler<GetPaymentsResponse>> => {
    const result = new RequestHandler<GetPaymentsResponse>();
    try {
        store.dispatch(startLoading({key:'home.getPayments'}));
        const res = await axios.get(URLs.home.payments)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'home.getPayments'}));
    }
    return result;
}

export const sendMessage = async (data: {message:string, subject:string}): Promise<RequestHandler<void>> => {
    const result = new RequestHandler<void>();
    try {
        store.dispatch(startLoading({key:'home.sendMessage'}));
        const res = await axios.post(URLs.home.message, data)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'home.sendMessage'}));
    }
    return result;
}
