import { mdiCake } from '@mdi/js';

import { BirthdateItem } from '../../services/contracts/home';
import { EmptyState, i18n } from "@pilarterapeutico/components";
import * as Tags from './styles';
import { CalculateYearsFromISO, DateFromISOToDayMonth } from '@pilarterapeutico/util';

export type CardBirthdaysProps = {
    birthdays: BirthdateItem[];
};

const CardBirthdays: React.FC<CardBirthdaysProps> = ({birthdays}:CardBirthdaysProps) => {

    return (<Tags.Content>
        {birthdays && birthdays.length > 0 ? 
            birthdays.map((b,i) => <Tags.BirthdayItem key={i}>
                <Tags.BirthdayDay>{DateFromISOToDayMonth(b.birthdate)}</Tags.BirthdayDay>
                <Tags.BirthdayName>{b.name}</Tags.BirthdayName>
                <Tags.BirthdayYears>{CalculateYearsFromISO(b.birthdate)} {i18n('patientView.labelYearsOld')}</Tags.BirthdayYears>
            </Tags.BirthdayItem>)
        :
            <EmptyState title={i18n("home.emptyBirthdates")} icon={mdiCake} size={2} />
        }
    </Tags.Content>)
}

export default CardBirthdays;
