import React from 'react';
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux';
import Store from './redux/stores';
import Router from './routes'
import * as Sentry from "@sentry/react";
import './locale';
import './index.css';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

Sentry.init({
    dsn: "https://40bc3a1a2979c5f8e72049da9475ef5c@o4506763555438592.ingest.sentry.io/4506763729436672",
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.replayIntegration()
    ],

    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/(:?.*?)\.pilarterapeutico\.com\.br/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={Store} >
    <Router />
  </Provider>
)
