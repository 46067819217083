import styled from "styled-components"

export const Login = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100vw;
    min-height: 100vh;

    @media (min-width: 1000px) {
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;
        height: 100vh;
    }
`

export const Banner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--theme-color-background, #dfdfdf);
    padding: 50px 0;

    @media (min-width: 1000px) {
        height: 100vh;
        flex: 1;
    }
`

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    background-color: var(--theme-color-background-aux, #8b8b8b);
    color: var(--theme-color-font, #000);
    padding: 70px 0;

    @media (min-width: 1000px) {
        width: 30%;
        flex: auto 0 0;
        height: 100vh;
        overflow: auto;

        &.register {
            width: 50%;
        }
    }
`

export const Content = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    @media (min-width: 1000px) {
        padding: 0;
    }
`

export const Logo = styled.div`
    width: 80%;
    margin: 0px 0 60px;
    
    @media (min-width: 1000px) {
        margin: 0 0 100px 0;
        width: 40%;
        cursor: pointer;
        
        &.register {
            width: 60%;
        }
    }
`

export const Ilustration = styled.img`
    width: 80%;
    
    @media (min-width: 1000px) {
        width: 50%;
        
        &.register {
            width: 70%;
        }
    }
`

export const Text = styled.p`
    margin: 30px 0 30px;
    font-size: 0.8rem;
    line-height: 1.3rem;
    text-align: justify;
`

export const Label = styled.label`
    font-size: 1rem;
    flex: 1;
`

export const ForgetLink = styled.div`
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 20px;
    
    & a {
        color: var(--theme-color-font-weak, #000);
        text-decoration: none;
    }
    & a:hover {
        color: var(--theme-color-primary, #8D7699);
    }
`

export const SimpleLink = styled.div`
    font-size: 1.1rem;
    text-decoration: none;
    color: var(--theme-color-font, #8D7699);
    cursor: pointer;
    
    & a {
        color: var(--theme-color-font, #8D7699);
        text-decoration: none;
    }
    & a:hover {
        color: var(--theme-color-primary, #000);
    }
    &:hover {
        color: var(--theme-color-primary, #000);
    }
`