const algorithm = {
    name: "RSA-OAEP",
    modulusLength: 2048,
    publicExponent: new Uint8Array([1, 0, 1]),
    hash: "SHA-256",
};

const StringToArrayBuffer = (str:string) => {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i=0; i<str.length; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

const b64_to_ab = (str:string) => {
    return StringToArrayBuffer(atob(str));
}

const ab_to_utf8 = (ab:ArrayBuffer) => {
    var binary = '';
    var bytes = new Uint8Array(ab);
    for (var i=0; i<bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return binary;
}

export const decryptData = async (keyStr:string, data:string) => {
    const binaryDer = b64_to_ab(keyStr);
    let key = await crypto.subtle.importKey("pkcs8", binaryDer, algorithm, true, ["decrypt"]);

    let dataParts = data.split(":");
    let result = "";

    if (dataParts && dataParts.length) {
        for (let part of dataParts) {
            let dec = await crypto.subtle.decrypt(algorithm, key, b64_to_ab(part));
            result += atob(ab_to_utf8(dec));
        }
    }

    return result;
};