import axios, { AxiosResponse } from "axios";
import { i18n } from "@pilarterapeutico/components";

export type DefaultRequesSuccess<T = any> = {
    status: string;
    payload: T;
};

export type FormError = {
    message: string[];
    type?: string;
    path?: string;
};

export class RequestHandler<ResponseType> {
    data?: ResponseType;
    error ?: string | string[] | FormError[];
    status ?: number;
    type ?: string;

    setData = (res: AxiosResponse<DefaultRequesSuccess<ResponseType>, any>) => {
        this.status = res.status;
        this.error = undefined;
        this.data = (res.data as DefaultRequesSuccess<ResponseType>)?.payload;
    }

    setError = (e:any) => {
        console.log(e);
        this.status = 1000;
        this.error = i18n('error');

        
        if (axios.isAxiosError(e)) {
            try{
                const resp = typeof e.response?.data === 'string' ? JSON.parse(e.response?.data) : e.response?.data;
                this.status = e.response?.status;
                this.error = resp.message || i18n('error');
                this.type = resp.type || 'generic';
            } catch (e) {
                this.status = 1000;
                this.error = i18n('error');
                this.type = 'generic';
            }
        }
    }
};