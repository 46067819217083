import { useEffect, useState } from "react";
import Icon from '@mdi/react';
import styled from "styled-components";
import { mdiAccount } from '@mdi/js';
import { useNavigate } from "react-router-dom";

import PatientItemType from "../../types/PatientItemType";

const Container = styled.div`
    flex: 1;
    max-width: 100%;
    min-width: 100%;
    background-color: var(--theme-color-background-aux, #c0c0c0);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    padding: 0.3rem;
    cursor: pointer;
    transition: all .4s ease 0s;

    &:hover {
        background-color: var(--theme-color-background-aux-hover, #cacaca);
    }

    &.inactive {
        opacity: 0.4;
    }

    @media (min-width: 1000px) {
        max-width: calc(33.333% - 0.85rem);
        min-width: calc(33.333% - 0.85rem);
        padding: 0.625rem;
    }
`;

const Photo = styled.div`
    width: 3.0rem;
    height: 3.0rem;
    background-color: var(--theme-color-primary, #863BD1);
    margin-right: 0.75rem;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate3d(-50%, -10%, 0);
    }

    @media (min-width: 1000px) {
        width: 3.75rem;
        height: 3.75rem;
    }
`;

const Image = styled.img`
    min-width: 100%;
    min-height: 100%;
`;

const Name = styled.div`
    font-size: 1.125rem;
`;

type PatientItemsProps = {
    item: PatientItemType,
    getImage: Function,
}

export const PatientItem = ({item, getImage}:PatientItemsProps) => {

    const navigate = useNavigate();
    const [photo, setPhoto] = useState<string>();

    const handleOpenPatient = () => {
        navigate(`/patients/${item.id}`);
    }

    useEffect(()=>{
        let update = true;

        (async () => {
            if (!item.photo) {
                return;
            }

            let photo = await getImage(`photo/thumb_${item.photo}`);
            if (update && photo) {
                setPhoto(photo);
            }
        })();
        return ()=>{
            update = false;
        };
    }, []);

  return <Container onClick={()=>{handleOpenPatient()}} className={item.active ? '' : 'inactive'}>
    <Photo>
        {photo ? 
            <Image src={photo} />
        :
            <Icon path={mdiAccount }
                size={3.75}
                color="rgba(255,255,255, 0.3)"
            />
        }
    </Photo>
    <Name>{item.name}</Name>
  </Container>
}