import axios from "axios";

import URLs from '../../urls';
import store from '../../redux/stores';
import { RequestHandler } from '../contracts';
import { RegisterFormType } from "types/RegisterDataType";
import { startLoading, stopLoading} from '../../redux/loadingReducer';
import { PostForgotCodeData, PostForgotPasswordData, PostLoginData, PostRegisterOrLoginResponse } from '../contracts/login';

export const postRegister = async (data: RegisterFormType): Promise<RequestHandler<PostRegisterOrLoginResponse>> => {
    const result = new RequestHandler<PostRegisterOrLoginResponse>();
    try {
        store.dispatch(startLoading({key:'login.postRegister'}));
        const res = await axios.post(URLs.login.register, data)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'login.postRegister'}));
    }
    return result;
}

export const postLogin = async (data: PostLoginData): Promise<RequestHandler<PostRegisterOrLoginResponse>> => {
    const result = new RequestHandler<PostRegisterOrLoginResponse>();
    try {
        store.dispatch(startLoading({key:'login.postLogin'}));
        const res = await axios.post(URLs.login.login, data)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'login.postLogin'}));
    }
    return result;
}

export const sendForgotCode = async (data: PostForgotPasswordData): Promise<RequestHandler<void>> => {
    const result = new RequestHandler<void>();
    try {
        store.dispatch(startLoading({key:'login.sendForgotCode'}));
        const res = await axios.post(URLs.login.forgot, data)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'login.sendForgotCode'}));
    }
    return result;
}

export const resetPassword = async (data: PostForgotCodeData): Promise<RequestHandler<void>> => {
    const result = new RequestHandler<void>();
    try {
        store.dispatch(startLoading({key:'login.resetPassword'}));
        const res = await axios.post(URLs.login.reset, data)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'login.resetPassword'}));
    }
    return result;
}