import { createSlice } from '@reduxjs/toolkit'

interface modalProps {
  show: boolean,
  content?: JSX.Element | JSX.Element[],
  time: number,
};

const initialState: modalProps = {
  show: false,
  content: undefined,
  time: 0,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalAndShow(state, action) {
        state.show = true;
        state.content = action.payload.content;
        state.time = Date.now();
    },

    hideModal(state) {
      state.show = false;
      state.time = Date.now();
    },
  }
});

export const { setModalAndShow, hideModal } = modalSlice.actions;
export default modalSlice.reducer;