import { useEffect, useMemo, useRef, useState } from 'react';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import { mdiClose, mdiCheck, mdiTrashCan, mdiLock } from '@mdi/js';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import ModalDelete from '../modals/ModalDelete';
import LocationDataType from '../types/LocationDataType';
import AppointmentFormSchema from '../forms/appointmentForm';
import { storeKey } from '../redux/authReducer';
import { setMessage } from '../redux/messagesReducer';
import { getPatients } from '../services/requests/patients';
import { decryptData } from '../util/crypt';
import { getLocations } from '../services/requests/locations';
import { AppointmentFormType } from '../types/AppointmentDataType';
import { PostAppointmentsResponse } from '../services/contracts/appointments';
import { FormError, RequestHandler } from '../services/contracts';
import { hideModal, setModalAndShow } from '../redux/modalReducer';
import { getCalendarData, deleteCalendar, createCalendar, updateCalendar } from '../services/requests/calendar';
import { TimeFromIntMinutesToTime, TimeFromTimeToIntMinutes, DateFromDateToUTC, DateFromUTCToDate, DateFromUTCToView, DateFromViewToDate } from '@pilarterapeutico/util';
import { Row, Card, Column, SelectPaged, Textarea, TimeInput, Breadcrumb, PageContent, TitleBar, Button, Checkbox, DateInput, Label, Select, Translate, i18n, RowOrColumn } from '@pilarterapeutico/components';
import * as Tags from './styles/PatientForm.styles';

const CalendarAppointmentForm = ({edit}:{edit?:boolean}) => {

    const [dataPatientId, setDataPatientId] = useState<string>("");
    const [dataPatientName, setDataPatientName] = useState<string>("");
    const [dataDate, setDataDate] = useState<Date>();
    const [dataTime, setDataTime] = useState<string>("");
    const [dataTimeEnd, setDataTimeEnd] = useState<string>("");
    const [dataStatus, setDataStatus] = useState<string>("WAITING");
    const [dataLocal, setDataLocal] = useState<string>("");
    const [dataLocationList, setDataLocationList] = useState<LocationDataType[]>([]);
    const [generatePayment, setGeneratePayment] = useState<boolean>(true);
    const [dataNote, setDataNote] = useState<string>("");
    const [dataNoteEncrypted, setDataNoteEncrypted] = useState<string>("");
    const [dataErrors, setDataErrors] = useState<FormError[]>([]);
    const [showNoteEdit, setShowNoteEdit] = useState<boolean>(false);
    const [keyQueue, setKeyQueue] = useState<[string,any][]>([]);
    
    const storedKey = useSelector<any, string|undefined>(s => s.auth.key);

    const params = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const refInput = useRef<HTMLInputElement>(null);

    const breadcrumbPath:any = {
        "/calendar": i18n("calendar.title")
    };

    const validateAll = (data:AppointmentFormType) => {
        try {
            AppointmentFormSchema().validateSync(data, {abortEarly:false});
            return true;
        } catch (e:any) {
            console.log(e.inner);
            if (e.inner && Array.isArray(e.inner)) {
                setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
            }
            return false;
        }
    };

    const handleSave = async () => {
        const data:AppointmentFormType = {
            date: DateFromDateToUTC(dataDate) ?? '',
            time: TimeFromTimeToIntMinutes(dataTime),
            timeend: TimeFromTimeToIntMinutes(dataTimeEnd),
            status: dataStatus,
            note: dataNote,
            location_id: dataLocal || undefined,
            patient_id: dataPatientId || undefined,
            generatePayment,
        };
    
        setDataErrors([]);
    
        const isValidForm = validateAll(data);
        if (!isValidForm) {
            return;
        }
    
        let response:RequestHandler<PostAppointmentsResponse>;
        if (edit) {
          data.id = params.appointmentId;
          response = await updateCalendar(data);
        } else {
          response = await createCalendar(data);
        }
    
        if (response.error) { 
            if (response.type === 'form') {
              setDataErrors((response.error ?? []) as FormError[]);
            } else {
              dispatch(setMessage({message: response.error ?? '', type: "error"}));
            }
        } else {
            dispatch(setMessage({message: i18n("calendar.saved"), type: "success"}));
            navigate('/calendar/',{replace:true});
        }
    }

    const handleCancel = () => {
        navigate(-1);
    }

    const handleDelete = () => {
        dispatch(setModalAndShow({
          content: <ModalDelete i18nKey={"patientView.appointments"} onConfirm={handleDeleteConfirm} />,
        }))
    }
    
    const handleDeleteConfirm = async () => {
        dispatch(hideModal());
        const response = await deleteCalendar(String(params.appointmentId));
        if (response.error) {
            dispatch(setMessage({
                message: i18n("appointment.unableToRemove"),
                type: "error",
            }));
        } else {
            dispatch(setMessage({
                message: i18n("appointment"),
                type: "success",
            }));
            navigate(`/calendar`);
        }
    }

    const getFistError = useMemo(() => {
        return (key:string) => dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
    }, [dataErrors]);

    const dateAsString = useMemo(()=>{
        return DateFromUTCToView(dataDate, false);
    }, [dataDate]);

    const handleUnlockNoteContent = () => {
        setKeyQueue(s => [...s, ['decrypt', {}]]);
    }

    const _decryptContent = async () => {
        try {
            let decrypted = await decryptData(storedKey ?? '', dataNoteEncrypted);
            setDataNote(decrypted);
            setShowNoteEdit(true);
        } catch (e) {
            console.error(e);
        }
    }

    const handleFileChange = () => {
        try {
            let file = (refInput.current && refInput.current.files) ? refInput.current?.files[0] : undefined;
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    let key = (e.target?.result as string);
                    dispatch(storeKey({content:key ?? undefined}));
                };
                reader.readAsText(file);
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(()=>{
        if (keyQueue.length === 0) {
            return;
        }
        
        if (!storedKey) {
            refInput.current?.click();
        } else {
            const item = keyQueue[0];
            const fn = item[0];
            switch(fn) {
                case 'decrypt':
                    _decryptContent(); break;
            }
            setKeyQueue(k => k.slice(1));
        }
    }, [keyQueue, storedKey]);


    useEffect(()=>{
        let time = TimeFromTimeToIntMinutes(dataTime) ?? 0;
        let dt = dataDate ? new Date(dataDate) : new Date();
        dt?.setHours(Math.floor(time/60));
        dt?.setMinutes(time % 60);

        console.log(time, dt);
        setDataDate(dt);
    }, [dataTime]);

    useEffect(()=>{
        let update = true;

        (async () => {
            if (edit) {
                let req = await getCalendarData(params.appointmentId ?? '0');

                if (req && update) {
                    let dt = DateFromUTCToDate(req.data?.date);
                    dt?.setHours(Math.floor(parseInt(req.data?.time ?? '', 10)/60));
                    dt?.setMinutes(parseInt(req.data?.time ?? '', 10) % 60);

                    setDataDate(dt);
                    setDataTime(TimeFromIntMinutesToTime(parseInt(req.data?.time ?? '', 10), ":") ?? "");
                    setDataTimeEnd(TimeFromIntMinutesToTime(req.data?.timeend ? parseInt(req.data?.timeend, 10) : undefined, ":") ?? "");
                    setDataStatus(req.data?.status ?? '');
                    setDataLocal(req.data?.location_id ?? '');
                    setDataPatientId(req.data?.patient_id?.id ?? "");
                    setDataPatientName(req.data?.patient_id?.name ?? "");
                    setDataNoteEncrypted(req.data?.note ?? '');
                    setShowNoteEdit(!Boolean(req.data?.note));
                }
            }

            let reqLocations = await getLocations();
    
            if (update && reqLocations) {
                setDataLocationList(reqLocations.data?.items ?? []);
            }
        })();

        return () => {update = false;}
    }, []);

    useEffect(() => {
        if (location.state?.date) {
            setDataDate(location.state?.date);
        }
        if (location.state?.hour) {
            setDataTime(TimeFromIntMinutesToTime(location.state?.hour*60,":") ?? "");
        }
    },[])

    return (<PageContent>
        <TitleBar>
            <Breadcrumb items={breadcrumbPath} current={i18n("calendar.path")} />
        </TitleBar>
        <Card title={i18n(`calendar.title${edit ? 'Edit' : 'New'}`)}>
            <div style={{padding:'1rem'}}>
                <RowOrColumn rowAlign='flex-start' rowMargin="2rem" columnAlign='stretch' columnMargin='0 0 0.5rem 0'>
                    <Column align="stretch" style={{flex:1}}>
                        <Row>
                        <Tags.Field style={{flex:1}}>
                                <Label><Translate path="calendar.patient" /></Label>
                                <SelectPaged 
                                    uid="calendar.patients_id" 
                                    value={dataPatientId} 
                                    labelValue={dataPatientName} 
                                    onChange={(option) => {setDataPatientId(option.value)}}
                                    loader={async (page:number, filter:string) => {return getPatients(page, {name:filter, active:'true', size:5})}}
                                    transform={(item:any, index:number)=>({index, value:item.id, label:item.name})}
                                />
                            </Tags.Field>
                        </Row>
                        <Row>
                            <Tags.Field style={{flex:1}}>
                                <Label><Translate path="patientAppointment.date" /></Label>
                                <DateInput uid="patientAppointment.date" value={dateAsString} onChange={(e) => {setDataDate(DateFromViewToDate((e.target as HTMLSelectElement).value))}} formError={getFistError('date')} />
                            </Tags.Field>
                        </Row>
                        <Row>
                            <Tags.Field>
                                <Label><Translate path="patientAppointment.time" /></Label>
                                <TimeInput uid="patientAppointment.time" value={dataTime} onChange={(e) => {setDataTime((e.target as HTMLSelectElement).value)}} formError={getFistError('time')}  />
                            </Tags.Field>
                            <Tags.Field>
                                <Label><Translate path="patientAppointment.timeend" /></Label>
                                <TimeInput uid="patientAppointment.timeend" value={dataTimeEnd} onChange={(e) => {setDataTimeEnd((e.target as HTMLSelectElement).value)}} formError={getFistError('timeend')} />
                            </Tags.Field>
                        </Row>
                        <Tags.Field>
                            <Label><Translate path="patientAppointment.status" /></Label>
                            <Select uid="patientAppointment.status" value={dataStatus} onChange={(e) => {setDataStatus((e.target as HTMLSelectElement).value)}}>
                                <option value="WAITING">{i18n("patientAppointment.statusValue.waiting")}</option>
                                <option value="CONFIRMED">{i18n("patientAppointment.statusValue.confirmed")}</option>
                                <option value="DONE">{i18n("patientAppointment.statusValue.done")}</option>
                                <option value="CANCELLED">{i18n("patientAppointment.statusValue.cancelled")}</option>
                                <option value="LOST">{i18n("patientAppointment.statusValue.lost")}</option>
                            </Select>
                        </Tags.Field>
                        <Tags.Field>
                            <Label><Translate path="patientAppointment.local" /></Label>
                            <Select uid="patientAppointment.local" value={dataLocal} onChange={(e) => {setDataLocal((e.target as HTMLSelectElement).value)}}>
                                <option value="0"></option>
                                {dataLocationList.map(location => <option value={location.id} key={location.id}>{location.name}</option>)}
                            </Select>
                        </Tags.Field>
                        {!edit ? <Tags.Field>
                            <Label><Translate path="patientAppointment.payments" /></Label>
                            <Checkbox uid='patientAppointment.payments' label={i18n('patientAppointment.paymentsCheckbox')} checked={generatePayment} onCheck={(e:boolean)=>setGeneratePayment(e)} />
                        </Tags.Field> : null}
                    </Column>
                    <Column align="stretch" justify='flex-start' style={{flex:2}}>
                        <Tags.Field>
                            <Label><Translate path="patientAppointment.notes" /></Label>
                            {!edit || showNoteEdit ? <Textarea uid="patientAppointment.notes" placeholder={i18n("patientAppointment.notesPlaceholder")} value={dataNote} onChange={(e) => {setDataNote((e.target as HTMLTextAreaElement).value)}} />
                            : <Tags.ContentAnswer>
                                <Tags.AnswerHeader style={{justifyContent:"space-between"}}>
                                    <Tags.AnswerCript style={{paddingLeft:0}}>
                                        <Icon path={mdiLock} size={0.7} />
                                        <div><Translate path={"patientActivity.cript"} /></div>
                                    </Tags.AnswerCript>
                                    <Tags.AnswerUnlock onClick={()=>{handleUnlockNoteContent()}}>{i18n("patientActivity.unlock")}</Tags.AnswerUnlock>
                                </Tags.AnswerHeader>
                            </Tags.ContentAnswer> }
                        </Tags.Field>
                    </Column>
                </RowOrColumn>
            </div>
            <Tags.ButtonBar style={{justifyContent:'space-between'}}>
                <Tags.ButtonGroup>
                    {edit ?
                        <Button size={6} icon={mdiTrashCan} onClick={()=>{handleDelete()}} color="#ff4444" title={i18n("delete")} />
                    : null}
                </Tags.ButtonGroup>
                <Tags.ButtonGroup>
                    <Button size={6} icon={mdiClose} onClick={()=>{handleCancel()}} color="#999999" title={i18n("cancel")} />
                    <Button size={6} icon={mdiCheck} onClick={()=>{handleSave()}} color="#090" title={i18n("save")} />
                </Tags.ButtonGroup>
            </Tags.ButtonBar>
        </Card>
        <Tags.FileInput ref={refInput} onChange={handleFileChange} accept='.pem' multiple={false}/>
    </PageContent>)
}

export default CalendarAppointmentForm;