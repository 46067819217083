import axios from "./interceptors";

import URLs from '../../urls';
import store from '../../redux/stores';
import { RequestHandler } from '../contracts';
import { startLoading, stopLoading} from '../../redux/loadingReducer';
import { GetSessionsResponse } from "../contracts/security";

export const getSessions = async (): Promise<RequestHandler<GetSessionsResponse>> => {
    const result = new RequestHandler<GetSessionsResponse>();
    try {
        store.dispatch(startLoading({key:'security.getSessions'}));
        const res = await axios.get(URLs.config.sessions)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'security.getSessions'}));
    }
    return result;
}

export const forceQuitSession = async (uuid?: string): Promise<RequestHandler<void>> => {
    const result = new RequestHandler<void>();
    try {
        store.dispatch(startLoading({key:'security.forceQuitSession'}));
        const res = await axios.delete(URLs.config.session(uuid))
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'security.forceQuitSession'}));
    }
    return result;
}

export const changePass = async (data: {currentPass?:string, newPass?:string, confirmPass?:string}): Promise<RequestHandler<void>> => {
    const result = new RequestHandler<void>();
    try {
        store.dispatch(startLoading({key:'security.changePass'}));
        const res = await axios.put(URLs.config.password, data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'security.changePass'}));
    }
    return result;
}
