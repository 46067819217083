import * as yup from 'yup';
import { i18n } from '../util/util';

const RegisterFormSchema = () => yup.object({
    name: yup.string()
        .min(2, i18n('register.minLengthName'))
        .max(64, i18n('register.maxLengthName'))
        .required(i18n('register.emptyName')),
    surname: yup.string()
        .min(2, i18n('register.minLengthSurname'))
        .max(64, i18n('register.maxLengthSurname'))
        .required(i18n('register.emptySurname')),
    email: yup.string()
        .email(i18n('register.emailInvalid'))
        .required(i18n('register.emptyEmail')),
    password: yup.string()
        .min(6, i18n('register.minLengthPassword'))
        .max(32, i18n('register.maxLengthPassword'))
        .required(i18n('register.emptyPassword')),
    password_confirm: yup.string()
        .oneOf([yup.ref('password'), ''], i18n('register.passwordMismatch'))
        .required(i18n('register.emptyPasswordConfirm')),
    crp_number: yup.string().required(i18n('register.emptyCRP')),
    crp_uf: yup.string().required(i18n('register.emptyCRPUF')),
    referrer: yup.string().optional(),
    plan: yup.string().optional(),
});

export default RegisterFormSchema;