import { FocusEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { mdiCheck, mdiClose } from "@mdi/js";

import { hideModal } from "../redux/modalReducer";
import { Button, Column, Translate, i18n, RowOrColumn, Input, Textarea, Row } from "@pilarterapeutico/components";
import * as Tags from './styles/ModalFilterPatients.styles';

type ModalHelpProps = {
    onConfirm: (data:{message:string, subject:string}) => Promise<void>;
};

const ModalHelp = ({onConfirm}:ModalHelpProps) => {

    const [dataMessage, setDataMessage] = useState<string>("");
    const [dataSubject, setDataSubject] = useState<string>("");

    const dispatch = useDispatch();

    const handleSubmit = () => {
        onConfirm({
            message: dataMessage,
            subject: dataSubject
        }).then(() => {
            setDataMessage("");
            setDataSubject("");
        });
    };

    return <Tags.Content>
        <Tags.Title style={{padding:"2rem 2rem 1rem"}}><Translate path="help.title" /></Tags.Title>
        <Tags.HelpForm>
            <RowOrColumn rowAlign="flex-start">
                <Column style={{flex:1, marginRight:"1rem"}} align="stretch">
                    {i18n("help.text")}<br /><br />
                    <Row align="center">Email: <Tags.Link href="mailto:suporte@pilarterapeutico.com.br">suporte@pilarterapeutico.com.br</Tags.Link></Row>
                    <Row align="center">Whatsapp: <Tags.Link href="https://wa.me/5532991253739" target="_blank" >(32) 9999-9999</Tags.Link></Row>
                    <Row align="center">Instagram: <Tags.Link href="https://instagram.com/pilarterapeutico" target="_blank" >@pilarterapeutico</Tags.Link></Row>
                    <Row align="center">Youtube: <Tags.Link href="https://youtube.com/@pilarterapeutico" target="_blank" >@pilarterapeutico</Tags.Link></Row>
                    <Row align="center">Linkedin: <Tags.Link href="https://linkedin.com/company/pilarterapeutico" target="_blank" >/pilarterapeutico</Tags.Link></Row>
                </Column>
                <Column style={{flex:1}}>
                    <Tags.Field style={{marginBottom: "0"}}>
                        <Tags.Label><Translate path="help.subject" /></Tags.Label>
                        <Input type="text" value={dataSubject} onChange={(e: FocusEvent<HTMLInputElement>) => setDataSubject(e.target.value)} uid="help.subject" />
                    </Tags.Field>
                    <Tags.Field>
                        <Tags.Label><Translate path="help.message" /></Tags.Label>
                        <Textarea type="text" value={dataMessage} onChange={(e: FocusEvent<HTMLTextAreaElement>) => setDataMessage(e.target.value)} uid="help.message" />
                    </Tags.Field>
                </Column>
            </RowOrColumn>
        </Tags.HelpForm>
        <Tags.ButtonRow>
            <Button onClick={()=>{dispatch(hideModal())}} color={"var(--theme-color-font-weak)"} border={true} icon={mdiClose} title={i18n('close')} />
            <Button onClick={()=>handleSubmit()} color={"var(--theme-color-primary)"} icon={mdiCheck} title={i18n('help.send')} />
        </Tags.ButtonRow>
    </Tags.Content>
}

export default ModalHelp;
