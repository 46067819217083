import { useLocation } from "react-router-dom";
import { getPatientData } from "../services/requests/patients";

let usePatientData = () => {
    const location = useLocation();
    
    const state = {
        update: true,
        fn: () => {
            state.update = false;
        },
        getData: (id?:string):Promise<any> => {
            let result:Promise<any>;

            if (location.state?.patient || !id) {
                result = Promise.resolve(location.state?.patient);
            } else {
                result = getPatientData(id).then(req => req.data ?? []);
            }

            return result;
        }
    }

    return state;
}

export default usePatientData;