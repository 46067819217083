import { createSlice } from '@reduxjs/toolkit'
import { ModalFilterData as PatientFilterType } from '../modals/ModalFilterPatients';
import { ModalFilterData as PaymentsFilterType } from '../modals/ModalFilterPatients';

interface filterProps {
  patientFilters?: PatientFilterType,
  paymentsFilters?: PaymentsFilterType,
};

const initialState: filterProps = {
  patientFilters: undefined,
  paymentsFilters: undefined,
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setPatientsFilter(state, action) {
        state.patientFilters = action.payload.filter;
    },

    setPaymentsFilter(state, action) {
        state.paymentsFilters = action.payload.filter;
    }
  }
});

export const { setPatientsFilter, setPaymentsFilter } = filtersSlice.actions;
export default filtersSlice.reducer;