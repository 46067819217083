export const baseURL = import.meta.env.VITE_API_URL;

export default {
    login: {
        login: baseURL + '/login',
        register: baseURL + '/login/register',
        renew: baseURL + '/login/renew',
        forgot: baseURL + '/login/forgot',
        reset: baseURL + '/login/reset',
    },
    statics: {
        base: (id:string) => `${baseURL}/statics/${id}`,
        photo: (id:string) => `${baseURL}/statics/photo/${id}`,
        file: (id:string) => `${baseURL}/statics/file/${id}`,
    },
    home: {
        birthdates: `${baseURL}/home/birthdates`,
        appointments: `${baseURL}/home/appointments`,
        payments: `${baseURL}/home/payments`,
        message: `${baseURL}/message`,
    },
    patients: {
        index: baseURL + '/patients',
        read: (id:string) => `${baseURL}/patients/${id}`,
        update: (id:string) => `${baseURL}/patients/${id}`,
        active: (id:string) => `${baseURL}/patients/${id}/op/active`,
        inactive: (id:string) => `${baseURL}/patients/${id}/op/inactive`,
        list: () => `${baseURL}/patients/list`,
        schedules: {
            index: (id:string) => `${baseURL}/patients/${id}/schedules`,
            update: (id:string, patient_id:string) => `${baseURL}/patients/${patient_id}/schedules/${id}`,
            read: (id:string, patient_id:string) => `${baseURL}/patients/${patient_id}/schedules/${id}`,
            delete: (id:string, patient_id:string) => `${baseURL}/patients/${patient_id}/schedules/${id}`,
        },
        appoitments: {
            index: (patient_id:string) => `${baseURL}/patients/${patient_id}/appointments`,
            data: (id:string, patient_id:string) => `${baseURL}/patients/${patient_id}/appointments/${id}`,
            agenda: (patient_id:string) => `${baseURL}/patients/${patient_id}/appointments/agenda`,
        },
        activities: {
            index: (patient_id:string) => `${baseURL}/patients/${patient_id}/activities`,
            data: (id:string, patient_id:string) => `${baseURL}/patients/${patient_id}/activities/${id}`,
        },
        payments: {
            index: (patient_id:string) => `${baseURL}/patients/${patient_id}/payments`,
            data: (id:string, patient_id:string) => `${baseURL}/patients/${patient_id}/payments/${id}`,
        },
    },
    locations: {
        index: () => `${baseURL}/locations`,
        data: (id:string) => `${baseURL}/locations/${id}`,
    },
    payments: {
        index: baseURL + '/payments',
        data: (id:string) => `${baseURL}/payments/${id}`,
        resume: baseURL + '/payments/resume',
        chart: baseURL + '/payments/chart',
        cashflow: baseURL + '/payments/cashflow',
        recomendations: baseURL + '/payments/recomendations',
    },
    calendar: {
        index: baseURL + '/calendar',
        data: (id:string) => `${baseURL}/calendar/${id}`,
        move: (id:string) => `${baseURL}/calendar/${id}/move`,
    },
    config: {
        index: baseURL + '/config',
        profile: baseURL + '/config/profile',
        savePreferences: baseURL + '/config/preferences',
        password: baseURL + '/config/password',
        sessions: baseURL + '/config/sessions',
        plans:  {
            index: baseURL + '/config/plans',
            status: baseURL + '/config/plans/status',
            active: baseURL + '/config/plans/active',
            subscribePix: baseURL + '/config/plans/subscribe/pix',
            subscribeCard: baseURL + '/config/plans/subscribe/card',
            subscribeChange: baseURL + '/config/plans/subscribe/change',
            cancel: baseURL + '/config/plans/subscribe/cancel'
        },
        session: (id?:string) => `${baseURL}/config/sessions/${id}`,
        templates: {
            index: baseURL + '/config/activitytemplates/',
            data: (id:string) => `${baseURL}/config/activitytemplates/${id}`,
        }
    },
    resources: {
        search: baseURL + '/search/',
        file: (id:string) => baseURL + '/resources/' + id,
    }
}