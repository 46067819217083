import { HTMLProps, useEffect, useState } from 'react';

import { GridTimeTable } from './gridtimetable';
import { GridTimeHeader } from './gridtimeheader';
import { AppointmentsListItemsType } from '../../types/Appointments';

type GridTypeProps = Omit<HTMLProps<HTMLDivElement>,'start'|'end'> & {
    start: Date;
    end: Date;
    refDate: Date;
    starttime: number;
    endtime: number;
    viewmode: 'MONTH'|'2WEEK'|'WEEK';
    appointments: AppointmentsListItemsType[];
    onMoveItem: (params: any, item: AppointmentsListItemsType)=>Promise<any>,
}

export const GridTime:React.FC<GridTypeProps> = ({start, end, refDate, starttime, endtime, viewmode, appointments, onMoveItem}:GridTypeProps) => {

    const [weeks, setWeeks] = useState<Date[]>([]);

    useEffect(()=>{
        let result:Date[] = [start];
        let dt = new Date(start);

        if (viewmode === '2WEEK') {
            dt.setDate(dt.getDate() + 7);
            result.push(dt);
        } else if (viewmode === 'MONTH') {
            dt.setDate(dt.getDate() + 7);
            while(dt.getTime() < end.getTime()) {
                result.push(new Date(dt));
                dt.setDate(dt.getDate() + 7);
            }
        }

        setWeeks(result);
    }, [start, viewmode]);

    return <>
        {weeks.map((day,i) => <div key={i}>
            <GridTimeHeader start={day} viewmode={viewmode} refDate={refDate} />
            <GridTimeTable onMoveItemFn={onMoveItem} startDate={day} appointments={appointments} starttime={starttime} endtime={endtime} viewmode={viewmode} refDate={refDate} />
        </div>)}
    </>
};