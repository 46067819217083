import { mdiCashMultiple } from '@mdi/js';
import { Column, EmptyState, i18n } from "@pilarterapeutico/components";
import * as Tags from './styles';
import { PaymentsItem } from '../../services/contracts/home';
import { useMemo } from 'react';
import { DateFromDateToMonthView, DateStrFromUTCToView, MoneyFromNumberToString } from '@pilarterapeutico/util';
import { useNavigate } from 'react-router-dom';

export type CardPaymentsProps = {
    payments: PaymentsItem[];
};

const CardPayments: React.FC<CardPaymentsProps> = ({payments}:CardPaymentsProps) => {

    const navigate = useNavigate();

    const paymentsIncome = useMemo<[PaymentsItem[],number]>(()=>{
        const items = payments.filter(p=>p.billing_type === 'INCOME');
        return [
            items,
            items.reduce((a,c)=>a+parseFloat(String(c.value_total)), 0)
        ];
    }, [payments]);

    const paymentsOutcome = useMemo<[PaymentsItem[],number]>(()=>{
        const items = payments.filter(p=>p.billing_type === 'OUTCOME');
        return [
            items,
            items.reduce((a,c)=>a+parseFloat(String(c.value_total)), 0)
        ]
    }, [payments]);

    const getPaymentDescription = (item:PaymentsItem):string => {
        switch(item.related_to) {
            case 'APPOINTMENT': return (item.appointments ?? []).length === 1 ? i18n("patientView.payments.appointmentItem").replace("$1", DateStrFromUTCToView(item.appointments[0])) : i18n(`patientView.payments.appointmentList`).replace("$1", String(item.appointments?.length ?? '0'));
            case 'MONTH': return DateFromDateToMonthView(new Date(item.month ?? ''));
            default: return item.description;
        }
    }

    const handleGoToPayment = (id:string) => {
        navigate(`/payments/${id}`);
    }

    return (<Tags.ContentRow style={{flex:1}}>
        {paymentsIncome[0] && paymentsIncome[0].length > 0 ? <>
                <Tags.RowHeader>{i18n("payments.titleToReceive")}</Tags.RowHeader>
                {paymentsIncome[0].map((p,i) => <Tags.ItemRow key={i} onClick={(e)=>handleGoToPayment(p.id)}>
                    <Column align="flex-start" justify="center" style={{flex:1}}>
                        <Tags.PaymentName>{p.patient_name}</Tags.PaymentName>
                        <Tags.PaymentDescription>{getPaymentDescription(p)}</Tags.PaymentDescription>
                    </Column>
                    <Tags.PaymentMoney>{MoneyFromNumberToString(p.value_total)}</Tags.PaymentMoney>
                </Tags.ItemRow>)}
                <Tags.RowFooter className="income">
                    <Tags.PaymentsLabel>{i18n("payments.total.toReceiveTitle")}</Tags.PaymentsLabel>
                    <Tags.PaymentsValue className="right">{MoneyFromNumberToString(paymentsIncome[1])}</Tags.PaymentsValue>
                </Tags.RowFooter>
        </> : null}
        
        {paymentsOutcome[0] && paymentsOutcome[0].length > 0 ? <>
                <Tags.RowHeader>{i18n("payments.titleToPay")}</Tags.RowHeader>
                {paymentsOutcome[0].map((p,i) => <Tags.ItemRow key={i} onClick={(e)=>handleGoToPayment(p.id)}>
                    <Column align="flex-start" justify="center" style={{flex:1}}>
                        <Tags.PaymentName>{p.description}</Tags.PaymentName>
                        <Tags.PaymentDescription>{i18n("patientView.defaultPayment.due")} {DateStrFromUTCToView(p.due_date)}</Tags.PaymentDescription>
                    </Column>
                    <Tags.PaymentMoney>{MoneyFromNumberToString(p.value_total)}</Tags.PaymentMoney>
                </Tags.ItemRow>)}
                <Tags.RowFooter className="outcome">
                    <Tags.PaymentsLabel>{i18n("payments.total.toPayTitle")}</Tags.PaymentsLabel>
                    <Tags.PaymentsValue className="right">{MoneyFromNumberToString(paymentsOutcome[1])}</Tags.PaymentsValue>
                </Tags.RowFooter>
        </> : null}

        {paymentsIncome[0].length === 0 && paymentsOutcome[0].length === 0 ? <div style={{flex:1,display:"flex",alignItems:"center",justifyContent:"center"}}>
            <EmptyState title={i18n("home.emptyPayments")} icon={mdiCashMultiple} size={2} />
        </div> : null}
    </Tags.ContentRow>)
}

export default CardPayments;
