import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { mdiClose, mdiCheck } from '@mdi/js';

import PatientFormSchema from '../forms/patienForm';
import { getImage } from "../services/requests/statics";
import { setMessage } from "../redux/messagesReducer";
import { RequestHandler } from "../services/contracts";
import type { FormError } from "../services/contracts";
import { PatientFormType } from "../types/PatienDataType";
import { PostPatientsResponse } from "../services/contracts/patients";
import { listActivityTemplate } from "../services/requests/activities";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createPatient, getPatientData, updatePatient } from "../services/requests/patients";
import { DateFromViewToUTC, DateFromUTCToView, DateStrFromUTCToView } from '@pilarterapeutico/util';
import { Row, Card, Column, DateInput, Checkbox, MoneyInput, ProfilePicture, Breadcrumb, PageContent, TitleBar, Button, Input, Label, Select, Textarea, SelectPaged, FormBuilder, QuestionsType, Upload, FileItem, FileItemExternal, Translate, i18n, PhoneInput, RowOrColumn } from '@pilarterapeutico/components';
import * as Tags from './styles/PatientForm.styles';

type PatientFormProps = {
  id?: string;
  edit?: boolean;
}

const PatientForm:React.FC<PatientFormProps> = ({edit}:PatientFormProps) => {

  const [dataName, setDataName] = useState<string>("");
  const [dataSurname, setDataSurname] = useState<string>("");
  const [dataBirthdate, setDataBirthdate] = useState<string>("");
  const [dataPatientSince, setDataPatientSince] = useState<string>(DateFromUTCToView(new Date(), false));
  const [dataEmail, setDataEmail] = useState<string>("");
  const [dataAllowAccess, setDataAllowAccess] = useState<boolean>(false);
  const [dataPhone, setDataPhone] = useState<string>("");
  const [dataIsWhatsapp, setDataIsWhatsapp] = useState<boolean>(false);
  const [dataPhotoFile, setDataPhotoFile] = useState<File>();
  const [dataPhotoPreview, setDataPhotoPreview] = useState<string>();
  const [dataErrors, setDataErrors] = useState<FormError[]>([]);
  const [dataValue, setDataValue] = useState<number>();
  const [dataFrequency, setDataFrequency] = useState<string>("APPOINTMENT");
  const [dataDue, setDataDue] = useState<string>("SAMEDAY");
  const [dataMethod, setDataMethod] = useState<string>("MONEY");
  const [anamnesis, setAnamnesis] = useState<string>("");
  const [templates, setTemplates] = useState<{id:string, label:string}[]>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<keyof PatientFormProps>() as PatientFormProps;

  const refUpdate = useRef<boolean>(true);

  const validateAll = (data:PatientFormType) => {
    try {
      PatientFormSchema().validateSync(data, {abortEarly:false});
      return true;
    } catch (e:any) {
      if (e.inner && Array.isArray(e.inner)) {
        setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
      }
      return false;
    }
  };

  const handleCancel = () => {
    navigate(-1);
  }

  const handleSave = async () => {
    const data:PatientFormType = {
      name: dataName,
      surname: dataSurname,
      birthdate: DateFromViewToUTC(dataBirthdate),
      patientSince: DateFromViewToUTC(dataPatientSince),
      email: dataEmail,
      allow_access: dataAllowAccess,
      phone: dataPhone,
      is_whatsapp: dataIsWhatsapp,
      photo: dataPhotoFile ?? (dataPhotoPreview ? 'keep' : 'none'),
      active: true,
      default_payment_value: dataValue ?? 0,
      default_payment_frequency: dataFrequency,
      default_payment_due: dataDue,
      default_payment_method: dataMethod,
      anamnesis: anamnesis || undefined,
    };

    setDataErrors([]);

    const isValidForm = validateAll(data);
    if (!isValidForm) {
      return;
    }

    let response:RequestHandler<PostPatientsResponse>;
    if (edit) {
      data.id = params.id;
      response = await updatePatient(data);
    } else {
      response = await createPatient(data);
    }

    if (response.error) { 
      if (response.type === 'form') {
        setDataErrors((response.error ?? []) as FormError[]);
      } else {
        dispatch(setMessage({message: response.error ?? '', type: "error"}));
      }
    } else {
      dispatch(setMessage({message: i18n("patientform.saved"), type: "success"}));
      navigate('/patients/'+(edit ? params.id : response.data?.patient.id));
    }

  }

  const getFistError = useMemo(() => {
    return (key:string) => dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
  }, [dataErrors]);

  useEffect(()=>{
    (async () => {
      if (!params.id) {
        return;
      }

      let req = await getPatientData(params.id);

      if(!req || !refUpdate.current) {
        return;
      }

      setDataName(req.data?.name ?? '');
      setDataSurname(req.data?.surname ?? '');
      setDataBirthdate(DateStrFromUTCToView(req.data?.birthdate ?? '', false));
      setDataPatientSince(DateStrFromUTCToView(req.data?.patient_since ?? '', false));
      setDataEmail(req.data?.email || req.data?.email_link || '');
      setDataAllowAccess(req.data?.allow_access ?? false);
      setDataPhone(req.data?.phone ?? '');
      setDataPhotoFile(undefined);
      setDataIsWhatsapp(req.data?.is_whatsapp ?? false);
      setDataValue(parseFloat(req.data?.default_payment_value ?? '0'));
      setDataFrequency(req.data?.default_payment_frequency ?? 'APPOINTMENT');
      setDataDue(req.data?.default_payment_due ?? 'SAMEDAY');
      setDataMethod(req.data?.default_payment_method ?? 'MONEY');

      const reqPhoto = await getImage("photo/" + req.data?.photo);

      if(reqPhoto && refUpdate.current) {
        setDataPhotoPreview(reqPhoto ?? undefined);
      }


    })();

    return () => {refUpdate.current = false;};
  }, [edit, params.id]);

  useEffect(()=>{
    listActivityTemplate("anamnesis").then(req => {
        if (refUpdate.current && req.data?.templates) {
            setTemplates(req.data?.templates.map(t => ({id:t.id ?? '', label:t.name})) ?? [])
        }
    });

    return ()=>{refUpdate.current=false;};
}, []);

  return (<PageContent>
    <TitleBar>
      {edit ?
        <Breadcrumb items={{
          "/patients": i18n("patients.title"), 
          [`/patients/${location.state?.patient?.id}`]: location.state?.patient?.name
        }} current={i18n("patients.edit")} />
      :
        <Breadcrumb items={{"/patients": i18n("patients.title")}} current={i18n("patients.new")} />
      }
    </TitleBar>
    <Card title={i18n("patientView.profileTitle")}>
      <div style={{padding:'1rem'}}>
        <RowOrColumn rowAlign="flex-start" columnAlign="stretch" columnJustify="flex-start">
          <Tags.Photo>
            <ProfilePicture uid="patiente.photo" value={dataPhotoPreview} onChange={(val:File)=>{setDataPhotoFile(val); setDataPhotoPreview(undefined)}} />
          </Tags.Photo>
          <Tags.ColumnFields>
            <Tags.LabelArea><Translate path="patientView.profileData"/></Tags.LabelArea>
            <Row>
              <Tags.Field>
                <Label>
                    <Translate path="patientform.label.name"/>
                </Label>
                <Input 
                  uid='patientform.name'
                  value={dataName} 
                  formError={getFistError('name')}
                  onChange={(e)=>{setDataName((e.target as HTMLInputElement).value)}} 
                />
              </Tags.Field>
              <Tags.Field>
                <Label>
                    <Translate path="patientform.label.surname"/>
                </Label>
                <Input 
                  uid='patientform.surname'
                  value={dataSurname} 
                  formError={getFistError('surname')}
                  onChange={(e)=>{setDataSurname((e.target as HTMLInputElement).value)}} 
                />
              </Tags.Field>
            </Row>
            <Row>
              <Tags.Field>
                <Label>
                    <Translate path="patientform.label.birthdate"/>
                </Label>
                <DateInput 
                  uid='patientform.birthdate'
                  value={dataBirthdate} 
                  formError={getFistError('birthdate')}
                  onChange={(e)=>{setDataBirthdate((e.target as HTMLInputElement).value)}} 
                />
              </Tags.Field>
              <Tags.Field>
                <Label>
                  <Translate path="patientform.label.patientsince"/>
                </Label>
                <DateInput 
                  value={dataPatientSince} 
                  uid='patientform.patientsince'
                  formError={getFistError('patientSince')}
                  onChange={(e)=>{setDataPatientSince((e.target as HTMLInputElement).value)}} 
                />
              </Tags.Field>
            </Row>
            <Row align="flex-end">
              <Tags.Field style={{width:'55%'}}>
                <Label>
                    <Translate path="patientform.label.email"/>
                </Label>
                <Input 
                  uid='patientform.email'
                  value={dataEmail} 
                  formError={getFistError('email')}
                  onChange={(e)=>{setDataEmail((e.target as HTMLInputElement).value)}} 
                />
              </Tags.Field>
              <Tags.Field>
                <Checkbox 
                  uid='patientform.allowAccess'
                  label={i18n("patientform.label.allowAccess")}
                  noWrap={true}
                  checked={dataAllowAccess} 
                  onCheck={(val:boolean)=>{setDataAllowAccess(val)}} 
                />
              </Tags.Field>
            </Row>
            <Row align="flex-end">
              <Tags.Field style={{width:'55%'}}>
                <Label>
                    <Translate path="patientform.label.phone"/>
                </Label>
                <PhoneInput 
                  uid='patientform.phone'
                  formError={getFistError('phone')}
                  value={dataPhone} 
                  onChange={(e)=>{setDataPhone((e.target as HTMLInputElement).value)}} 
                />
              </Tags.Field>
              <Tags.Field>
                <Checkbox 
                  uid='patientform.isWhatsapp'
                  label={i18n("patientform.label.isWhatsapp")}
                  noWrap={true}
                  checked={dataIsWhatsapp} 
                  onCheck={(val:boolean)=>{setDataIsWhatsapp(val)}} 
                />
              </Tags.Field>
            </Row>
          </Tags.ColumnFields>
          <Tags.ColumnDefaults>
            <Tags.LabelArea><Translate path="patientView.defaultPayment.title"/></Tags.LabelArea>
            <Row>
              <Tags.Field style={{flex:1}}>
                <Label>
                    <Translate path="patientView.defaultPayment.value"/>
                </Label>
                <MoneyInput 
                  uid='patientform.value'
                  value={dataValue} 
                  onChange={(e)=>{setDataValue(e)}} 
                />
              </Tags.Field>
              <Tags.Field style={{flex:1}}>
                <Label>
                    <Translate path="patientView.defaultPayment.method"/>
                </Label>
                <Select 
                  uid='patientform.method'
                  value={dataMethod} 
                  onChange={(e)=>{setDataMethod((e.target as HTMLSelectElement).value)}} 
                >
                  <option value="MONEY">{i18n("patientView.defaultPayment.methodMONEY")}</option>
                  <option value="CREDITCARD">{i18n("patientView.defaultPayment.methodCREDITCARD")}</option>
                  <option value="PIX">{i18n("patientView.defaultPayment.methodPIX")}</option>
                </Select>
              </Tags.Field>
            </Row>
            <Row>
              <Tags.Field style={{flex:1}}>
                <Label>
                    <Translate path="patientView.defaultPayment.frequency"/>
                </Label>
                <Select 
                  uid='patientform.frequency'
                  value={dataFrequency} 
                  onChange={(e)=>{setDataFrequency((e.target as HTMLSelectElement).value)}} 
                >
                  <option value="APPOINTMENT">{i18n("patientView.defaultPayment.frequencyAPPOINTMENT")}</option>
                  <option value="MONTH">{i18n("patientView.defaultPayment.frequencyMONTH")}</option>
                  <option value="MANUAL">{i18n("patientView.defaultPayment.frequencyMANUAL")}</option>
                </Select>
              </Tags.Field>
              <Tags.Field style={{flex:1}}>
                <Label>
                    <Translate path="patientView.defaultPayment.due"/>
                </Label>
                <Select 
                  uid='patientform.due'
                  value={dataDue} 
                  onChange={(e)=>{setDataDue((e.target as HTMLSelectElement).value)}} 
                >
                  <option value="SAMEDAY">{i18n("patientView.defaultPayment.dueSAMEDAY")}</option>
                  <option value="SEVENDAY">{i18n("patientView.defaultPayment.dueSEVENDAY")}</option>
                  <option value="MONTHDAY5">{i18n("patientView.defaultPayment.dueMONTHDAY5")}</option>
                  <option value="MONTHDAY10">{i18n("patientView.defaultPayment.dueMONTHDAY10")}</option>
                  <option value="MONTHDAY15">{i18n("patientView.defaultPayment.dueMONTHDAY15")}</option>
                  <option value="MONTHDAYLAST">{i18n("patientView.defaultPayment.dueMONTHDAYLAST")}</option>
                </Select>
              </Tags.Field>
            </Row>
            {!edit ? <>
              <Tags.LabelArea><Translate path="config.template.anamnesis"/></Tags.LabelArea>
              <Row>
                <Tags.Field style={{flex:1}}>
                  <Label>
                    <Translate path="config.template.anamnesis"/>
                  </Label>
                  <Select 
                    uid='patientform.anamnesis'
                    value={anamnesis} 
                    onChange={(e)=>{setAnamnesis((e.target as HTMLSelectElement).value)}} 
                  >
                    <option value=""></option>
                    {templates.map(t => <option key={t.id} value={t.id}>{t.label}</option>)}
                  </Select>
                </Tags.Field>
              </Row>
            </> : null }
          </Tags.ColumnDefaults>
        </RowOrColumn>
      </div>
      <Tags.ButtonBar>
        <Button size={6} icon={mdiClose} onClick={()=>{handleCancel()}} color="#999999" title={i18n("cancel")} />
        <Button size={6} icon={mdiCheck} onClick={()=>{handleSave()}} color="#090" title={i18n("save")} />
      </Tags.ButtonBar>
    </Card>
  </PageContent>)
}

export default PatientForm;