import { useSelector } from 'react-redux';

import * as Tags from './styles';
import { Translate } from '../i18n/Translate';

export const Loading:React.FC<{}> = () => {

    const isLoading = useSelector<any, string[]>(s => s.loading.loading);

    return <Tags.Loading className={ isLoading && isLoading.length ? 'active' : ''}>
        <Translate path="loading" />
    </Tags.Loading>
    
}