import { useEffect, useMemo, useRef, useState } from 'react';
import Icon from '@mdi/react';
import { useDispatch } from 'react-redux';
import { mdiClose, mdiCheck, mdiTrashCan } from '@mdi/js';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import ModalDelete from '../modals/ModalDelete';
import PatienDataType from '../types/PatienDataType';
import usePatientData from '../hooks/usePatientData';
import PaymentFormSchema from '../forms/paymentForm';
import { setMessage } from '../redux/messagesReducer';
import { PaymentFormType } from '../types/PaymentDataType';
import { PostPaymentsResponse } from '../services/contracts/payments';
import { getAppointmentsWithFilter } from '../services/requests/appointments';
import { FormError, RequestHandler } from '../services/contracts';
import { hideModal, setModalAndShow } from '../redux/modalReducer';
import { createPayment, deletePayment, getPaymentData, updatePayment } from '../services/requests/payments';
import { TimeFromIntMinutesToTime, DateFromDateToUTC, DateFromISOToTimeAt, DateFromUTCToDate, DateFromUTCToView, DateFromViewToDate, DateStrFromUTCToView } from '@pilarterapeutico/util';
import { Row, Card, Column, Textarea, TitleBar, Button, DateInput, Input, Label, Select, Breadcrumb, MonthInput, MoneyInput, SelectPaged, PageContent, Translate, i18n, RowOrColumn} from '@pilarterapeutico/components';
import * as Tags from './styles/PatientForm.styles';

const PatientPaymentForm = ({edit}:{edit?:boolean}) => {

    const [dataRelatedTo, setDataRelatedTo] = useState<string>("MONTH");
    const [dataAppointmentId, setDataAppointmentId] = useState<string>("");
    const [dataAppointmentsList, setDataAppointmentList] = useState<{value:string, label:string}[]>([]);
    const [dataMonth, setDataMonth] = useState<Date>();
    const [dataDescription, setDataDescription] = useState<string>("");
    const [dataType, setDataType] = useState<string>("MONEY");
    const [dataValueTotal, setDataValueTotal] = useState<number>(0);
    const [dataValuePaid, setDataValuePaid] = useState<number>(0);
    const [dataStatus, setDataStatus] = useState<string>("PENDING");
    const [dataDueDate, setDataDueDate] = useState<Date>();
    const [dataPaidAt, setDataPaidAt] = useState<Date>();
    const [dataNotes, setDataNotes] = useState<string>("");
    const [dataErrors, setDataErrors] = useState<FormError[]>([]);
    const [patientData, setPatientData] = useState<PatienDataType>();

    const params = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const patient = usePatientData();

    const refUpdate = useRef<boolean>(true);

    const validateAll = (data:PaymentFormType) => {
        try {
            PaymentFormSchema().validateSync(data, {abortEarly:false});
            return true;
        } catch (e:any) {
            console.log(e.inner);
            if (e.inner && Array.isArray(e.inner)) {
                setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
            }
            return false;
        }
    };

    const handleSave = async () => {
        const data:PaymentFormType = {
            related_to: dataRelatedTo,
            appointment_id: dataAppointmentsList.map(a=>a.value),
            month: DateFromDateToUTC(dataMonth),
            description: dataDescription,
            type: dataType,
            value_total: dataValueTotal,
            value_paid: dataValuePaid,
            status: dataStatus,
            due_date: DateFromDateToUTC(dataDueDate),
            paid_at: DateFromDateToUTC(dataPaidAt),
            notes: dataNotes,
        };
    
        setDataErrors([]);
    
        const isValidForm = validateAll(data);
        if (!isValidForm) {
            return;
        }
    
        let response:RequestHandler<PostPaymentsResponse>;
        if (edit) {
          data.id = params.paymentId;
          response = await updatePayment(data, String(params.id));
        } else {
          response = await createPayment(data, String(params.id));
        }
    
        if (response.error) { 
            if (response.type === 'form') {
              setDataErrors((response.error ?? []) as FormError[]);
            } else {
              dispatch(setMessage({message: response.error ?? '', type: "error"}));
            }
        } else {
            dispatch(setMessage({message: i18n("patientform.saved"), type: "success"}));
            navigate(`/patients/${params.id}`,{replace:true});
        }
    }

    const handleCancel = () => {
        navigate(-1);
    }

    const handleDelete = () => {
        dispatch(setModalAndShow({
          content: <ModalDelete i18nKey={"patientView.payments"} onConfirm={handleDeleteConfirm} />,
        }))
    }

    const handleAppendAppointment = (option: {value:string, label:string}) => {
        setDataAppointmentList(o => {
            if (o.some(i => i.value === option.value)) {
                return o;
            }
            return [...o, option]
        });
        setDataAppointmentId(String(Date.now()));
    }

    const handleRemoveAppointment = (value:string) => {
        setDataAppointmentList(o => o.filter(i => i.value !== value));
    }
    
    const handleDeleteConfirm = async () => {
        dispatch(hideModal());
        const response = await deletePayment(String(params.paymentId), String(params.id));
        if (response.error) {
            dispatch(setMessage({
                message: i18n("payment.unableToRemove"),
                type: "error",
            }));
        } else {
            dispatch(setMessage({
                message: i18n("payment"),
                type: "success",
            }));
            navigate(`/patients/${params.id}`);
        }
    }

    const getFistError = useMemo(() => {
        return (key:string) => dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
    }, [dataErrors]);

    useEffect(()=>{
        (async () => {
            if (edit) {
                let req = await getPaymentData(params.paymentId ?? '0', String(params.id));

                if (req && refUpdate.current) {
                    setDataRelatedTo(req.data?.related_to ?? "");
                    setDataMonth(DateFromUTCToDate(req.data?.month));
                    setDataDescription(req.data?.description ?? "");
                    setDataType(req.data?.type ?? "");
                    setDataValueTotal(req.data?.value_total ?? 0);
                    setDataValuePaid(req.data?.value_paid ?? 0);
                    setDataStatus(req.data?.status ?? "");
                    setDataDueDate(DateFromUTCToDate(req.data?.due_date));
                    setDataPaidAt(DateFromUTCToDate(req.data?.paid_at));
                    setDataNotes(req.data?.notes ?? "");
                    setDataAppointmentList(req.data?.appointment_id?.map(a => ({value:a.id, label:DateFromISOToTimeAt(a.date) ?? ''})) ?? []);
                }
            }
        })();

        return () => {refUpdate.current = false;}
    }, [params.paymentId]);

    useEffect(()=>{
        (async () => {
          let data = await patient.getData(params.id);
          if (patient.update) {
            setPatientData(data);
          }
        })();
    
        return patient.fn;
    }, [location.state?.patient?.name]);

    useEffect(()=>{
        return () => {refUpdate.current = false;};
    }, []);

    const patientPath = `/patients/${params.id ?? ''}`;
    const breadcrumbPath:any = {};
    breadcrumbPath["/patients"] = i18n("patients.title");
    breadcrumbPath[patientPath] = patientData?.name ?? '';

    return (<PageContent>
        <TitleBar>
            <Breadcrumb items={breadcrumbPath} current={i18n("patientPayment.path")} />
        </TitleBar>
        <Card title={i18n(`patientPayment.title${edit ? 'Edit' : 'New'}`)}>
            <div style={{padding:'1rem'}}>
                <RowOrColumn rowAlign='flex-start' rowMargin="2rem" columnAlign='stretch' columnMargin='0 0 0.5rem 0'>
                    <Column align="stretch" style={{flex:1}}>
                        <Row>
                            <Tags.Field style={{flex:2}}>
                                <Label><Translate path="patientPayment.relatedTo" /></Label>
                                <Select uid="patientPayment.relatedTo" value={dataRelatedTo} onChange={(e) => {setDataRelatedTo((e.target as HTMLSelectElement).value)}} formError={getFistError('related_to')}>
                                    <option value="APPOINTMENT">{i18n("patientPayment.relatedToAppointment")}</option>
                                    <option value="MONTH">{i18n("patientPayment.relatedToMonth")}</option>
                                    <option value="OTHER">{i18n("patientPayment.relatedToOther")}</option>
                                </Select>
                            </Tags.Field>
                            {dataRelatedTo === 'APPOINTMENT' ? <Tags.Field style={{flex:3}}>
                                <Label><Translate path="patientPayment.appointment" /></Label>
                                <SelectPaged 
                                    uid="patientPayment.appointment_id" 
                                    value={dataAppointmentId} 
                                    onChange={(option) => {handleAppendAppointment(option)}}
                                    loader={async (page:number, filter:string) => {return getAppointmentsWithFilter(params.id ?? '0', page, filter)}}
                                    transform={(item:any, index:number)=>({index, value:item.id, label:i18n("patientPayment.relatedToAppointmentValue").replace("$1",DateStrFromUTCToView(item.date)).replace("$2",TimeFromIntMinutesToTime(item.time)??'')})}
                                />
                            </Tags.Field> : null}
                            {dataRelatedTo === 'MONTH' ? <Tags.Field style={{flex:3}}>
                                <Label><Translate path="patientPayment.month" /></Label>
                                <MonthInput uid="patientPayment.month" value={dataMonth} onChange={(dt:Date) => {setDataMonth(dt)}} formError={getFistError('month')}/>
                            </Tags.Field> : null}
                            {dataRelatedTo === 'OTHER' ? <Tags.Field style={{flex:3}}>
                                <Label><Translate path="patientPayment.description" /></Label>
                                <Input uid="patientPayment.description" value={dataDescription} onChange={(e) => {setDataDescription((e.target as HTMLInputElement).value)}} formError={getFistError('description')} />
                            </Tags.Field> : null}
                        </Row>
                        {dataRelatedTo === 'APPOINTMENT' ? <Column>
                            {dataAppointmentsList.length ? dataAppointmentsList.map(a => <Tags.AppointmentsItemsRow>
                                <div>{i18n("patientPayment.appointmentsAtDay")} {a.label}</div>
                                <div className='btn' onClick={()=>{handleRemoveAppointment(a.value)}}>
                                    <Icon path={mdiClose} size={0.8} />
                                </div>
                            </Tags.AppointmentsItemsRow>) : <Tags.NoAppointmens>{i18n("patientPayment.noAppointments")}</Tags.NoAppointmens>}
                            {getFistError('appointment_id') !== null ? <div>Campo obrigatório</div> : null}
                        </Column> : null}
                        <Row>
                            <Tags.Field style={{flex:2}}>
                                <Label><Translate path="patientPayment.status" /></Label>
                                <Select uid="patientPayment.status" value={dataStatus} onChange={(e) => {setDataStatus((e.target as HTMLSelectElement).value)}}>
                                    <option value="PENDING">{i18n("patientPayment.statusPending")}</option>
                                    <option value="REFUSED">{i18n("patientPayment.statusRefused")}</option>
                                    <option value="PROCESSING">{i18n("patientPayment.statusProcessing")}</option>
                                    <option value="PAID">{i18n("patientPayment.statusPaid")}</option>
                                    <option value="CANCELLED">{i18n("patientPayment.statusCancelled")}</option>
                                </Select>
                            </Tags.Field>
                            <Tags.Field style={{flex:3}}>
                                <Label><Translate path="patientPayment.dueDate" /></Label>
                                <DateInput uid="patientPayment.dueDate" value={DateFromUTCToView(dataDueDate)} onChange={(e) => {setDataDueDate(DateFromViewToDate((e.target as HTMLInputElement).value))}} formError={getFistError('due_date')} />
                            </Tags.Field>
                        </Row>
                        <Row>
                            <Tags.Field style={{flex:3}}>
                                <Label><Translate path="patientPayment.type" /></Label>
                                <Select uid="patientPayment.type" value={dataType} onChange={(e) => {setDataType((e.target as HTMLSelectElement).value)}} formError={getFistError('type')} >
                                    <option value="MONEY">{i18n("patientPayment.typesValues.money")}</option>
                                    <option value="CREDITCARD">{i18n("patientPayment.typesValues.creditcard")}</option>
                                    <option value="PIX">{i18n("patientPayment.typesValues.pix")}</option>
                                </Select>
                            </Tags.Field>
                            <Tags.Field style={{flex:2}}>
                                <Label><Translate path="patientPayment.valueTotal" /></Label>
                                <MoneyInput uid="patientPayment.valueTotal" value={dataValueTotal} onChange={(v:number) => {setDataValueTotal(v)}} formError={getFistError('value_total')} />
                            </Tags.Field>
                        </Row>
                    </Column>
                    <Column align="stretch" justify='flex-start' style={{flex:2}}>
                        <Tags.Field>
                            <Label><Translate path="patientPayment.notes" /></Label>
                            <Textarea uid="patientPayment.notes" placeholder={i18n("patientPayment.notesPlaceholder")} value={dataNotes} onChange={(e) => {setDataNotes((e.target as HTMLTextAreaElement).value)}} formError={getFistError('notes')} />
                        </Tags.Field>
                    </Column>
                </RowOrColumn>
            </div>
            <Tags.ButtonBar style={{justifyContent:'space-between'}}>
                <Tags.ButtonGroup>
                    {edit ?
                        <Button size={6} icon={mdiTrashCan} onClick={()=>{handleDelete()}} color="#ff4444" title={i18n("delete")} />
                    : null}
                </Tags.ButtonGroup>
                <Tags.ButtonGroup>
                    <Button size={6} icon={mdiClose} onClick={()=>{handleCancel()}} color="#999999" title={i18n("cancel")} />
                    <Button size={6} icon={mdiCheck} onClick={()=>{handleSave()}} color="#090" title={i18n("save")} />
                </Tags.ButtonGroup>
            </Tags.ButtonBar>
        </Card>
    </PageContent>)
}

export default PatientPaymentForm;