import { useCallback, useEffect, useMemo, useState } from "react";
import Icon from "@mdi/react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { mdiAttachment, mdiTextBox, mdiFormatListText, mdiPlus, mdiPencil, mdiCloseCircleOutline, mdiCalendarClock, mdiHeadCheck, mdiChat, mdiCashMultiple, mdiCheckCircleOutline, mdiClose, mdiHelp, mdiCheck  } from '@mdi/js';


import ModalDelete from "../modals/ModalDelete";
import PatienDataType from "../types/PatienDataType";
import useMobileDetect from "../hooks/useMobile";
import { getImage } from "../services/requests/statics";
import { setMessage } from "../redux/messagesReducer";
import { getPayments } from "../services/requests/payments";
import { iconWhatsapp } from "../forms/icons";
import { getSchedules } from "../services/requests/schedules";
import { getActivities } from "../services/requests/activities";
import { RequestHandler } from "../services/contracts";
import { getAppointments } from "../services/requests/appointments";
import { ActivityItemType } from "../types/ActivityDataType";
import { GetActivitiesResponse } from "../services/contracts/activities";
import { GetPatientDataResponse } from "../services/contracts/patients";
import { hideModal, setModalAndShow } from "../redux/modalReducer";
import { GetPaymentsResponse, PaymentsListItemsType } from "../services/contracts/payments";
import { GetSchedulesResponse, SchedulesListItemsType } from "../services/contracts/schedules";
import { activePatient, getPatientData, inactivePatient } from "../services/requests/patients";
import { AppointmentsListItemsType, GetAppointmentsResponse } from "../services/contracts/appointments";
import { Row, Card, Column, Breadcrumb, Paginator, TitleBar, Button, Tag, EmptyState, PageContent, Translate as T, i18n, RowOrColumn } from '@pilarterapeutico/components';
import { TimeFromIntMinutesToTime, MoneyFromNumberToString, CalculateYearsFromDate, DayFromInternal, MonthNameShortFromInternal, DayNameFromInternal, DateStrFromUTCToView, DateFromUTCToDate, DateFromDateToMonthView } from '@pilarterapeutico/util';
import * as Tags from './styles/PatientView.styles';

type PatientViewParams = {
  id: string;
}

const PatientView = () => {

  const params = useParams<keyof PatientViewParams>() as PatientViewParams;

  const [patient, setPatientData] = useState<PatienDataType>();
  const [patientPhoto, setPatientPhoto] = useState<string>("");
  
  const [schedulesList, setSchedulesList] = useState<SchedulesListItemsType[]>([]);
  const [schedulesPage, setSchedulesPage] = useState<number>(1);
  const [schedulesTotal, setSchedulesTotal] = useState<number>(1);

  const [appointmentsPrevList, setAppointmentsPrevList] = useState<AppointmentsListItemsType[]>([]);
  const [appointmentsPrevPage, setAppointmentsPrevPage] = useState<number>(1);
  const [appointmentsPrevTotal, setAppointmentsPrevTotal] = useState<number>(1);
  
  const [appointmentsNextList, setAppointmentsNextList] = useState<AppointmentsListItemsType[]>([]);
  const [appointmentsNextPage, setAppointmentsNextPage] = useState<number>(1);
  const [appointmentsNextTotal, setAppointmentsNextTotal] = useState<number>(1);

  const [activitiesList, setActivitiesList] = useState<ActivityItemType[]>([]);
  const [activitiesPage, setActivitiesPage] = useState<number>(1);
  const [activitiesTotal, setActivitiesTotal] = useState<number>(1);

  const [paymentsList, setPaymentsList] = useState<PaymentsListItemsType[]>([]);
  const [paymentsPage, setPaymentsPage] = useState<number>(1);
  const [paymentsTotal, setPaymentsTotal] = useState<number>(1);

  const mobileDetect = useMobileDetect();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getStatusColor = (status:string):string => {
    switch(status) {
      case 'WAITING': return "#999";
      case 'CONFIRMED': return "#090";
      case 'DONE': return "#27f";
      case 'CANCELLED': return "#f93";
      case 'LOST': return "#f33";
      default: return "#999";
    }
  }

  const getConfirmByPatientColor = (confirm?:number):string => {
    switch(confirm) {
      case 1: return "var(--theme-color-value-green)";
      case 0: return "var(--theme-color-value-red)";
      default: return "var(--theme-color-value-gray)";
    }
  }

  const getAttachmentIcon = (type:string, done?:number):string => {
    if (done === 1) {
      return mdiCheck;
    }
    
    switch(type) {
      case "file": return mdiAttachment;
      case "form": return mdiFormatListText;
      default: return mdiTextBox;
    }
  }

  const getPaymentDescription = (item:PaymentsListItemsType):string => {
    
    switch(item.related_to) {
      case 'APPOINTMENT': return (item.appointment_id ?? []).length === 1 ? i18n("patientView.payments.appointmentItem").replace("$1", DateStrFromUTCToView(item.appointment_id[0].date)) : i18n(`patientView.payments.appointmentList`).replace("$1", String(item.appointment_id?.length ?? '0'));
      case 'MONTH': return DateFromDateToMonthView(new Date(item.month ?? ''));
      default: return item.description;
    }
  }

  const getActivityRelated = (related_to:{id:string, date:string}[]):string => {
    return related_to.length === 1 ? i18n("patientView.payments.appointmentItem").replace("$1", DateStrFromUTCToView(related_to[0].date)) : i18n(`patientView.payments.appointmentList`).replace("$1", String(related_to.length ?? '0'));
  }

  const handleChangeSchedulesPage = useCallback((page: number) => {
    let update = true;

    setSchedulesPage(page);

    (async () => {      
      const schedulesReq = await getSchedules(params.id, page);
      if (update && schedulesReq.data && schedulesReq.data.items) {
        setSchedulesList(schedulesReq.data.items);
        setSchedulesTotal(Math.ceil(schedulesReq.data.total/5));
      }
    })();

    return () => {
      update = false;
    }
  }, [params.id]);

  const handleChangeAppointmentsPrevPage = useCallback((page: number) => {
    let update = true;

    setAppointmentsPrevPage(page);

    (async () => {
      const appointmentsReq = await getAppointments(params.id, 'prev', page);
      const appointments = appointmentsReq as RequestHandler<GetAppointmentsResponse>;

      if (update && appointments.data && appointments.data.items) {
        setAppointmentsPrevList(appointments.data.items[0] ?? []);
        setAppointmentsPrevTotal(Math.ceil(appointments.data.total[0]/5));
      }
    })();

    return () => {
      update = false;
    }
  }, [params.id]);

  const handleChangeAppointmentsNextPage = useCallback((page: number) => {
    let update = true;

    setAppointmentsNextPage(page);

    (async () => {
      const appointmentsReq = await getAppointments(params.id, 'next', page);
      const appointments = appointmentsReq as RequestHandler<GetAppointmentsResponse>;

      if (update && appointments.data && appointments.data.items) {
        setAppointmentsNextList(appointments.data.items[0] ?? []);
        setAppointmentsNextTotal(Math.ceil(appointments.data.total[1]/2));
      }
    })();

    return () => {
      update = false;
    }
  }, [params.id]);

  const handleChangeActivitiesPage = useCallback((page: number) => {
    let update = true;

    setActivitiesPage(page);

    (async () => {      
      const activitiesReq = await getActivities(params.id, page);
      if (update && activitiesReq.data && activitiesReq.data.items) {
        setActivitiesList(activitiesReq.data.items);
        setActivitiesTotal(Math.ceil(activitiesReq.data.total/4));
      }
    })();

    return () => {
      update = false;
    }
  }, [params.id]);

  const handleChangePaymentsPage = useCallback((page: number) => {
    let update = true;

    setPaymentsPage(page);

    (async () => {      
      const paymentsReq = await getPayments(params.id, page);
      if (update && paymentsReq.data && paymentsReq.data.items) {
        setPaymentsList(paymentsReq.data.items);
        setPaymentsTotal(Math.ceil(paymentsReq.data.total/5));
      }
    })();

    return () => {
      update = false;
    }
  }, [params.id]);

  const handleGoToEdit = useCallback(() => {
    navigate(`/patients/${params.id}/edit`, {replace: false, state: {patient}});
  }, [patient]);

  const handleGoToScheduleAdd = useCallback(() => {
    navigate(`/patients/${params.id}/schedule/new`, {replace: false, state: {patient}});
  }, [patient]);

  const handleGoToScheduleEdit = useCallback((id: number) => {
    navigate(`/patients/${params.id}/schedule/${id}/edit`, {replace: false, state: {patient}});
  }, [patient]);

  const handleGoToActivityAdd = useCallback(() => {
    navigate(`/patients/${params.id}/activity/new`, {replace: false, state: {patient}});
  }, [patient]);

  const handleGoToActivityEdit = useCallback((id?: string) => {
    navigate(`/patients/${params.id}/activity/${id}`, {replace: false, state: {patient}});
  }, [patient]);

  const handleGoToAppointmentAdd = useCallback(() => {
    navigate(`/patients/${params.id}/appointment/new`, {replace: false, state: {patient}});
  }, [patient]);

  const handleGoToAppointmentEdit = useCallback((id: string) => {
    navigate(`/patients/${params.id}/appointment/${id}`, {replace: false, state: {patient}});
  }, [patient]);

  const handleGoToPaymentAdd = useCallback(() => {
    navigate(`/patients/${params.id}/payment/new`, {replace: false, state: {patient}});
  }, [patient]);

  const handleGoToPaymentEdit = useCallback((id: string) => {
    navigate(`/patients/${params.id}/payment/${id}`, {replace: false, state: {patient}});
  }, [patient]);

  const handleConfirmInactive = () => {
      dispatch(setModalAndShow({
        content: <ModalDelete i18nKey={"patientView.inactive"} confirmLabel={i18n("patientView.inactive.inactiveOk")} icon={mdiCloseCircleOutline} onConfirm={()=>handlePostInactive(false)} />,
      }))
  };

  const handleConfirmActive = () => {
      dispatch(setModalAndShow({
        content: <ModalDelete color="var(--theme-color-green)" i18nKey={"patientView.active"} confirmLabel={i18n("patientView.active.activeOk")} icon={mdiCheckCircleOutline} onConfirm={()=>handlePostInactive(true)} />,
      }))
  };

  const handlePostInactive = async (setActiveTo:boolean) => {
    dispatch(hideModal());
    const response = await (setActiveTo ? activePatient(params.id) : inactivePatient(params.id));
    if (response.error) {
        dispatch(setMessage({
            message: i18n(`patientView.unableTo${setActiveTo ? 'Active' : 'Inactive'}`),
            type: "error",
        }));
    } else {
        dispatch(setMessage({
            message: i18n(`patientView.${setActiveTo ? 'active' : 'inactive'}Success`),
            type: "success",
        }));
        setPatientData((s:PatienDataType) => ({...s, active: setActiveTo ? 1 : 0}));
    }
  }

  const getScheduleListing = useMemo(()=>{
    const format = (schedule: SchedulesListItemsType) => {
      let date = DateFromUTCToDate(schedule.start_date);
      let repeatValue = schedule.repeat.toLowerCase();
      let repeat = i18n(`patientSchedule.repeatValues.${repeatValue}`);
      let weekday = i18n(`dates.daynameshort.${date?.getUTCDay()}`);

      let timeStart = TimeFromIntMinutesToTime(schedule.start_time) ?? '00h00';
      let timeEnd = TimeFromIntMinutesToTime(schedule.end_time) ?? '23h59';

      return [
        <Tags.TableColumn width="50%" key="col1" >{repeat}</Tags.TableColumn>,
        <Tags.TableColumn className="RowOrColumn" key="col2"><div>{weekday}</div> <Tags.DateDetail>{DateStrFromUTCToView(schedule.start_date, false)}</Tags.DateDetail></Tags.TableColumn>,,
        <Tags.TableColumn className="RowOrColumn" width="7rem" mWidth="5rem" key="col3"><div>{timeStart}</div><Tags.HideMobile> - </Tags.HideMobile><div>{timeEnd}</div></Tags.TableColumn>
      ];
    }

    return schedulesList.map(schedule => <Tags.TableRow key={schedule.id} onClick={()=>handleGoToScheduleEdit(schedule.id)} >
      {format(schedule)}
    </Tags.TableRow>);
  }, [schedulesList]);

  const renderScheduleAndAppointments = useMemo(()=>{
    return [
      <Card key="schedule" title={[
        <div key="title">{i18n("patientView.scheduleTitle")}</div>,
        <Button color="#5300A6" icon={mdiPlus} onClick={handleGoToScheduleAdd} key="button"/>
      ]}>
        {schedulesList.length ? <>
          <Tags.TableHeader>
            <Tags.TableColumn width="50%">
              <Tags.TableHeaderLabel>
                <T path="patientSchedule.repeat" />
              </Tags.TableHeaderLabel>
            </Tags.TableColumn>
            <Tags.TableColumn>
              <Tags.TableHeaderLabel>
                <T path="patientSchedule.weekdays" />
              </Tags.TableHeaderLabel>
            </Tags.TableColumn>
            <Tags.TableColumn width="7rem" mWidth="5rem">
              <Tags.TableHeaderLabel>
                <T path="patientSchedule.time" />
              </Tags.TableHeaderLabel>
            </Tags.TableColumn>
          </Tags.TableHeader>
          {schedulesList?.length ? getScheduleListing : null}
          <Tags.FillSpace />
        </> : <EmptyState size={2} title={i18n("patientView.scheduleNoData")} icon={mdiCalendarClock} />}
        <Tags.TableFooter>
          <Paginator page={schedulesPage} total={schedulesTotal} onPageChange={handleChangeSchedulesPage} />
        </Tags.TableFooter>
      </Card>,
      <Card key="appointment" title={[
        <div key="title">{i18n("patientView.appointmentTitle")}</div>,
        <Button color="#5300A6" icon={mdiPlus} onClick={handleGoToAppointmentAdd} key="button"/>
      ]}>
        <Tags.TableHeader>
          <Tags.TableColumn>
            <Tags.TableHeaderLabel>
              <T path="patientView.appointments.past" />
            </Tags.TableHeaderLabel>
          </Tags.TableColumn>
        </Tags.TableHeader>
        {appointmentsPrevList.length > 0 ? <>
          {appointmentsPrevList.map((item:AppointmentsListItemsType, index:number) => <Tags.TableRow key={index} onClick={(e)=>handleGoToAppointmentEdit(item.id)}>
            <Tags.TableColumn width="10rem">{DateStrFromUTCToView(item.date, false)} {TimeFromIntMinutesToTime(item.time)}</Tags.TableColumn>
            <Tags.TableColumn><T path={`patientView.appointments.status${item.status.toLocaleLowerCase()}`} /></Tags.TableColumn>
          </Tags.TableRow>)}
        </> : <EmptyState size={2} title={i18n("patientView.appointmentNoData")} icon={mdiChat} />}
        <Tags.TableFooter style={{marginBottom:'2px'}}>
          <Paginator page={appointmentsPrevPage} total={appointmentsPrevTotal} onPageChange={handleChangeAppointmentsPrevPage} />
        </Tags.TableFooter>
        <Tags.TableHeader>
          <Tags.TableColumn width="10rem">
            <Tags.TableHeaderLabel>
              <T path="patientView.appointments.next" />
            </Tags.TableHeaderLabel>
          </Tags.TableColumn>
        </Tags.TableHeader>
        {appointmentsNextList.length > 0 ? <>
          <Tags.ContentGrid>
            {appointmentsNextList.map((item:AppointmentsListItemsType, index:number) => <Tags.AppointmentItem key={index} onClick={(e)=>handleGoToAppointmentEdit(item.id)}>
              <Row>
                <Column style={{paddingRight: '0.5rem', borderRight: '1px solid var(--theme-color-font-empty, #333)'}}>
                  <Tags.AppointmentDay>{DayFromInternal(item.date.substring(0,10))}</Tags.AppointmentDay>
                  <Tags.AppointmentMonth>{MonthNameShortFromInternal(item.date.substring(0,10), i18n)}</Tags.AppointmentMonth>
                </Column>
                <Column align="flex-start" justify="center">
                  <Tags.AppointmentDate>{DayNameFromInternal(item.date.substring(0,10), i18n)}, {TimeFromIntMinutesToTime(item.time)}</Tags.AppointmentDate>
                  <Row align="center" justify="flex-start">
                    <Tags.AppointmentStatus color={getStatusColor(item.status ?? '')}><T path={"patientView.scheduleStatus."+item.status.toLowerCase()} /></Tags.AppointmentStatus>
                    <Tags.AppointmentConfirmByPatient color={getConfirmByPatientColor(item.confirm_by_patient ?? '')}>
                      {item.confirm_by_patient === 1 ? <Icon size={0.75} path={mdiCheck} /> : null}
                      {item.confirm_by_patient === 0 ? <Icon size={0.75} path={mdiClose} /> : null}
                      {item.confirm_by_patient === null ? <Icon size={0.75} path={mdiHelp} /> : null}
                    </Tags.AppointmentConfirmByPatient>
                  </Row>
                </Column>
              </Row>
            </Tags.AppointmentItem>)}
          </Tags.ContentGrid>
        </> : <EmptyState size={2} title={i18n("patientView.appointmentNextNoData")} icon={mdiChat} />}
        <Tags.TableFooter style={{marginBottom:'2px'}}>
          <Paginator page={appointmentsNextPage} total={appointmentsNextTotal} onPageChange={handleChangeAppointmentsNextPage} />
        </Tags.TableFooter>
      </Card>
    ];
  },[handleGoToScheduleAdd, schedulesList, appointmentsPrevList, appointmentsPrevPage, appointmentsPrevTotal, appointmentsNextList, appointmentsNextPage, appointmentsNextTotal]);

  const renderActivitiesAndPayments = useMemo(()=>{
    return [
      <Card key="activities" title={[
        <div key="title">{i18n("patientView.activitiesTitle")}</div>,
        <Button color="#5300A6" icon={mdiPlus} onClick={handleGoToActivityAdd} key="button"/>
      ]}>
        {activitiesList.length ? <>
          <Tags.ContentGrid>
            <Tags.ContentGridTop>
              {activitiesList.map((item:ActivityItemType, index:number) => <Tags.ActivityItem key={index} onClick={()=>{handleGoToActivityEdit(item.id)}}>
                <Row>
                  <Icon size={1} path={getAttachmentIcon(item.type, item.done)} style={{marginRight:"0.3rem", minWidth:"1.6rem"}} />
                  <Column align="flex-start" style={{flex:1}}>
                    <Tags.ActivityName>{item.name}</Tags.ActivityName>
                    <Tags.ActivityDate>{getActivityRelated(item.appointment_id ?? [])}</Tags.ActivityDate>
                  </Column>
                </Row>
              </Tags.ActivityItem>)}
            </Tags.ContentGridTop>
          </Tags.ContentGrid>
        </> : <EmptyState size={2} margin='0 0 1rem 0' title={i18n("patientView.activitiesNoData")} icon={mdiHeadCheck} />}
        <Tags.TableFooter>
          <Paginator page={activitiesPage} total={activitiesTotal} onPageChange={handleChangeActivitiesPage} />
        </Tags.TableFooter>
      </Card>,
      <Card key="payment" title={[
        <div key="title">{i18n("patientView.paymentTitle")}</div>,
        <Button color="#5300A6" icon={mdiPlus} onClick={handleGoToPaymentAdd} key="button"/>
      ]}>
        {paymentsList.length ? <>
          <Tags.TableHeader>
            <Tags.TableColumn width="6rem">
              <Tags.TableHeaderLabel>
                <T path="patientView.payments.value" />
              </Tags.TableHeaderLabel>
            </Tags.TableColumn>
            <Tags.TableColumn>
              <Tags.TableHeaderLabel>
                <T path="patientView.payments.description" />
              </Tags.TableHeaderLabel>
            </Tags.TableColumn>
            <Tags.TableColumn width="8rem">
              <Tags.TableHeaderLabel>
                <T path="patientView.payments.status" />
              </Tags.TableHeaderLabel>
            </Tags.TableColumn>
          </Tags.TableHeader>
          { paymentsList.map((item:PaymentsListItemsType, index:number) => <Tags.TableRow className={item.status.toLowerCase()} key={index} onClick={()=>{handleGoToPaymentEdit(item.id ?? '0')}}>
            <Tags.TableColumn width="6rem">{MoneyFromNumberToString(item.value_total)}</Tags.TableColumn>
            <Tags.TableColumn>{getPaymentDescription(item)}</Tags.TableColumn>
            <Tags.TableColumn width="8rem">
              <Tags.PaymentsStatus className={`status${item.status}`}>
                <T path={`patientView.payments.status${item.status}`} />
              </Tags.PaymentsStatus>
            </Tags.TableColumn>
          </Tags.TableRow>)}
          <Tags.FillSpace />
        </> : <EmptyState size={2} title={i18n("patientView.paymentNoData")} icon={mdiCashMultiple} />}
        <Tags.TableFooter>
          <Paginator page={paymentsPage} total={paymentsTotal} onPageChange={handleChangePaymentsPage} />
        </Tags.TableFooter>
      </Card>
    ];
  },[patient, activitiesList, activitiesPage, activitiesTotal, paymentsList, paymentsPage, paymentsTotal, handleChangeActivitiesPage]);

  const renderProfileData = useMemo(()=>{
    return [
      <Tags.Photo key='photo'>
        {patientPhoto ? <Tags.Image src={patientPhoto} /> : null}
      </Tags.Photo>,
      <Column style={{flex:1}} align="stretch" justify="flex-start"  key={'data'}>
        <Tags.Name>
          <Tags.NameValue>{patient?.name} {patient?.surname}</Tags.NameValue>
          {patient?.active ? <Tag color="#008C00" ><T path="active" /></Tag> : <Tag color="#FF3333" ><T path="inactive" /></Tag>}
        </Tags.Name>
        <RowOrColumn columnAlign="stretch">
          <Column style={{flex:3}} align="flex-start" justify="flex-start">
            <Tags.ValueRow>
              <Tags.Label><T path="patientView.labelAge" /></Tags.Label>
              <Tags.Value>{patient?.birthdate ? (CalculateYearsFromDate(patient?.birthdate) + " " + i18n("patientView.labelYearsOld")) : null}</Tags.Value>
            </Tags.ValueRow>
            <Tags.ValueRow>
              <Tags.Label><T path="patientView.labelBirthdate" /></Tags.Label>
              <Tags.Value>{DateStrFromUTCToView(patient?.birthdate ?? '', false)}</Tags.Value>
            </Tags.ValueRow>
            <Tags.ValueRow>
              <Tags.Label><T path="patientView.labelEmail" /></Tags.Label>
              <Tags.Value>{patient?.email}</Tags.Value>
            </Tags.ValueRow>
            <Tags.ValueRow>
              <Tags.Label><T path="patientView.labelAllowAccess" /></Tags.Label>
              <Tags.Value>{patient?.allow_access ? <Tag color="#008C00" ><T path="yes" /></Tag> : <Tag color="#FF3333" ><T path="no" /></Tag>}</Tags.Value>
            </Tags.ValueRow>
            <Tags.ValueRow>
              <Tags.Label><T path="patientView.labelPhone" /></Tags.Label>
              <Tags.Value>{patient?.is_whatsapp ? <Tags.WpLink target="_blank" href={"https://wa.me/55"+patient?.phone.replace(/\D/gi,'')}>{patient?.phone}<Icon path={iconWhatsapp} size={0.75} /></Tags.WpLink> : patient?.phone}</Tags.Value>
            </Tags.ValueRow>
          </Column>
          <Column style={{flex:2}} align="flex-start" justify="flex-start">
            <Tags.ValueRow>
              <Tags.Label><T path="patientView.defaultPayment.value" /></Tags.Label>
              <Tags.Value>{MoneyFromNumberToString(parseFloat(patient?.default_payment_value ?? '0'))}</Tags.Value>
            </Tags.ValueRow>
            <Tags.ValueRow>
              <Tags.Label><T path="patientView.defaultPayment.frequency" /></Tags.Label>
              <Tags.Value>{i18n('patientView.defaultPayment.frequency'+(patient?.default_payment_frequency ?? ''))}</Tags.Value>
            </Tags.ValueRow>
            <Tags.ValueRow>
              <Tags.Label><T path="patientView.defaultPayment.due" /></Tags.Label>
              <Tags.Value>{i18n("patientView.defaultPayment.due" + (patient?.default_payment_due ?? ''))}</Tags.Value>
            </Tags.ValueRow>
            <Tags.ValueRow>
              <Tags.Label><T path="patientView.defaultPayment.method" /></Tags.Label>
              <Tags.Value>{i18n("patientView.defaultPayment.method" + (patient?.default_payment_method ?? ''))}</Tags.Value>
            </Tags.ValueRow>
            <Tags.ValueRow>
              <Tags.Label><T path="patientView.labelPatientSince" /></Tags.Label>
              <Tags.Value>{DateStrFromUTCToView(patient?.patientSince ?? '', false)}</Tags.Value>
            </Tags.ValueRow>
          </Column>
        </RowOrColumn>
      </Column>
    ];
  },[patient, patientPhoto]);

  useEffect(() => {
    let update = true;

    (async () => {
      const patientReq = getPatientData(params.id);
      const schedulesReq = getSchedules(params.id);
      const appointmentsReq = getAppointments(params.id, 'both');
      const activitiesReq = getActivities(params.id);
      const paymentsReq = getPayments(params.id);

      Promise.all<RequestHandler<any>>([patientReq, schedulesReq, appointmentsReq, activitiesReq, paymentsReq]).then(async (results) => {
        const patient = results[0] as RequestHandler<GetPatientDataResponse>;
        const schedules = results[1] as RequestHandler<GetSchedulesResponse>;
        const appointments = results[2] as RequestHandler<GetAppointmentsResponse>;
        const activities = results[3] as RequestHandler<GetActivitiesResponse>;
        const payments = results[4] as RequestHandler<GetPaymentsResponse>;

        if (update && patient.data) {
          setPatientData({
            ...patient.data,
            patientSince: patient.data.patient_since,
          });
  
          if (patient.data?.photo) {
            const photo = await getImage(`photo/${patient.data?.photo}`); 
            if (update) {
              setPatientPhoto(photo);
            }
          }
        }

        if (update && schedules.data && schedules.data.items) {
          setSchedulesList(schedules.data.items);
          setSchedulesTotal(Math.ceil(schedules.data.total/5));
        }

        if (update && activities.data && activities.data.items) {
          setActivitiesList(activities.data.items);
          setActivitiesTotal(Math.ceil(activities.data.total/4));
        }

        if (update && payments.data && payments.data.items) {
          setPaymentsList(payments.data.items);
          setPaymentsTotal(Math.ceil(payments.data.total/5));
        }

        if (update && appointments.data && appointments.data.items) {
          setAppointmentsPrevList(appointments.data.items[0] ?? []);
          setAppointmentsNextList(appointments.data.items[1] ?? []);
          setAppointmentsPrevTotal(Math.ceil(appointments.data.total[0]/5));
          setAppointmentsNextTotal(Math.ceil(appointments.data.total[1]/2));
        }
      });

    })();

    return () => {
      update = false;
    };
  }, []);

  return (<PageContent>
    <TitleBar>
      <Breadcrumb items={{"/patients": i18n("patients.title")}} current={patient ? patient.name : ''} />
      <Tags.RowButtons>
        <Button color="#0250C7" title={i18n("patientView.buttonEdit")} icon={mdiPencil} onClick={handleGoToEdit} />
        {patient?.active === 1 ? <Button color="#BF0000" title={i18n("patientView.buttonInactive")} icon={mdiCloseCircleOutline } onClick={handleConfirmInactive} /> : null }
        {patient?.active === 0 ? <Button color="var(--theme-color-green)" title={i18n("patientView.buttonActive")} icon={mdiCheckCircleOutline } onClick={handleConfirmActive} /> : null }
      </Tags.RowButtons>
    </TitleBar>
    <Card title={i18n("patientView.profileTitle")} key='profile'>
      <RowOrColumn style={{padding:'1.25rem'}} columnAlign="stretch">
        {renderProfileData}
      </RowOrColumn>
    </Card>
    <RowOrColumn rowAlign="stretch" rowMargin="1rem">
      {renderScheduleAndAppointments}
    </RowOrColumn>
    <RowOrColumn rowAlign="stretch" rowMargin="1rem">
      {renderActivitiesAndPayments}
    </RowOrColumn>
  </PageContent>)
}

export default PatientView;