import axios from "axios";

import URLs from '../../urls';
import store from '../../redux/stores';
import { RequestHandler } from '../contracts';
import { ScheduleFormType } from "../../types/ScheduleDataType";
import { GetScheduleDataResponse, GetSchedulesResponse, PostScheduleResponse } from '../contracts/schedules';
import { startLoading, stopLoading} from '../../redux/loadingReducer';

export const getSchedules = async (patientId: string, page?: number): Promise<RequestHandler<GetSchedulesResponse>> => {
    const result = new RequestHandler<GetSchedulesResponse>();
    try {
        store.dispatch(startLoading({key:'schedule.getSchedules'}));
        const data = page ? {page} : {};
        const res = await axios.get(URLs.patients.schedules.index(patientId), {params: data})
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'schedule.getSchedules'}));
    }
    return result;
}

export const createSchedule = async (data: ScheduleFormType): Promise<RequestHandler<PostScheduleResponse>> => {
    const result = new RequestHandler<PostScheduleResponse>();
    try {
        store.dispatch(startLoading({key:'schedule.createSchedule'}));
        
        const res = await axios.post(URLs.patients.schedules.index(data.patient_id), data);

        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'schedule.createSchedule'}));
    }
    return result;
}

export const updateSchedule = async (data: ScheduleFormType): Promise<RequestHandler<PostScheduleResponse>> => {
    const result = new RequestHandler<PostScheduleResponse>();
    try {
        store.dispatch(startLoading({key:'schedule.updateSchedule'}));
        
        const res = await axios.put(URLs.patients.schedules.update(data.id ?? '0', data.patient_id), data);

        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'schedule.updateSchedule'}));
    }
    return result;
}

export const getScheduleData = async (id: string, patient_id: string): Promise<RequestHandler<GetScheduleDataResponse>> => {
    const result = new RequestHandler<GetScheduleDataResponse>();
    try {
        store.dispatch(startLoading({key:'schedule.getScheduleData'}));
        const res = await axios.get(URLs.patients.schedules.read(id, patient_id))
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'schedule.getScheduleData'}));
    }
    return result;
}

export const deleteSchedule = async (id: string, patient_id: string, data: any): Promise<RequestHandler<PostScheduleResponse>> => {
    const result = new RequestHandler<PostScheduleResponse>();
    try {
        store.dispatch(startLoading({key:'schedule.deleteSchedule'}));
        const res = await axios.delete(URLs.patients.schedules.delete(id, patient_id), {data})
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'schedule.deleteSchedule'}));
    }
    return result;
}