import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { mdiAccountMultiple, mdiCreditCardOutline, mdiCalendarCheck, mdiCog } from "@mdi/js";

import locale from "../locale";
import axios from '../services/requests/interceptors';
import ModalHelp from "../modals/ModalHelp";
import { logout } from '../redux/authReducer';
import { Content } from './styles/Home.styles';
import { getImage } from "../services/requests/statics";
import { AppState } from '../redux/stores';
import { setMessage } from "../redux/messagesReducer";
import { sendMessage } from "../services/requests/home";
import { getSearchData } from "../services/requests/search";
import { GetSearchResponse } from "../services/contracts/search";
import { closeMenu, openMenu } from '../redux/menuReducer';
import { hideModal, setModalAndShow } from "../redux/modalReducer";
import { Menu, Modal, Topbar, Message, Loading, i18n } from "@pilarterapeutico/components";

let menuItems = [
    {key:'/patients', label:'patients', icon:mdiAccountMultiple},
    {key:'/payments', label:'payments', icon:mdiCreditCardOutline},
    {key:'/calendar', label:'calendar', icon:mdiCalendarCheck},
    {key:'/config', label:'config', icon:mdiCog},
];

const Home = () => {

    const [searchResults, setSearchResults] = useState<GetSearchResponse>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = useSelector<AppState>(s => s.auth.accessToken);
    const lang = useSelector<AppState,string>(s => s.auth.lang);
    const theme = useSelector<AppState,string>(s => s.auth.theme);
    const refUpdate = useRef<boolean>(true);

    const handleSearch = async (val:string) => {
        let req = await getSearchData(val);

        if (refUpdate.current && req && req.data) {
            setSearchResults(req.data);
        }
    };

    const handleOpenMenu = async () => {
        dispatch(openMenu());
    };

    const handleOpenHelp = () => {
        dispatch(setModalAndShow({
            content: <ModalHelp onConfirm={handleSendMessage} />,
        }));
    };

    const handleSendMessage = (data: {message:string, subject: string}) => {
        return new Promise<void>((resolve, reject) => {
            if (!data || !data.message || !data.subject || data.message.length < 1 || data.subject.length < 1) {
                dispatch(setMessage({type:'error', message: i18n('help.error2')}));
                reject();
            }

            sendMessage(data).then((res) => {
                if (res.error) {
                    dispatch(setMessage({type:'error', message: i18n('help.error')}));
                    reject();
                } else {
                    dispatch(setMessage({type:'success', message: i18n('help.success')}));
                    dispatch(hideModal());
                    resolve();
                }
            }).catch((err) => {
                dispatch(setMessage({type:'error', message: i18n('help.error')}));
                reject();
            });
        });
    };

    const handleCloseMenu = async () => {
        dispatch(closeMenu());
    };

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token, navigate]);

    useEffect(() => {
        const body = document.querySelector("body");
        if (!body) {return}

        body.classList.remove('theme-light');
        body.classList.remove('theme-dark');
        if (theme) {
            body.classList.add('theme-'+theme)
        }
    }, [theme]);

    useEffect(() => {
        locale.changeLanguage(lang ?? 'pt-BR');
    }, [lang]);

    useEffect(() => {
        return () => {refUpdate.current = false;};
    }, []);

    return <>
        <Menu items={menuItems} onClose={handleCloseMenu} />
        <Content>
            <Outlet />
            <Topbar 
                getImage={getImage} 
                search={searchResults} 
                onLogout={()=>logout({instance:axios})} 
                onSearch={handleSearch} 
                onMenuOpen={handleOpenMenu}
                opOpenHelp={handleOpenHelp}
            />
        </Content>
        <Loading />
        <Message />
        <Modal onClose={()=>hideModal()}/>
    </>
}

export default Home;

