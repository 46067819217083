import styled from "styled-components"

export const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
`;

export const ButtonBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--theme-color-background-aux2, "#bbb");
    padding: 1.5rem;

    & > :not(:first-child) {
        margin-left: 1rem;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    & > :not(:first-child) {
        margin-left: 1rem;
    }
`;

export const Photo = styled.div`
    width: 15rem;
    margin: 0 auto;

    @media (min-width: 1000px) {
        margin: 0 1rem 0 0;
    }
`;

export const Subtitle = styled.div`
    font-size: 1rem;
    opacity: 0.7;
    line-height: 1.4rem;
    margin-top: 0rem;
`;

export const Value = styled.div`
    font-size: 1rem;
    height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    margin: 0 0 1rem 0;
`;

