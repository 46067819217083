import styled from "styled-components"

export const ViewModeRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;

    & > * {
        margin-left: 0.3rem;
    }

    @media (min-width: 1000px) {
        margin: 0;
    }
`;

export const GridTime = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    background-color: var(--theme-color-background-aux);
    overflow: auto;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

export const GridTimeContent = styled.div`
    min-width: 800px;
`;