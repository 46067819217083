import axios from 'axios';

import urls from '../../urls';
import store from '../../redux/stores';
import { router } from '../../routes';
import { login, logout } from '../../redux/authReducer';

const instance = axios;//.create();
let isRequestingRenew = false;
let requestingRenewCall:Promise<void>|undefined = undefined;

try {
    const savedUser = JSON.parse(localStorage.getItem('config.auth') || `{}`);
    instance.defaults.headers.common['x-access-token'] = savedUser.accessToken || null;
    instance.defaults.headers.common['Accept-Language'] = "pt-BR,pt;q=0.9";
} catch (e) {
    console.log('Error parseing saved user');
}

const getTokens = () => {
    try {
        let data = JSON.parse(localStorage.getItem("config.auth") ?? "{}");
        return {
            accessToken: String(data.accessToken),
            refreshToken: String(data.refreshToken)
        };
    } catch(e) {
        return undefined;
    }
}

const setTokens = (tokens: {accessToken:string|null, refreshToken:string|null} | null) => {
    if (tokens === null || !tokens.accessToken || !tokens.refreshToken) {
        store.dispatch(logout({instance}));
        return undefined;
    }
    
    try {
        let data = JSON.parse(localStorage.getItem("config.auth") ?? "{}");
        let newData = {
            ...data,
            accessToken: tokens.accessToken,
            refreshToken: tokens.refreshToken,
        };
        
        store.dispatch(login({
            instance,
            data:newData
        }));
    } catch(e) {
        store.dispatch(logout({instance}));
        return undefined;
    }
}

const requestRenewToken = async (t:{accessToken: string, refreshToken: string}| undefined, cb:(value: any) => void | PromiseLike<any>) => {
    try {
        if (isRequestingRenew) {
            const chained = requestingRenewCall?.then(cb);
            requestingRenewCall = chained;
            return chained;
        }
        isRequestingRenew = true;
        instance.defaults.headers.common['x-access-token'] = null;
        let call = axios.post(urls.login.renew, {}, {headers: {
            "x-refresh-token": t?.refreshToken
        }}).then(({data}) => {
            isRequestingRenew = false;
            requestingRenewCall = undefined;
            setTokens(data?.payload);
            return data?.payload;
        }).then(cb).catch(()=>{
            isRequestingRenew = false;
            setTokens(null);
            router.navigate('/login');
        });
        requestingRenewCall = call;
        return call;
    } catch (e) {
        console.log('ERROR, renew token', e);
        setTokens(null);
        return Promise.reject();
    }
}

export const forceRenew = async () => {
    try {
        instance.defaults.headers.common['x-access-token'] = null;

        const auth = JSON.parse(localStorage.getItem('config.auth') ?? '{}');
        const req = await axios.post(urls.login.renew, {}, {headers: {
            "x-refresh-token": auth.refreshToken
        }});

        const newData = {
            ...auth,
            accessToken: req.data?.payload.accessToken,
            refreshToken: req.data?.payload.refreshToken,
        };
        
        store.dispatch(login({
            instance,
            data:newData
        }));
    } catch (e) {
        console.log('ERROR, force renew token', e);
    }
}

instance.interceptors.response.use((r) => r, (error) => {
    if (error.response && error.response.status === 401 && error.response.headers["x-refresh-status"] === '2') {
        try {
            const t = getTokens();
            return requestRenewToken(t, (data) => {
                error.config.baseURL = undefined;
                const tokens = getTokens();
                return axios.request({
                    ...error.config,
                    headers: {
                        ...error.config.headers,
                        'x-access-token': tokens?.accessToken
                    }
                });
            });
        } catch (e) {
            return Promise.reject(error);
        }
    }
    return Promise.reject(error);
});

export default instance;