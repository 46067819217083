import styled from "styled-components"

export const Content = styled.div`
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
`;

export const Title = styled.div`
    width: 100%;
    font-size: 1.2rem;
    padding: 1rem;
    color: var(--theme-color-font, #000);
`;

export const Form = styled.div`
    font-size: 0.9rem;
    padding: 0 1rem;
    min-width: 90vw;
    max-width: 90vw;
    width: 100%;
    
    @media (min-width: 1000px) {
        min-width: 750px;
        max-width: 900px;
        width: 100%;
        padding: 0 2rem;

        & > * > *:not(:last-child) {
            margin-right: 2rem;
        }        
    }
`;

export const HelpForm = styled.div`
    font-size: 1rem;
    padding: 0;
    min-width: 90vw;
    max-width: 90vw;
    width: 100%;
    
    @media (min-width: 1000px) {
        min-width: 750px;
        max-width: 900px;
        width: 100%;
        padding: 0 2rem;       
    }
`;

export const Link = styled.a`
    display: inline;
    color: var(--theme-color-primary);
    cursor: pointer;
    margin: 0;
    padding: 0 0.3rem;

    &:hover {
        text-decoration: underline;
        color: var(--theme-font);
    }
`;

export const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1.5rem;
`;

export const Label = styled.div`
    opacity: .5;
`;

export const LabelCheckbox = styled.div`
    opacity: .5;
    margin-bottom: 0.5rem;
`;

export const LabelInRow = styled.div`
    font-size: 0.8rem;
    flex: 1;
`;

export const ButtonRow = styled.div`
    max-width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    background-color: var(--theme-color-background-aux);
    align-items: center;
    justify-content: flex-end;

    & > :not(:first-child) {
        margin-left: 10px;
    }


`;

export const PatientsItemsRow = styled.div`
    font-size: 1rem;
    line-height: 2.4rem;
    background-color: var(--theme-color-background-aux2, #ddd);
    margin: 0 0 2px 0;
    width: 100%;
    height: 2.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    justify-content: space-between;

    &:last-child {
        margin-bottom: 0.5rem;
    }

    & .btn {
        height: 1rem;
        width: 1rem;
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &:hover .btn {
        display: flex;
    }
`;
