import styled from "styled-components"

export const Header = styled.div`
    font-size: 1.2rem;
    margin: 0 0 1rem 0;
    color: var(--theme-color-font-weak);
`;

export const Field = styled.div<{noFlex?:boolean}>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    ${p => p.noFlex ? '' : 'flex: 1;'}
`;

export const ButtonRow = styled.div`
    display: flex;
    width: 100%;
    min-height: 3.125rem;
    height: 3.125rem;
    margin-bottom: 0.625rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const tr = styled.div`
    display: flex;
    width: 100%;
    min-height: 2.4rem;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
`;

export const TableHeader = styled(tr)`
    background-color: var(--theme-color-background-aux2);
    color: var(--theme-color-font-weak);
`;

export const TableRow = styled(tr)`
    background-color: transparent;
    color: var(--theme-color-font-color);
`;

export const TableRowSelect = styled(tr)`
    background-color: transparent;
    color: var(--theme-color-font-color);
    cursor: pointer;

    &:hover {
        background-color: var(--theme-color-background-aux2);
    }
    &.selected {
        background-color: var(--theme-color-primary);
        color: var(--theme-color-primary-font);
    }
`;

export const TableHeaderCell = styled.div`
    display: flex;
    flex: 1;
    min-height: 2.4rem;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    padding: 0.5rem;
`;