import styled from "styled-components"
import { Card } from "@pilarterapeutico/components";

export const Username = styled.div`
    display: inline;
    color: var(--theme-color-primary, #000);
`;

export const RowBirth = styled.div`
    width: 100%;
    margin: 0;
`;

export const CardCalendar = styled(Card)`
    margin-right: 1.5rem;
`;