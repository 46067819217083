import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mdiCashSync, mdiTransferRight } from '@mdi/js';

import ActivePlan from '../components/plans/activePlan';
import CardInvite from '../components/plans/Invite';
import { forceRenew } from '../services/requests/interceptors';
import { getActivePayment, getStatus } from '../services/requests/plans';
import { ActiveDiscountType, PlansItemType, LastPaymentType, ActivePlanType } from '../services/contracts/plans';
import { Button, ButtonRow, FilterBar, FilterRow, Row, RowOrColumn, i18n } from "@pilarterapeutico/components";
import { DateFromUTCToDate, DateFromUTCToView, DateStrFromUTCToView, MoneyFromNumberToString } from '@pilarterapeutico/util';
import * as Tags from './styles/Plans.styles';

const ConfigPlansActive = () => {

    const [plan, setPlan] = useState<ActivePlanType|PlansItemType>();
    const [nextPlan, setNextPlan] = useState<ActivePlanType>();
    const [payment, setPayment] = useState<LastPaymentType>();
    const [discount, setDiscount] = useState<ActiveDiscountType>();

    const update = useRef<boolean>(true);
    const location = useLocation();
    const navigate = useNavigate();

    const handleChangePlan = () => {
        navigate('/config/plans', {state: {...location.state, changingPlan:true, changingMethod:false}});
    }

    const handleUpdatePayment = async () => {
        navigate('/config/plans/payment', {state: {...location.state, changingPlan:false, changingMethod:true}});
    }

    const nextRenew = useMemo(()=>{
        if (!payment?.plan_dt_end) {
            return '';
        }
        let dt = DateFromUTCToDate(payment.plan_dt_end) ?? new Date();
        
        if (payment.payment_method === 'pix') {
            dt.setDate(dt.getDate() - 7);
        }

        return DateFromUTCToView(dt);
    }, [payment]);

    const getCardBrand = useMemo(()=>{ 
        switch (payment?.payment_card_brand) {
            case 'visa':
                return "/img/icon/visa.svg";
            case 'mastercard':
                return "/img/icon/mastercard.svg";
            default:
                return "/img/icon/generic_card.svg";
        }
    }, [payment?.payment_card_brand]);
    
    useEffect(()=>{
        update.current = true;

        (async () => {
            await forceRenew();
            let plan = location.state?.plan;
            let discounts = location.state?.discounts;
            
            const reqStatus = await getStatus();
            if (!update.current) {
                return;
            }
            plan = reqStatus.data?.plan;
            discounts = reqStatus.data?.discounts;

            if (!plan) {
                navigate('/config/plans', {state: {...location.state}});
            }

            if (reqStatus.data?.payment && reqStatus.data?.payment.payment_status !== 'approved') {
                navigate('/config/plans/payment', {state: {...location.state}});
            }

            const reqActive = await getActivePayment();
            if (!update.current) {
                return;
            }
            const payment = reqActive.data?.payment;

            setPlan(plan);
            setNextPlan(reqStatus.data?.nextPlan);
            setPayment(payment);
            setDiscount(discounts);
        })();
    
        return () => {update.current = false}
    }, [location.state?.plan, location.state?.payment]);

    return <Tags.Content>
        { location.state?.isnew ?
            <FilterBar style={{backgroundColor: "var(--theme-color-background-green)"}}>
                <FilterRow>{i18n("config.plans.thankyou").replace("$1", plan ? (('name' in plan ? plan?.name : plan?.plan_name) ?? '') : '')}</FilterRow>
            </FilterBar>
        : null }
        { location.state?.isChanged ?
            <FilterBar style={{backgroundColor: "var(--theme-color-background-green)"}}>
                <FilterRow>{i18n("config.plans.planChangeSuccess").replace("$1", plan ? (('name' in plan ? plan?.name : plan?.plan_name) ?? '') : '')}</FilterRow>
            </FilterBar>
        : null }
        { location.state?.isChangedMethod ?
            <FilterBar style={{backgroundColor: "var(--theme-color-background-green)"}}>
                <FilterRow>{i18n("config.plans.planChangeMethodSuccess")}</FilterRow>
            </FilterBar>
        : null }
        {nextPlan ? <FilterBar style={{backgroundColor: "var(--theme-color-background-filter)"}}>
            <FilterRow>{i18n("config.plans.planGoingChange")
                .replace("$1", DateStrFromUTCToView(nextPlan.dt_start))
                .replace("$2", plan ? (('name' in plan ? plan?.name : plan?.plan_name) ?? '') : '')
                .replace("$3", nextPlan.plan_name ?? '')
            }</FilterRow>
        </FilterBar> : null}
        <RowOrColumn rowAlign="stretch" columnAlign="stretch" rowJustify="stretch" columnJustify="flex-start" style={{marginBottom:"1rem"}}>
            <ActivePlan plan={plan} discount={discount} />
            {payment ? <Tags.Content style={{flex:1, backgroundColor:"var(--theme-color-background-aux3)"}}>
                <Row justify='space-between' align='center' style={{marginBottom: "1rem"}}>
                    <Tags.AreaTitle>{i18n("config.plans.payment")}</Tags.AreaTitle>
                </Row>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.paymentMethod")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{i18n(`config.plans.paymentValue${payment.payment_method}`)}</Tags.PlanLabel>
                </Row>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.nextRenew")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{DateStrFromUTCToView(payment.plan_dt_end)}</Tags.PlanLabel>
                </Row>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.activeSince")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{DateStrFromUTCToView(payment.plan_dt_start)}</Tags.PlanLabel>
                </Row>
                {payment.payment_method === 'pix' ? <Tags.PlanText style={{marginTop:"0.5rem", lineHeight:"1.2rem", fontSize:"0.9rem", color:"var(--theme-color-font-weak)"}}>
                    {i18n("config.plans.pixText3").replace('$1', nextRenew).replace('$2', plan ? (('name' in plan ? plan?.name : plan?.plan_name) ?? '') : '')}
                </Tags.PlanText> : <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.currentCard")}</Tags.PlanLabel>
                    <Tags.PlanLabel>
                        <Row align="center">
                            <Tags.CardBrand><Tags.CardImg src={getCardBrand} /></Tags.CardBrand>
                            <Tags.CardNumber>**** **** **** {payment.payment_card_last4}</Tags.CardNumber>
                        </Row>
                    </Tags.PlanLabel>
                </Row>}
                <Tags.LabelArea>
                    {i18n("config.plans.areaLastPayment")}
                </Tags.LabelArea>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.planPaidAt")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{DateStrFromUTCToView(payment.payment_date_approved)}</Tags.PlanLabel>
                </Row>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.planPaidMethod")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{i18n(`config.plans.paymentValue${payment.payment_paid_method}`)}</Tags.PlanLabel>
                </Row>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.planPrice")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{MoneyFromNumberToString(payment.plan_price)}</Tags.PlanLabel>
                </Row>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.discount")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{MoneyFromNumberToString(payment.plan_discount)}</Tags.PlanLabel>
                </Row>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.priceTotal")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{MoneyFromNumberToString(parseFloat(payment.payment_value))}</Tags.PlanLabel>
                </Row>
                {!nextPlan ? <ButtonRow style={{marginTop:"2rem"}}>
                    <Button icon={mdiTransferRight} color="var(--theme-color-primary)" onClick={handleChangePlan}>{i18n("config.plans.changePlan")}</Button>
                    <Button icon={mdiCashSync} color="var(--theme-color-button-blue)" onClick={handleUpdatePayment}>{i18n("config.plans.updatePayment")}</Button>
                </ButtonRow> : null}
            </Tags.Content> : <></> }
        </RowOrColumn>
        {discount ? <CardInvite discount={discount} /> : null}
    </Tags.Content>
}

export default ConfigPlansActive;
