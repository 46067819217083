import styled from "styled-components"

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.div`
    font-size: 1.2rem;
    margin: 0 2rem 2rem;
    color: var(--theme-color-font, #000);
`;

export const Description = styled.div`
    font-size: 0.9rem;
    margin: 0 0 2rem 0;
    max-width: 550px;
    padding: 0 2rem;
    color: var(--theme-color-font-weak, #000);
`;

export const ButtonRow = styled.div`
    max-width: 550px;
    padding: 0 2rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > :not(:first-child) {
        margin-left: 10px;
    }


`;