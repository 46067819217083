import { HTMLProps, FocusEvent, useState, useEffect } from 'react'

import { InputElement, InputCopyWrapper, CopyContent, CopyButton } from './styles';
import { i18n } from '../i18n/Translate';

type CopyInputProps = HTMLProps<HTMLDivElement> & {
    uid: string,
}

export const CopyInput:React.FC<CopyInputProps> = ({uid, ...props}: CopyInputProps) => {
    const [fieldValue, setFieldValue] = useState<string>("");

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        document.querySelector("body")?.click();

        if (props.onFocus) {
            props.onFocus(e);
        }
    }

    const handleCopy = () => {
        const id = uid.replace(/\./gi,"_");
        const copyText = document.querySelector("#"+id) as HTMLInputElement;

        if (!copyText) {
            return;
        }

        copyText.select();
        copyText.setSelectionRange(0, 99999);

        navigator.clipboard.writeText(copyText.value);
    }
    
    useEffect(()=>{
        if (props.value !== null) {
            setFieldValue(String(props.value));
        }
    }, [props.value]);

    return <InputCopyWrapper className={props.className ?? ''} style={props.style ?? {}}>
        <CopyContent>
            <InputElement 
                id={uid.replace(/\./gi,"_")}
                type="text"
                readOnly={true}
                value={fieldValue}
                onFocus={(e)=>handleFocus(e)}
                style={{paddingRight:"4rem"}}
            />
            <CopyButton onClick={handleCopy}>{i18n("copy")}</CopyButton>
        </CopyContent>
    </InputCopyWrapper>
}