import axios from "axios";

import URLs from '../../urls';
import store from '../../redux/stores';
import { RequestHandler } from '../contracts';
import { startLoading, stopLoading} from '../../redux/loadingReducer';
import { PaymentsListItemsType, GetPaymentsResponse, PostPaymentsResponse, GetPaymentDataResponse, GetPaymentsResumeResponse, PaymentsChartItem, GetPaymentsCashflowResponse, GetPaymentsRecomendations } from "../contracts/payments";
import PaymentDataType, { PaymentFormType } from "../../types/PaymentDataType";
import { ModalFilterData as PaymentsFilterData } from "../../modals/ModalFilterPayments";

export const getPayments = async (patient_id:string, page?: number): Promise<RequestHandler<GetPaymentsResponse>> => {
    const result = new RequestHandler<GetPaymentsResponse>();
    try {
        store.dispatch(startLoading({key:'payment.getPayments'}));
        const data = {page};
        const res = await axios.get(URLs.patients.payments.index(patient_id), {params: data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'payment.getPayments'}));
    }
    return result;
}

export const getPaymentData = async (id: string, patient_id?:string): Promise<RequestHandler<GetPaymentDataResponse>> => {
    const result = new RequestHandler<PaymentsListItemsType>();
    try {
        store.dispatch(startLoading({key:'payment.getPaymentData'}));
        const url = patient_id ? URLs.patients.payments.data(id, patient_id) : URLs.payments.data(id); 
        const res = await axios.get(url);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'payment.getPaymentData'}));
    }
    return result;
}

export const createPayment = async (data: PaymentFormType, patient_id?:string): Promise<RequestHandler<PostPaymentsResponse>> => {
    const result = new RequestHandler<PostPaymentsResponse>();
    try {
        store.dispatch(startLoading({key:'payment.createPayment'}));
        const url = patient_id ? URLs.patients.payments.index(patient_id) : URLs.payments.index;
        const res = await axios.post(url, data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'payment.createPayment'}));
    }
    return result;
}

export const updatePayment = async (data: PaymentFormType, patient_id?:string): Promise<RequestHandler<PostPaymentsResponse>> => {
    const result = new RequestHandler<PostPaymentsResponse>();
    try {
        store.dispatch(startLoading({key:'payment.updatePayment'}));
        const url = patient_id ? URLs.patients.payments.data(data.id ?? '', patient_id) : URLs.payments.data(data.id ?? '');
        const res = await axios.put(url, data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'payment.updatePayment'}));
    }
    return result;
}

export const deletePayment = async (id:string, patient_id?:string): Promise<RequestHandler<PostPaymentsResponse>> => {
    const result = new RequestHandler<PostPaymentsResponse>();
    try {
        store.dispatch(startLoading({key:'payment.deletePayment'}));
        const url = patient_id ? URLs.patients.payments.data(id, patient_id) : URLs.payments.data(id);
        const res = await axios.delete(url);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'payment.deletePayment'}));
    }
    return result;
}

export const getPaymentsByType = async (type: string, page?: number, month?:Date, filter?:PaymentsFilterData): Promise<RequestHandler<GetPaymentsResponse>> => {
    const result = new RequestHandler<GetPaymentsResponse>();
    try {
        store.dispatch(startLoading({key:'payment.getPaymentsByType'+type}));
        const data:any = {page, size:5, type, month: month ? month.toISOString() : undefined};
        for (let i in filter) {
            if (Object(filter).hasOwnProperty(i) && Boolean(filter[i])){
                if (Array.isArray(filter[i])) {
                    data[i] = JSON.stringify(filter[i]);
                } else {
                    data[i] = filter[i];
                }
            }
        }
        const res = await axios.get(URLs.payments.index, {params: data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'payment.getPaymentsByType'+type}));
    }
    return result;
}

export const getPaymentsResume = async (month?:Date, filter?:PaymentsFilterData): Promise<RequestHandler<GetPaymentsResumeResponse>> => {
    const result = new RequestHandler<GetPaymentsResumeResponse>();
    try {
        store.dispatch(startLoading({key:'payment.getPaymentsResume'}));
        const data:any = {month: month ? month.toISOString() : undefined};
        for (let i in filter) {
            if (Object(filter).hasOwnProperty(i) && Boolean(filter[i])){
                if (Array.isArray(filter[i])) {
                    data[i] = JSON.stringify(filter[i]);
                } else {
                    data[i] = filter[i];
                }
            }
        }
        const res = await axios.get(URLs.payments.resume, {params: data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'payment.getPaymentsResume'}));
    }
    return result;
}

export const getPaymentsChart = async (): Promise<RequestHandler<PaymentsChartItem[]>> => {
    const result = new RequestHandler<PaymentsChartItem[]>();
    try {
        store.dispatch(startLoading({key:'payment.getPaymentsChart'}));
        const res = await axios.get(URLs.payments.chart);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'payment.getPaymentsChart'}));
    }
    return result;
}

export const getPaymentsCashflow = async (page?:number): Promise<RequestHandler<GetPaymentsCashflowResponse>> => {
    const result = new RequestHandler<GetPaymentsCashflowResponse>();
    try {
        store.dispatch(startLoading({key:'payment.getPaymentsCashflow'}));
        const data = {page};
        const res = await axios.get(URLs.payments.cashflow, {params: data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'payment.getPaymentsCashflow'}));
    }
    return result;
}

export const getPaymentsRecomendations = async (): Promise<RequestHandler<GetPaymentsRecomendations>> => {
    const result = new RequestHandler<GetPaymentsRecomendations>();
    try {
        store.dispatch(startLoading({key:'payment.getPaymentsRecomendations'}));
        const res = await axios.get(URLs.payments.recomendations);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'payment.getPaymentsRecomendations'}));
    }
    return result;
}