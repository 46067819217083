import { useNavigate } from 'react-router-dom';

import { Column, CopyInput, Row, RowOrColumn, i18n } from "@pilarterapeutico/components";
import * as Tags from '../../pages/styles/Plans.styles';
import { ActiveDiscountType } from 'services/contracts/plans';
import { MoneyFromNumberToString } from '@pilarterapeutico/util';
import { useMemo } from 'react';

export type CardInviteProps = {
    discount: ActiveDiscountType;
};

const CardInvite: React.FC<CardInviteProps> = ({discount}:CardInviteProps) => {

    const discountValue = useMemo<number>(()=>{
        let disc = discount.discountPro + discount.discountPlatinum;
        let total = Math.max(parseFloat(discount.originalPrice),1);

        return Math.min(Math.round(100*disc/total),100);
    },[discount]);

    return <Tags.Content style={{backgroundColor:"var(--theme-color-background-aux3)"}}>
        <Row justify='space-between' align='center' style={{marginBottom: "1rem"}}>
            <Tags.AreaTitle>{i18n("config.plans.referenceTitle")}</Tags.AreaTitle>
        </Row>
        <RowOrColumn rowAlign='flex-start'>
            <Column style={{flex:1}} align='stretch' justify='flex-referenceTitle'>
                <Tags.PlanText>{i18n('config.plans.referenceText1')}</Tags.PlanText>
                <Tags.PlanText>{i18n('config.plans.referenceText2')}</Tags.PlanText>
                <Tags.PlanText>{i18n('config.plans.referenceText3')}</Tags.PlanText>
                <Tags.PlanText>{i18n('config.plans.referenceText4')}</Tags.PlanText>
                <Tags.PlanText>{i18n('config.plans.referenceText5')}</Tags.PlanText>
            </Column>
            <Column style={{flex:1}} align='stretch' justify='flex-start'>
                <Tags.PlanText>{i18n("config.plans.referenceLinkLabel")}</Tags.PlanText>
                <CopyInput uid="plans.inviteLink" value={`${import.meta.env.VITE_THERAPISTAREA_URL}/invite/${discount.code}`} />
                <Tags.PlanText>{i18n("config.plans.referenceDetails")}</Tags.PlanText>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.referenceCreated")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{discount.users}</Tags.PlanLabel>
                </Row>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.referenceActivePro")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{discount.usersPro}</Tags.PlanLabel>
                </Row>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.referenceActivePlatinum")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{discount.usersPlatinum}</Tags.PlanLabel>
                </Row>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.referenceDiscountValue")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{MoneyFromNumberToString(discount.discountPro + discount.discountPlatinum)}</Tags.PlanLabel>
                </Row>
                <Row justify='space-between'>
                    <Tags.PlanLabel>{i18n("config.plans.referenceDiscountPercent")}</Tags.PlanLabel>
                    <Tags.PlanLabel>{discountValue}%</Tags.PlanLabel>
                </Row>
            </Column>
        </RowOrColumn>
    </Tags.Content>
}

export default CardInvite;
