import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { mdiPlus, mdiFilter, mdiAccount, mdiMagnify } from '@mdi/js';

import PatientItemType from "../types/PatientItemType";
import { AppState } from "../redux/stores";
import { getImage } from "../services/requests/statics";
import { getPatients } from "../services/requests/patients";
import { setPatientsFilter } from "../redux/filtersReducer";
import { CurrentPlanDataType } from "../types/PlansDataTypes";
import { hideModal, setModalAndShow } from "../redux/modalReducer";
import { DateFromUTCToView, MoneyFromNumberToString } from "@pilarterapeutico/util";
import { Grid, Paginator, Button, ButtonRow, Title, TitleBar, FilterBar, EmptyState, PageContent, PatientItem, Translate as T, i18n, Column, FilterRow, RowOrColumn } from "@pilarterapeutico/components"; 
import ModalFilterPatients, { ModalFilterData } from "../modals/ModalFilterPatients";
import * as Tags from './styles/Home.styles';

const Patients = () => {

  const [patients, setPatients] = useState<PatientItemType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [totalFilter, setTotalFilter] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const filters = useSelector<AppState, ModalFilterData|undefined>(s => s.filters.patientFilters);
  const currentPlan = useSelector<AppState, CurrentPlanDataType|undefined>(s => s.auth.plan);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const update = useRef<boolean>(true);

  const getListingData = async ({page, filter}:{page?:number, filter?:ModalFilterData}) => {
    let patients = await getPatients(page, filter);
          
    if (update.current) {
      setPatients(patients.data?.items ? [...patients.data?.items] : []);
      setTotal(patients.data?.total ?? 0);
      setTotalFilter(patients.data?.totalResult ?? 0);
      setTotalPages(Math.ceil((patients.data?.totalResult ?? 0) / 15))
    }
  };

  const getFilterValue = (filterKey:string) => {
    if (!filters) return '';
    let v = filters[filterKey];
    switch (filterKey) {
      case 'birthMonth': return i18n('dates.monthLong.'+v);
      case 'name':
      case 'ageMin':
      case 'ageMax': return v;
      case 'valueMax':
      case 'valueMin': return MoneyFromNumberToString(v);
      case 'appointmentDateMin':
      case 'appointmentDateMax': return DateFromUTCToView(v);
      case 'frequencyAppointment':
      case 'frequencyMonth':
      case 'frequencyManual':
      case 'dueSameday':
      case 'dueNextweek':
      case 'dueMonthDay5':
      case 'dueMonthDay10':
      case 'dueMonthDay15':
      case 'dueLastMonthDay':
      case 'methodPix':
      case 'methodCredit':
      case 'methodMoney':
      case 'paymentOutOfData':
      case 'paymentDue':
      case 'active': return v ? i18n('yes') : i18n('no'); 
    }
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    getListingData({page:newPage, filter:filters});
  };

  const handleNewPatient = () => {
    navigate(`/patients/new`);
  };

  const handleFilter = () => {
    dispatch(setModalAndShow({
      content: <ModalFilterPatients onConfirm={handleFilterList} />,
    }));
  };

  const handleFilterList = async (data:any) => {
    dispatch(setPatientsFilter({filter: data}));
    dispatch(hideModal());
  }

  useEffect(()=> {
    setPage(1);
    getListingData({page:1, filter:filters});
  }, [filters]);

  useEffect(() => {
    update.current = true;

    return () => {
      update.current = false;
    };
  }, []);

  return (<PageContent>
    <TitleBar>
      <Title>
        <T path="patients.title" />
      </Title>
      <ButtonRow>
        <Button color="#0A35CE" icon={mdiFilter} onClick={handleFilter}><T path="patients.filter" /></Button>
        <Button color="#2B7100" icon={mdiPlus} onClick={handleNewPatient}><T path="patients.new" /></Button>
      </ButtonRow>
    </TitleBar>
    {filters ? <FilterBar>
      <FilterRow><T path="patients.hasFilter" /></FilterRow>
      {Object.keys(filters).filter(f=>filters[f]).map((f,i) => <FilterRow key={i}>{i18n(`patients.filterValues.${f}`)}: <span style={{paddingLeft:"0.3rem", fontWeight:600}}>{getFilterValue(f)}</span></FilterRow>)}
    </FilterBar> : null }
    <Grid mobileMargin="0 0 0.625rem 0">
      {patients && patients.length === 0 ? <EmptyState title={i18n("patients.none")} icon={mdiAccount} /> : null }
      {patients && patients.length > 0 ? patients.map(p => <PatientItem key={p.id} getImage={getImage} item={p} />) : null}
    </Grid>
    <RowOrColumn rowJustify="space-between" columnJustify="flex-start" rowAlign="center" columnAlign="center">
      <Column align="stretch">
        <Tags.ShowingState>{i18n("patients.showing")
          .replace("$1", String(patients.length ?? 0))
          .replace("$2", String(filters ? totalFilter : total))
        } {filters ? i18n("patients.showingFilter").replace('$1', String(total)) : ''}</Tags.ShowingState>
        {[0,undefined].indexOf(currentPlan?.patients) < 0 ? <Tags.LimitState>{i18n("patients.limit").replace("$1", String(currentPlan?.patients))}</Tags.LimitState> : null}
      </Column>
      <Paginator 
        page={page} 
        total={totalPages} 
        onPageChange={handleChangePage}
        className="spaced"
      />
    </RowOrColumn>
  </PageContent>)
}

export default Patients;
