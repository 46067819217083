import { useEffect, useMemo, useRef, useState, MouseEvent } from "react";
import { mdiCheck, mdiClose } from "@mdi/js";

import PasswordFormSchema from "../forms/passwordForm";
import { FormError } from "../services/contracts";
import { setMessage } from "../redux/messagesReducer";
import { SessionItem } from "../services/contracts/security";
import { useDispatch } from "react-redux";
import { DateFromISOToTimeAt } from "@pilarterapeutico/util";
import { getSessions, forceQuitSession, changePass } from "../services/requests/security";
import { Button, Input, Label, Row, PageContent, Translate as T, i18n, RowOrColumn } from "@pilarterapeutico/components";
import * as Tags from './styles/ConfigSecurity.styles';

interface FormPasswordType {
    currentPass: string,
    newPass: string,
    confirmPass: string,
}

const ConfigSecurity = () => {

    const [dataCurrentPass, setDataCurrentPass] = useState<string>("");
    const [dataNewPass, setDataNewPass] = useState<string>("");
    const [dataConfirmPass, setDataConfirmPass] = useState<string>("");
    const [dataSessionsList, setDataSessionsList] = useState<SessionItem[]>([]);
    const [dataErrors, setDataErrors] = useState<FormError[]>([]);

    const dispatch = useDispatch();
    const update = useRef<boolean>(true);

    const validateAll = (data:FormPasswordType) => {
        try {
            PasswordFormSchema().validateSync(data, {abortEarly:false});
            return true;
        } catch (e:any) {
            if (e.inner && Array.isArray(e.inner)) {
                setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
            }
            return false;
        }
    };

    const updateSessionList = async () => {
        let req = await getSessions();

        if (req && update.current) {
            setDataSessionsList(req.data?.sessions ?? []);
        }
    }

    const changePassword = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault(); 
        e.stopPropagation();

        let data = {
            currentPass: dataCurrentPass,
            newPass: dataNewPass,
            confirmPass: dataConfirmPass
        };

        setDataErrors([]);
        const isValidForm = validateAll(data);
        if (!isValidForm) {
            return;
        }

        let req = await changePass(data);

        if (!update.current) {
            return;
        }

        if (req.error) { 
            console.log(req);
            if (req.type === 'form') {
                setDataErrors((req.error ?? []) as FormError[]);
            } else {
                dispatch(setMessage({message: req.error ?? '', type: "error"}));
            }
        } else {
            dispatch(setMessage({message: i18n("patientform.saved"), type: "success"}));
            setDataCurrentPass("");
            setDataNewPass("");
            setDataConfirmPass("");
            updateSessionList();
        }
    }

    const handleForceQuit = (uuid?:string) => {
        (async () => {
            await forceQuitSession(uuid);
            updateSessionList();
        })();
    };
    
    const getFistError = useMemo(() => {
        return (key:string) => dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
    }, [dataErrors]);

    useEffect(()=>{
        updateSessionList();
        return ()=>{update.current=false;};
    }, []);

    return <div style={{padding:'1rem'}}>
        <Tags.Header>
            <T path="config.security.pass" />
        </Tags.Header>
        <form autoComplete="off">
            <RowOrColumn rowAlign="stretch" columnAlign="stretch">
                <Tags.Field>
                    <Label>
                        <T path="config.security.currentPass"/>
                    </Label>
                    <Input
                        type='password' 
                        uid='security.currentPass'
                        value={dataCurrentPass} 
                        autoComplete="off"
                        formError={getFistError('currentPass')}
                        onChange={(e)=>{setDataCurrentPass((e.target as HTMLInputElement).value)}} 
                    />
                </Tags.Field>
                <Tags.Field>
                    <Label>
                        <T path="config.security.newPass"/>
                    </Label>
                    <Input
                        type='password' 
                        uid='security.newPass'
                        value={dataNewPass} 
                        autoComplete="off"
                        formError={getFistError('newPass')}
                        onChange={(e)=>{setDataNewPass((e.target as HTMLInputElement).value)}} 
                    />
                </Tags.Field>
                <Tags.Field>
                    <Label>
                        <T path="config.security.confirmPass"/>
                    </Label>
                    <Input
                        type='password' 
                        uid='security.confirmPass'
                        value={dataConfirmPass} 
                        autoComplete="off"
                        formError={getFistError('confirmPass')}
                        onChange={(e)=>{setDataConfirmPass((e.target as HTMLInputElement).value)}} 
                    />
                </Tags.Field>
                <Tags.Field style={{justifyContent:"flex-end"}}>
                    <Tags.ButtonRow>
                        <Button size={6} icon={mdiCheck} title={i18n("save")} color="var(--theme-color-value-green)" onClick={(e)=>{changePassword(e);}} />
                    </Tags.ButtonRow>
                </Tags.Field>
            </RowOrColumn>
        </form>
        <Tags.Header style={{marginTop:"2rem"}}>
            <T path="config.security.session" />
        </Tags.Header>
        <Tags.TableHeader>
            <Tags.TableHeaderCell style={{flex: 2}}><T path="config.security.device" /></Tags.TableHeaderCell>
            <Tags.TableHeaderCell><T path="config.security.lastActivity" /></Tags.TableHeaderCell>
            <Tags.TableHeaderCell></Tags.TableHeaderCell>
        </Tags.TableHeader>
        {dataSessionsList.map((session,i) => <Tags.TableRow key={i}>
            <Tags.TableHeaderCell style={{flex: 2}}>{session.device}</Tags.TableHeaderCell>
            <Tags.TableHeaderCell>{DateFromISOToTimeAt(session.date)}</Tags.TableHeaderCell>
            <Tags.TableHeaderCell style={{padding:"0.3125rem 0", justifyContent:"flex-end"}}>
                <Button icon={mdiClose} color="var(--theme-color-value-red)" border={true} title={i18n("config.security.forceQuit")} onClick={()=>handleForceQuit(session.uuid)} />
            </Tags.TableHeaderCell>
        </Tags.TableRow>)}
    </div>
}

export default ConfigSecurity;
