import axios from "./interceptors";

import URLs from '../../urls';
import store from '../../redux/stores';
import { RequestHandler } from '../contracts';
import { PreferencesType, ProfileFormType } from "../../types/ProfileDataType";
import { startLoading, stopLoading} from '../../redux/loadingReducer';
import { GetProfileDataResponse } from '../contracts/profile';

export const updateProfile = async (data: ProfileFormType): Promise<RequestHandler<GetProfileDataResponse>> => {
    const result = new RequestHandler<GetProfileDataResponse>();
    try {
        store.dispatch(startLoading({key:'profile.updateProfile'}));
        
        let formdata = new FormData();
        
        formdata.append("name", data.name);
        formdata.append("surname", data.surname);
        formdata.append("phone", data.phone);
        formdata.append("is_whatsapp", String(data.is_whatsapp));
        formdata.append("photo", data.photo);
        
        if (data.birthdate) {
            formdata.append("birthdate", data.birthdate);
        }
        
        const res = await axios.put(URLs.config.profile, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'profile.updateProfile'}));
    }
    return result;
}

export const getProfileData = async (): Promise<RequestHandler<GetProfileDataResponse>> => {
    const result = new RequestHandler<GetProfileDataResponse>();
    try {
        store.dispatch(startLoading({key:'profile.getProfileData'}));
        const res = await axios.get(URLs.config.profile)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'profile.getProfileData'}));
    }
    return result;
}

export const saveProfileConfig = async (data:PreferencesType): Promise<RequestHandler<void>> => {
    const result = new RequestHandler<void>();
    try {
        store.dispatch(startLoading({key:'profile.saveProfileConfig'}));
        const res = await axios.put(URLs.config.savePreferences,{...data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'profile.saveProfileConfig'}));
    }
    return result;
}
