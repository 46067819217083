import { useMemo, useState } from "react";
import { mdiClose, mdiMagnify, mdiMagnifyMinusOutline } from "@mdi/js";
import { useDispatch } from "react-redux";

import { hideModal } from "../redux/modalReducer";
import { setPatientsFilter } from "../redux/filtersReducer";
import { Checkbox, DateInput, Input, Select, MoneyInput, Row, Column, Button, Translate, i18n, RowOrColumn } from "@pilarterapeutico/components";
import { DateFromUTCToView, DateFromViewToDate } from "@pilarterapeutico/util";
import * as Tags from './styles/ModalFilterPatients.styles';

type ModalFilterPatientsProps = {
    onConfirm: Function;
};

export type ModalFilterData = {
    active?:string,
    name?:string,
    birthMonth?:string,
    ageMin?:string,
    ageMax?:string,
    valueMin?:number,
    valueMax?:number,
    frequencyAppointment?:boolean,
    frequencyMonth?:boolean,
    frequencyManual?:boolean,
    dueSameday?:boolean,
    dueNextweek?:boolean,
    dueMonthDay5?:boolean,
    dueMonthDay10?:boolean,
    dueMonthDay15?:boolean,
    dueLastMonthDay?:boolean,
    methodPix?:boolean,
    methodCredit?:boolean,
    methodMoney?:boolean,
    appointmentDateMin?:Date,
    appointmentDateMax?:Date,
    paymentOutOfData?:boolean,
    paymentDue?:boolean,

    [i:string]:any,
};

const ModalFilterPatients = ({onConfirm}:ModalFilterPatientsProps) => {

    const [dataActive, setDataActive] = useState<string>('');
    const [dataName, setDataName] = useState<string>('');
    const [dataBirthMonth, setDataBirthMonth] = useState<string>('');
    const [dataAgeMin, setDataAgeMin] = useState<string>('');
    const [dataAgeMax, setDataAgeMax] = useState<string>('');
    const [dataValueMin, setDataValueMin] = useState<number>(0);
    const [dataValueMax, setDataValueMax] = useState<number>(0);
    const [dataFrequencyAppointment, setDataFrequencyAppointment] = useState<boolean>(false);
    const [dataFrequencyMonth, setDataFrequencyMonth] = useState<boolean>(false);
    const [dataFrequencyManual, setDataFrequencyManual] = useState<boolean>(false);
    const [dataDueSameday, setDataDueSameday] = useState<boolean>(false);
    const [dataDueNextweek, setDataDueNextweek] = useState<boolean>(false);
    const [dataDueMonthDay5, setDataDueMonthDay5] = useState<boolean>(false);
    const [dataDueMonthDay10, setDataDueMonthDay10] = useState<boolean>(false);
    const [dataDueMonthDay15, setDataDueMonthDay15] = useState<boolean>(false);
    const [dataDueLastMonthDay, setDataDueLastMonthDay] = useState<boolean>(false);
    const [dataMethodPix, setDataMethodPix] = useState<boolean>(false);
    const [dataMethodCredit, setDataMethodCredit] = useState<boolean>(false);
    const [dataMethodMoney, setDataMethodMoney] = useState<boolean>(false);
    const [dataAppointmentDateMin, setDataAppointmentDateMin] = useState<Date>();
    const [dataAppointmentDateMax, setDataAppointmentDateMax] = useState<Date>();
    const [dataPaymentOutOfData, setDataPaymentOutOfData] = useState<boolean>();
    const [dataPaymentDue, setDataPaymentDue] = useState<boolean>();
    const dispatch = useDispatch();


    const handleClearFilter = () => {
        setDataActive('');
        setDataName('');
        setDataBirthMonth('');
        setDataAgeMin('');
        setDataAgeMax('');
        setDataValueMin(0);
        setDataValueMax(0);
        setDataFrequencyAppointment(false);
        setDataFrequencyMonth(false);
        setDataFrequencyManual(false);
        setDataDueSameday(false);
        setDataDueNextweek(false);
        setDataDueMonthDay5(false);
        setDataDueMonthDay10(false);
        setDataDueMonthDay15(false);
        setDataDueLastMonthDay(false);
        setDataMethodPix(false);
        setDataMethodCredit(false);
        setDataMethodMoney(false);
        setDataAppointmentDateMin(undefined);
        setDataAppointmentDateMax(undefined);
        setDataPaymentOutOfData(false);
        setDataPaymentDue(false);

        dispatch(hideModal());
        dispatch(setPatientsFilter({filter: undefined}));
    };

    const handleSubmitFilter = () => {
        const data:ModalFilterData = {
            active: dataActive,
            name: dataName,
            birthMonth: dataBirthMonth,
            ageMin: dataAgeMin,
            ageMax: dataAgeMax,
            valueMin: dataValueMin,
            valueMax: dataValueMax,
            frequencyAppointment: dataFrequencyAppointment,
            frequencyMonth: dataFrequencyMonth,
            frequencyManual: dataFrequencyManual,
            dueSameday: dataDueSameday,
            dueNextweek: dataDueNextweek,
            dueMonthDay5: dataDueMonthDay5,
            dueMonthDay10: dataDueMonthDay10,
            dueMonthDay15: dataDueMonthDay15,
            dueLastMonthDay: dataDueLastMonthDay,
            methodPix: dataMethodPix,
            methodCredit: dataMethodCredit,
            methodMoney: dataMethodMoney,
            appointmentDateMin: dataAppointmentDateMin,
            appointmentDateMax: dataAppointmentDateMax,
            paymentOutOfData: dataPaymentOutOfData,
            paymentDue: dataPaymentDue
        };
        onConfirm(data);
    };

    const renderMonths = useMemo(()=> Array(13).fill(0).map((_,i) => <option key={i} value={i ? i : ''}>{i18n(`dates.monthLong.${i}`)}</option>), []);

    return <Tags.Content>
        <Tags.Title><Translate path="patients.modalFilter.title" /></Tags.Title>
        <Tags.Form>
            <RowOrColumn rowAlign="stretch" columnAlign="stretch" columnJustify="flex-start">
                <Column style={{flex:1}} align="flex-start" justify="flex-start">
                    <Tags.Field>
                        <Tags.Label><Translate path="patients.modalFilter.labelActive" /></Tags.Label>
                        <Select uid="patients.modalFilter.active"  value={dataActive} onChange={(e)=>setDataActive((e.target as HTMLSelectElement).value)}>
                            <option value="">{i18n("patients.modalFilter.activeBoth")}</option>
                            <option value="true">{i18n("patients.modalFilter.activeTrue")}</option>
                            <option value="false">{i18n("patients.modalFilter.activeFalse")}</option>
                        </Select>
                        <Tags.Label><Translate path="patients.modalFilter.labelName" /></Tags.Label>
                        <Input uid="patients.modalFilter.name" style={{width:"100%"}} value={dataName} onChange={(e)=>setDataName((e.target as HTMLInputElement).value)} />
                        <Tags.Label><Translate path="patients.modalFilter.labelBirthMonth" /></Tags.Label>
                        <Select uid="patients.modalFilter.birthMonth" style={{width:"100%"}} value={dataBirthMonth} onChange={(e)=>setDataBirthMonth((e.target as HTMLSelectElement).value)}>
                            {renderMonths}
                        </Select>
                        <Tags.Label><Translate path="patients.modalFilter.labelAge" /></Tags.Label>
                        <Row>
                            <Tags.LabelInRow><Translate path="patients.modalFilter.labelMin" /></Tags.LabelInRow>
                            <Tags.LabelInRow><Translate path="patients.modalFilter.labelMax" /></Tags.LabelInRow>
                        </Row>
                        <Row>
                            <Input uid="patients.modalFilter.agemin" style={{flex:1}} value={dataAgeMin} onChange={(e)=>setDataAgeMin((e.target as HTMLInputElement).value)} />
                            <Input uid="patients.modalFilter.agemax" style={{flex:1}} value={dataAgeMax} onChange={(e)=>setDataAgeMax((e.target as HTMLInputElement).value)} />
                        </Row>
                    </Tags.Field>
                </Column>
                <Column style={{flex:1}} align="flex-start" justify="flex-start">
                    <Tags.Field>
                        <Tags.Label><Translate path="patients.modalFilter.labelValue" /></Tags.Label>
                        <Row>
                            <Tags.LabelInRow><Translate path="patients.modalFilter.labelMin" /></Tags.LabelInRow>
                            <Tags.LabelInRow><Translate path="patients.modalFilter.labelMax" /></Tags.LabelInRow>
                        </Row>
                        <Row>
                            <MoneyInput uid="patients.modalFilter.valuemin" style={{flex:1}} value={dataValueMin} onChange={(e)=>setDataValueMin(e ?? 0)} />
                            <MoneyInput uid="patients.modalFilter.valuemax" style={{flex:1}} value={dataValueMax} onChange={(e)=>setDataValueMax(e ?? 0)} />
                        </Row>
                    </Tags.Field>
                    <Tags.Field>
                        <Tags.LabelCheckbox><Translate path="patients.modalFilter.labelPayments" /></Tags.LabelCheckbox>
                        <Checkbox className='compact' uid="patients.modalFilter.paymentOutOfData" label={i18n("patients.modalFilter.paymentOutOfData")} checked={dataPaymentOutOfData} onCheck={(e:boolean)=>setDataPaymentOutOfData(e)}/>
                        <Checkbox className='compact' uid="patients.modalFilter.paymentDue" label={i18n("patients.modalFilter.paymentDue")} checked={dataPaymentDue} onCheck={(e:boolean)=>setDataPaymentDue(e)}/>
                    </Tags.Field>
                    <Tags.Field>
                        <Tags.LabelCheckbox><Translate path="patients.modalFilter.labelMethod" /></Tags.LabelCheckbox>
                        <Checkbox className='compact' uid="patients.modalFilter.methodPix" label={i18n("patients.modalFilter.methodPix")} checked={dataMethodPix} onCheck={(e:boolean)=>setDataMethodPix(e)}/>
                        <Checkbox className='compact' uid="patients.modalFilter.methodCredit" label={i18n("patients.modalFilter.methodCredit")} checked={dataMethodCredit} onCheck={(e:boolean)=>setDataMethodCredit(e)}/>
                        <Checkbox className='compact' uid="patients.modalFilter.methodMoney" label={i18n("patients.modalFilter.methodMoney")} checked={dataMethodMoney} onCheck={(e:boolean)=>setDataMethodMoney(e)}/>
                    </Tags.Field>
                </Column>
                <Column style={{flex:1}} justify="flex-start">
                    <Tags.Field>
                        <Tags.LabelCheckbox><Translate path="patients.modalFilter.labelDue" /></Tags.LabelCheckbox>
                        <Checkbox className='compact' uid="patients.modalFilter.dueSameday" label={i18n("patients.modalFilter.dueSameday")} checked={dataDueSameday} onCheck={(e:boolean)=>setDataDueSameday(e)}/>
                        <Checkbox className='compact' uid="patients.modalFilter.dueNextweek" label={i18n("patients.modalFilter.dueNextweek")} checked={dataDueNextweek} onCheck={(e:boolean)=>setDataDueNextweek(e)}/>
                        <Checkbox className='compact' uid="patients.modalFilter.dueMonthDay5" label={i18n("patients.modalFilter.dueMonthDay5")} checked={dataDueMonthDay5} onCheck={(e:boolean)=>setDataDueMonthDay5(e)}/>
                        <Checkbox className='compact' uid="patients.modalFilter.dueMonthDay10" label={i18n("patients.modalFilter.dueMonthDay10")} checked={dataDueMonthDay10} onCheck={(e:boolean)=>setDataDueMonthDay10(e)}/>
                        <Checkbox className='compact' uid="patients.modalFilter.dueMonthDay15" label={i18n("patients.modalFilter.dueMonthDay15")} checked={dataDueMonthDay15} onCheck={(e:boolean)=>setDataDueMonthDay15(e)}/>
                        <Checkbox className='compact' uid="patients.modalFilter.dueLastMonthDay" label={i18n("patients.modalFilter.dueLastMonthDay")} checked={dataDueLastMonthDay} onCheck={(e:boolean)=>setDataDueLastMonthDay(e)}/>
                    </Tags.Field>
                    <Tags.Field>
                        <Tags.LabelCheckbox><Translate path="patients.modalFilter.labelFrequency" /></Tags.LabelCheckbox>
                        <Checkbox className='compact' uid="patients.modalFilter.frequencyAppointment" label={i18n("patients.modalFilter.frequencyAppointment")} checked={dataFrequencyAppointment} onCheck={(e:boolean)=>setDataFrequencyAppointment(e)}/>
                        <Checkbox className='compact' uid="patients.modalFilter.frequencyMonth" label={i18n("patients.modalFilter.frequencyMonth")} checked={dataFrequencyMonth} onCheck={(e:boolean)=>setDataFrequencyMonth(e)}/>
                        <Checkbox className='compact' uid="patients.modalFilter.frequencyManual" label={i18n("patients.modalFilter.frequencyManual")} checked={dataFrequencyManual} onCheck={(e:boolean)=>setDataFrequencyManual(e)}/>
                    </Tags.Field>
                    <Tags.Field>
                        <Tags.Label><Translate path="patients.modalFilter.labelAppointmentDate" /></Tags.Label>
                        <Row>
                            <Tags.LabelInRow><Translate path="patients.modalFilter.labelMin" /></Tags.LabelInRow>
                            <Tags.LabelInRow><Translate path="patients.modalFilter.labelMax" /></Tags.LabelInRow>
                        </Row>
                        <Row>
                            <DateInput uid="patients.modalFilter.datemin" style={{flex:2}} value={DateFromUTCToView(dataAppointmentDateMin)} onChange={(e)=>setDataAppointmentDateMin(DateFromViewToDate((e.target as HTMLInputElement).value))} />
                            <DateInput uid="patients.modalFilter.datemax" style={{flex:2}} value={DateFromUTCToView(dataAppointmentDateMax)} onChange={(e)=>setDataAppointmentDateMax(DateFromViewToDate((e.target as HTMLInputElement).value))} />
                        </Row>
                    </Tags.Field>
                </Column>
            </RowOrColumn>
        </Tags.Form>
        <Tags.ButtonRow>
            <Button onClick={()=>{handleClearFilter()}} color={"var(--theme-color-font-weak)"} border={true} icon={mdiMagnifyMinusOutline} title={i18n('clear')} />
            <Button onClick={()=>{dispatch(hideModal())}} color={"var(--theme-color-font-weak)"} border={true} icon={mdiClose} title={i18n('close')} />
            <Button onClick={()=>handleSubmitFilter()} color={"#004BA3"} icon={mdiMagnify} title={i18n('filter')} />
        </Tags.ButtonRow>
    </Tags.Content>
}

export default ModalFilterPatients;
