import * as yup from 'yup';
import { i18n } from '../util/util';

const PatientFormSchema = () => yup.object({
    name: yup.string().required(i18n('patientform.invalid.name')).min(2, i18n('patientform.invalid.min')).max(64, i18n('patientform.invalid.max')),
    surname: yup.string().required(i18n('patientform.invalid.surname')).min(2, i18n('patientform.invalid.min')).max(64, i18n('patientform.invalid.max')),
    email: yup.string().email(i18n('patientform.invalid.email')).optional(),
    phone: yup.string().trim().matches(/^[\d)(-\s]{14,18}$/gi, {excludeEmptyString: true, message: i18n('patientform.invalid.phone')}).optional(),
    birthdate: yup.date().optional().default(new Date()).typeError(i18n('patientform.invalid.date')),
    patientSince: yup.date().optional().default(new Date()).typeError(i18n('patientform.invalid.date')),
    active: yup.boolean().default(true),
    allow_access: yup.boolean().default(false),
    is_whatsapp: yup.boolean().default(true),
    default_payment_value: yup.number().optional(),
    default_payment_frequency: yup.string().oneOf(['MONTH','APPOINTMENT','MANUAL'], i18n('patient.paymentFrequencyInvalid')),
    default_payment_due: yup.string().oneOf(['SAMEDAY','SEVENDAY','MONTHDAY5','MONTHDAY10','MONTHDAY15','MONTHDAYLAST'], i18n('patient.paymentDueInvalid')),
    default_payment_method: yup.string().oneOf(['CREDITCARD','MONEY','PIX'], i18n('patient.paymentMethodInvalid')),
    anamnesis: yup.string().optional().typeError(i18n('patient.anamnesisInvalid')),
});

export default PatientFormSchema;