import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";

import { Button, Radio, Translate, i18n } from "@pilarterapeutico/components";
import { useState } from "react";
import * as Tags from './styles/ModalDeleteSchedule.styles';
import { useDispatch } from "react-redux";
import { hideModal } from "../redux/modalReducer";

type ModalDeleteScheduleProps = {
  count: number;
  onConfirm: Function;
};

const ModalDeleteSchedule = ({count, onConfirm}:ModalDeleteScheduleProps) => {

  const [dataAppointments, setDataAppointments] = useState<string>('REMOVE');
  const dispatch = useDispatch();

  return <Tags.Content>
    <Icon path={mdiTrashCan} size={4} color={'#f44'} style={{margin:'2rem 0'}} />
    <Tags.Title><Translate path="scheduleform.modalRemove.title" /></Tags.Title>
    <Tags.Description>
      <Translate path="scheduleform.modalRemove.description" /><br />
      <Translate path="scheduleform.modalRemove.appointmentsActions" />
    </Tags.Description>
    <Tags.Description>
      <Radio uid={"modal.removeSchedule.remove"} checked={dataAppointments === 'REMOVE'} labelContent={i18n("scheduleform.modalRemove.appointmentsRemove")} isVertical={false} onCheck={() => {setDataAppointments('REMOVE')}} />
      <Radio uid={"modal.removeSchedule.keep"} checked={dataAppointments === 'KEEP'} labelContent={i18n("scheduleform.modalRemove.appointmentsKeep")} isVertical={false} onCheck={() => {setDataAppointments('KEEP')}} />
    </Tags.Description>
    <Tags.Description style={{width:'100%', textAlign:'left'}}>
      {i18n("scheduleform.modalRemove.appointmentsNext").replace('$1', count + ' ' + i18n('scheduleform.modalRemove.appointment' + (count === 1 ? '' : 's')))}
    </Tags.Description>
    <Tags.ButtonRow>
      <Button onClick={()=>onConfirm({appointments: dataAppointments})} color={"#f44"} size={6} title={i18n('delete')} />
      <Button onClick={()=>{dispatch(hideModal())}} color={"#999"} size={6} title={i18n('cancel')} />
    </Tags.ButtonRow>
  </Tags.Content>
}

export default ModalDeleteSchedule;
