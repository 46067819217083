import * as yup from 'yup';
import { i18n } from '../util/util';

const ForgotPasswordCodeFormSchema = () => yup.object({
    email: yup.string()
        .email(i18n('register.emailInvalid'))
        .required(i18n('register.emptyEmail')),
    code: yup.string()
        .required(i18n('register.emptyEmail')),
    password: yup.string()
        .required(i18n("config.security.invaliidPassword"))
        .min(6, i18n("config.security.invaliidPassword"))
        .typeError(i18n('config.security.invalidPassword')),
    password_confirm: yup.string()
        .required(i18n("config.security.invaliidPassword"))
        .equals([yup.ref('password')], i18n('config.security.diffPassword'))
        .typeError(i18n('config.security.invalidPassword')),
});

export default ForgotPasswordCodeFormSchema;