import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import axios from '../services/requests/interceptors';
import RegisterFormSchema from "../forms/registerForm";
import { login } from '../redux/authReducer';
import { AppState } from "../redux/stores";
import { FormError } from "@pilarterapeutico/types";
import { setMessage } from "../redux/messagesReducer";
import { statesList } from "../util/util";
import { postRegister } from '../services/requests/login';
import { RequestHandler } from "../services/contracts";
import { RegisterFormType } from "../types/RegisterDataType";
import { PostRegisterOrLoginResponse } from "../services/contracts/login";
import { Row, Button, Input, Select, Title, Translate, i18n, Column, RowOrColumn, Message, Loading } from '@pilarterapeutico/components';
import * as Tags from "./styles/Login.styles";

const LoginFormPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector<AppState>(s => s.auth.accessToken);
    const params = useParams();
    const [query] = useSearchParams();
    
    const [dataName, setDataName] = useState<string>("");
    const [dataSurname, setDataSurname] = useState<string>("");
    const [dataEmail, setDataEmail] = useState<string>("");
    const [dataPassword, setDataPassword] = useState<string>("");
    const [dataConfPassword, setDataConfPassword] = useState<string>("");
    const [dataCRP, setDataCRP] = useState<string>("");
    const [dataCRPUF, setDataCRPUF] = useState<string>("");
    const [dataErrors, setDataErrors] = useState<FormError[]>([]);

    const validateAll = (data:RegisterFormType) => {
        try {
            RegisterFormSchema().validateSync(data, {abortEarly:false});
            return true;
        } catch (e:any) {
            if (e.inner && Array.isArray(e.inner)) {
                setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
            }
            return false;
        }
    };

    const getFirstError = useMemo(() => {
        return (key:string) => dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
    }, [dataErrors]);

    const handleSubmitForm = async () => {
        const data:RegisterFormType = {
            name: dataName,
            surname: dataSurname,
            email: dataEmail,
            password: dataPassword,
            password_confirm: dataConfPassword,
            crp_number: dataCRP,
            crp_uf: dataCRPUF,
            referrer: params.referrer ?? undefined,
            plan: query.get('plan') ?? undefined,
        };
    
        setDataErrors([]);
    
        const isValidForm = validateAll(data);
        if (!isValidForm) {
            return;
        }
    
        let response:RequestHandler<PostRegisterOrLoginResponse> = await postRegister(data);
    
        if (response.error) { 
            if (response.type === 'form') {
              setDataErrors((response.error ?? []) as FormError[]);
            } else {
              dispatch(setMessage({message: response.error ?? '', type: "error"}));
            }
        } else {
            dispatch(setMessage({message: i18n("register.saved"), type: "success"}));
            if (response.data?.accessToken) {
                dispatch(login({
                    instance: axios,
                    data: {
                        ...response.data
                    }
                }));
            }
        }
    }

    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token, navigate]);

    return <Tags.Login>
        <Tags.Banner className="register">
                <Tags.Logo className="register">
                    <Link to="https://pilarterapeutico.com.br">
                        <img src="/img/logo.svg" alt="" width="100%" />
                    </Link>
                </Tags.Logo>
            <Tags.Ilustration className="register" src="/img/banner.svg" />
        </Tags.Banner>
        <Tags.Sidebar className="register">
            <Tags.Content>
                <Title className="uppercase">
                    <Translate path="register.title"/>
                </Title>
                <Tags.Text>
                    <Translate path="register.message"/>
                </Tags.Text>

                <RowOrColumn columnAlign="stretch">
                    <Column align="stretch" style={{flex:1}}>
                        <Tags.Label>
                            <Translate path="register.name"/>
                        </Tags.Label>
                        <Input 
                            uid="register.name"
                            value={dataName} 
                            onChange={(e)=>{setDataName((e.target as HTMLInputElement).value)}} 
                            formError={getFirstError('name')}
                        />
                    </Column>
                    <Column align="stretch" style={{flex:1}}>
                        <Tags.Label>
                            <Translate path="register.surname"/>
                        </Tags.Label>
                        <Input 
                            uid="register.surname"
                            value={dataSurname} 
                            onChange={(e)=>{setDataSurname((e.target as HTMLInputElement).value)}} 
                            formError={getFirstError('surname')}
                        />
                    </Column>
                </RowOrColumn>
                <RowOrColumn columnAlign="stretch">
                    <Column align="stretch" style={{flex:1}}>
                        <Tags.Label>
                            <Translate path="register.email"/>
                        </Tags.Label>
                        <Input 
                            uid="register.email"
                            value={dataEmail} 
                            onChange={(e)=>{setDataEmail((e.target as HTMLInputElement).value)}} 
                            formError={getFirstError('email')}
                        />
                    </Column>
                    <Row justify="stretch" style={{flex:1}}>
                        <Column align="stretch" style={{flex:1}}>
                            <Tags.Label>
                                <Translate path="register.password"/>
                            </Tags.Label>
                            <Input 
                                uid="register.password"
                                type="password"
                                value={dataPassword} 
                                onChange={(e)=>{setDataPassword((e.target as HTMLInputElement).value)}} 
                                formError={getFirstError('password')}
                            />
                        </Column>
                        <Column align="stretch" style={{flex:1}}>
                            <Tags.Label>
                                <Translate path="register.confPassword"/>
                            </Tags.Label>
                            <Input 
                                uid="register.confpassword"
                                type="password"
                                value={dataConfPassword} 
                                onChange={(e)=>{setDataConfPassword((e.target as HTMLInputElement).value)}} 
                                formError={getFirstError('password_confirm')}
                            />
                        </Column>
                    </Row>
                </RowOrColumn>
                <RowOrColumn>
                    <Row justify="stretch" style={{flex:1}}>
                        <Column align="stretch" style={{flex:1}}>
                            <Tags.Label>
                                <Translate path="register.crp"/>
                            </Tags.Label>
                            <Input 
                                uid="register.crp"
                                value={dataCRP} 
                                onChange={(e)=>{setDataCRP((e.target as HTMLInputElement).value)}} 
                                formError={getFirstError('crp_number')}
                            />
                        </Column>
                        <Column align="stretch" style={{flex:1}}>
                            <Tags.Label>
                                <Translate path="register.state"/>
                            </Tags.Label>
                            <Select 
                                uid="register.crpstate"
                                value={dataCRPUF} 
                                onChange={(e)=>{setDataCRPUF((e.target as HTMLSelectElement).value)}} 
                                formError={getFirstError('crp_uf')}
                            >
                                <option value=""></option>
                                {statesList.map(uf => <option key={uf.id} value={uf.id}>{uf.id}</option>)}
                            </Select>
                        </Column>
                    </Row>
                    <Row justify="stretch" style={{flex:1}}>
                    </Row>
                </RowOrColumn>                
                <Row style={{marginTop: '1rem'}} justify="flex-end">
                    <Row align="center">
                        <span>Já tem conta?</span>
                        <Tags.SimpleLink>
                            <Link to="/login">Login</Link>
                        </Tags.SimpleLink>
                    </Row>
                    <Button color="var(--theme-color-primary)" size={7} onClick={()=>{handleSubmitForm()}} >
                        <Translate path="register.create"/>
                    </Button>
                </Row>
            </Tags.Content>
        </Tags.Sidebar>
        <Loading />
        <Message />
    </Tags.Login>
}

export default LoginFormPage;
