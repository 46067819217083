import * as yup from 'yup';
import { i18n } from '../util/util';

const ScheduleFormSchema = () => yup.object({
    start_date: yup.date().required(i18n('scheduleform.invalid.dateEmpty')).typeError(i18n('scheduleform.invalid.dateEmpty')),
    start_time: yup.number().integer().positive().required(i18n('scheduleform.invalid.startTime')).typeError(i18n('scheduleform.invalid.startTime')),
    end_time: yup.number().integer().positive().min(yup.ref('start_time'), i18n('scheduleform.invalid.startTime')).required(i18n('scheduleform.invalid.startTime')).typeError(i18n('scheduleform.invalid.startTime')),
    location_id: yup.string().optional(),
    appointments_modify: yup.string().oneOf(["MODIFY","KEEP"]).optional().default('MODIFY'),
    repeat: yup.string().oneOf(["ONCE", "WEEK", "TWOWEEKS", "TWICEAMONTH", "MONTH"], i18n('scheduleform.invalid.repeat')).optional().default("ONCE"),
    end_at: yup.string().required(i18n('scheduleform.invalid.finish')).oneOf(["NEVER","TIMES","DATE"], i18n('scheduleform.invalid.finish')).default("NEVER").when("repeat", {
        is: "ONCE",
        then: () => yup.string().optional(),
    }),
    end_at_date: yup.date().optional().typeError(i18n('scheduleform.invalid.repeatDate')).when("end_at", {
        is: "DATE",
        then: () => yup.date().typeError(i18n('scheduleform.invalid.repeatDate')).required(i18n('scheduleform.invalid.repeatDate'))
    }),
    end_at_times: yup.number().optional().typeError(i18n('scheduleform.invalid.repeatTimes')).when("end_at", {
        is: "TIMES",
        then: () => yup.number().positive().integer().typeError(i18n('scheduleform.invalid.repeatTimes')).required(i18n('scheduleform.invalid.repeatTimes'))
    }),
});

export default ScheduleFormSchema;