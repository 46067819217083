import React from 'react';
import Icon from '@mdi/react';
import { mdiCheck, mdiMinus } from '@mdi/js';

import { Row, i18n } from '@pilarterapeutico/components';
import { ActiveDiscountType, ActivePlanType, PlansItemType } from '../../services/contracts/plans';
import { MoneyFromNumberToString } from '@pilarterapeutico/util';
import * as Tags from '../../pages/styles/Plans.styles';

interface ActivePlanProps {
    plan?: ActivePlanType | PlansItemType;
    discount?: ActiveDiscountType;
}

const ActivePlan: React.FC<ActivePlanProps> = ({ plan, discount }) => {

    if (!plan) {
        return <></>;
    }

    const name = 'plan_name' in plan ? plan.plan_name : plan.name;
    const plan_type = 'plan_type' in plan ? plan.plan_type : plan.type;
    const plan_patients = 'plan_patients' in plan ? plan.plan_patients : plan.limit_max_patients;
    const plan_events = 'plan_events' in plan ? plan.plan_events : plan.limit_max_week_events;
    const plan_history = 'plan_history' in plan ? plan.plan_history : plan.limit_max_financial_history;
    const plan_payments = 'plan_payments' in plan ? plan.plan_payments : plan.limit_has_accept_payments;
    const plan_call = 'plan_call' in plan ? plan.plan_call : plan.limit_has_call_manager;
    const plan_price = String('plan_price' in plan ? plan.plan_price : plan.price);

    return <Tags.Content style={{flex:1, display:"flex", flexDirection:"column", alignContent:"stretch", justifyContent:"stretch", backgroundColor:"var(--theme-color-background-aux3)"}}>
        <Row justify='space-between' style={{marginBottom: "1rem"}}>
            <Tags.AreaTitle>{i18n("config.plans.plan")}</Tags.AreaTitle>
            <Tags.AreaTitle>{String(plan_type ?? '').toUpperCase()}</Tags.AreaTitle>
        </Row>
        <Row justify='space-between'>
            <Tags.PlanLabel>{i18n("config.plans.planDatailMaxPatients")}</Tags.PlanLabel>
            <Tags.PlanLabel>{plan_patients || i18n("config.plans.unlimited")}</Tags.PlanLabel>
        </Row>
        <Row justify='space-between'>
            <Tags.PlanLabel>{i18n("config.plans.planDatailMaxAppointments")}</Tags.PlanLabel>
            <Tags.PlanLabel>{plan_events || i18n("config.plans.unlimited")}</Tags.PlanLabel>
        </Row>
        <Row justify='space-between'>
            <Tags.PlanLabel>{i18n("config.plans.planDatailMaxFinancial")}</Tags.PlanLabel>
            <Tags.PlanLabel>{plan_history || i18n("config.plans.unlimited")}</Tags.PlanLabel>
        </Row>
        <Row justify='space-between'>
            <Tags.PlanLabel>{i18n("config.plans.planDatailHasPayments")}</Tags.PlanLabel>
            <Tags.PlanLabel>{
                plan_payments 
                ? <Icon path={mdiCheck} color="var(--theme-color-font)" size={1} />
                : <Icon path={mdiMinus} color="var(--theme-color-font-empty)" size={1} />
            }</Tags.PlanLabel>
        </Row>
        <Row justify='space-between'>
            <Tags.PlanLabel>{i18n("config.plans.planDatailHasCalls")}</Tags.PlanLabel>
            <Tags.PlanLabel>{
                plan_call
                ? <Icon path={mdiCheck} color="var(--theme-color-font)" size={1} />
                : <Icon path={mdiMinus} color="var(--theme-color-font-empty)" size={1} />
            }</Tags.PlanLabel>
        </Row>
        <div style={{flex:1}}></div>
        <Row justify='space-between' style={{marginTop: "1rem"}}>
            <Tags.PlanLabel style={{marginBottom:0}}>{i18n("config.plans.planPrice")}</Tags.PlanLabel>
            <Tags.PlanLabel style={{marginBottom:0}}>{MoneyFromNumberToString(parseFloat(plan_price) ?? 0) ?? 0}</Tags.PlanLabel>
        </Row>
        <Row justify='space-between' style={{marginTop: "1rem"}}>
            <Tags.PlanLabel style={{marginBottom:0}}>{i18n("config.plans.discount")}</Tags.PlanLabel>
            <Tags.PlanLabel style={{marginBottom:0}}>{MoneyFromNumberToString(discount?.total ?? 0) ?? 0}</Tags.PlanLabel>
        </Row>
        <Row justify='space-between' style={{marginTop: "1rem"}}>
            <Tags.AreaTitle style={{marginBottom:0}}>{i18n("config.plans.total")}</Tags.AreaTitle>
            <Tags.AreaTitle style={{marginBottom:0}}>{MoneyFromNumberToString((parseFloat(plan_price) - (discount?.total ?? 0))) ?? 0}{i18n("config.plans.month")}</Tags.AreaTitle>
        </Row>
    </Tags.Content>;
};

export default ActivePlan;