import axios from 'axios';

import URLs from '../../urls';


export const getImage = async (file: string):Promise<string> => {
    return new Promise(async (resolve, reject) => {
        if (!file) {
            reject('');
        }
        
        let img = await axios.get(URLs.statics.base(file), { 
            responseType: 'arraybuffer'
        }).then((res) => {
            let image = btoa(
                new Uint8Array(res.data)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            return `data:${(res.headers['content-type'] ?? "").toLowerCase()};base64,${image}`;
        }).catch((error) => {
            return '';
        });
        
        resolve(img);
    });
};

export const getFile = async (id: string, key?:string):Promise<Blob|undefined> => {
    return new Promise(async (resolve, reject) => {
        if (!id) {
            reject('');
        }

        let url = URLs.statics.file(id);
        let opts:any = {responseType: 'arraybuffer'};

        let req = key ? axios.post(url, {key}, opts) : axios.get(url, opts);
        
        let file = await req.then((res) => {
            let arr = new Uint8Array(res.data);
            return new Blob([arr], {type: res.headers['content-type']});
        }).catch((error) => {
            console.error(error);
            return undefined;
        });
        
        resolve(file);
    });
};