import { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mdiArrowLeft, mdiHome } from '@mdi/js';

import { Translate, i18n } from '../i18n/Translate';
import { MenuElement, MenuBackground, Header, Logo, BackButton, MenuItems, Item } from './styles';
import { useSelector } from 'react-redux';

type MenuItemType = {key:string, label:string, icon:string};
interface MenuProps {
    items:MenuItemType[];
    onClose: Function;
}

export const Menu: React.FC<MenuProps> = ({items, onClose}:MenuProps) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [currentItem, setCurrentItem] = useState<string>('');

    const menuOpened = useSelector<any, boolean>(s => s.menu.show);

    const isSelected = (item: string) => {
        if (currentItem === item) {
            return 'active ';
        }
        return '';
    };

    const handleClick = (link:string) => {
        navigate(link);
        onClose();
    }

    useEffect(() => {
        let updated = false;
        for(let item of items) {
            if (location.pathname.startsWith(item.key)) {
                setCurrentItem(item.label);
                updated = true;
                break;
            }
        }
        if (!updated) {
            setCurrentItem('home');
        }
    }, [location]);


    return <>
        <MenuBackground className={menuOpened ? 'opened' : ''} onClick={()=>onClose()} />
        <MenuElement className={menuOpened ? 'opened' : ''}>
            <Header>
                <BackButton onClick={()=>onClose()}><Icon path={mdiArrowLeft} size={1} /></BackButton>
                <Logo src="/img/logo.svg" alt={i18n('menu.logo')} />
            </Header>
            <MenuItems>
                <Item className={isSelected('home')} onClick={()=>{handleClick('/')}}>
                    <Icon style={{marginRight:'0.5rem'}} size={1.2} path={mdiHome} />
                    <Translate path="menu.home" />
                </Item>
                {items.map((v,i) => <Item key={i} className={isSelected(v.label)} onClick={()=>{handleClick(v.key)}}>
                    <Icon style={{marginRight:'0.5rem'}} size={1.2} path={v.icon} />
                    <Translate path={`menu.${v.label}`} />
                </Item>)}
            </MenuItems>
        </MenuElement>
    </>
}