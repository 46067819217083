import { useEffect, useMemo, useRef, useState } from 'react';
import Icon from '@mdi/react';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { mdiClose, mdiQrcodeRemove, mdiQrcodeScan, mdiTransferUp, mdiTrashCan } from '@mdi/js';

import CardInvite from '../components/plans/Invite';
import ActivePlan from '../components/plans/activePlan';
import ModalDelete from '../modals/ModalDelete';
import useWebsocket from '../hooks/useWebsocket';
import CreditCardComponent from '../components/plans/creditCardComponent';
import { AppState } from '../redux/stores';
import { FormError } from '@pilarterapeutico/types';
import { setMessage } from '../redux/messagesReducer';
import { capitalize } from '@pilarterapeutico/util';
import { hideModal, setModalAndShow } from '../redux/modalReducer';
import { LastPaymentType, PlansItemType, ActivePlanType, ActiveDiscountType } from '../services/contracts/plans';
import { getPlans, getStatus, subscribeByPix, cancelPayment, subscribeByCreditCard, subscribeChange } from '../services/requests/plans';
import { Button, ButtonRow, Column, Input, Label, CopyInput, CPFInput, Row, RowOrColumn, Select, i18n } from "@pilarterapeutico/components";
import * as Tags from './styles/Plans.styles';

const ConfigPlansPayment = () => {
    
    const [plans, setPlans] = useState<PlansItemType[]>([]);
    const [discounts, setDiscounts] = useState<ActiveDiscountType>();
    const [isChangingPlan, setIsChangingPlan] = useState<boolean>(false);
    const [isChangingMethod, setIsChangingMethod] = useState<boolean>(false);
    const [activePlan, setActivePlan] = useState<ActivePlanType|PlansItemType>();
    const [dataPaymentId, setDataPaymentId] = useState<string>();
    const [dataPaymentMethod, setDataPaymentMethod] = useState<string>("");
    const [dataPayerName, setDataPayerName] = useState<string>("");
    const [dataPayerCPF, setDataPayerCPF] = useState<string>("");
    const [dataPixQrCode, setDataPixQrCode] = useState<string>("");
    const [dataPixCopiaCola, setDataPixCopiaCola] = useState<string>("");
    const [paymentStatus, setPaymentStatus] = useState<string>("unknown");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<FormError[]>([]);

    const userEmail = useSelector<AppState, string>(s => s.auth.email);

    const update = useRef<boolean>(true);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const websocket = useWebsocket();

    const paymentForm = useRef<any>();

    const getFirstError = useMemo(() => {
        return (key:string) => formErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
    }, [formErrors]);

    const handleSubmitPayment = async () => {
        setIsLoading(true);
        try {
            if (location.state?.changingPlan) {
                let res = await subscribeChange({
                    plan: location.state?.planChosenId ?? ''
                });

                if (!update.current) {
                    return;
                }

                if (res.error) {
                    dispatch(setMessage({message: i18n("error"), type: "error"}));
                } else {
                    navigate('/config/plans/active', {replace:true, state: {isChanged:true}});
                }
            } else {
                if (paymentForm.current) {
                    const pm = await paymentForm.current?.saveCreditCard();
                    const payer = paymentForm.current?.getPayerInfo();

                    paymentForm.current?.setLoading(true);
                    
                    if (!pm.success) {
                        throw new Error(i18n("config.plans.errorCreatePaymentMethod"));
                    }

                    let options = {
                        payData: pm.paymentMethod,
                        plan: location.state?.planChosenId ?? '',
                        cpf: payer.cpf.replace(/\D/gi,""),
                        paymentMethod: 'creditcard',
                        name: payer.name,
                    };

                    let res = await (isChangingMethod ? 
                        subscribeChange(options) 
                    : 
                        subscribeByCreditCard(options)
                    );

                    if (!update.current) {
                        return;
                    }

                    if (res.data) {
                        setPaymentStatus(res.data.payment?.payment_status ?? 'unknown');
                        setDataPaymentId(res.data.payment.payment_id ?? undefined);
                        if (res.data.payment.payment_status === 'succeeded') {
                            navigate('/config/plans/active', {replace:true, state: {isnew:true}});
                        }
                    } else if (res.error) {
                        dispatch(setMessage({message: i18n("error"), type: "error"}));
                    } else if (isChangingMethod) {
                        navigate('/config/plans/active', {replace:true, state: {isChangedMethod:true}});
                    }
                } else {
                    let options = {
                        plan: location.state?.planChosenId ?? '',
                        cpf: dataPayerCPF.replace(/\D/gi,""),
                        paymentMethod: 'pix',
                        name: dataPayerName,
                    };

                    let res = await (isChangingMethod ? 
                        subscribeChange(options) 
                    : 
                        subscribeByPix(options)
                    );

                    if (!update.current) {
                        return;
                    }

                    if (res.data) {
                        setPaymentStatus(res.data.payment?.payment_status ?? 'unknown');
                        setDataPaymentId(res.data.payment.payment_id ?? undefined);
                        setDataPixQrCode(res.data.payment.payment_qr_code_base64 ?? undefined);
                        setDataPixCopiaCola(res.data.payment.payment_qr_code ?? undefined);
                    } else if (res.error) {
                        dispatch(setMessage({message: i18n("error"), type: "error"}));
                    } else if (isChangingMethod) {
                        navigate('/config/plans/active', {replace:true, state: {isChangedMethod:true}});
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
        paymentForm.current?.setLoading(false);
        setIsLoading(false);
    };

    const handleCancelPayment = () => {
        setIsLoading(true);
        cancelPayment().then((res) => {
            if (!update.current) {
                return;
            }
            
            if (res.data) {
                setPaymentStatus(res.data.payment?.payment_status ?? 'unknown');
                setDataPaymentId(res.data.payment?.payment_id ?? undefined);
                setDataPaymentMethod(res.data.payment?.payment_method ?? '');
                setDataPixQrCode(res.data.payment?.payment_qr_code_base64 ?? undefined);
                setDataPixCopiaCola(res.data.payment?.payment_qr_code ?? undefined);

                location.state.payment = res.data.payment;

                dispatch(setMessage({message: i18n("config.plans.successCancelPayment"), type: "success"}));
                navigate('/config/plans/active',{replace: true});
            } else if (res.error) {
                dispatch(setMessage({message: res.error, type: "error"}));
            }
        }).finally(() => {
            setIsLoading(false);
            dispatch(hideModal());
        });
    }

    const handleBack = () => {
        navigate('/config/plans',{replace: true});
    }

    const handleCancel = () => {
        dispatch(setModalAndShow({
          content: <ModalDelete icon={mdiQrcodeRemove} cancelLabel={i18n("register.back")} confirmLabel={i18n("config.plans.cancelPayment.modalDelete.confirm")} i18nKey={"config.plans.cancelPayment"} onConfirm={handleCancelPayment} />,
        }))
    }

    const getPlanChangeType = useMemo(()=>{
        const newPlan = String(plans.find((p:PlansItemType) => p.id === location.state?.planChosenId)?.type ?? '').toUpperCase();
        const curPlan = String(location.state?.plan?.plan_type).toUpperCase();
        const levels = ['BASIC','PRO','PLATINUM'];

        if (levels.indexOf(curPlan) < 0 || levels.indexOf(newPlan) < 0) {
            return '';
        } else if (newPlan === 'BASIC') {
            return 'Basic';
        } else {
            let diff = levels.indexOf(curPlan) - levels.indexOf(newPlan);
            return diff > 0 ? "Downgrade" : (diff < 0 ? "Upgrade" : "");
        }
    }, [location.state, plans]);

    const planNames = useMemo(()=>{
        return [
            capitalize(location.state?.plan?.plan_type ?? ''),
            capitalize(plans.find((p:PlansItemType) => p.id === location.state?.planChosenId)?.type ?? '')
        ];
    }, [activePlan, plans]);

    useEffect(()=>{
        (async () => {
            if (!location.state?.planChosenId && !location.state?.changingMethod && !location.state?.payment) {
                navigate('/config/plans',{replace: true});
                return;
            }

            let plansList = location.state?.plans;

            if (!plansList) {
                let req = await getPlans();
                if (update.current && req.data?.items) {
                    setPlans(req.data.items);
                    plansList = req.data.items;
                }
            }

            const setPaymentData = (payment:LastPaymentType) => {
                if(!payment) {
                    return;
                }
                setPaymentStatus(payment.payment_status ?? 'unknown');
                setDataPaymentId(payment.payment_id ?? undefined);
                setDataPaymentMethod(payment.payment_method ?? '');
                setDataPixQrCode(payment.payment_qr_code_base64 ?? undefined);
                setDataPixCopiaCola(payment.payment_qr_code ?? undefined);
            };

            let payment = location.state?.payment;
            let discounts = location.state?.discounts;

            if (!payment || !discounts) {
                let req = await getStatus();
                if (update.current && req.data) {
                    payment = req.data?.payment;
                    discounts = req.data?.discounts;
                }
            }

            let plan = plansList.find((p:PlansItemType) => location.state?.planChosenId ? p.id === location.state?.planChosenId : p.type === location.state?.payment?.plan_type) ?? location.state?.plan ?? plansList[0];

            setPlans(plansList);
            setPaymentData(payment);
            setActivePlan(plan);
            setDiscounts(discounts);
        })();
    }, [location.state]);

    useEffect(()=>{ 
        update.current = true;

        setIsChangingPlan(location.state?.changingPlan ?? false);
        setIsChangingMethod(location.state?.changingMethod ?? false);
               
        websocket.start().then(() => {
            let userId = uuid();
            websocket.sendData('open', {userId: userEmail + '#' + userId});
            websocket.onReceiveData((msg:any) => {
                if (msg.type === 'data') {
                    setPaymentStatus(msg.status ?? 'unknown');

                    if (['approved','succeeded'].indexOf(msg.status) >= 0) {
                        navigate('/config/plans/active', {replace:true, state: {isNew:true}});
                    }
                }
            });
        });

        return () => {update.current = false}
    },[]);


    return <Tags.Content>
        <Tags.AreaTitle>{location.state?.changingMethod ? i18n("config.plans.changePaymentMethod") : i18n("config.plans.choosePlan")}</Tags.AreaTitle>
        <RowOrColumn rowAlign="stretch" columnAlign="stretch" rowJustify="stretch" columnJustify="flex-start" style={{marginBottom:"1rem"}}>
            <ActivePlan plan={activePlan} discount={discounts} />
            <Tags.PaymentForm>
                {isChangingPlan ? <>
                    <Label style={{width:"100%", marginBottom: "1.5rem"}}>
                        {i18n('config.plans.changingPlanText').replace(/\$1/gi,planNames[0]).replace(/\$2/gi,planNames[1])}<br /><br />
                        {i18n('config.plans.changingPlan'+getPlanChangeType).replace(/\$1/gi,planNames[1]).replace(/\$2/gi,planNames[0])}<br /><br />
                        {i18n('config.plans.changingPlanText2')}
                    </Label>
                </> : <></>}
                {!isChangingPlan ? <RowOrColumn rowAlign='flex-start' columnAlign='stretch'>
                    <Tags.Field style={{flex:1}}>
                        <Label>
                            {i18n("config.plans.labelMethod")}
                        </Label>
                        <Select 
                            uid='plans.paymentMethod'
                            value={dataPaymentMethod} 
                            readOnly={!!dataPaymentId}
                            onChange={(e)=>{setDataPaymentMethod((e.target as HTMLSelectElement).value)}} 
                        >
                            <option value=""></option>
                            <option value="creditcard">{i18n("config.plans.paymentsCard")}</option>
                            <option value="pix">{i18n("config.plans.paymentsPix")}</option>
                        </Select>
                    </Tags.Field>
                    <Tags.FieldText style={{flex:2}}>
                        {i18n(`config.plans.paymentDesc${dataPaymentMethod}`).replace("$1",String(new Date().getDate()))}
                    </Tags.FieldText>
                </RowOrColumn> : <></>}
                <RowOrColumn style={{marginTop:"1rem"}} rowAlign='flex-start'>
                    {dataPaymentMethod === 'pix' ? <>
                        <Tags.PixQRCode>
                            {dataPixQrCode ? <img width="100%" alt="" src={`data:image/jpeg;base64,${dataPixQrCode}`} /> : <>
                                {!isLoading ? 
                                    <Icon path={mdiQrcodeScan} color="var(--theme-color-font-empty)" size={1.6} style={{marginBottom:"1rem"}} />
                                : <Tags.LoadingIndicator />}
                                <Tags.PixQRCodeLabel>{i18n(!isLoading ? "config.plans.labelQrCode" : "loading")}</Tags.PixQRCodeLabel>
                            </>}
                        </Tags.PixQRCode>
                        <div style={{flex:2}}>
                            <RowOrColumn rowAlign='flex-start' rowJustify='stretch' columnAlign='stretch' columnJustify='flex-start'>
                                {dataPixCopiaCola ? <>
                                    <Column style={{width:"100%"}}>
                                        <Label style={{width:"100%", marginBottom: "0.5rem"}}>
                                            Status: <b>{i18n(`config.plans.mpStatus.${paymentStatus}`)}</b>
                                        </Label>
                                        <CopyInput style={{width:"100%"}} uid="plan.pixcopypaste" value={dataPixCopiaCola} />
                                        <Tags.FieldText style={{margin:"0.5rem 0", height:"auto"}}>
                                            {i18n(`config.plans.pixText2`)}
                                        </Tags.FieldText>
                                    </Column>
                                </> : <>
                                    <Tags.Field style={{flex:1}}>
                                        <Label>
                                            {i18n("config.plans.labelPayerName")}
                                        </Label>
                                        <Input 
                                            uid='plans.payerName'
                                            value={dataPayerName} 
                                            onChange={(e)=>{setDataPayerName((e.target as HTMLSelectElement).value)}} 
                                            formError={getFirstError("holderName")}
                                        />
                                    </Tags.Field>
                                    <Tags.Field style={{flex:1}}>
                                        <Label>
                                            {i18n("config.plans.labelPayerCPF")}
                                        </Label>
                                        <CPFInput
                                            uid='plans.payerCPF'
                                            value={dataPayerCPF} 
                                            onChange={(e)=>{setDataPayerCPF((e.target as HTMLSelectElement).value)}} 
                                            formError={getFirstError("holderCPF")}
                                        />
                                    </Tags.Field>
                                </>}
                            </RowOrColumn>
                            { !dataPixQrCode ? <Tags.FieldText style={{marginTop:0, height:"auto"}}>
                                {i18n(`config.plans.${dataPaymentMethod}Text1`)}
                            </Tags.FieldText> : <></>}
                        </div>
                    </> : <></>}
                    {dataPaymentMethod === 'creditcard' ? <>
                        {!dataPaymentId ?
                            <CreditCardComponent ref={paymentForm} />
                        : 
                            <Column align='stretch' style={{width:"100%"}}>
                                <Label style={{width:"100%", marginBottom: "0.5rem"}}>
                                    Status: <b>{paymentStatus}</b>
                                </Label>
                                <Label style={{width:"100%", marginBottom: "0.5rem"}}>
                                    Card info here {dataPaymentId}
                                </Label>
                            </Column>}
                    </> : <></>}
                </RowOrColumn>
                <div style={{flex:1}}></div>
                {dataPaymentMethod !== '' || isChangingPlan ? <ButtonRow style={{width:"100%", justifyContent:"space-between", marginTop:"2rem"}}>
                    <Tags.ButtonWrapper>
                        {dataPaymentId ? 
                            <Button icon={mdiTrashCan} disabled={isLoading} color="var(--theme-color-button-red)" onClick={()=>handleCancel()}>{i18n("cancel")}</Button>
                        : null}
                    </Tags.ButtonWrapper>
                    <Tags.ButtonWrapper>
                        {!dataPaymentId ? <>
                            <Button icon={mdiClose} disabled={isLoading} color="var(--theme-color-button-gray)" onClick={()=>handleBack()}>{i18n("back")}</Button>
                            <Button icon={mdiTransferUp} disabled={isLoading} color="var(--theme-color-button-green)" onClick={()=>handleSubmitPayment()}>{i18n(isChangingPlan ? "change" : "config.plans.subscribe")}</Button>
                        </> : null}
                    </Tags.ButtonWrapper>
                </ButtonRow> : <></>}
            </Tags.PaymentForm>
        </RowOrColumn>
        {discounts ? <CardInvite discount={discounts} /> : null}
    </Tags.Content>
}

export default ConfigPlansPayment;