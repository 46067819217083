import * as yup from 'yup';
import { i18n } from '../util/util';

const LoginFormSchema = () => yup.object({
    email: yup.string()
        .email(i18n('register.emailInvalid'))
        .required(i18n('register.emptyEmail')),
    password: yup.string()
        .min(6, i18n('register.minLengthPassword'))
        .max(32, i18n('register.maxLengthPassword'))
        .required(i18n('register.emptyPassword')),
});

export default LoginFormSchema;