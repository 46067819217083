import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import axios from '../services/requests/interceptors';
import LoginFormSchema from "../forms/loginForm";
import { login } from "../redux/authReducer";
import { AppState } from '../redux/stores';
import { FormError } from "@pilarterapeutico/types";
import { postLogin } from "../services/requests/login";
import { setMessage } from "../redux/messagesReducer";
import { LoginFormType } from "../types/LoginDataType";
import { RequestHandler } from "../services/contracts";
import { PostRegisterOrLoginResponse } from "../services/contracts/login";
import { Button, Input, Loading, Message, Row, Title, Translate, i18n } from "@pilarterapeutico/components";
import { Login, Banner, Sidebar, Logo, Ilustration, Content, Text, Label, ForgetLink, SimpleLink } from "./styles/Login.styles";

const LoginPage = () => {

    const [dataEmail, setDataEmail] = useState<string>("");
    const [dataPassword, setDataPassword] = useState<string>("");
    const [dataErrors, setDataErrors] = useState<FormError[]>([]);

    const token = useSelector<AppState>(s => s.auth.accessToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const refPass = useRef<HTMLInputElement>(null);

    const validateAll = (data:LoginFormType) => {
        try {
            LoginFormSchema().validateSync(data, {abortEarly:false});
            return true;
        } catch (e:any) {
            if (e.inner && Array.isArray(e.inner)) {
                setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
            }
            return false;
        }
    };

    const getFistError = useMemo(() => {
        return (key:string) => dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
    }, [dataErrors]);

    const handleSubmitForm = async () => {
        const data:LoginFormType = {
            email: dataEmail,
            password: dataPassword
        };
    
        setDataErrors([]);
    
        const isValidForm = validateAll(data);
        if (!isValidForm) {
            return;
        }
    
        let response:RequestHandler<PostRegisterOrLoginResponse> = await postLogin(data);
    
        if (response.error) { 
            if (response.type === 'form') {
              setDataErrors((response.error ?? []) as FormError[]);
            } else {
                setDataPassword('');
              dispatch(setMessage({message: response.error ?? '', type: "error"}));
            }
        } else {
            if (response.data?.accessToken) {
                dispatch(login({
                    instance: axios,
                    data: {
                        ...response.data
                    }
                }));
            }
        }
    }

    const handleJumpToPassword = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            (event.target as HTMLInputElement).blur();
            refPass.current?.focus();
        }
    }

    const handleSubmitOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            (event.target as HTMLInputElement).blur();
            handleSubmitForm();
        }
    }

    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token, navigate]);

    return <Login>
        <Banner>
            <Logo className="register">
                <Link to="https://pilarterapeutico.com.br">
                    <img src="/img/logo.svg" alt="" width="100%" />
                </Link>
            </Logo>
            <Ilustration src="/img/banner.svg" />
        </Banner>
        <Sidebar>
            <Content>
                <Title className="uppercase">
                    <Translate path="login.title"/>
                </Title>
                <Text>
                    <Translate path="login.message"/>
                </Text>
                <Label>
                    <Translate path="login.email"/>
                </Label>
                <Input 
                    uid="login.email"
                    value={dataEmail} 
                    type="email"
                    formError={getFistError('email')}
                    onChange={(e)=>{setDataEmail((e.target as HTMLInputElement).value)}} 
                    onKeyDown={(e)=>handleJumpToPassword(e)}
                    />
                <Label>
                    <Translate path="login.password"/>
                </Label>
                <Input
                    uid="login.password"
                    className="noMargin"
                    value={dataPassword}
                    type="password"
                    reference={refPass}
                    formError={getFistError('password')}
                    onChange={(e)=>{setDataPassword((e.target as HTMLInputElement).value)}}
                    onKeyDown={(e)=>handleSubmitOnEnter(e)}
                />
                <ForgetLink>
                    <Link to='/forgot'>
                        <Translate path="login.forget"/>
                    </Link>
                </ForgetLink>
                <Row justify="space-between">
                    <SimpleLink>
                        <Link to="/register">
                            <Translate path="login.create"/>
                        </Link>
                    </SimpleLink>
                    <div></div>
                    <Button 
                        size={7}
                        color="var(--theme-color-primary)"
                        onClick={()=>{handleSubmitForm()}}>
                        <Translate path="login.enter"/>
                    </Button>
                </Row>
            </Content>
        </Sidebar>
        <Loading />
        <Message />
    </Login>
}

export default LoginPage;
