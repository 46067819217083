import { getPatientData } from "../services/requests/patients";

interface WebsocketSingletonType {
    _ws?: Promise<WebSocket>,
    _callbacks: ((msg:any)=>void)[],
    _connect: () => Promise<WebSocket>,
    start: () => Promise<WebSocket>,
    sendData: (type:string, content: any) => Promise<void>,
    onReceiveData: (fn: (msg:any) => void) => void,
};

const websocketSingleton: WebsocketSingletonType  = {
    _ws: undefined,
    _callbacks: [],

    _connect: async () => {
        return new Promise<WebSocket>((res) => {
            websocketSingleton._callbacks = [];
            let ws = new WebSocket(import.meta.env.VITE_WEBSOCKET_URL);
                
            ws.onopen = () => {
                res(ws);
            };

            ws.onclose = () => {};

            ws.onerror = (e) => {
                console.error('Error on websocket:', e);
            };

            ws.onmessage = (e) => {
                let data = JSON.parse(e.data);
                for(let fn of websocketSingleton._callbacks) {
                    fn(data);
                }
            };
        });
    },

    start: async () => {
        if (websocketSingleton._ws !== undefined) {
            let state = (await websocketSingleton._ws)?.readyState;

            if (state === WebSocket.OPEN) {
                return websocketSingleton._ws;
            }
        }

        websocketSingleton._ws = websocketSingleton._connect();
        return websocketSingleton._ws;
    },

    sendData: async (type:string, content: object) => {
        if (websocketSingleton._ws === undefined) {
            return;
        }

        let ws = await websocketSingleton._ws;
        ws.send(JSON.stringify({type, ...content}));
    },

    onReceiveData: (fn: (msg:any) => void) => {
        if (websocketSingleton._ws === undefined) {
            return;
        }

        websocketSingleton._callbacks.push(fn);
    }
};

let useWebsocket = () => {
    const state = websocketSingleton;
    return state;
}

export default useWebsocket;