import { useEffect, useRef, useState } from 'react';
import Icon from '@mdi/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mdiCheck, mdiMinus, mdiTransferDown, mdiTransferUp } from '@mdi/js';

import CardInvite from '../components/plans/Invite';
import { getStatus, getPlans } from '../services/requests/plans';
import { ActiveDiscountType, PlansItemType } from '../services/contracts/plans';
import { Button, FilterBar, FilterRow, RowOrColumn, i18n } from "@pilarterapeutico/components";
import * as Tags from './styles/Plans.styles';


const ConfigPlans = () => {
    
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [plans, setPlans] = useState<PlansItemType[]>([]);
    const [discounts, setDiscounts] = useState<ActiveDiscountType>();
    const [currentPlan, setCurrentPlan] = useState<string>();
    const [isChangingPlan, setIsChangingPlan] = useState<boolean>(false);
    
    const navigate = useNavigate();
    const location = useLocation();
    const update = useRef<boolean>(true);
    const locationState = useRef<any>({});

    const getAttributeForPlan = (type: string, attr: string) => {
        for(let i in plans) {
            if (plans[i].type === type) {
                return plans[i][attr] ?? '';
            }
        }
    };

    const handleGoToPlan = (id:string, slug:string) => {
        let state = location.state;
        navigate(`/config/plans/payment`, {state: {
            ...state,
            ...locationState.current,
            planType: String(slug).toUpperCase(),
            planChosenId: id,
            changingPlan:isChangingPlan,
            plans: plans,
            discounts: discounts,
        }});
    }

    useEffect(() => {
        update.current = true;
        setIsLoading(true);

        const isChanging:boolean = location.state?.changingPlan ?? false;
        setIsChangingPlan(isChanging);

        
        getStatus().then((req) => {
            if (!update.current || !req.data) {
                return;
            }
            
            setIsLoading(false);
            if(req.data.payment && req.data.payment?.payment_status !== 'approved' && req.data.payment?.payment_status !== 'succeeded') {
                navigate('/config/plans/payment', {replace:true, state: {...location.state, ...req.data}});
            } else if (req.data?.plan?.plan_type !== 'BASIC' && !location.state?.changingPlan) {
                navigate(`/config/plans/active`, {replace:true, state: {...location.state, ...req.data}});
            } else {
                locationState.current = {...req.data};
                setCurrentPlan(req.data?.plan?.plan_type);
                getPlans().then(req => {
                    if (update.current) {
                        setPlans(req.data?.items ?? []);
                        setIsLoading(false);
                    }
                });
            }

            if (req.data?.discounts) {
                setDiscounts(req.data?.discounts);
            }
        });
        
        return () => {update.current = false}
    }, []);

    if (isLoading) {
        return <></>;
    }

    return <Tags.Content>
        {!isChangingPlan ? <FilterBar>
            <FilterRow>{i18n("config.plans.basicHeader")}</FilterRow>
        </FilterBar> : <Tags.PageLabel>{i18n("config.plans.changeLabel")}</Tags.PageLabel>}
        <Tags.Content style={{marginBottom:"1rem",backgroundColor:"var(--theme-color-background-aux3)"}}>
            <Tags.AreaTitle>{i18n("config.plans.plansTitle")}</Tags.AreaTitle>
            <RowOrColumn rowAlign="stretch" columnAlign="stretch" rowJustify="stretch" columnJustify="flex-start">
                <Tags.PlanWrapper>
                    <Tags.PlanName>Basic</Tags.PlanName>
                    <Tags.PlanDesc>{i18n("config.plans.planBasicDesc")}</Tags.PlanDesc>
                    <Tags.PlanResources>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planBasicRow1").replace('$1', String(getAttributeForPlan('BASIC','limit_max_patients') ?? ''))}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planBasicRow2").replace('$1', String(getAttributeForPlan('BASIC','limit_max_week_events') ?? ''))}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planBasicRow3").replace('$1', String(getAttributeForPlan('BASIC','limit_max_financial_history') ?? ''))}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planRow4")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planRow5")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiMinus} size={1} color="var(--theme-color-font-empty)" /><span>{i18n("config.plans.planRow6")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiMinus} size={1} color="var(--theme-color-font-empty)" /><span>{i18n("config.plans.planRow7")}</span>
                        </Tags.PlanResItem>
                    </Tags.PlanResources>
                    <Tags.PlanPrice>
                        <Tags.PlanPriceSmall>R$</Tags.PlanPriceSmall>
                        <Tags.PlanPriceBig>{String(getAttributeForPlan('BASIC','price') ?? '').replace('.',',')}</Tags.PlanPriceBig>
                        <Tags.PlanPriceSmall>/mês</Tags.PlanPriceSmall>
                    </Tags.PlanPrice>
                    {currentPlan === 'BASIC' || !currentPlan ? <Tags.CurrentPlan>{i18n("config.plans.yourCurrentPlan")}</Tags.CurrentPlan> : <Tags.ButtonRowCenter>
                        <Button size={7} icon={mdiTransferDown} onClick={()=>{handleGoToPlan(String(getAttributeForPlan('BASIC','id')),'BASIC')}} color="var(--theme-color-primary)">{i18n("config.plans.subscribe")}</Button>
                    </Tags.ButtonRowCenter>}
                </Tags.PlanWrapper>
                <Tags.PlanWrapper>
                    <Tags.PlanName>Pro</Tags.PlanName>
                    <Tags.PlanDesc>{i18n("config.plans.planProDesc")}</Tags.PlanDesc>
                    <Tags.PlanResources>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planProRow1")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planProRow2")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planProRow3")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planRow4")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planRow5")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiMinus} size={1} color="var(--theme-color-font-empty)" /><span>{i18n("config.plans.planRow6")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiMinus} size={1} color="var(--theme-color-font-empty)" /><span>{i18n("config.plans.planRow7")}</span>
                        </Tags.PlanResItem>
                    </Tags.PlanResources>
                    <Tags.PlanPrice>
                        <Tags.PlanPriceSmall>R$</Tags.PlanPriceSmall>
                        <Tags.PlanPriceBig>{String(getAttributeForPlan('PRO','price') ?? '').replace('.',',')}</Tags.PlanPriceBig>
                        <Tags.PlanPriceSmall>/mês</Tags.PlanPriceSmall>
                    </Tags.PlanPrice>
                    {currentPlan === 'PRO' ? <Tags.CurrentPlan>{i18n("config.plans.yourCurrentPlan")}</Tags.CurrentPlan> : <Tags.ButtonRowCenter>
                        <Button size={7} icon={currentPlan === 'PLATINUM' ? mdiTransferDown : mdiTransferUp} onClick={()=>{handleGoToPlan(String(getAttributeForPlan('PRO','id')),'PRO')}} color="var(--theme-color-primary)">{i18n("config.plans.subscribe")}</Button>
                    </Tags.ButtonRowCenter>}
                </Tags.PlanWrapper>
                <Tags.PlanWrapper>
                    <Tags.PlanName>Platinum</Tags.PlanName>
                    <Tags.PlanDesc>{i18n("config.plans.planPlatinumDesc")}</Tags.PlanDesc>
                    <Tags.PlanResources>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planProRow1")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planProRow2")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planProRow3")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planRow4")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planRow5")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planRow6")}</span>
                        </Tags.PlanResItem>
                        <Tags.PlanResItem>
                            <Icon path={mdiCheck} size={1} color="var(--theme-color-font)" /><span>{i18n("config.plans.planRow7")}</span>
                        </Tags.PlanResItem>
                    </Tags.PlanResources>
                    <Tags.PlanPrice>
                        <Tags.PlanPriceSmall>R$</Tags.PlanPriceSmall>
                        <Tags.PlanPriceBig>{String(getAttributeForPlan('PLATINUM','price') ?? '').replace('.',',')}</Tags.PlanPriceBig>
                        <Tags.PlanPriceSmall>/mês</Tags.PlanPriceSmall>
                    </Tags.PlanPrice>
                    <Tags.ButtonRowCenter>
                        <Tags.PlanSoon>{i18n('config.plans.soon')}</Tags.PlanSoon>
                    </Tags.ButtonRowCenter>
                    {/*currentPlan === 'PLATINUM' ? <Tags.CurrentPlan>{i18n("config.plans.yourCurrentPlan")}</Tags.CurrentPlan> : <Tags.ButtonRowCenter>
                        <Button size={7} icon={mdiTransferUp} onClick={()=>{handleGoToPlan(String(getAttributeForPlan('PLATINUM','id')),'PLATINUM')}} color="var(--theme-color-primary)">{i18n("config.plans.subscribe")}</Button>
                    </Tags.ButtonRowCenter>*/}
                </Tags.PlanWrapper>
            </RowOrColumn>
        </Tags.Content>
        {discounts ? <CardInvite discount={discounts} /> : null}
    </Tags.Content>
}

export default ConfigPlans;
