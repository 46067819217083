import { useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiCheck, mdiClose } from '@mdi/js';

import * as Tags from './styles';
import { useEffect, useRef, useState } from 'react';

interface MessageProps {
    onPop?: () => void;
}

export const Message:React.FC<MessageProps> = ({onPop}) => {

    const messageContent = useSelector<any, string>(s => s.messages.message);
    const messageType = useSelector<any>(s => s.messages.type);
    const messageTime = useSelector<any, number>(s => s.messages.time);

    const [show, setShow] = useState<boolean>(false);

    const autoCloseTimer = useRef<number>();
    const firstRender = useRef<boolean>(true);

    useEffect(()=> {
        if (messageTime > 0 && !firstRender.current) {
            setShow(true);

            autoCloseTimer.current = setTimeout(() => {
                setShow(false);
                setTimeout(() => {
                    if (onPop) {
                        onPop();
                    }
                }, 500);
            }, 5000);
        }

        return () => {
            clearTimeout(autoCloseTimer.current);
        }
    }, [messageTime]);

    useEffect(()=> {
        if (firstRender.current) {
            firstRender.current = false;
        }
    }, []);

    const getIcon = () => {
        switch(messageType) {
            case "success": return mdiCheck;
            case "error": return mdiClose;
            default: return mdiClose;
        }
    }

    return <Tags.Message className={ (show ? 'active ' : '') + messageType}>
        <Icon size={1} path={getIcon()} />
        {messageContent}
    </Tags.Message>  
};