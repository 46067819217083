import axios from "axios";

import URLs from '../../urls';
import store from '../../redux/stores';
import { RequestHandler } from '../contracts';
import { startLoading, stopLoading} from '../../redux/loadingReducer';
import { GetActivitiesResponse, PostActivitiesResponse, GetActivityDataResponse, GetActivitiesTemplateListResponse, ActivitiesTemplateItem, GetActivitiesTemplateDataResponse } from "../contracts/activities";
import ActivityDataType from "../../types/ActivityDataType";
import { RequestJsonAsForm } from "../../util/util";

export const getActivities = async (patient_id:string, page?: number): Promise<RequestHandler<GetActivitiesResponse>> => {
    const result = new RequestHandler<GetActivitiesResponse>();
    try {
        store.dispatch(startLoading({key:'activity.getActivities'}));
        const data = {page};
        const res = await axios.get(URLs.patients.activities.index(patient_id), {params: data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'activity.getActivities'}));
    }
    return result;
}

export const getActivityData = async (id: string, patient_id:string): Promise<RequestHandler<GetActivityDataResponse>> => {
    const result = new RequestHandler<GetActivityDataResponse>();
    try {
        store.dispatch(startLoading({key:'activity.getActivityData'}));
        const res = await axios.get(URLs.patients.activities.data(id, patient_id));
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'activity.getActivityData'}));
    }
    return result;
}

export const createActivity = async (data: ActivityDataType, patient_id:string): Promise<RequestHandler<PostActivitiesResponse>> => {
    const result = new RequestHandler<PostActivitiesResponse>();
    try {
        store.dispatch(startLoading({key:'activity.createActivity'}));
        const res = await axios.post(URLs.patients.activities.index(patient_id), RequestJsonAsForm(data), {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'activity.createActivity'}));
    }
    return result;
}

export const updateActivity = async (data: ActivityDataType, patient_id:string): Promise<RequestHandler<PostActivitiesResponse>> => {
    const result = new RequestHandler<PostActivitiesResponse>();
    try {
        store.dispatch(startLoading({key:'activity.updateActivity'}));
        const res = await axios.put(URLs.patients.activities.data(data.id ?? '', patient_id), RequestJsonAsForm(data), {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'activity.updateActivity'}));
    }
    return result;
}

export const deleteActivity = async (id:string, patient_id:string): Promise<RequestHandler<PostActivitiesResponse>> => {
    const result = new RequestHandler<PostActivitiesResponse>();
    try {
        store.dispatch(startLoading({key:'activity.deleteActivity'}));
        const res = await axios.delete(URLs.patients.activities.data(id, patient_id));
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'activity.deleteActivity'}));
    }
    return result;
}

export const listActivityTemplate = async (type?:'activity'|'anamnesis'): Promise<RequestHandler<GetActivitiesTemplateListResponse>> => {
    const result = new RequestHandler<GetActivitiesTemplateListResponse>();
    try {
        store.dispatch(startLoading({key:'activitytemplate.listActivityTemplate'}));
        let data = type ? {anamnesis: (type === 'activity' ? 'false' : 'true')} : {};
        const res = await axios.get(URLs.config.templates.index,{params:data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'activitytemplate.listActivityTemplate'}));
    }
    return result;
}

export const updateTemplate = async (data:ActivitiesTemplateItem): Promise<RequestHandler<GetActivitiesTemplateListResponse>> => {
    const result = new RequestHandler<GetActivitiesTemplateListResponse>();
    try {
        store.dispatch(startLoading({key:'activitytemplate.updateTemplate'}));
        if (!data.id) {
            throw new Error("Id is missing");
        }
        const res = await axios.put(URLs.config.templates.data(data.id), data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'activitytemplate.updateTemplate'}));
    }
    return result;
}

export const createTemplate = async (data:ActivitiesTemplateItem): Promise<RequestHandler<GetActivitiesTemplateListResponse>> => {
    const result = new RequestHandler<GetActivitiesTemplateListResponse>();
    try {
        store.dispatch(startLoading({key:'activitytemplate.createTemplate'}));
        const res = await axios.post(URLs.config.templates.index, data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'activitytemplate.createTemplate'}));
    }
    return result;
}

export const getTemplateData = async (id:string): Promise<RequestHandler<GetActivitiesTemplateDataResponse>> => {
    const result = new RequestHandler<GetActivitiesTemplateDataResponse>();
    try {
        store.dispatch(startLoading({key:'activitytemplate.getTemplateData'}));
        const res = await axios.get(URLs.config.templates.data(id));
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'activitytemplate.getTemplateData'}));
    }
    return result;
}

export const deleteTemplate = async (id:string): Promise<RequestHandler<GetActivitiesTemplateDataResponse>> => {
    const result = new RequestHandler<GetActivitiesTemplateDataResponse>();
    try {
        store.dispatch(startLoading({key:'activitytemplate.deleteTemplate'}));
        const res = await axios.delete(URLs.config.templates.data(id));
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'activitytemplate.deleteTemplate'}));
    }
    return result;
}
