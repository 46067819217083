export default {
    translations: {
        error: "Ops. erro inexperado :(",
        locale: "pt-BR",
        yes: "Sim",
        no: "Não",
        or: "ou",
        and: "e",
        active: "Ativo",
        inactive: "Inativo",
        cancel: "Cancelar",
        close: "Fechar",
        new: "Novo",
        save: "Salvar",
        delete: "Excluir",
        filter: "Filtrar",
        change: "Alterar",
        clear: "Limpar",
        loading: "Carregando...",
        upload: "Enviar arquivo",
        auto: "Automático",
        copy: "Copiar",
        login: {
            title: 'Entrar',
            message: 'Para acessar, você precisa ter um perfil. Caso já tenha criado o seu, faça o login para continuar. Se ainda não tem, basta clicar em "Criar seu perfil" mais abaixo e  criar o seu.',
            email: 'Email',
            password: 'Senha',
            invalidEmail: 'Ops, este email parece incorreto',
            forget: 'Esqueci minha senha',
            enter: 'Entrar',
            create: 'Criar sua conta',
        },
        register: {
            title: "Criar nova conta",
            message: "Vamos criar sua conta no Pila Terapêutico? É rápido e, após realizar o cadastro, você poderá utilizar os recursos da plataforma, configurar tudo do seu jeito e organizar seus atendimentos. Lembre-se de usar seu melhor email, pois você receberá instruções importantes logo que finalizar seu cadastro.",
            name: "Nome",
            surname: "Sobrenome",
            email: "Email",
            password: "Senha",
            confPassword: "Confirmar Senha",
            crp: "CRP",
            state: "Estado",
            back: "Voltar",
            create: "Criar Perfil",
            invalidName: "Por favor, informe seu nome",
            invalidSurname: "Por favor, informe seu sobrenome",
            invalidEmail: "Este email parece inválido",
            invalidPassword: "Senha é obrigatória",
            invalidConfPassword: "Senhas não conferem",
            invalidCRP: "CRP é obrigatório",
            invalidCRPUF: "Estado é obrigatório",
            minLengthName: "Nome muito curto",
            maxLengthName: "Nome muito longo",
            emptyName: "Nome é obrigatório",
            minLengthSurname: "Sobrenome muito curto",
            maxLengthSurname: "Sobrenome muito longo",
            emptySurname: "Sobrenome é obrigatório",
            emailInvalid: "Email invalido",
            emptyEmail: "Email é obrigatório",
            minLengthPassword: "Senha muito curta",
            maxLengthPassword: "Senha muito longa",
            emptyPassword: "Senha é obrigatório",
            passwordMismatch: "Senhas não conferem",
            emptyPasswordConfirm: "Senha é obrigatória",
            emptyCRP: "CRP é obrigatório",
            emptyCRPUF: "Estado é obrigatório",
        },
        forgot: {
            title: "Esqueci minha senha",
            message: "Podemos definir uma nova senha para que você continue a ter acesso à plataforma. Para isso, vamos enviar um código de confirmação para o email da sua conta. Este código será utilizado para validar sua identidade e, após isso, você poderá definir uma nova senha.",
            askMail: "Por tanto, precisamos que você forneça seu email de acesso.",
            askCode: "Ótimo, agora verifique seu email e insira o código que enviamos para você.",
            code: "Código de confirmação",
            cancelation: "Se você errar o código algumas vezes, não se preocupe. O código atual será cancelado e você poderá gerar um novo código.",
            success: "Senha alterada com sucesso!"
        },
        menu: {
            logo: 'Logo do Pilar Terapêutico',
            home: 'Início',
            patients: 'Pacientes',
            payments: 'Pagamentos',
            calendar: 'Agenda',
            config: 'Configurações',
        },
        topbar: {
            search: "Buscar por...",
            logout: "Sair",
            patients: "Pacientes",
            activities: "Atividades",
            payments: "Pagamentos",
            appointments: "Consultas",
            resources: "Recursos",
            birthdate: "Aniversário",
            appointment: "Consulta em",
            dueDate: "Vencimento",
            value: "Valor",
            patient: "Paciente",
            profile: "Meu perfil",
            changeProfile: "Acessar como Paciente",
            help: "Ajuda",
        },
        help: {
            title: "Precisa de ajuda?",
            text: "Ficou com alguma dúvida ou algo não saiu como o esperado? Entre em contato com a gente pelo formulário a seguir, pelo email, whatsapp ou pelas redes sociais do Pilar Terapêutico.",
            send: "Enviar",
            subject: "Assunto",
            message: "Mensagem",
            error: "Ops, algo deu errado ao enviar sua mensagem",
            error2: "Ops, parece que você esqueceu de preencher algum campo",
            success: "Sua mensagem foi recebida com sucesso! Em breve entraremos em contato.",
        },
        home: {
            night: "Boa noite, ",
            morning: "Bom dia, ",
            afternoon: "Boa tarde, ",
            nextBirthdates: "Próximos Aniversários",
            emptyBirthdates: "Não há aniversários nos próximos dias",
            schedule: "Resumo da Agenda",
            emptySchedule: "Você não tem consultas agendadas",
            payments: "Resumo de Pagamentos",
            emptyPayments: "Não há contas a pagar ou receber",
            isBasic: "No momento, você está utilizando o Plano Basic do Pilar Terapeutico. Faça o upgrade para aproveitar todos os recursos disponíveis na plataforma.",
            showPayment: "O Código QR para pagamento de sua assinatura já está disponível. Clique aqui para visualizar.",
        },
        patients: {
            title: "Pacientes",
            hasFilter: "Os seguintes filtros estão aplicas à lista de pacientes:",
            filter: "Filtrar",
            new: "Novo Paciente",
            edit: "Editar Paciente",
            showing: "Exibindo $1 de $2 pacientes",
            limit: "Seu limite é de $1 pacientes ativos",
            showingFilter: "(com filtros). Total $1",
            none: "Você ainda não tem nenhum paciente vinculado.",
            modalFilter: {
                title: "Filtrar pacientes",
                labelActive: "Ativo",
                labelName: "Nome",
                labelBirthMonth: "Aniversário em",
                labelAge: "Idade",
                labelMin: "Maior que",
                labelMax: "Menor que",
                labelValue: "Valor padrão",
                labelFrequency: "Frequência de pagamento",
                labelDue: "Vencimento padrão",
                labelMethod: "Forma de pagamento",
                labelAppointmentDate: "Data da próxima consulta",
                labelPayments: "Com pagamentos",
                activeBoth: "Indiferente",
                activeTrue: "Apenas ativos",
                activeFalse: "Apenas inativos",
                frequencyAppointment: "Por consulta",
                frequencyMonth: "Por mês",
                frequencyManual: "Manual",
                dueSameday: "Mesmo dia",
                dueNextweek: "Próxima semana",
                dueMonthDay5: "Dia 5 de cada mês",
                dueMonthDay10: "Dia 10 de cada mês",
                dueMonthDay15: "Dia 15 de cada mês",
                dueLastMonthDay: "Último dia de cada mês",
                methodPix: "Pix",
                methodCredit: "Cartão de Crédito",
                methodMoney: "Dinheiro",
                paymentOutOfData: "Vencidos",
                paymentDue: "Por vencer"
            },
            filterValues: {
                frequencyAppointment: "Frequência de pagamento por consulta",
                frequencyMonth: "Frequência de pagamento por mês",
                frequencyManual: "Frequência de pagamento manual",
                dueSameday: "Vencimento padrão no mesmo dia",
                dueNextweek: "Vencimento padrão em 1 semana",
                dueMonthDay5: "Vencimento padrão no dia 5 do mês",
                dueMonthDay10: "Vencimento padrão no dia 10 do mês",
                dueMonthDay15: "Vencimento padrão no dia 15 do mês",
                dueLastMonthDay: "Vencimento padrão no último dia do mês",
                methodPix: "Método de pagamento por pix",
                methodCredit: "Método de pagamento por cartão",
                methodMoney: "Método de pagamento em dinheiro",
                paymentOutOfData: "Com pagamentos em atraso",
                paymentDue: "Com pagamentos por vencer",
                birthMonth: "Mês de aniversário",
                name: "Nome",
                ageMin: "Idade maior que",
                ageMax: "Idade menor que",
                valueMax: "Valor padrão menor que",
                valueMin: "Valor padrão maior que",
                appointmentDateMin: "Próxima consulta depois de",
                appointmentDateMax: "Próxima consulta antes de",
                active: "Ativo",
            }
        },
        patientView: {
            profileTitle: "Perfil do Paciente",
            scheduleTitle: "Agendamento recorrente",
            nextAppointmentsTitle: "Próximos Horários",
            activitiesTitle: "Atividades",
            appointmentTitle: "Consultas",
            paymentTitle: "Pagamentos",
            activitiesNoData: "Sem atividades registradas",
            scheduleNoData: "Sem horários agendados",
            appointmentNoData: "Sem consultas realizadas",
            appointmentNextNoData: "Sem consultas agendadas",
            paymentNoData: "Sem pagamentos registrados",
            labelAge: "Idade",
            labelYearsOld: " anos",
            labelBirthdate: "Nascimento",
            labelEmail: "Email",
            labelAllowAccess: "Acesso habilitado",
            labelWhatsapp: "Whatsapp",
            labelPhone: "Telefone",
            labelPatientSince: "Paciente desde",
            buttonEdit: "Editar",
            buttonInactive: "Inativar",
            buttonActive: "Reativar",
            profileData: "Dados pessoais",
            unableToInactive: "Ops, não foi possivel inativar o paciente",
            unableToActive: "Ops, não foi possivel ativar o paciente",
            activeSuccess: "Paciente reactivado com sucesso!",
            inactiveSuccess: "Paciente desativado com sucesso!",
            inactive: {
                inactiveOk: "Inativar",
                modalDelete: {
                    title: "Deseja realmente inativar este paciente?",
                    description: "Pacientes desativados não terão mais consultas agendadas, mas seus históricos permanecerão acessíveis e inalterados."
                }
            },
            active: {
                activeOk: "Reativar",
                modalDelete: {
                    title: "Deseja realmente reativar este paciente?",
                    description: "Este pacientes voltará a aparecer nas pesquisas e seu histórico será recuperado."
                }
            },
            defaultPayment: {
                title: "Padrões de pagamento",
                value: "Valor",
                frequency: "Frequência",
                due: "Vencimento",
                method: "Pagamento",
                frequencyMONTH: "Por mês",
                frequencyAPPOINTMENT: "Por consulta",
                frequencyMANUAL: "Manual",
                dueSAMEDAY: "Mesmo dia",
                dueSEVENDAY: "Próxima semana",
                dueMONTHDAY5: "Dia 5 de cada mês",
                dueMONTHDAY10: "Dia 10 de cada mês",
                dueMONTHDAY15: "Dia 15 de cada mês",
                dueMONTHDAYLAST: "Último dia do mês",
                methodCREDITCARD: "Cartão de Crédito",
                methodMONEY: "Dinheiro",
                methodPIX: "Pix",
            },
            scheduleStatus: {
                confirmed: "Confirmada",
                cancelled: "Cancelada",
                waiting: "Pendente",
                done: "Realizada",
                lost: "Perdida",
            },
            appointments: {
                past: "Consultas passadas",
                statuswaiting:'Consulta pendente',
                statusconfirmed:'Consulta confirmada',
                statusdone:'Consulta realizada',
                statuscancelled:'Consulta cancelada (justificada)',
                statuslost:'Consulta perdida (não justificada)',
                statusfuture: "Consulta futura",
                next: "Próximas consultas",
                modalDelete: {
                    title: "Deseja realmente remover esta consulta?",
                    description: "Esta ação não poderá ser desfeita. Pagamentos, anotações e atividades relativas à consulta permanecerão inalteradas.",
                },
            },
            payments: {
                value: "Valor",
                description: "Descrição",
                status: "Status",
                statusPENDING: "Pendente",
                statusREFUSED: "Recusado",
                statusPROCESSING: "Em Processamento",
                statusPAID: "Pago",
                statusCANCELLED: "Cancelado",
                appointmentItem: "Consulta no dia $1",
                appointmentList: "Referente a $1 consultas",
                modalDelete: {
                    title: "Deseja realmente remover este pagamento?",
                    description: "Esta ação não poderá ser desfeita. Comprovantes associados também serão removidos.",
                },
            }
        },
        patientform: {
            invalid: {
                name: "Por favor, informe um nome",
                surname: "Por favor, informe um sobrenome",
                max: "Informe um valor menor",
                min: "Informe um valor maior",
                phone: "Por favor, informe um telefone válido",
                date: "Por favor, informe uma data válida",
                email: "Por favor, informe um email válido",
            },
            label: {
                name: "Nome",
                surname: "Sobrenome",
                email: "Email",
                birthdate: "Data de Nascimento",
                patientsince: "Paciente Desde",
                phone: "Telefone",
                isWhatsapp: "É Whatsapp?",
                allowAccess: "Permitir acesso",
            },
            saved: "Paciente salvo com sucesso!",
        },
        patientSchedule: {
            path: "Agendamento",
            title: "Novo Agendamento",
            titleEdit: "Editar Agendamento",
            calendar: "Calendário",
            appointments: "Consultas associadas",
            appointmentsInsert: "Ao criar esse agendamento, serão gerados múltiplas consultas para as datas futuras, seguindo a regra definida aqui. Serão geradas consultas para os próximos dois meses e, ao inicio de cada mês, novas consultas serão geradas automaticamente.",
            appointmentsModify: "Modificar todas as consultas",
            appointmentsModifyDetail: "Desta forma, todas as consultas posteriores a hoje serão modificadas para se enquadrar neste agendamento",
            appointmentsKeep: "Manter consultas agendadas",
            appointmentsKeepDetail: "Desta forma, as consultas já agendadas permanecerão sem alterações, e as novas consultas serão geradas a partir das novas regras editadas agora",
            date: "Data",
            agenda: "Agenda",
            start: "Hora de início",
            end: "Hora de fim",
            weekdays: "Dia",
            time: "Horário",
            repeat: "Repetir",
            finish: "Encerrar",
            never: "Nunca",
            atDate: "No dia",
            after: "Após",
            times: "vezes",
            repeatValues: {
                once: "Única vez",
                week: "Uma vez por semana",
                twoweeks: "A cada duas semanas",
                twiceamonth: "Duas vezes por mês",
                month: "Uma vez por mês",
            },
            location: "Local",
        },
        scheduleform: {
            saved: "Agemdamento salvo com sucesso!",
            invalid: {
                dateEmpty: "Data inválida",
                startTime: "Hora inválida",
                endTime: "Hora inválida",
                repeat: "Repetição inválida",
                finish: "Encerramento inválido",
                repeatDate: "Data inválida",
                repeatTimes: "Nº inválido",
            },
            modalRemove: {
                title: "Deseja realmente remover este agendamento?",
                description: "A remoção será permanente e não poderá ser desfeita.",
                appointmentsActions: "As consultas já realizadas, perdidas ou canceladas NÃO serão removidas. Você pode escolher como tratar as consultas ainda não realizadas (posteriores a hoje).",
                appointmentsRemove: "Remover junto com este agendamento",
                appointmentsKeep: "Manter consultas futuras",
                appointmentsNext: "Esta ação afetará $1.",
                appointment: 'consulta',
                appointments: 'consultas',
            },
            unableToRemove: "Ops, não foi possivel remover o agendamento...",
            deleted: "Agendamento removido com sucesso!",
        },
        patientActivity: {
            path: "Atividades",
            titleNew: "Nova Atividade",
            titleEdit: "Editar Atividade",
            name: "Nome da atividade",
            related: "Relativo à consulta em",
            relatedValue: "$1 às $2",
            instructions: "Instruções para a atividade",
            instructionsPlaceholder: "Descreva aqui as instruções para a realização da atividade. O que e como deve ser feito?",
            type: "Tipo de atividade",
            text: "Texto ou Arquivo",
            form: "Formulário",
            done: "Concluída",
            content: "Conteúdo da Atividade",
            noContent: "No momento, não há respostas enviadas.",
            response: "Resposta do(a) paciente",
            files: "Arquivos anexos",
            saved: "Atividade salva com sucesso!",
            answerSendAt: "Resposta enviada em",
            cript: "Protegida por criptografia",
            unlock: "Liberar",
            errorCrypt: "Erro ao decodificar dados",
            lockedToEdit: "Não é possivel modificar este valor pois já há respostas registradas",
            unableToRemove: "Erro ao remover atividade",
            removed: "Atividade removida com sucesso!",
            modalDelete: {
                title: "Deseja realmente remover esta atividade?",
                description: "Esta ação não podera ser desfeita. Todos os arquivos e textos anexados a esta atividade serão excluidos",
            },
            invalid: {
                name: "Por favor, defina um nome",
                type: "Por favor, selecione um tipo",
                instructions: "Forneça instruções para o seu paciente",
            }
        },
        patientAppointment: {
            path: "Consultas",
            titleNew: "Nova consulta",
            titleEdit: "Editar consulta",
            date: "Data",
            time: "Hora de Início",
            timeend: "Hora de Término",
            status: "Status",
            statusValue: {
                "waiting": "Aguardando confirmação",
                "confirmed": "Consulta confirmada",
                "done": "Consulta Realizada",
                "cancelled": "Consulta Cancelada (justificada)",
                "lost": "Consulta Não Realizada (não justificada)",
            },
            invalid: {
                "date": "A data é obrigatória",
                "time": "Início é obrigatório",
                "timeendbefore": "Termino deve ser após início",
                "timeend": "Término é obrigatório",
                "status": "Status é obrigatório",
            },
            local: "Local",
            notes: "Notas da consulta",
            notesPlaceholder: "Informações específicas sobre a consulta, anotações e detalhes",
            notesEncrypt: "Há notas critografas para esta consulta",
            saved: "Consulta salva com sucesso!",
            payments: "Pagamento",
            paymentsCheckbox: "Gerar pagamento automaticamente",
            confirm_by_patient: "Confirmação do(a) paciente",
            confirmValueNone: "Aguardando confirmação",
            confirmValue1: "Sim, confirmado",
            confirmValue0: "Não, declinado",
        },
        patientPayment: {
            titleNew: "Novo pagamento",
            titleEdit: "Editar pagamento",
            saved: "Pagamento salvo com sucesso!",
            path: "Pagamentos",
            title: "Pagamentos",
            relatedTo: "Relativo a",
            relatedToAppointment: "Consulta",
            relatedToAppointmentValue: "$1 às $2",
            relatedToMonth: "Mês",
            relatedToOther: "Outro",
            appointment: "Consulta",
            month: "Mês",
            description: "Descrição",
            type: "Forma de Pagamento",
            typesValues: {
                money: "Dinheiro",
                creditcard: "Cartão de Credito",
                pix: "Pix",
            },
            valueTotal: "Valor",
            status: "Status",
            statusPending: "Pendente",
            statusRefused: "Recusado",
            statusProcessing: "Em Processamento",
            statusPaid: "Pago",
            statusCancelled: "Cancelado",
            dueDate: "Vencimento",
            notes: "Notas",
            notesPlaceholder: "Adicione aqui caso haja alguma observação",
            noAppointments: "Sem consultas selecionadas",
            appointmentsAtDay: "Consulta do dia",
        },
        payments: {
            title: "Pagamentos",
            titleReports: "Relatórios",
            filter: "Filtrar",
            prevMonth: "Mês anterior",
            nextMonth: "Mês seguinte",
            new: "Novo Pagamento",
            hasFilter: "Os seguintes filtros estão aplicados aos pagamentos:",
            titleToReceive: "Contas a Receber",
            titleToPay: "Contas a Pagar",
            paymentNoItemToReceive: "Sem contas a receber",
            paymentNoItemToPay: "Sem contas a pagar",
            paymentNoItemCashflow: "Sem dados para fluxo de caixa",
            limit: "Seu limite atual é de $1 meses de histórico financeiro",
            chartTitle: "Resumo dos últimos 12 meses",
            chartReceived: "Recebido",
            chartPaid: "Pago",
            chartDiff: "Resultado",
            saved: "Pagamento criado com sucesso",
            invalidValueTotal: "Obrigatório",
            invalidDescription: "Informe uma descrição",
            invalidMonth: "Informe um mês",
            invalidBillingType: "Informe o Tipo de Conta",
            payments: {
                description: "Descrição",
                dueDate: "Vencimento",
                value: "Valor"
            },
            total: {
                toReceiveTitle: "Total a Receber",
                received: "Já Recebido",
                toPayTitle: "Total a Pagar",
                paid: "Já Pago",
                due: "Por vencer",
                delayed: "Em atraso",
            },
            cashflow: {
                title: "Fluxo de Caixa",
                month: "Mês",
                received: "Receitas",
                paid: "Despesas",
                diff: "Resultado",
                balance: "Saldo",
            },
            recomendations: {
                title: "Recomendações",
                grossValue: "Valor bruto no mês",
                vacations: "Provisão de férias",
                income13: "Provisão de 13º salário",
                vacationsThird: "1/3 de férias",
                socialSecurity: "INSS",
                socialSecurity13: "INSS sobre 13º",
                incomeTax: "IRPF",
                total: "Total Liquido",
                text: "Sabemos que ser profissional liberal não é uma tarefa simples. Além de realizar as tarefas pertinentes â profissão, é necessário cuidar das finanças para evitar surpresas desagradáveis.\\nPensando nisso, criamos esta calculadora que recomenda o quanto você deve separar de seus recebimentos para cada conta. Isto inclui 13º, férias e INSS, além de Importo de Renda.\\nImportante salientar que estes valores são calculados baseado no rendimento do mês, e não no valor total do ano (pois o valor pode ser variável). Não tome estes valores como verdade absoluta, mas sim como um parâmetro pra organização pessoal.\\nSempre busque ajuda de um profissional combabilista qualificado.",
            },
            modalFilter: {
                title: "Filtrar pagamentos",
                status: "Status",
                statusPending: "Pendente",
                statusProcessing: "Em processamento",
                statusPaid: "Pago",
                statusRefused: "Recusado",
                statusCancelled: "Cancelado",
                dueDate: "Data de Vencimento",
                dueDateMin: "Posterior a",
                dueDateMax: "Anterior a",
                method: "Forma de Pagamento",
                methodPix: "PIX",
                methodMoney: "Dinheiro",
                methodCreditCard: "Cartão de Crédito",
                value: "Valor",
                valueMin: "Maior que",
                valueMax: "Menor que",
                patients: "Pacientes"
            },
            filterValues: {
                dueDateMin:"Vencimento depois de",
                dueDateMax:"Vencimento antes de",
                valueMin:"Valor maior que",
                valueMax:"Valor menor que",
                patients_id:"Relativo à",
                statusPending:"Com situação como Pendente",
                statusProcessing:"Com situação como Processando",
                statusPaid:"Com situação como Pago",
                statusCancelled:"Com situação como Cancelado",
                statusRefused:"Com situação como Recusado",
                methodPix:"Com método de pagamento como Pix",
                methodCredit:"Com método de pagamento como Cartão",
                methodMoney:"Com método de pagamento como Dinheiro",
            },
            form: {
                titleAdd: "Novo Pagamento",
                titleEdit: "Editar Pagamento",
                billingType: "Tipo de Conta",
                billingTypeIncome: "Receber",
                billingTypeOutcome: "Pagar",
                patient: "Paciente",
                invalidBillingType: "Tipo de Conta inválido",
            },
            modalDelete: {
                title: "Deseja realmente remover este pagamento?",
                description: "Esta ação não poderá ser desfeita. Comprovantes associados também serão removidos.",
            },
        },
        calendar: {
            title: "Agenda",
            viewModeWeek: "Ver semana",
            viewMode2Week: "Ver duas semanas",
            viewModeMonth: "Ver mês",
            viewNext: "Próximo periodo",
            viewPrev: "Periodo anterior",
            path: "Nova consulta",
            titleNew: "Nova consulta",
            titleEdit: "Editar consulta",
            patient: "Paciente",
            saved: "Consulta salva com sucesso!",
            limit: "Você vizualisará apenas os $1 primeiros eventos da semana. Para ver todos os eventos, assine o Plano Pro."
        },
        config: {
            title: "Configurações",
            tabGeneral: "Geral",
            tabProfile: "Perfil",
            tabSecurity: "Segurança",
            tabActivities: "Anamnese & Atividades",
            tabPlans: "Planos",
            tabCalendar: "Agenda",
            created_at: "Perfil criado em",
            labelLang: "Idioma da interface",
            labelTheme: "Tema da interface",
            themeDark: "Tema Escuro",
            themeLight: "Tema Claro",
            plans: {
                plan: "Plano",
                plansTitle: "Planos",
                choosePlan: "Plano Escolhido",
                changePaymentMethod: "Alterando forma de pagamento",
                basicHeader: "No momento, você está utilizando o Plano Basic. Faça o upgrade para liberar todos os recursos e extrair o máximo que o Pilar Terapêutico pode te oferecer.",
                planBasicDesc: "Para quem está começando ou quer conhecer a plataforma",
                planProDesc: "Para quem ama atendimento clínico e quer se organizar",
                planPlatinumDesc: "Para quem vive de atendimento clínico e precisa extrair o máximo que a plataforma pode oferecer",
                planBasicRow1: "Até $1 pacientes ativos",
                planBasicRow2: "$1 consultas semanais na agenda",
                planBasicRow3: "$1 meses de histórico financeiro",
                planProRow1: "Pacientes ativos ilitmiados",
                planProRow2: "Consultas ilimitadas na agenda",
                planProRow3: "Histórico financeiro complato",
                planRow4: "Criptografia avançada",
                planRow5: "Acesso mobile e desktop",
                planRow6: "Receba pagamentos pela plataforma",
                planRow7: "Gerenciamento de vídeo-chamadas",
                subscribe: "Assinar",
                soon: "Em breve",
                changePlan: "Mudar Plano",
                updatePayment: "Mudar Forma De Pagamento",
                currentPlan: "Plano atual",
                yourCurrentPlan: "Este é o seu plano atual",
                planDatailMaxPatients: "Pacientes ativos",
                planDatailMaxAppointments: "Consultas por semana",
                planDatailMaxFinancial: "Histórico financeiro",
                planDatailHasPayments: "Receber pagamentos por cartão",
                planDatailHasCalls: "Gerenciar Video-Chamadas",
                unlimited: "Ilimitado",
                total: "Total",
                month: "/mês",
                labelMethod: "Método de pagamento",
                labelPayerName: "Nome do Pagador",
                labelPayerCPF: "CPF",
                labelQrCode: "O Código QR aparecerá aqui",
                labelCardNumber: "Número do Cartão",
                labelCardDue: "Validade",
                labelCardCode: "CVV",
                labelCardHolderName: "Nome do Titular",
                paymentsCard: "Cartão de Credito",
                paymentsPix: "Pix",
                paymentsBoleto: "Boleto",
                paymentDesc: "Primeiramente, escolha a forma de pagamento desejada",
                paymentDesccreditcard: "Ao escolher cartão de crédito, sua renovação será automática, sempre no dia $1 de cada mês",
                paymentDescpix: "Ao escolher Pix, sua renovação será manual e um novo código QR será gerado 7 dias antes do vencimento da assinatura.",
                paymentDescboleto: "Ao escolher Boleto Bancário, sua renovação será manual e um novo boleto será gerado 7 dias antes do vencimento da assinatura.",
                boletoText1: "Clique em Assinar para gerar o boleto para pagamento. Você poderá pagar o boleto utilizando o app do seu banco.",
                pixText1: "Clique em Assinar para gerar o Pix de pagamento. Você poderá pagar utilizando o Código QR ou o Pix Copia e Cola.",
                pixText2: "Realize o pagamento do Pix pelo app do seu banco utilizando o Código QR ou o Pix Copia e Cola. A identificação do pagamento é automática e lembre-se que o Pix pode levar até alguns minutos para ser processado. A validade para pagamento é 24h.",
                pixText3: "Um novo código Pix será gerado em $1. Para manter sua assinatura do Plano $2 ativada, não se esqueça de efetuar o pagamento antes do vencimento.",
                ccText3: "Informe os dados do novo cartão a ser utilizando na renovação da fatura. Não se preocupe, sua assinatura não será renovada agora.",
                payment: "Pagamento",
                paymentMethod: "Método de pagamento",
                paymentValuepix: "Pix",
                paymentValuecreditcard: "Cartão",
                nextRenew: "Próxima renovação",
                activeSince: "Ativo desde",
                planPaidAt: "Pago em",
                planPaidMethod: "Pago por",
                planPrice: "Valor do plano",
                discount: "Descontos",
                priceTotal: "Valor total",
                areaLastPayment: "Dados do último pagamento",
                thankyou: "Sua assinatura do Plano $1 está confirmada! Obrigado por utilizar o Pilar Terapêutico :D",
                planChangeSuccess: "Sua mundança de plano foi realizada com sucesso!",
                planChangeMethodSuccess: "Sua forma de pagamento foi alterada com sucesso!",
                planGoingChange: "No dia $1, seu plano vai mudar de $2 para $3. Até lá, seu plano atual permanecerá sem alterações.",
                referenceTitle: "Descontos & Indicações",
                referenceText1: "Você sabia que pode ganhar descontos na sua assinatura dos Planos Pro e Platinum? Basta indicar o Pilar Terapêutico para outros profissionais.",
                referenceText2: "Aqui você encontra um link para compartilhar com outras pessoas. Quando esta pessoa cria sua conta e assina o Plano Pro ou o Plano Platinum, você recebe 15% do valor pago por ela em desconto na sua próxima renovação, cumulativo e enquanto a assinatura do indicado continuar ativa!",
                referenceText3: "Imagine que você indica para duas outras pessoas. Uma delas opta por assinar o plano Pro, enquanto a outra opta pelo plano Platinum. Enquanto elas estiverem com suas devidas assinaturas ativas, você recebe um desconto total de R$ 20,97 na sua assinatura (15% sobre um Plano Pro + 15% sobre um Plano Platinum).",
                referenceText4: "Caso uma destas pessoas mude seu plano, seu desconto também será atualizado. No mesmo exemplo, suponha que a pessoa com o Plano Platinum opta por um downgrade e passe para o Plano Pro. Na sua próxima renovação, seu desconto será de R$ 13,47 (2x 15% do Plano Pro). Seu desconto máximo é de 100% da sua assinatura, independente de quantas pessoas tenham assinado algum dos planos.",
                referenceText5: "Note que, indicando para 6 pessoas que assinem o mesmo plano que você, a sua assinatura fica grátis!",
                referenceLinkLabel: "Abaixo, segue seu link para indicação",
                referenceDetails: "Detalhamento de seus convites e descontos",
                referenceCreated: "Contas criadas por indicação sua",
                referenceActivePro: "Assinantes do Plano Pro ativos",
                referenceActivePlatinum: "Assinantes do Plano Platinum ativos",
                referenceDiscountValue: "Desconto na próxima renovação",
                referenceDiscountPercent: "Desconto efetivo",
                currentCard: "Cartão atual",
                savingCard: "Aguarde, estamos verificando o cartão...",
                changeLabel: "Escolha o novo plano que deseja assinar",
                changingPlanText: "Você está alterando sua assinatura do Plano $1 para o Plano $2.",
                changingPlanUpgrade: "Ao realizar o upgrade, você pagará proporcionalmente de acordo com os dias restantes até a próxima renovação (pro rata). Depois disto, a combrança será realizada normalmente no valor do Plano $1. Os recursos do novo plano já ficam ativos logo após a confirmação do pagamento.",
                changingPlanDowngrade: "Ao realizar o downgrade, os recursos do seu plano atual permanecerão ativos até o dia da renovação. Nesta data, o Plano $1 será ativado e a cobrança será realizada no valor do Plano $1.",
                changingPlanText2: "Se desejar fazer tanbém uma mudança na forma de pagamento, realize primeiro a mudança da forma de pagamento, depois constinue com esta etapa. Ao realizar a mudança de plano, você não poderá realizar atualizações na forma de pagamento e plano atual até a renovação.",
                changingPlanBasic: "Ao realizar o downgrade, a renovação da sua assinatura será desativada (não serão realizadas novas cobranças) e você poderá continuar utilizando os recursos do plano atual até o final do periodo.",
                errorCreatePaymentMethod: "Erro ao cadastrar forma de pagamento",
                successCancelPayment: "Pagamento cancelado com sucesso",
                cardCompliancy: "Seguimos as normas de segurança de PCI DSS (Payment Card Industry Data Security Standard) e não armazenamos dados sensíveis de cartão de crédito. Todos os dados são criptografados e enviados diretamente para a operadora de cartão.",
                cancelPayment: {
                    modalDelete: {
                        title: "Deseja realmente cancelar este pagamento?",
                        description: "Sua assinatura não poderá ser iniciada/renovada e você precisará reiniciar o processo de assinatura caso mude de ideia.",
                        confirm: "Sim, cancelar",
                    },
                },
                mpStatus: {
                    unknown: '-',
                    pending: 'Aguardando pagamento',
                    approved: 'Aprovado',
                    authorized: 'Autorizado',
                    in_process: 'Em análise',
                    in_mediation: 'Em análise',
                    rejected: 'Rejeitado (tente novamente)',
                    cancelled: 'Cancelado/Expirado',
                    refunded: 'Devolvido',
                    charged_back: 'Estornado',
                },
                stripeErrors: {
                    incorrect_number: "O número do cartão está incorreto.",
                    invalid_number: "O número do cartão não é um número válido.",
                    invalid_expiry_month: "A data de validade do cartão é inválida.",
                    invalid_expiry_year: "A data de validade do cartão é inválida.",
                    invalid_cvc: "O código de segurança é inválido",
                    expired_card: "O cartão está expirado.",
                    incorrect_cvc: "O código de segurança do cartão está incorreto.",
                    incorrect_zip: "The card's zip code failed validation.",
                    card_declined: "O seu cartão foi recuzado. Tente novamente em instantes",
                    missing: "Erro ao realizar a cobrança",
                    processing_error: "Ocorreu um erro ao processar o cartão. Tente novamente.",
                    rate_limit:  "Erro ao realizar a cobrança. Por favor, contacte o suporte."
                }
            },
            security: {
                pass: "Mudar senha",
                currentPass: "Senha atual",
                newPass: "Nova senha",
                confirmPass: "Confirmar senha",
                session: "Logins ativos",
                device: "Dispositivo",
                lastActivity: "Última atividade",
                forceQuit: "Forçar saída",
                invaliidPassword: "Senha inválida",
                diffPassword: "As senhas não conferem"
            },
            template: {
                anamnesis: "Amamneses",
                activities: "Modelos de Atividades",
                noAnamnesis: "Sem Amamneses criadas",
                noActivities: "Sem Modelos de Atividades",
                noForm: "Selecione um item para visualizá-lo, ou crie um novo",
                nameActivity: "Nome do Modelo",
                nameAnamnesis: "Nome da Anamnese",
                form: "Conteúdo do formulário",
                invaliidName: "Nome é obrigatório",
                invaliidId: "Item não identificado",
                modalDelete: {
                    title: "Deseja realmente remover este item?",
                    description: "Anamneses e Atividade criadas serão mantidas como estão, mas você não poderá criar novas utilizando este modelo.",
                },
                removeOk: "Item removido com sucesso!",
                unableToRemove: "Não foi possivel removeer o item"
            },
            savedProfile: "Perfil salvo com sucesso!",
            savedPassword: "Senha modificada com sucesso!",
            savedSessionEnd: "Login encerrado com sucesso!",
            savedAnamnesis: "Anamnese salvo com sucesso!",
            savedActivities: "Modelo de Atividade salvo com sucesso!",
            savedPayments: "Pagamento salvo com sucesso!",
            savedCalendar: "Agenda salva com sucesso!",
            savedPreferences: "Preferências salvas com sucesso!"
        },
        creditcard: {
            invalidMethod: "Método inválido",
            minLengthCardNumber: "Número muito curto",
            maxLengthCardNumber: "Número muito longo",
            emptyCardNumber: "Número é obrigatório",
            invalidExpiration: "Data inválida",
            emptyExpiration: "Data é obrigatória",
            minLengthCVV: "CVV muito curto",
            maxLengthCVV: "CVV muito longo",
            invalidCVV: "CVV Invalido",
            emptyCVV: "CVV é obrigatório",
            invalidCPF: "CPF inválido",
            emptyHolderName: "Nome é obrigatório",
            emptyCPF: "CPF é obrigatório",
            noSubscription: "Erro ao criar assinatura",
        },
        formbuilder: {
            new: "Nova pergunta",
            template: "Utilizar modelo",
            title: "Adicione um título à pergunta",
            answer: "Adicione uma resposta",
            text: "Texto",
            check: "Seleção múltipla",
            radio: "Seleção única",
            rating: "Escala",
            textareadata: "A resposta do paciente aparecerá aqui",
            noData: "Sem perguntas adicionadas.\nPara adicionar perguntas, utilize os botões acima",
        },
        picture: {
            drag: "Arraste a foto para enviar",
            search: "Escolher arquivo",
            maxsize: "Tamanho máximo 1Mb",
        },
        paginator: {
            label: "$1 de $2",
        },
        erros: {
            noId: "ID é obrigatório para editar um item"
        },
        time: {
            range: "De $1 até $2",
            hour: "hora",
            hours: "horas",
            minutes: "minutos",
            mix: "$1h $2min",
            today: "Hoje",
            tomorrow: "Amanhã"
        },
        dates: {
            timeAt: "$1 às $2",
            intervalFull: "De $1 a $2",
            monthShort: {
                "01": "Jan",
                "02": "Fev",
                "03": "Mar",
                "04": "Abr",
                "05": "Mai",
                "06": "Jun",
                "07": "Jul",
                "08": "Ago",
                "09": "Set",
                "10": "Out",
                "11": "Nov",
                "12": "Dez",
            },
            monthShort2: {
                "1": "Jan",
                "2": "Fev",
                "3": "Mar",
                "4": "Abr",
                "5": "Mai",
                "6": "Jun",
                "7": "Jul",
                "8": "Ago",
                "9": "Set",
                "10": "Out",
                "11": "Nov",
                "12": "Dez",
            },
            monthLong: {
                "0": "",
                "1": "Janeiro",
                "2": "Fevereiro",
                "3": "Março",
                "4": "Abril",
                "5": "Maio",
                "6": "Junho",
                "7": "Julho",
                "8": "Agosto",
                "9": "Setembro",
                "10": "Outubro",
                "11": "Novembro",
                "12": "Dezembro",
            },
            dayname: {
                "0": "Domingo",
                "1": "Segunda",
                "2": "Terça",
                "3": "Quarta",
                "4": "Quinta",
                "5": "Sexta",
                "6": "Sábado",
            },
            daynameshort: {
                "0": "Dom",
                "1": "Seg",
                "2": "Ter",
                "3": "Qua",
                "4": "Qui",
                "5": "Sex",
                "6": "Sáb",
            }
        }
    }
}