import Home from './pages/Home'
import Login from './pages/Login'
import RegisterForm from './pages/Register'
import Dashboard from './pages/Dashboard'
import Patients from './pages/Patients'
import Payments from './pages/Payments'
import Calendar from './pages/Calendar'
import Config from './pages/Config'
import PaymentForm from './pages/PaymentForm'
import PatientView from './pages/PatientView'
import PatientForm from './pages/PatientForm'
import PatientScheduleForm from './pages/PatientScheduleForm'
import PatientActivityForm from './pages/PatientActivityForm'
import PatientAppointmentForm from './pages/PatientAppointmentForm'
import PatientPaymentForm from './pages/PatientPaymentForm'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import CalendarAppointmentForm from './pages/CalendarAppointmentForm'
import ConfigGeneral from './pages/ConfigGeneral'
import ConfigProfile from './pages/ConfigProfile'
import ConfigSecurity from './pages/ConfigSecurity'
import ConfigActivities from './pages/ConfigActivities'
import ConfigPlanChoose from './pages/ConfigPlanChoose'
import ConfigPlanPayment from './pages/ConfigPlanPayment'
import ConfigPlanActive from './pages/ConfigPlanActive'
import ConfigAgenda from './pages/ConfigAgenda'
import ForgotPassword from './pages/ForgotPassword'

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "/patients",
        element: <Patients />,
      },
      {
        path: "/patients/:id",
        element: <PatientView />,
      },
      {
        path: "/patients/new",
        element: <PatientForm />,
      },
      {
        path: "/patients/:id/edit",
        element: <PatientForm edit={true}/>,
      },
      {
        path: "/patients/:id/schedule/new",
        element: <PatientScheduleForm />,
      },
      {
        path: "/patients/:id/schedule/:scheduleId/edit",
        element: <PatientScheduleForm edit={true} />,
      },
      {
        path: "/patients/:id/activity/new",
        element: <PatientActivityForm />,
      },
      {
        path: "/patients/:id/activity/:activityId",
        element: <PatientActivityForm edit={true} />,
      },
      {
        path: "/patients/:id/appointment/new",
        element: <PatientAppointmentForm />,
      },
      {
        path: "/patients/:id/appointment/:appointmentId",
        element: <PatientAppointmentForm edit={true} />,
      },
      {
        path: "/patients/:id/payment/new",
        element: <PatientPaymentForm />,
      },
      {
        path: "/patients/:id/payment/:paymentId",
        element: <PatientPaymentForm edit={true} />,
      },
      {
        path: "/payments",
        element: <Payments />,
      },
      {
        path: "/payments/new",
        element: <PaymentForm />,
      },
      {
        path: "/payments/:paymentId",
        element: <PaymentForm edit={true}/>,
      },
      {
        path: "/calendar",
        element: <Calendar />,
      },
      {
        path: "/calendar/new",
        element: <CalendarAppointmentForm />,
      },
      {
        path: "/calendar/:appointmentId",
        element: <CalendarAppointmentForm edit={true} />,
      },
      {
        path: "/config",
        element: <Config />,
        children: [
          {
            path: "/config/general",
            element: <ConfigGeneral />,
          },
          {
            path: "/config/profile",
            element: <ConfigProfile />,
          },
          {
            path: "/config/security",
            element: <ConfigSecurity />,
          },
          {
            path: "/config/activities",
            element: <ConfigActivities />,
          },
          /*{
            path: "/config/plans/:type",
            element: <ConfigPlanForm />,
          },*/
          {
            path: "/config/plans/active",
            element: <ConfigPlanActive />,
          },
          {
            path: "/config/plans/payment",
            element: <ConfigPlanPayment />,
          },
          {
            path: "/config/plans",
            element: <ConfigPlanChoose />,
          },
          {
            path: "/config/agenda",
            element: <ConfigAgenda />,
          }
        ]
      },
      {
        path: "/",
        element: <Dashboard />,
      }
    ]
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <RegisterForm />,
  },
  {
    path: "/forgot",
    element: <ForgotPassword />,
  },
  {
    path: "/invite/:referrer?",
    element: <RegisterForm />,
  }
])

const Router = () => <RouterProvider router={router} />

export default Router;
