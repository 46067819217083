import { configureStore } from '@reduxjs/toolkit'

import authReducer from '../authReducer'
import filtersReducer from '../filtersReducer'
import loadingReducer from '../loadingReducer'
import messagesReducer from '../messagesReducer'
import modalReducer from '../modalReducer'
import menuReducer from '../menuReducer'

const store = configureStore({
  reducer: {
    auth: authReducer,
    filters: filtersReducer,
    loading: loadingReducer,
    messages: messagesReducer,
    modal: modalReducer,
    menu: menuReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppState = ReturnType<typeof store.getState>

export default store;