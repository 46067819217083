import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow: auto;
`;

export const BirthdayItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0.5rem;

    &:first-child {
        padding-left: 1rem;
    }

    &:last-child {
        padding-right: 1rem;
    }
`;

export const BirthdayDay = styled.div`
    color: var(--theme-color-font-weak);
    font-size: 1.4rem;
`;

export const BirthdayName = styled.div`
    white-space: nowrap;
    color: var(--theme-color-font);
`;

export const BirthdayYears = styled.div`
    color: var(--theme-color-font-empty);
    font-size: 0.8rem;
    line-height: 1rem;
`;

export const ContentRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
`;

export const RowHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    height: 2.5rem;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0 1rem;
    color: var(--theme-color-font-weak);
    background-color: var(--theme-color-background-aux2);
    margin-bottom: 2px;
    
    & ~ & {
        margin-bottom: 0;
    }

    &:not(:first-child) {
        margin-top: 2px;
    }
`;

export const RowFooter = styled(RowHeader)`
    &.income {
        color: var(--theme-color-value-green);
    }
    &.outcome {
        color: var(--theme-color-value-red);
    }
`;

export const ItemRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    padding: 0.7rem 1rem;
    cursor: pointer;

    &:hover {
        background-color: var(--theme-color-background-aux3);
    }
`;

export const AppointmentTime = styled.div`
    font-size: 1rem;
    line-height: 1rem;
    color: var(--theme-color-font);
`;

export const AppointmentDuration = styled.div`
    font-size: 0.8rem;
    line-height: 1rem;
    color: var(--theme-color-font-weak);
`;

export const AppointmentPatient = styled.div`
    font-size: 1rem;
    line-height: 1rem;
    flex: 1;
    color: var(--theme-color-font);
`;

export const AppointmentStatus = styled.div<{color:string}>`
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--theme-color-font);
    padding: 0.4rem;
    height: 1.5rem;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    border-right: 1px solid #fff;
    background-color: ${(props:{color:string}) => props.color ?? '#999'};
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 0;
`;

export const AppointmentConfirmByPatient = styled.div`
    font-size: 0.75rem;
    height: 1.5rem;
    width: 1rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${(props:{color:string}) => props.color ?? '#999'};
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const PaymentsLabel = styled.div`
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 600;
    flex: 1;

    &.right {
        text-align: right;
    }
`;

export const PaymentsValue = styled.div`
    font-size: 1.3rem;
    line-height: 1rem;
    font-weight: 600;
    flex: 1;

    &.right {
        text-align: right;
    }
`;

export const PaymentName = styled.div`
    font-size: 1rem;
    line-height: 1rem;
    color: var(--theme-color-font);
`;
export const PaymentDescription = styled.div`
    font-size: 0.8rem;
    line-height: 1rem;
    color: var(--theme-color-font-weak);
`;
export const PaymentMoney = styled.div`
    font-size: 1rem;
    line-height: 1rem;
    color: var(--theme-color-font);
`;
