export default {
    translations: {
        error: "Oops. unexpected errror :(",
        locale: "en-US",
        yes: "Yes",
        no: "No",
        or: "or",
        and: "and",
        active: "Active",
        inactive: "Inactive",
        cancel: "Cancel",
        close: "Close",
        new: "New",
        save: "Save",
        delete: "Delete",
        filter: "Filter",
        change: "Change",
        clear: "Clear",
        loading: "Loading...",
        upload: "Send File",
        auto: "Auto",
        copy: "Copy",
        login: {
            title: 'Login',
            message: 'To access, you must have a profile. If you already has yours, just log in to continue. Otherwise, just click at Create your profile" down below to create one.',
            email: 'Email',
            password: 'Password',
            invalidEmail: 'Oops, this Email seems invalid',
            forget: "Forgot my password",
            enter: "Enter",
            create: 'Create your profile',
        },
        register: {
            title: "Create new profile",
            message: "Please provide below data for your profile. These informations will only be visible to yout therapist. If you are a therapist, you will be able to complete your profile on “Configurations, after sign in.",
            name: "Name",
            surname: "Surname",
            email: "Email",
            password: "Password",
            confPassword: "Confirm Password",
            crp: "CRP",
            state: "State",
            back: "Back",
            create: "Create Profile",
            invalidName: "Please, provide your name",
            invalidSurname: "Please, provide your surname",
            invalidEmail: "This email seems to be invalid",
            invalidPassword: "Password is mandatory",
            invalidConfPassword: "Passwords doesnt match",
            invalidCRP: "CRP is mandatory",
            invalidCRPUF: "State is mandatory",
            minLengthName: "Name is too short",
            maxLengthName: "Name is too long",
            emptyName: "Name is mandatory",
            minLengthSurname: "Surname is too short",
            maxLengthSurname: "Surname is too long",
            emptySurname: "Surname is mandatory",
            emailInvalid: "Invalid Email",
            emptyEmail: "Email is mandatory",
            minLengthPassword: "Password too short",
            maxLengthPassword: "Password is too long",
            emptyPassword: "Password is mandatory",
            passwordMismatch: "Password doesn't match",
            emptyPasswordConfirm: "Confirmation is mandatory",
            emptyCRP: "CRP is mandatory",
            emptyCRPUF: "State is mandatory",
        },
        forgot: { 
            title: "Forgot my password",
            message: "We can define a new password for you to continue to the platform. For this, we will send a confirmation code to your account's email. This code will be used to validate your identity and, after that, you can define a new password.",
            askMail: "So, we need you to provide your access email.",
            askCode: "Great, now check your email and insert the code we sent to you.",
            code: "Confirmation code",
            cancelation: "If you miss the code some times, don't worry. The current code will be canceled and you will be able to generate a new one.",
            success: "Password successfully changed!",
        },
        menu: {
            logo: 'Logo of Pilar Terapêutico',
            home: 'Home',
            patients: 'Patients',
            payments: 'Payments',
            calendar: 'Calemdar',
            config: 'Settings',
        },
        topbar: {
            search: "Search for...",
            logout: "Logout",
            patients: "Patients",
            activities: "Activities",
            payments: "Payments",
            appointments: "Appointments",
            resources: "Resources",
            birthdate: "Birthdate",
            appointment: "Appointment on ",
            dueDate: "Due Date ",
            value: "Value",
            patient: "Patient",
            profile: "My profile",
            changeProfile: "Enter as patient",
            help: "Help",
        },
        help: {
            title: "Do you need help?",
            text: "Do you have any questions or something didn't go as expected? Contact us through the form below, by email, whatsapp or through Pilar Terapêutico's social networks.",
            send: "Send",
            subject: "Subject",
            message: "Message",
            error: "Oops, something went wrong when sending your message",
            error2: "Oops, it seems that you forgot to fill in some field",
            success: "Your message was successfully received! We will contact you soon.",
        },
        home: {
            night: "Good Evening, ",
            morning: "Good Morning, ",
            afternoon: "Good Afternoon, ",
            nextBirthdates: "Next Birthdays",
            emptyBirthdates: "There are no birthdays at next days",
            schedule: "Schedule Summary",
            emptySchedule: "There are no appointment scheduled",
            payments: "Payments Summary",
            emptyPayments: "There are no payments to pay or receive",
            isBasic: "Right now, you are using Pilar Terapeutico's Basic Plan. Subscribe to enjoy all available resources from platform.",
            showPayment: "The QR Code to pay your subscription is already available. Click here to view it.",
        },
        patients: {
            title: "Pacientes",
            hasFilter: "The following filters are applyed to patients list:",
            filter: "Filter",
            new: "New Patient",
            edit: "Edit Patient",
            showing: "Showing $1 of $2 patients",
            limit: "Your limit is $1 active patients",
            showingFilter: "(with filters). Total $1",
            none: "You still haven't any patient linked.",
            modalFilter: {
                title: "Filter patients",
                labelActive: "Active",
                labelName: "Name",
                labelBirthMonth: "Birthdate on",
                labelAge: "Age",
                labelMin: "Greater than",
                labelMax: "Less than",
                labelValue: "Default price",
                labelFrequency: "Payment frequency",
                labelDue: "Default due date",
                labelMethod: "Paymente method",
                labelAppointmentDate: "Next appointment",
                labelPayments: "With payments",
                activeBoth: "Both",
                activeTrue: "Only Active",
                activeFalse: "Only Inactive",
                frequencyAppointment: "By appointment",
                frequencyMonth: "By month",
                frequencyManual: "Manual",
                dueSameday: "Same day",
                dueNextweek: "Next week",
                dueMonthDay5: "Day 5 of month",
                dueMonthDay10: "Day 10 of month",
                dueMonthDay15: "Day 15 of month",
                dueLastMonthDay: "Last day of month",
                methodPix: "Pix",
                methodCredit: "Credit Card",
                methodMoney: "Money",
                paymentOutOfData: "Out of Date",
                paymentDue: "Next to Date",
            },
            filterValues: {
                frequencyAppointment: "Payment frequency by appointment",
                frequencyMonth: "Payment frequency by month",
                frequencyManual: "Payment frequency manual",
                dueSameday: "Default due date to same day",
                dueNextweek: "Default due date to 1 week",
                dueMonthDay5: "Default due date to day 5 of month",
                dueMonthDay10: "Default due date to day 10 of month",
                dueMonthDay15: "Default due date to day 15 of month",
                dueLastMonthDay: "Default due date to last day of month",
                methodPix: "Payment method by pix",
                methodCredit: "Payment method by credit card",
                methodMoney: "Payment method by in cash",
                paymentOutOfData: "With delayed payments",
                paymentDue: "With payments next to due date",
                birthMonth: "Birthdate month",
                name: "Name",
                ageMin: "Age greater than",
                ageMax: "Age less than",
                valueMax: "Default payment greater than",
                valueMin: "Default payment less than",
                appointmentDateMin: "Next appointment after",
                appointmentDateMax: "Next appointment before",
                active: "Active",
            }
        },
        patientView: {
            profileTitle: "Patient Profile",
            scheduleTitle: "Recurrent schedule",
            nextAppointmentsTitle: "Next appointments",
            activitiesTitle: "Activities",
            appointmentTitle: "Appointments",
            paymentTitle: "Payments",
            activitiesNoData: "No activities registered",
            scheduleNoData: "No time scheduled",
            appointmentNoData: "No appointment done",
            appointmentNextNoData: "No schedule appointment",
            paymentNoData: "No payment registered",
            labelAge: "Age",
            labelYearsOld: " years old",
            labelBirthdate: "Birthdate",
            labelEmail: "Email",
            labelWhatsapp: "Whatsapp",
            labelAllowAccess: "Access enabled",
            labelPhone: "Phone",
            labelPatientSince: "Patient since",
            buttonEdit: "Edit",
            buttonInactive: "Inactive",
            buttonActive: "Active",
            profileData: "Personal data",
            unableToInactive: "Oops, unable to inactivate patient",
            unableToActive: "Oops, unable to activate patient",
            activeSuccess: "Patient reactivated successfully!",
            inactiveSuccess: "Patient inactivated successfully!",
            inactive: {
                inactiveOk: "Inactivate",
                modalDelete: {
                    title: "Do you really want to inactivate this patient?",
                    description: "Deactivated patients will no longer have scheduled appointments, but their histories will remain accessible and unchanged."
                }
            },
            active: {
                activeOk: "Reactivate",
                modalDelete: {
                    title: "Do you really want to reactivate this patient?",
                    description: "This patient will be shonw again in your searches and his/her history will be recovered."
                }
            },
            defaultPayment: {
                title: "Payment defaults",
                value: "Value",
                frequency: "Frequency",
                due: "Due Dates",
                method: "Payment",
                frequencyMONTH: "Monthly",
                frequencyAPPOINTMENT: "By appointment",
                frequencyMANUAL: "Manual",
                dueSAMEDAY: "Same day",
                dueSEVENDAY: "Next week",
                dueMONTHDAY5: "Day 5 every month",
                dueMONTHDAY10: "Day 10 every month",
                dueMONTHDAY15: "Day 15 every month",
                dueMONTHDAYLAST: "Last month day",
                methodCREDITCARD: "Credit card",
                methodMONEY: "Money",
                methodPIX: "Pix",
            },
            scheduleStatus: {
                confirmed: "Confirmed",
                cancelled: "Cancelled",
                waiting: "Waiting",
                done: "Done",
                lost: "Lost",
            },
            appointments: {
                past: "Previous appointments",
                statuswaiting:'Waiting confirmation',
                statusconfirmed:'Appointment confirmed',
                statusdone:'Appointment done',
                statuscancelled:'Appointment cancelled (justified)',
                statuslost:'Appointment lost (not justified)',
                statusfuture: "Future appointment",
                next: "Next appointments",
                modalDelete: {
                    title: "Do you really want to remove this appointment?",
                    description: "This action canoot be undone. Payments, notes e activities related to this appointment will be keept unchanged.",
                },
            },
            payments: {
                value: "Value",
                description: "Description",
                status: "Status",
                statusPENDING: "Pending",
                statusREFUSED: "Refused",
                statusPROCESSING: "Processing",
                statusPAID: "Paid",
                statusCANCELLED: "Cancelled",
                appointmentItem: "Appointment at date $1",
                appointmentList: "Related to $1 appointments",
                modalDelete: {
                    title: "Do you really want to remove this payment?",
                    description: "This action canoot be undone. Vouchers attached also will be removed.",
                },
            }
        },
        patientform: {
            invalid: {
                name: "Please, provide a name",
                surname: "Please, provide a surname",
                max: "Provide a longer value",
                min: "Provade a short value",
                phone: "Please, provide a valid phone",
                date: "Please, provide a valid date",
                email: "Plase, provide a valid email",
            },
            label: {
                name: "Name",
                surname: "Surname",
                email: "Email",
                birthdate: "Birthdate",
                patientsince: "Patient Since",
                phone: "Phone",
                isWhatsapp: "Is Whatsapp?",
                allowAccess: "Allow access",
            },
            saved: "Patient successfully saved!",
        },
        patientSchedule: {
            path: "Schedule",
            title: "New Schedule",
            titleEdit: "Change Schedule",
            calendar: "Calendar",
            appointments: "Associated Appointments",
            appointmentsInsert: "Creating this schedule, will be created multple appointments for next days, following rules defined here. Will be generated appointments for next two months and, at the end of every month, new appointments will be generated automatically.",
            appointmentsModify: "Modify all appointments",
            appointmentsModifyDetail: "This way, all appointments after today will be modified to fit this schedule",
            appointmentsKeep: "Keep schedule appointments",
            appointmentsKeepDetail: "This way, appointments already schedule will keep unchanged, and the new ones will be generated following these rules",
            date: "Date",
            agenda: "Schedule",
            start: "Time start",
            end: "Time end",
            weekdays: "Day",
            time: "Time",
            repeat: "Repeat every",
            finish: "Finish",
            never: "Never",
            atDate: "At Day",
            after: "After",
            times: "times",
            repeatValues: {
                once: "Unique",
                week: "Once a week",
                twoweeks: "Every two weeks",
                twiceamonth: "Twice a month",
                month: "Once a month",
            },
            location: "Location",
        },
        scheduleform: {
            saved: "Schedule successfully saved!",
            invalid: {
                dateEmpty: "Date invalid",
                startTime: "Time invalid",
                endTime: "Time invalid",
                repeat: "Repeat invalid",
                finish: "Finish invalid",
                repeatDate: "Date invalid",
                repeatTimes: "No invalid",
            },
            modalRemove: {
                title: "Do you really want to remove this schedule?",
                description: "This action will be permanent and cannot be undone.",
                appointmentsActions: "All appointments done, lost or cancelled will NOT be removed. You can choose what to do with appointments waiting (schedules for after today).",
                appointmentsRemove: "Remove next appointments together",
                appointmentsKeep: "Keep next appointments",
                appointmentsNext: "This action will affect $1.",
                appointment: 'appoitment',
                appointments: 'appoitments',
            },
            unableToRemove: "Oops, couldn't remove schedule...",
            deleted: "Schedule successfully removed!",
        },
        patientActivity: {
            path: "Activities",
            titleNew: "New Activity",
            titleEdit: "Edit Activity",
            name: "Activity name",
            related: "Related to appointement",
            relatedValue: "$1 at $2",
            instructions: "Instructions to activity",
            instructionsPlaceholder: "Describe here instructions to do the activity. How it should be done?",
            type: "Acitivity Type",
            text: "Text or File",
            form: "Form",
            done: "Done",
            content: "Activity Content",
            noContent: "There are no answer at this moment.",
            response: "Response from patient",
            files: "Files attached",
            saved: "Activity saved successfully!",
            answerSendAt: "Answer sent on",
            cript: "Protected by criptography",
            unlock: "Unlock",
            errorCrypt: "Error to decode data",
            lockedToEdit: "Unable to modify this value becouse there are registred answers",
            unableToRemove: "Unable to remove activity",
            removed: "Activity removed successfully!",
            modalDelete: {
                title: "Do you really want to remove this activity?",
                description: "This action cannot be undone. All files and texts attacched to this activity will be permanently removed",
            },
            invalid: {
                name: "Plese, define a name",
                type: "Plese, select a type",
                instructions: "Plese, provide instructions por your patient",
            }
        },
        patientAppointment: {
            path: "Appointments",
            titleNew: "New Appointment",
            titleEdit: "Edit Appointment",
            date: "Date",
            time: "Start time",
            timeend: "End time",
            status: "Status",
            statusValue: {
                "waiting": "Waiting confirmation",
                "confirmed": "Appointment confirmed",
                "done": "Appointment done",
                "cancelled": "Appointment cancelled (justified)",
                "lost": "Appointment lost (not justified)",
            },
            invalid: {
                "date": "Set a valid date",
                "time": "Set a valid start",
                "timeendbefore": "End must be after Start",
                "timeend": "Set a valid end",
                "status": "Set a valid status",
            },
            local: "Local",
            notes: "Appointment notes",
            notesPlaceholder: "Specific information about appointment, notes and details",
            notesEncrypt: "There are encrypted notes for this appointment",
            saved: "Appointment saved successfully!",
            payments: "Payment",
            paymentsCheckbox: "Auto generate payment",
            confirm_by_patient: "Confirmed by patient",
            confirmValueNone: "Waiting for confirmation",
            confirmValue1: "Yes, confirmed",
            confirmValue0: "No, declined",
        },
        patientPayment: {
            titleNew: "New payment",
            titleEdit: "Edit payment",
            saved: "Payment saved successfully!",
            path: "Payments",
            title: "Payments",
            relatedTo: "Related to",
            relatedToAppointment: "Appointment",
            relatedToAppointmentValue: "$1 at $2",
            relatedToMonth: "Month",
            relatedToOther: "Other",
            appointment: "Appointment",
            month: "Month",
            description: "Description",
            type: "Payment method",
            typesValues: {
                money: "Money",
                creditcard: "Credit Card",
                pix: "Pix",
            },
            valueTotal: "Value",
            status: "Status",
            statusPending: "Pending",
            statusRefused: "Refused",
            statusProcessing: "Processing",
            statusPaid: "Paid",
            statusCancelled: "Cancelled",
            dueDate: "Due Date",
            notes: "Notes",
            notesPlaceholder: "Add some notes here",
            noAppointments: "No appointments selected",
            appointmentsAtDay: "Appointment at date",
        },
        payments: {
            title: "Payments",
            titleReports: "Reports",
            filter: "Filter",
            prevMonth: "Previous Month",
            nextMonth: "Next Month",
            new: "New Payment",
            hasFilter: "The following filters are applyed to payments:",
            titleToReceive: "Bills to Receive",
            titleToPay: "Bills to Pay",
            paymentNoItemToReceive: "No bills to receive",
            paymentNoItemToPay: "No bills to pay",
            paymentNoItemCashflow: "No data to cashflow",
            limit: "Your current limit is $1 months from financial history",
            chartTitle: "Summary from last 12 months",
            chartReceived: "Received",
            chartPaid: "Paid",
            chartDiff: "Difference",
            saved: "Payment successfully saved",
            invalidValueTotal: "Required",
            invalidDescription: "Please, set a description",
            invalidMonth: "Please, set a month",
            invalidBillingType: "Please, set billing type",
            payments: {
                description: "Description",
                dueDate: "Due Date",
                value: "Value"
            },
            total: {
                toReceiveTitle: "Total to Receive",
                received: "Already Received",
                toPayTitle: "Total to Pay",
                paid: "Already Paid",
                due: "Close to Due",
                delayed: "Delayed",
            },
            cashflow: {
                title: "Cashflow",
                month: "Month",
                received: "Received",
                paid: "Paid",
                diff: "Result",
                balance: "Balance",
            },
            recomendations: {
                title: "Recomendations",
                grossValue: "Month Gross Value",
                vacations: "Vacation Reserve",
                income13: "13º Income Reserve",
                vacationsThird: "1/3 Vacation",
                socialSecurity: "Social Security",
                socialSecurity13: "Social Security over 13º",
                incomeTax: "Income Tax",
                total: "Total Net",
                text: "Sabemos que ser profissional liberal não é uma tarefa simples. Além de realizar as tarefas pertinentes â profissão, é necessário cuidar das finanças para evitar surpresas desagradáveis.\\nPensando nisso, criamos esta calculadora que recomenda o quanto você deve separar de seus recebimentos para cada conta. Isto inclui 13º, férias e INSS, além de Importo de Renda.\\nImportante salientar que estes valores são calculados baseado no rendimento do mês, e não no valor total do ano (pois o valor pode ser variável). Não tome estes valores como verdade absoluta, mas sim como um parâmetro pra organização pessoal.\\nSempre busque ajuda de um profissional combabilista qualificado.",
            },
            modalFilter: {
                title: "Payments Filter",
                status: "Status",
                statusPending: "Pending",
                statusProcessing: "Processing",
                statusPaid: "Paid",
                statusRefused: "Refused",
                statusCancelled: "Canceled",
                dueDate: "Due Date",
                dueDateMin: "After",
                dueDateMax: "Before",
                method: "Payment Method",
                methodPix: "PIX",
                methodMoney: "Money",
                methodCreditCard: "Credit Card",
                value: "Value",
                valueMin: "Greater then",
                valueMax: "Less then",
                patients: "Patients"
            },
            filterValues: {
                dueDateMin:"Due date after",
                dueDateMax:"Due date before",
                valueMin:"Value greater than",
                valueMax:"Value less than",
                patients_id:"Related to",
                statusPending:"With status as Pending",
                statusProcessing:"With status as Processing",
                statusPaid:"With status as Paid",
                statusCancelled:"With status as Cancelled",
                statusRefused:"With status as Refused",
                methodPix:"With payment method as Pix",
                methodCredit:"With payment method as Credit Card",
                methodMoney:"With payment method as Cash",
            },
            form: {
                titleAdd: "New Payment",
                titleEdit: "Edit Payment",
                billingType: "Billing type",
                billingTypeincome: "Income",
                billingTypeOutcome: "Outcome",
                patient: "Patient",
                invalidBillingType: "Invalid billing type",
            },
            modalDelete: {
                title: "Do you really want to remove this payment?",
                description: "This action canoot be undone. Vouchers attached also will be removed.",
            },
        },
        calendar: {
            title: "Calendar",
            viewModeWeek: "View week",
            viewMode2Week: "View two weeks",
            viewModeMonth: "View month",
            viewNext: "Next period",
            viewPrev: "Previous period",
            path: "New Appointment",
            titleNew: "New Appointment",
            titleEdit: "Edit Appointment",
            patient: "Patient",
            saved: "Appointmente fuccessfully saved!",
            limit: "You can view just $1 first events by week. To see all events, subscribe Plan Pro."
        },
        config: {
            title: "Configurations",
            tabGeneral: "General",
            tabProfile: "Proflle",
            tabSecurity: "Security",
            tabActivities: "Anamnesis & Activities",
            tabPlans: "Plans",
            tabCalendar: "Calendar",
            created_at: "Profile created at",
            labelLang: "Interface Language",
            labelTheme: "Interface Theme",
            themeDark: "Dark Theme",
            themeLight: "Light Theme",
            plans: {
                plan: "Plan",
                plansTitle: "Plans",
                choosePlan: "Choosen Plan",
                changePaymentMethod: "Changing Payment Method",
                basicHeader: "Right now, you are using the Basic Plan. Upgrade your account to unlock all resources and get max of Pilar Terapuèutico can offer to you.",
                planBasicDesc: "For who is starting or just want to discover the platform",
                planProDesc: "For who loves clinical care and wanto to be organized",
                planPlatinumDesc: "For who is living of clinical care and needs to extract all from what platform can offer",
                planBasicRow1: "Until $1 active patients",
                planBasicRow2: "$1 appointments by week on calendar",
                planBasicRow3: "$1 months financial history",
                planProRow1: "Unlimited active patients",
                planProRow2: "Unlimited appointments on calendar",
                planProRow3: "Complete financial history",
                planRow4: "Advanced Cryptography",
                planRow5: "Mobile and Desktop access",
                planRow6: "Receive payments through the platform (only for Brasil)",
                planRow7: "Able to manage video calls",
                subscribe: "Subscribe",
                soon: "Available soon",
                changePlan: "Change Plan",
                updatePayment: "Change Payment Method",
                currentPlan: "Current plan",
                yourCurrentPlan: "This is your current plan",
                planDatailMaxPatients: "Active patients",
                planDatailMaxAppointments: "Appointments by week",
                planDatailMaxFinancial: "Financial history",
                planDatailHasPayments: "Can receive payments",
                planDatailHasCalls: "Can manage calls",
                unlimited: "Unlimited",
                total: "Total",
                month: "/month",
                labelMethod: "Payment Method",
                labelPayerName: "Payer name",
                labelPayerCPF: "CPF",
                labelQrCode: "QR Code will be shown here",
                labelCardNumber: "Card Number",
                labelCardDue: "Due Date",
                labelCardCode: "CVV",
                labelCardHolderName: "Holder Name",
                paymentsCard: "Credit Card",
                paymentsPix: "Pix",
                paymentsBoleto: "Boleto",
                paymentDesc: "Firstly, choose your payment method",
                paymentDesccreditcard: "Choosing credit card, the renew process will occur every month on day $1.",
                paymentDescpix: "Choosing Pix, the renew process will be manual and a new QR Code will be generated 7 days before due date of your subscription.",
                paymentDescboleto: "Choosing Boleto Bancário, the renew process will be manual and a new boleto will be generated 7 days before due date of your subscription.",
                boletoText1: "Click on Subscribe to generate an boleto payment. You can pay this using your bank's app.",
                pixText1: "Click on Subscribe to generate an Pix payment. Você You can pay this using the QRCode or Pix Copy and paste.",
                pixText2: "Pay this Pix by your bank's app using the QR Code or Pix Copy and Paste. Your payment will be automatically deteced and remember that Pix can take some minutes to be processed. The due time to pay is 24h.",
                pixText3: "A new Pix will be generated $1. To keep your subscription of Plan $2 active, don't forget to pay before due date.",
                ccText3: "Insert new's card data to be used on your plan renew. Don't worry, your subscription will not be renewed now.",
                payment: "Payment",
                paymentMethod: "Payment method",
                paymentValuepix: "Pix",
                paymentValuecreditcard: "Card",
                nextRenew: "Next renew",
                activeSince: "Active since",
                planPaidAt: "Paid at",
                planPaidMethod: "Paid by",
                planPrice: "Plan value`",
                discount: "Discounts",
                priceTotal: "Total value",
                areaLastPayment: "Last payment details",
                thankyou: "Your subscription to Plan $1 is confirmed! Thank you for using Pilar Terapêutico :D",
                planChangeSuccess: "Your plan change was successfully completed!",
                planChangeMethodSuccess: "Your payment method was successfully updated!",
                planGoingChange: "On $1, your plan will change from $2 to $3. Until then, your current plan will remain unchanged.",
                referenceTitle: "Discounts & Invites",
                referenceText1: "Do you know that you can receive discounts on your subscription of Plan Pro and Plan Platinum? You just need to invite other professionals to use Pilar Terapêutico.",
                referenceText2: "Here you can find a link to share with other people. When a person creates his/her account and subscribe to a Plan Pro or Plan Platinum, you receive 15% of paid value as discount on your next renew, cumulative while his/her plan continues active!",
                referenceText3: "Imagine that you invite two other people. One of then choose to subscribe Plan Pro, and other one choose to Plan Platinum. While they stay with theirs subscription active, you receive a total discount of R$ 20,97 on your subscription (15% from one Plan Pro + 15% from one Plan Platinum).",
                referenceText4: "In case of a person choose to change his/her plan , your discount also will be updated. In same example, think that person on Plan Platinum choose to downgrade to Plan Pro. On yours next renew, your discount will be R$ 13,47 (2x 15% from Plan Pro). Your max discount is 100% from your subscription, no matter how many people have been subscribed a plan.",
                referenceText5: "Note thar inviting 6 people subscribing the same plan as you, your subscription will be free!",
                referenceLinkLabel: "Below, you can get your link to invete someone",
                referenceDetails: "Details of your invites and discounts",
                referenceCreated: "Account created by your invite",
                referenceActivePro: "Active Plan Pro subscribers",
                referenceActivePlatinum: "Active Plan Platinum subscribers",
                referenceDiscountValue: "Discount on your next renew",
                referenceDiscountPercent: "Discount effective",
                currentCard: "Current credit card",
                savingCard: "Please wait, verifying your card...",
                changeLabel: "Choose the new plan you desire to subscribe",
                changingPlanText: "You are going to change your subscription from Plan $1 to Plan $2.",
                changingPlanUpgrade: "By upgrading, you will pay proportionally according to the remaining days until the next renewal (pro rata). After that, the charge will be made normally in the amount of Plan $1. The resources of the new plan are already active after payment confirmation.",
                changingPlanDowngrade: "By downgrading, the resources of your current plan will remain active until the renewal date. On this date, Plan $1 will be activated and the charge will be made in the amount of Plan $1.",
                changingPlanText2: "If you also want to change the payment method, first change the payment method, then continue with this step. When changing plans, you will not be able to update the payment method until the renewal.",
                changingPlanBasic: "By downgrading, the renewal of your subscription will be deactivated (no new charges will be made) and you can continue using the resources of the current plan until the end of the period.",
                errorCreatePaymentMethod: "Error on save payment method",
                successCancelPayment: "Payment successfully cancelled",
                cardCompliancy: "We follow the security standards of PCI DSS (Payment Card Industry Data Security Standard) and do not store sensitive credit card data. All data is encrypted and sent directly to the card operator.",
                cancelPayment: {
                    modalDelete: {
                        title: "Do you really want to cancel this payment?",
                        description: "Your subscription will not be able to be started/renewed and you will need to restart the subscription process if you change your mind.",
                        confirm: "Yes, cancel"
                    },
                },
                mpStatus: {
                    unknown: '-',
                    pending: 'Waiting payment',
                    approved: 'Approved',
                    authorized: 'Authorized',
                    in_process: 'In analisys',
                    in_mediation: 'In analisys',
                    rejected: 'Rejected (please try again)',
                    cancelled: 'Cancelled/Expirated',
                    refunded: 'Refunded',
                    charged_back: 'Charged Back',
                },
                stripeErrors: {
                    incorrect_number: "The card number is incorrect.",
                    invalid_number: "The card number is not a valid credit card number.",
                    invalid_expiry_month: "The card's expiration month is invalid.",
                    invalid_expiry_year: "The card's expiration year is invalid.",
                    invalid_cvc: "The card's security code is invalid.",
                    expired_card: "The card has expired.",
                    incorrect_cvc: "The card's security code is incorrect.",
                    incorrect_zip: "The card's zip code failed validation.",
                    card_declined: "The card was declined.",
                    missing: "There is no card on a customer that is being charged.",
                    processing_error: "An error occurred while processing the card.",
                    rate_limit:  "An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error."
                }
            },
            security: {
                pass: "Change Password",
                currentPass: "Current Password",
                newPass: "New Password",
                confirmPass: "Confirm Password",
                session: "Active Logins",
                device: "Device",
                lastActivity: "Last Activity",
                forceQuit: "Force Quit",
                invaliidPassword: "Invalid password",
                diffPassword: "Password mismatch"
            },
            template: {
                anamnesis: "Amamnesis",
                activities: "Activities Templates",
                noAnamnesis: "No Amamnesis created",
                noActivities: "No Activities templates",
                noForm: "Select one item to show, or create a new one",
                nameActivity: "Template name",
                nameAnamnesis: "Anamnesis name",
                form: "Form content",
                invaliidName: "Name is mandatory",
                invaliidId: "Item has on id",
                modalDelete: {
                    title: "Do you really want to remove this item",
                    description: "Anamnesis and Activities already created will be keept as is, but you wont be able to create a new one using this template",
                },
                removeOk: "Item successfully removed!",
                unableToRemove: "Unable to remove item"
            },
            savedProfile: "Proflle successfully saved!",
            savedPassword: "Password successfully changed!",
            savedSessionEnd: "Logout successfully!",
            savedAnamnesis: "Anamnesis successfully saved!",
            savedActivities: "Activities Templates successfully saved!",
            savedPayments: "Payments successfully saved!",
            savedCalendar: "Calendar successfully saved!",
            savedPreferences: "Preferences successfully saved!"
        },
        creditcard: {
            invalidMethod: "Invalid method",
            minLengthCardNumber: "Number to short",
            maxLengthCardNumber: "Number to long",
            emptyCardNumber: "Number is mandatory",
            invalidExpiration: "Invalid date",
            emptyExpiration: "Date is mandatory",
            minLengthCVV: "CVV to short",
            maxLengthCVV: "CVV to long",
            invalidCVV: "Invalid CVV",
            emptyCVV: "CVV is mandatory",
            invalidCPF: "Invalid CPF",
            emptyHolderName: "Name is mandatory",
            emptyCPF: "CPF is mandatory",
            noSubscription: "Error creating subscription",
        },
        formbuilder: {
            new: "New question",
            template: "Use template",
            title: "Add a question title",
            answer: "Add an answer",
            text: "Text",
            check: "Choose many",
            radio: "Choose one",
            rating: "Rating",
            textareadata: "Patient's answer will be shonw here",
            noData: "No questions added.\nTo add questions, use buttons above.",
        },
        picture: {
            drag: "Drag photo to send",
            search: "Chose file",
            maxsize: "Max size 1Mb",
        },
        paginator: {
            label: "$1 of $2",
        },
        erros: {
            noId: "ID is mandatory to edit an item"
        },
        time: {
            range: "from $1 to $2",
            hour: "hora",
            hours: "horas",
            minutes: "minutos",
            mix: "$1h $2min",
            today: "Today",
            tomorrow: "Tomorrow"
        },
        dates: {
            timeAt: "$1 at $2",
            intervalFull: "From $1 until $2",
            monthShort: {
                "01": "Jan",
                "02": "Feb",
                "03": "Mar",
                "04": "Apr",
                "05": "May",
                "06": "Jun",
                "07": "Jul",
                "08": "Ago",
                "09": "Sep",
                "10": "Oct",
                "11": "Nov",
                "12": "Dec",
            },
            monthShort2: {
                "01": "Jan",
                "02": "Feb",
                "03": "Mar",
                "04": "Apr",
                "05": "May",
                "06": "Jun",
                "07": "Jul",
                "08": "Ago",
                "09": "Sep",
                "10": "Oct",
                "11": "Nov",
                "12": "Dec",
            },
            monthLong: {
                "0": "",
                "1": "January",
                "2": "February",
                "3": "March",
                "4": "April",
                "5": "May",
                "6": "June",
                "7": "July",
                "8": "Agoust",
                "9": "September",
                "10": "October",
                "11": "November",
                "12": "December",
            },
            dayname: {
                "0": "Sunday",
                "1": "Monday",
                "2": "Tuesday",
                "3": "Wednesday",
                "4": "Thursday",
                "5": "Friday",
                "6": "Saturday",
            },
            daynameshort: {
                "0": "Sun",
                "1": "Mon",
                "2": "Tue",
                "3": "Wed",
                "4": "Thu",
                "5": "Fri",
                "6": "Sat",
            }
        }
    }
}