import * as yup from 'yup';
import { i18n } from '../util/util';

const AppointmentFormSchema = () => yup.object({
    date: yup.date().nullable().default(new Date()).typeError(i18n('patientAppointment.invalid.date')),
    time: yup.number().required(i18n('patientAppointment.invalid.time')).typeError(i18n('patientAppointment.invalid.time')),
    timeend: yup.number().min(yup.ref('time'), i18n("patientAppointment.invalid.timeendbefore")).required(i18n('patientAppointment.invalid.timeend')).typeError(i18n('appointment.timeendInvalid')),
    status: yup.string().required(i18n("patientAppointment.invalid.status")),
    location_id: yup.string().optional(),
    patient_id: yup.string().optional(),
    generatePayment: yup.boolean().default(true)
});

export default AppointmentFormSchema;