import URLs from '../../urls';
import store from '../../redux/stores';
import axios from "./interceptors";
import { i18n } from "../../util/util";
import { RequestHandler } from '../contracts';
import { PatientFormType } from "../../types/PatienDataType";
import { ModalFilterData } from "../../modals/ModalFilterPatients";
import { GetPatientsResponse, GetPatientDataResponse, PostPatientsResponse } from '../contracts/patients';
import { startLoading, stopLoading} from '../../redux/loadingReducer';
import { GetAppointmentsResponse } from "../contracts/appointments";

export const getPatients = async (page?: number, filter?: ModalFilterData): Promise<RequestHandler<GetPatientsResponse>> => {
    const result = new RequestHandler<GetPatientsResponse>();
    try {
        store.dispatch(startLoading({key:'patientes.getPatients'}));
        const data:any = {page};
        for (let i in filter) {
            if (Object(filter).hasOwnProperty(i) && Boolean(filter[i])){
                data[i] = filter[i];
            }
        }
        const res = await axios.get(URLs.patients.index, {params: data})
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'patientes.getPatients'}));
    }
    return result;
}

export const createPatient = async (data: PatientFormType): Promise<RequestHandler<PostPatientsResponse>> => {
    const result = new RequestHandler<PostPatientsResponse>();
    try {
        store.dispatch(startLoading({key:'patientes.createPatients'}));
        
        let formdata = new FormData();

        formdata.append("name", data.name);
        formdata.append("surname", data.surname);
        formdata.append("email", data.email);
        formdata.append("phone", data.phone);
        formdata.append("allow_access", String(data.allow_access));
        formdata.append("is_whatsapp", String(data.is_whatsapp));
        formdata.append("photo", data.photo);
        formdata.append("default_payment_value", String(data.default_payment_value) ?? '0');
        formdata.append("default_payment_frequency", data.default_payment_frequency ?? '');
        formdata.append("default_payment_due", data.default_payment_due ?? '');
        formdata.append("default_payment_method", data.default_payment_method ?? '');

        if (data.anamnesis) {
            formdata.append("anamnesis_id", data.anamnesis);
        }
        if (data.birthdate) {
            formdata.append("birthdate", data.birthdate);
        }
        if (data.patientSince) {
            formdata.append("patientSince", data.patientSince ?? '');
        }

        const res = await axios.post(URLs.patients.index, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'patientes.createPatients'}));
    }
    return result;
}

export const updatePatient = async (data: PatientFormType): Promise<RequestHandler<PostPatientsResponse>> => {
    const result = new RequestHandler<PostPatientsResponse>();
    try {
        store.dispatch(startLoading({key:'patientes.updatePatient'}));
        if (!data.id) {
            throw Error(i18n("erros.noId"));
        }
        let formdata = new FormData();
        
        formdata.append("name", data.name);
        formdata.append("surname", data.surname);
        formdata.append("email", data.email);
        formdata.append("phone", data.phone);
        formdata.append("allow_access", String(data.allow_access));
        formdata.append("is_whatsapp", String(data.is_whatsapp));
        formdata.append("photo", data.photo);
        formdata.append("default_payment_value", String(data.default_payment_value) ?? '0');
        formdata.append("default_payment_frequency", data.default_payment_frequency ?? '');
        formdata.append("default_payment_due", data.default_payment_due ?? '');
        formdata.append("default_payment_method", data.default_payment_method ?? '');
        
        if (data.birthdate) {
            formdata.append("birthdate", data.birthdate);
        }
        if (data.patientSince) {
            formdata.append("patientSince", data.patientSince ?? '');
        }
        
        const res = await axios.put(URLs.patients.update(data.id), formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'patientes.updatePatient'}));
    }
    return result;
}

export const getPatientData = async (id:string): Promise<RequestHandler<GetPatientDataResponse>> => {
    const result = new RequestHandler<GetPatientDataResponse>();
    try {
        store.dispatch(startLoading({key:'patientes.getPatientData'}));
        const res = await axios.get(URLs.patients.read(id))
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'patientes.getPatientData'}));
    }
    return result;
}

export const activePatient = async (id:string): Promise<RequestHandler<PostPatientsResponse>> => {
    const result = new RequestHandler<PostPatientsResponse>();
    try {
        store.dispatch(startLoading({key:'patientes.activePatient'}));
        const res = await axios.post(URLs.patients.active(id), {});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'patientes.activePatient'}));
    }
    return result;
}

export const inactivePatient = async (id:string): Promise<RequestHandler<PostPatientsResponse>> => {
    const result = new RequestHandler<PostPatientsResponse>();
    try {
        store.dispatch(startLoading({key:'patientes.activePatient'}));
        const res = await axios.post(URLs.patients.inactive(id), {});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'patientes.activePatient'}));
    }
    return result;
}

export const getActivePatientsSelect = async (page?: number, filter?:string): Promise<RequestHandler<GetAppointmentsResponse>> => {
    const result = new RequestHandler<GetAppointmentsResponse>();
    try {
        store.dispatch(startLoading({key:'patients.getActivePatientsSelect'}));
        const data = {page, filter};
        const res = await axios.get(URLs.patients.list(), {params: data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'patients.getActivePatientsSelect'}));
    }
    return result;
}