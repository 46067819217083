import axios from "axios";

import URLs from '../../urls';
import store from '../../redux/stores';
import { RequestHandler } from '../contracts';
import { startLoading, stopLoading} from '../../redux/loadingReducer';
import { GetLocationsResponse, PostLocationsResponse } from "../contracts/locations";
import LocationDataType from "../../types/LocationDataType";

export const getLocations = async (): Promise<RequestHandler<GetLocationsResponse>> => {
    const result = new RequestHandler<GetLocationsResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.getLocations'}));
        const res = await axios.get(URLs.locations.index());
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.getLocations'}));
    }
    return result;
}

export const getLocationData = async (id: string): Promise<RequestHandler<GetLocationsResponse>> => {
    const result = new RequestHandler<GetLocationsResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.getLocationData'}));
        const res = await axios.get(URLs.locations.data(id));
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.getLocationData'}));
    }
    return result;
}

export const createLocation = async (data: LocationDataType): Promise<RequestHandler<PostLocationsResponse>> => {
    const result = new RequestHandler<PostLocationsResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.createLocation'}));
        const res = await axios.post(URLs.locations.index(), data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.createLocation'}));
    }
    return result;
}

export const updateLocation = async (data: LocationDataType): Promise<RequestHandler<PostLocationsResponse>> => {
    const result = new RequestHandler<PostLocationsResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.createLocation'}));
        const res = await axios.put(URLs.locations.index(), data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.createLocation'}));
    }
    return result;
}

export const deleteLocation = async (id:string): Promise<RequestHandler<PostLocationsResponse>> => {
    const result = new RequestHandler<PostLocationsResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.createLocation'}));
        const res = await axios.delete(URLs.locations.data(id));
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.createLocation'}));
    }
    return result;
}
