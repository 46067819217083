import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Elements } from '@stripe/react-stripe-js';

import CreditCardForm from "./creditCardForm";
import { loadStripe } from '@stripe/stripe-js';
import { Column, i18n } from "@pilarterapeutico/components";

import * as Tags from '../../pages/styles/Plans.styles';

type CreditCardComponentProps = {
    ref?: React.Ref<any>;
};

const CreditCardComponent: React.FC<CreditCardComponentProps> = forwardRef(({}, ref) => {
    
    const [loading, setLoading] = useState<boolean>(true);
    const stripe = useRef<any>();
    const cardForm = useRef<any>();

    useImperativeHandle(ref, () => ({
        saveCreditCard: () => cardForm.current?.saveCreditCard(),
        getPayerInfo: () => cardForm.current?.getPayerInfo(),
        setLoading: (loading:boolean) => cardForm.current?.setLoading(loading)
    }));

    useEffect(()=>{
        stripe.current = loadStripe(import.meta.env.VITE_STRIPE_PUBLICKEY as string);
        setLoading(false);
    }, []);

    return !loading && stripe.current ? <Elements stripe={stripe.current}>
        <Column align="stretch">
            <CreditCardForm ref={cardForm} />
            <Tags.PlanText style={{marginTop:"0.5rem", lineHeight:"1.2rem", fontSize:"0.9rem", color:"var(--theme-color-font-weak)"}}>
                {i18n('config.plans.cardCompliancy')}
            </Tags.PlanText>
        </Column>
    </Elements> : null;
});

export default CreditCardComponent;
