import axios from "axios";
import store from '../../redux/stores';

import URLs from '../../urls';
import AppointmentDataType from "../../types/AppointmentDataType";
import { RequestHandler } from '../contracts';
import { startLoading, stopLoading} from '../../redux/loadingReducer';
import { GetAppointmentsAgendaResponse, GetAppointmentsDataResponse, GetAppointmentsResponse, PostAppointmentsResponse } from "../contracts/appointments";

export const getAppointments = async (patientId: string, type: 'both'|'prev'|'next', page?: number, size?:number): Promise<RequestHandler<GetAppointmentsResponse>> => {
    const result = new RequestHandler<GetAppointmentsResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.getAppointments'}));
        const data = {page, type, size};
        const res = await axios.get(URLs.patients.appoitments.index(patientId), {params: data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.getAppointments'}));
    }
    return result;
}

export const getAppointmentsWithFilter = async (patientId: string, page?: number, filter?:string): Promise<RequestHandler<GetAppointmentsResponse>> => {
    const result = new RequestHandler<GetAppointmentsResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.getAppointments'}));
        const data = {page, filter, hasFilter:1};
        const res = await axios.get(URLs.patients.appoitments.index(patientId), {params: data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.getAppointments'}));
    }
    return result;
}


export const getAppointmentData = async (patientId: string, appointmentId: string): Promise<RequestHandler<GetAppointmentsDataResponse>> => {
    const result = new RequestHandler<GetAppointmentsDataResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.getAppointmentData'}));
        const res = await axios.get(URLs.patients.appoitments.data(appointmentId, patientId));
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.getAppointmentData'}));
    }
    return result;
}

export const getTherapistAgenda = async (patientId: string, date?: string): Promise<RequestHandler<GetAppointmentsAgendaResponse>> => {
    const result = new RequestHandler<GetAppointmentsAgendaResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.getTherapistAgenda'}));
        const data = date ? {date} : {};
        const res = await axios.get(URLs.patients.appoitments.agenda(patientId), {params: data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.getTherapistAgenda'}));
    }
    return result;
}

export const createAppointment = async (patientId: string, data: AppointmentDataType): Promise<RequestHandler<PostAppointmentsResponse>> => {
    const result = new RequestHandler<PostAppointmentsResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.createAppointment'}));
        const res = await axios.post(URLs.patients.appoitments.index(patientId), data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.createAppointment'}));
    }
    return result;
}

export const updateAppointment = async (patientId: string, data: AppointmentDataType): Promise<RequestHandler<PostAppointmentsResponse>> => {
    const result = new RequestHandler<PostAppointmentsResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.updateAppointment'}));
        const res = await axios.put(URLs.patients.appoitments.data(data.id ?? '0', patientId), data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.updateAppointment'}));
    }
    return result;
}

export const deleteAppointment = async (id: string, patientId: string): Promise<RequestHandler<PostAppointmentsResponse>> => {
    const result = new RequestHandler<PostAppointmentsResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.deleteAppointment'}));
        const res = await axios.delete(URLs.patients.appoitments.data(id, patientId));
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.deleteAppointment'}));
    }
    return result;
}