import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { mdiCheck, mdiCheckboxMultipleOutline, mdiClose, mdiCloseBoxMultipleOutline, mdiPlus, mdiTrashCan } from "@mdi/js";

import {Row, Column, EmptyState, Button, Input, Label, Translate as T, i18n, FormBuilder, QuestionsType, RowOrColumn } from "@pilarterapeutico/components";
import ActivityTemplateFormSchema from "../forms/activityTemplateForm";
import { FormError, RequestHandler } from "../services/contracts";
import { createTemplate, deleteTemplate, getTemplateData, listActivityTemplate, updateTemplate } from "../services/requests/activities";
import { ActivitiesTemplateItem, GetActivitiesTemplateListResponse } from "../services/contracts/activities";
import * as Tags from './styles/ConfigSecurity.styles';
import { ButtonBar, ButtonGroup } from "./styles/ConfigProfileForm.styles";
import { setMessage } from "../redux/messagesReducer";
import { hideModal, setModalAndShow } from "../redux/modalReducer";
import ModalDelete from "../modals/ModalDelete";

interface FormTemplateType {
    id?: string,
    name: string,
    anamnesis: string,
    form: string,
}

const ConfigActivities = () => {

    const [listActivities, setListActivities] = useState<ActivitiesTemplateItem[]>([]);
    const [listAnamnesis, setListAnamnesis] = useState<ActivitiesTemplateItem[]>([]);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [dataId, setDataId] = useState<string>("");
    const [dataName, setDataName] = useState<string>("");
    const [dataAnamnesis, setDataAnamnesis] = useState<boolean>(false);
    const [dataFormContent, setDataFormContent] = useState<QuestionsType[]>([]);
    const [dataErrors, setDataErrors] = useState<FormError[]>([]);

    const dispatch = useDispatch();
    const update = useRef<boolean>(true);

    const validateAll = (data:FormTemplateType) => {
        console.log(data);
        try {
            ActivityTemplateFormSchema().validateSync(data, {abortEarly:false});
            return true;
        } catch (e:any) {
            if (e.inner && Array.isArray(e.inner)) {
                setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
            }
            return false;
        }
    };

    const handleOpenFormNewAnamnesis = () => {
        setDataId("");
        setDataName("");
        setDataFormContent([]);
        setDataAnamnesis(true);
        setShowForm(true);
    }

    const handleOpenFormNewActivity = () => {
        setDataId("");
        setDataName("");
        setDataFormContent([]);
        setDataAnamnesis(false);
        setShowForm(true);
    }

    const handleCancelForm = () => {
        setShowForm(false);
    }

    const handleConfirmDeleteForm = () => {
        dispatch(setModalAndShow({
          content: <ModalDelete i18nKey={"config.template"} onConfirm={handleDeleteConfirm} />,
        }))
    }

    const handleDeleteConfirm = async () => {
        dispatch(hideModal());
        const response = await deleteTemplate(dataId);
        if (response.error) {
            dispatch(setMessage({
                message: i18n("config.template.unableToRemove"),
                type: "error",
            }));
        } else {
            dispatch(setMessage({
                message: i18n("config.template.removeOk"),
                type: "success",
            }));
            setShowForm(false);
            updateList(dataAnamnesis ? 'anamnesis' : 'activity');
        }
    }

    const handleSelectItem = async (id?:string) => {
        try {
            if (!id) {
                throw new Error(i18n("config.template."));
            }
            setDataId(id);
            let req = await getTemplateData(id);

            if (req && req.data && update.current) {
                setShowForm(true);
                setDataName(req.data?.template.name ?? "");
                setDataFormContent(JSON.parse(req.data?.template.form));
                setDataAnamnesis(Boolean(req.data?.template.anamnesis));
            }
        } catch (e:any) {
            dispatch(setMessage({message: e.message ?? '', type: "error"}));
        }
    }

    const handleSaveForm = async () => {
        const data:FormTemplateType = {
            name: dataName,
            form: JSON.stringify(dataFormContent),
            anamnesis: String(dataAnamnesis),
        };

        setDataErrors([]);

        const isValidForm = validateAll(data);
        if (!isValidForm) {
            return;
        }

        let response:RequestHandler<GetActivitiesTemplateListResponse>;
        if (dataId) {
            data.id = dataId;
            response = await updateTemplate(data);
        } else {
            response = await createTemplate(data);
        }

        if (response.error) { 
            if (response.type === 'form') {
              setDataErrors((response.error ?? []) as FormError[]);
            } else {
              dispatch(setMessage({message: response.error ?? '', type: "error"}));
            }
        } else {
            if (dataAnamnesis) {
                updateList('anamnesis');
                dispatch(setMessage({message: i18n('config.savedAnamnesis'), type: "success"}));
            } else {
                updateList('activity');
                dispatch(setMessage({message: i18n('config.savedActivities'), type: "success"}));
            }
            setShowForm(false);
        }
    }

    const updateList = async (type?:'anamnesis'|'activity') => {
        let req = await listActivityTemplate(type);

        if (req && update.current) {
            let anamnesis:ActivitiesTemplateItem[] = [];
            let activities = req.data?.templates.filter(i => !Boolean(i.anamnesis) ? true : (anamnesis.push(i) && false)) ?? [];

            if (type === 'anamnesis' || !type) {
                setListAnamnesis(anamnesis);
            }
            if (type === 'activity' || !type) {
                setListActivities(activities);
            }
        }
    }

    const getFistError = useMemo(() => {
        return (key:string) => dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
    }, [dataErrors]);

    useEffect(()=>{
        updateList();
        return ()=>{update.current=false;};
    }, []);

    return <>
        <div style={{padding:'1rem'}}>
            <RowOrColumn rowAlign="stretch" columnAlign="stretch" columnMargin="0 0 3rem 0">
                <div style={{flex:2, borderRight:"1px solid var(--theme-color-line2)"}}>
                    <Column align="stretch" style={{paddingRight:"1rem", height: "100%"}}>
                        <Row justify="space-between" align="center" style={{marginBottom:"1rem"}}>
                            <Tags.Header style={{margin:0}}>
                                <T path="config.template.anamnesis" />
                            </Tags.Header>
                            <Button onClick={()=>handleOpenFormNewAnamnesis()} color="var(--theme-color-green)" icon={mdiPlus}>{i18n("new")}</Button>
                        </Row>
                        {listAnamnesis && listAnamnesis.length > 0 ? listAnamnesis.map((item, i) => <Tags.TableRowSelect onClick={()=>handleSelectItem(item.id)} className={dataId === item.id ? 'selected' : ''} key={i}>
                            <Tags.TableHeaderCell style={{flex: 1}}>{item.name}</Tags.TableHeaderCell>
                        </Tags.TableRowSelect>) : <EmptyState title={i18n("config.template.noAnamnesis")} icon={mdiCloseBoxMultipleOutline} size={2} />}

                        <Row justify="space-between" align="center" style={{margin:"2rem 0 1rem"}}>
                            <Tags.Header style={{margin:"0"}}>
                                <T path="config.template.activities" />
                            </Tags.Header>
                            <Button onClick={()=>handleOpenFormNewActivity()} color="var(--theme-color-green)" icon={mdiPlus}>{i18n("new")}</Button>
                        </Row>
                        {listActivities && listActivities.length > 0 ? listActivities.map((item, i) => <Tags.TableRowSelect onClick={()=>handleSelectItem(item.id)} className={dataId === item.id ? 'selected' : ''} key={i}>
                            <Tags.TableHeaderCell style={{flex: 1}}>{item.name}</Tags.TableHeaderCell>
                        </Tags.TableRowSelect>) : <EmptyState title={i18n("config.template.noActivities")} icon={mdiCloseBoxMultipleOutline} size={2} />}
                    </Column>
                </div>
                <div style={{flex:3, marginBottom:"2rem"}}>
                    <Column style={{height: "100%"}} justify="flex-start" align="stretch">
                        {showForm ? <>
                            <Tags.Field noFlex={true}>
                                <Label><T path={`config.template.name${dataAnamnesis ? 'Anamnesis' : 'Activity'}`} /></Label>
                                <Input
                                    uid='template.name'
                                    value={dataName} 
                                    autoComplete="off"
                                    formError={getFistError('name')}
                                    onChange={(e)=>{setDataName((e.target as HTMLInputElement).value)}} 
                                />
                            </Tags.Field>
                            <Tags.Field noFlex={true}>
                                <Label><T path="config.template.form" /></Label>
                            </Tags.Field>
                            <FormBuilder value={dataFormContent} onChange={setDataFormContent} />
                        </> : <EmptyState title={i18n("config.template.noForm")} icon={mdiCheckboxMultipleOutline} size={3} />}
                    </Column>
                </div>
            </RowOrColumn>
        </div>
        {showForm ? <ButtonBar style={{justifyContent:"space-between"}}>
            <ButtonGroup>
                {dataId ? <Button onClick={()=>handleConfirmDeleteForm()} title={i18n("delete")} icon={mdiTrashCan} color={"var(--theme-color-button-red)"}/> : null}
            </ButtonGroup>
            <ButtonGroup>
                <Button onClick={()=>handleCancelForm()} title={i18n("cancel")} icon={mdiClose} color={"var(--theme-color-button-gray)"}/>
                <Button onClick={()=>handleSaveForm()} title={i18n("save")} icon={mdiCheck} color={"var(--theme-color-button-green)"}/>
            </ButtonGroup>
        </ButtonBar> : null}
    </>
}

export default ConfigActivities;
