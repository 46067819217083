export const notEmpty = (value: string | null | undefined, message: string): string | boolean => {
    if (!value || !value.length || value.length < 2) {
        return message;
    }
    return true;
}

export const notEmptyDate = (value: Date | null | undefined, message: string): string | boolean => {
    if (!value || !value.getTime()) {
        return message;
    }
    return true;
}

export const matchEqual = (value: string | null | undefined, compareTo: string | null | undefined, message: string): string | boolean => {
    if (value !== compareTo) {
        return message;
    }
    return true;
}

export const betweenValues = (value: number | null | undefined, minVal: number | null | undefined, maxVal: number | null | undefined, message: string): string | boolean => {
    if (!value || (Boolean(minVal) && value < (minVal ?? Number.NEGATIVE_INFINITY)) || (Boolean(maxVal) && value > (maxVal ?? Number.POSITIVE_INFINITY))) {
        return message;
    }

    console.log('ok');

    return true;
}