import i18next from "i18next";

export const MinMax = (v: number, min: number, max: number) => {
    return Math.min(Math.max(v,min),max);
}

export const RequestJsonAsForm = (obj: {[key:string]:any}) => {
    let formdata = new FormData();

    for(let i of Object.keys(obj)) {
        if (obj.hasOwnProperty(i)) {
            if (Array.isArray(obj[i])) {
                obj[i].map((o:any) => formdata.append(i,o))
            } else {
                formdata.append(i, obj[i]);
            }
        }
    }

    return formdata;
}

export const isElementInViewport = (el: HTMLElement) => {
    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export const classSet = (obj:{[i:string|number]: any}):string => {
    let result = "";

    for(let i in obj) {
        if (i && Boolean(obj[i])) {
            result += " "+i;
        }
    }

    return result.trim();
}

export const i18n = (path:string) => {
    return i18next.t(path) || "";
}

export const statesList = [
    {id:"AC", name:"Acre"},
    {id:"AL", name:"Alagoas"},
    {id:"AP", name:"Amapá"},
    {id:"AM", name:"Amazonas"},
    {id:"BA", name:"Bahia"},
    {id:"CE", name:"Ceará"},
    {id:"DF", name:"Distrito Federal"},
    {id:"ES", name:"Espírito Santo"},
    {id:"GO", name:"Goiás"},
    {id:"MA", name:"Maranhão"},
    {id:"MT", name:"Mato Grosso"},
    {id:"MS", name:"Mato Grosso do Sul"},
    {id:"MG", name:"Minas Gerais"},
    {id:"PA", name:"Pará"},
    {id:"PB", name:"Paraíba"},
    {id:"PR", name:"Paraná"},
    {id:"PE", name:"Pernambuco"},
    {id:"PI", name:"Piauí"},
    {id:"RJ", name:"Rio de Janeiro"},
    {id:"RN", name:"Rio Grande do Norte"},
    {id:"RS", name:"Rio Grande do Sul"},
    {id:"RO", name:"Rondônia"},
    {id:"RR", name:"Roraima"},
    {id:"SC", name:"Santa Catarina"},
    {id:"SP", name:"São Paulo"},
    {id:"SE", name:"Sergipe"},
    {id:"TO", name:"Tocantins"},
];