import axios from "./interceptors";

import URLs from '../../urls';
import { RequestHandler } from '../contracts';
import { GetSearchResponse } from '../contracts/search';

export const getSearchData = async (val: string): Promise<RequestHandler<GetSearchResponse>> => {
    const result = new RequestHandler<GetSearchResponse>();
    try {
        const res = await axios.get(URLs.resources.search, {params: {search: val}});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    }
    return result;
}
