import styled from "styled-components"

export const Content = styled.div`
    padding: 1rem;
`;

export const PageLabel = styled.div`
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 1rem;
`;

export const AreaTitle = styled.div`
    margin-bottom: 1rem;
    font-size: 1.2rem;
    line-height: 1rem;
`;

export const PlanLabel = styled.div`
    margin-bottom: 1rem;
    line-height: 1rem;
`;

export const PlanText = styled.div`
    margin-bottom: 1rem;
    line-height: 1.5rem;
`;

export const PlanWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    margin: 0 0 3rem 0;
    
    &:last-child {
        margin: 0 0 1rem 0;
    }
    
    @media (min-width: 1000px) {
        margin: 0 0 0 1rem;
        
        &:last-child {
            margin: 0;
        }
    }
`;

export const PlanName = styled.div`
    font-size: 1.2rem;
    margin-bottom: 1rem;
`;

export const PlanDesc = styled.div`
    font-size: 0.9rem;
    line-height: 1rem;
    height: 4rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const PlanResources = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 0.5rem;
    width: 100%;
`;

export const PlanResItem = styled.li`
    font-size: 1rem;
    margin: 0 0 0.4rem;
    padding-left: 1rem;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    & > span {
        margin-left: 0.3rem;
    }
`;

export const PlanPrice = styled.div`
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    height: 3rem;
    width: 100%;
`;

export const PlanPriceSmall = styled.div`
    font-size: 0.8rem;
    line-height: 1rem;
`;

export const PlanPriceBig = styled.div`
    font-size: 2.5rem;
    line-height: 2rem;
`;

export const CurrentPlan = styled.div`
    font-size: 0.8rem;
    line-height: 1rem;
    color: var(--theme-color-font-weak);
    margin-top: 0.5rem;

    @media (min-width: 1000px) {
        margin-top: 2.5rem;
    }
`;

export const ButtonRowCenter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 0.5rem;
    
    @media (min-width: 1000px) {
        margin-top: 2rem;
    }
`;

export const PlanSoon = styled.div`
    font-size: 1.5rem;
    color: var(--theme-color-primary);
    text-transform: uppercase;
`;

export const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

export const FieldText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    margin-top:1.8rem;
    height: 3rem;
    line-height: 1.2rem;
`;

export const PixQRCode = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10rem;
    width: 10rem;
    background-color: var(--theme-color-background-input);
    border: 1px solid var(--theme-color-border-input);

    @media (max-width: 1000px) {
        margin-bottom: 2rem;
    }
`;

export const PixQRCodeLabel = styled.div`
    width: 70%;
    text-align: center;
    font-size: 0.9rem;
    line-height: 1rem;
    color: var(--theme-color-font-empty);
`;

export const LoadingIndicator = styled.div`
    width: 1.6rem;
    height: 1.6rem;
    margin-bottom: 1rem;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 1.2rem;
        height: 1.2rem;
        border: 3px solid var(--theme-color-font-empty);
        border-bottom: 3px solid transparent;
        border-right: 3px solid transparent;
        border-radius: 50%;
        animation: loading-spinner 1s linear forwards infinite;
    }
`;

export const Input = styled.div`
    font-size: 1rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: 3rem;
    background-color: var(--theme-color-background-input);
    border: 1px solid var(--theme-color-border-input);
    position: relative;
`;

export const InputContent = styled.div`
    position: absolute;
    inset: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 3rem;
    line-height: 3rem;
    padding: 0 0.5rem;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;

    & > :not(:last-child) {
        margin-right: 10px;
    }
`;


export const Element = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    height: 3rem;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid var(--theme-color-border-input);
    background-color: var(--theme-color-background-input);

    &.focus {
        outline: var(--theme-color-font-input) auto 1px;
    }
`;

export const ElementContent = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.5rem;
`;

export const MessageError = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 0.75rem;
    border: 1px solid var(--theme-color-background-red);
    background-color: var(--theme-color-font-error);
    font-size: 1rem;
    line-height: 1.2rem;
    color: #fff;
    margin-top: 1rem;
`;

export const CardNumber = styled.div`
    flex: 1;
`;

export const CardBrand = styled.div`
    margin-right: 1rem;
`;

//https://github.com/aaronfagan/svg-credit-card-payment-icons/
export const CardImg = styled.img`
    width: 31.2px; // 780
    height: 20px; // 500
`;

export const CardModify = styled.div`
    color: var(--theme-color-value-blue);
    text-decoration: none;
    font-size: 1.2rem;
    cursor: pointer;
`;

export const Loading = styled.div`
    background-color: var(--theme-color-background-aux);
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--theme-color-font);
`;

export const LabelArea = styled.div`
    font-size: 0.8rem;
    line-height: 2.4rem;
    width: 100%;
    height: 2.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    color: var(--theme-color-font-empty, #999);
    text-transform: uppercase;
    justify-content: stretch;

    :before {
        margin-right: 0.5rem;
        flex: 1;
        height: 0px;
        content: '';
        border-bottom: 1px solid var(--theme-color-background-aux2, #aaa);
    }

    :after {
        margin-left: 0.5rem;
        flex: 1;
        height: 0px;
        content: '';
        border-bottom: 1px solid var(--theme-color-background-aux2, #aaa);
    }
`;

export const PaymentForm = styled.div`
    margin: 2rem 0 5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    flex: 2;

    @media (min-width: 1000px) {
        margin: 0;
        padding: 0 0 0 1rem;
    }
`;