import { useEffect, useMemo, useRef, useState } from 'react';
import Icon from '@mdi/react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { mdiClose, mdiCheck, mdiTrashCan, mdiLock } from '@mdi/js';

import ModalDelete from '../modals/ModalDelete';
import PatienDataType from '../types/PatienDataType';
import usePatientData from '../hooks/usePatientData';
import ActivityFormSchema from '../forms/activityForm';
import { getFile } from '../services/requests/statics';
import { storeKey } from '../redux/authReducer';
import { setMessage } from '../redux/messagesReducer';
import { decryptData } from '../util/crypt';
import { ActivityFormType } from '../types/ActivityDataType';
import { PostActivitiesResponse } from '../services/contracts/activities';
import { FormError, RequestHandler } from '../services/contracts';
import { getAppointmentsWithFilter } from '../services/requests/appointments';
import { setModalAndShow, hideModal } from '../redux/modalReducer';
import { createActivity, deleteActivity, getActivityData, getTemplateData, listActivityTemplate, updateActivity } from '../services/requests/activities';
import { TimeFromIntMinutesToTime, DateFromISOToTimeAt, DateStrFromUTCToView } from '@pilarterapeutico/util';
import { Row, Card, Column, Breadcrumb, PageContent, TitleBar, Button, Input, Label, Select, Textarea, SelectPaged, FormBuilder, QuestionsType, Upload, FileItem, FileItemExternal, Translate, i18n, Checkbox, FormResult, RowOrColumn } from '@pilarterapeutico/components';
import * as Tags from './styles/PatientForm.styles';

const PatientActivityForm = ({edit}:{edit?:boolean}) => {

    const [dataName, setDataName] = useState<string>("");
    const [dataRelated, setDataRelated] = useState<string>("");
    const [dataRelatedList, setDataRelatedList] = useState<{value:string, label:string}[]>([]);
    const [dataInstructions, setDataInstructions] = useState<string>("");
    const [dataType, setDataType] = useState<string>("TEXT");
    const [contentAnswerList, setContentAnswerList] = useState<{id:string, content:string, date:string, decrypted?: string}[]>([]);
    const [dataForm, setDataForm] = useState<QuestionsType[]>([]);
    const [dataDone, setDataDone] = useState<boolean>(false);
    const [dataFiles, setDataFiles] = useState<FileItem[]>([]);
    const [dataFilesList, setDataFilesList] = useState<FileItemExternal[]>([]);
    const [dataFilesListRemove, setDataFilesListRemove] = useState<string[]>([]);
    const [dataFilesSent, setDataFilesSent] = useState<FileItemExternal[]>([]);
    const [dataAnswersDecriptedIds, setDataAnswersDecriptedIds] = useState<string[]>([]);
    const [dataErrors, setDataErrors] = useState<FormError[]>([]);
    const [patientData, setPatientData] = useState<PatienDataType>();
    const [keyQueue, setKeyQueue] = useState<[string,any][]>([]);
    const [templates, setTemplates] = useState<{id:string, label: string}[]>([]);

    const storedKey = useSelector<any, string|undefined>(s => s.auth.key);

    const refInput = useRef<HTMLInputElement>(null);
    const refUpdate = useRef<boolean>(true);

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const patient = usePatientData();

    const validateAll = (data:ActivityFormType) => {
        try {
            ActivityFormSchema().validateSync(data, {abortEarly:false});
            return true;
        } catch (e:any) {
            if (e.inner && Array.isArray(e.inner)) {
                setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
            }
            return false;
        }
    };

    const handleSave = async () => {
        const data:ActivityFormType = {
            name: dataName,
            appointment_id: JSON.stringify(dataRelatedList?.map(r => r.value) ?? undefined),
            type: dataType,
            instructions: dataInstructions,
            form: JSON.stringify(dataForm),
            done: dataDone,
            files: dataFiles.map(f => f.file),
            files_ids: dataFiles.map(f => f.id),
            files_remove: JSON.stringify(dataFilesListRemove ?? undefined),
        };

        setDataErrors([]);

        const isValidForm = validateAll(data);
        if (!isValidForm) {
            return;
        }

        let response:RequestHandler<PostActivitiesResponse>;
        if (edit) {
            data.id = params.activityId;
            response = await updateActivity(data, String(params.id));
        } else {
            response = await createActivity(data, String(params.id));
        }

        if (response.error) { 
            if (response.type === 'form') {
              setDataErrors((response.error ?? []) as FormError[]);
            } else {
              dispatch(setMessage({message: response.error ?? '', type: "error"}));
            }
        } else {
            dispatch(setMessage({message: i18n("patientform.saved"), type: "success"}));
            navigate(`/patients/${params.id}`,{replace:true});
        }
    }
    
    const handleCancel = () => {
        navigate(-1);
    }

    const handleDelete = () => {
        dispatch(setModalAndShow({
          content: <ModalDelete i18nKey={"patientActivity"} onConfirm={handleDeleteConfirm} />,
        }))
    }
    
    const handleDeleteConfirm = async () => {
        dispatch(hideModal());
        const response = await deleteActivity(String(params.activityId), String(params.id));
        if (response.error) {
          dispatch(setMessage({
            message: i18n("patientActivity.unableToRemove"),
            type: "error",
          }));
        } else {
          dispatch(setMessage({
            message: i18n("patientActivity.removed"),
            type: "success",
          }));
          navigate(`/patients/${params.id}`);
        }
    }

    const handleSetRelated = (option: {value:string, label:string}) => {
        setDataRelatedList(o => {
            if (o.some(i => i.value === option.value)) {
                return o;
            }
            return [...o, option]
        });
        setDataRelated(String(Date.now()));
    }

    const handleRemoveRelated = (value:string) => {
        setDataRelatedList(o => o.filter(i => i.value !== value));
    }

    const handleRemoveFileExternal = (value:string) => {
        setDataFilesListRemove(s => [...s, value]);
        setDataFilesList(list => list.filter(f => f.id !== value));
    }

    const handleGetTemplateData = (id:string) => {
        getTemplateData(id).then(req => {
            if(refUpdate.current && req.data?.template) {
                try {
                    setDataForm(JSON.parse(req.data?.template.form));
                } catch (e) {
                    dispatch(setMessage({
                        message: e,
                        type: "error",
                    }))
                }
            }
        });
    }

    const getFistError = useMemo(() => {
        return (key:string) => dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
    }, [dataErrors]);

    const handleFileChange = () => {
        try {
            let file = (refInput.current && refInput.current.files) ? refInput.current?.files[0] : undefined;
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    let key = (e.target?.result as string);
                    dispatch(storeKey({content:key ?? undefined}));
                };
                reader.readAsText(file);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const handleDownloadFile = async (id:string, name:string) => {
        setKeyQueue(s => [...s, ['getFile', {id,name}]]);
    }

    const handleUnlockAnswerContent = (id:string) => {
        setKeyQueue(s => [...s, ['decrypt', {id}]]);
    }

    const _getFile = async (id:string, name:string) => {
        const content = await getFile(id, storedKey);

        if (!content) {
            return;
        }
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(content);
        link.download = name; 
        link.click();
    }

    const _decryptContent = async (id:string) => {
        try {
            const newContent = [...contentAnswerList];

            for(let i in newContent) {
                if (newContent[i].id === id) {
                    newContent[i].decrypted = await decryptData(storedKey ?? '', newContent[i].content);
                    setContentAnswerList(newContent);
                    break;
                }
            }
            
            setDataAnswersDecriptedIds(ids => ids.indexOf(id) >= 0 ? ids : [...ids, id])
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(()=>{
        if (keyQueue.length === 0) {
            return;
        }
        
        if (!storedKey) {
            refInput.current?.click();
        } else {
            const item = keyQueue[0];
            const fn = item[0];
            switch(fn) {
                case 'getFile':
                    _getFile(item[1].id, item[1].name); break;
                case 'decrypt':
                    _decryptContent(item[1].id); break;
            }
            setKeyQueue(k => k.slice(1));
        }
    }, [keyQueue, storedKey]);

    useEffect(()=>{
        if (!params.activityId) {
            return;
        }

        (async () => {
            let reqData = await getActivityData(params.activityId ?? '', params.id ?? '');

            if (reqData && refUpdate.current) {
                setDataName(reqData.data?.name ?? '');
                setDataRelated(String(Date.now()));
                setDataRelatedList(reqData.data?.appointment_id.map(a => ({value:a.id, label:DateFromISOToTimeAt(a.date) ?? ''})) ?? []);
                setDataInstructions(reqData.data?.instructions ?? '');
                setDataType(reqData.data?.type ?? 'TEXT');
                setDataDone(reqData.data?.done === 1);
                setDataFilesList(reqData.data?.files.filter(f=>f.sender==='T') ?? []);
                setDataFilesListRemove([]);
                setContentAnswerList(reqData.data?.answers ?? []);
                setDataFilesSent(reqData.data?.files.filter(f=>f.sender==='P') ?? []);

                try {
                    setDataForm(JSON.parse(reqData.data?.form ?? 'undefined'))
                } catch (e) {
                    console.error(e);
                }
            }
        })();
    }, [params.activityId]);

    useEffect(()=>{
        (async () => {
          let data = await patient.getData(params.id);
          if (patient.update) {
            setPatientData(data);
          }
        })();
    
        return patient.fn;
    }, [location.state?.patient?.name]);

    useEffect(()=>{
        listActivityTemplate("activity").then(req => {
            if (refUpdate.current && req.data?.templates) {
                setTemplates(req.data?.templates.map(t => ({id:t.id ?? '', label:t.name})) ?? [])
            }
        });
    
        return ()=>{refUpdate.current=false;};
    }, []);

    const patientPath = `/patients/${params.id ?? ''}`;
    const breadcrumbPath:any = {};
    breadcrumbPath["/patients"] = i18n("patients.title");
    breadcrumbPath[patientPath] = patientData?.name ?? '';

    return (<PageContent>
        <TitleBar>
            <Breadcrumb items={breadcrumbPath} current={i18n("patientActivity.path")} />
        </TitleBar>
        <Card title={i18n(`patientActivity.title${edit ? 'Edit' : 'New'}`)}>
            <div style={{padding:'1rem'}}>
                <RowOrColumn rowAlign='flex-start' rowMargin="2rem" columnAlign='stretch' columnMargin='0 0 1rem 0'>
                    <Column align="stretch" style={{flex:1}}>
                        <Tags.Field>
                            <Label><Translate path="patientActivity.name" /></Label>
                            <Input uid="patientActivity.name" value={dataName} onChange={(e) => {setDataName((e.target as HTMLInputElement).value)}} formError={getFistError('name')} />
                        </Tags.Field>
                        <Tags.Field>
                            <Label><Translate path="patientActivity.related" /></Label>
                            <SelectPaged 
                                    uid="patientActivity.related" 
                                    value={dataRelated} 
                                    onChange={(option) => {handleSetRelated(option)}}
                                    loader={async (page:number, filter:string) => {return getAppointmentsWithFilter(params.id ?? '0', page, filter)}}
                                    transform={(item:any, index:number)=>({index, value:item.id, label:i18n("patientPayment.relatedToAppointmentValue").replace("$1",DateStrFromUTCToView(item.date)).replace("$2",TimeFromIntMinutesToTime(item.time)??'')})}
                                />
                        </Tags.Field>
                        <Column style={{marginBottom: "1rem"}}>
                            {dataRelatedList.length ? dataRelatedList.map((a,i) => <Tags.AppointmentsItemsRow key={i}>
                                <div>{i18n("patientPayment.appointmentsAtDay")} {a.label}</div>
                                <div className='btn' onClick={()=>{handleRemoveRelated(a.value)}}>
                                    <Icon path={mdiClose} size={0.8} />
                                </div>
                            </Tags.AppointmentsItemsRow>) : <Tags.NoAppointmens>{i18n("patientPayment.noAppointments")}</Tags.NoAppointmens>}
                        </Column>
                        <Tags.Field>
                            <Label><Translate path="patientActivity.instructions" /></Label>
                            <Textarea uid="patientActivity.instructions" placeholder={i18n("patientActivity.instructionsPlaceholder")} value={dataInstructions} onChange={(e) => {setDataInstructions((e.target as HTMLTextAreaElement).value)}} formError={getFistError('instructions')} />
                        </Tags.Field>
                        <Tags.Field>
                            <Label><Translate path="patientActivity.type" /></Label>
                            <Select uid="patientActivity.type" value={dataType} onChange={(e) => {setDataType((e.target as HTMLSelectElement).value)}} readOnly={contentAnswerList && contentAnswerList.length > 0}>
                                <option value="TEXT">{i18n("patientActivity.text")}</option>
                                <option value="FORM">{i18n("patientActivity.form")}</option>
                            </Select>
                            {contentAnswerList && contentAnswerList.length > 0 ? <Tags.Subtitle style={{textAlign: 'right',opacity:.5,marginTop:"-0.5rem", marginBottom:"1rem"}}>
                                <Translate path="patientActivity.lockedToEdit" />
                            </Tags.Subtitle> : null}
                        </Tags.Field>
                        {edit ? <Tags.Field>
                            <Checkbox uid='patientActivity.done' label={i18n('patientActivity.done')} checked={dataDone} onCheck={(e:boolean)=>setDataDone(e)} />
                        </Tags.Field> : null}
                        <Tags.Field>
                            <Label><Translate path="patientActivity.files" /></Label>
                            <Upload uid="patientActivity.type" multiple={true} value={dataFiles} onChange={setDataFiles} onRemoveFileExternal={(id:string)=>handleRemoveFileExternal(id)} externalFiles={dataFilesList} />
                        </Tags.Field>
                    </Column>
                    <Column align="stretch" justify='flex-start' style={{flex:2}}>
                        <Tags.Field>
                            <Label><Translate path="patientActivity.content" /></Label>
                            {contentAnswerList.length > 0 ? contentAnswerList.map((answer,i) => <Tags.ContentAnswer key={i}>
                                <Tags.AnswerHeader>
                                    <Tags.AnswerDate><Translate path={"patientActivity.answerSendAt"} /><br />{DateFromISOToTimeAt(answer.date)}</Tags.AnswerDate>
                                    <Tags.AnswerCript>
                                        <Icon path={mdiLock} size={0.7} />
                                        <div><Translate path={"patientActivity.cript"} /></div>
                                    </Tags.AnswerCript>
                                    {dataAnswersDecriptedIds.indexOf(answer.id) < 0 ?
                                        <Tags.AnswerUnlock onClick={()=>{handleUnlockAnswerContent(answer.id)}}>{i18n("patientActivity.unlock")}</Tags.AnswerUnlock>
                                    : null}
                                </Tags.AnswerHeader>
                                {dataAnswersDecriptedIds.indexOf(answer.id) >= 0 ? <Tags.AnswerList>
                                    {dataType === 'TEXT' ? 
                                        answer.decrypted?.replace(/[\n|\r]{1,}/g,'\n').split("\n").map((text,i) => <p key={i}>{text}</p>) || i18n("patientActivity.errorCrypt")
                                    :
                                        <FormResult form={dataForm} value={JSON.parse(answer.decrypted ?? '{}')} />
                                    }
                                </Tags.AnswerList> : null}
                                
                            </Tags.ContentAnswer>) 
                            : <>
                                {dataType === 'TEXT' ? <>
                                    <Tags.NoContent><Translate path="patientActivity.noContent" /></Tags.NoContent>
                                </> : <>
                                    <FormBuilder value={dataForm} onChange={setDataForm} templates={templates} onTemplateSelect={handleGetTemplateData} />
                                </>}
                            </>}
                        </Tags.Field>
                        {dataFilesSent && dataFilesSent.length > 0 ? <>
                            {dataFilesSent.map((file, i) => <div style={{cursor:"pointer"}} key={i} onClick={()=>handleDownloadFile(file.id, file.name)}>{file.name}</div>)}
                        </> : null}
                    </Column>
                </RowOrColumn>
            </div>
            <Tags.ButtonBar style={{justifyContent:'space-between'}}>
                <Tags.ButtonGroup>
                    {edit ?
                        <Button size={6} icon={mdiTrashCan} onClick={()=>{handleDelete()}} color="#ff4444" title={i18n("delete")} />
                    : null}
                </Tags.ButtonGroup>
                <Tags.ButtonGroup>
                    <Button size={6} icon={mdiClose} onClick={()=>{handleCancel()}} color="#999999" title={i18n("cancel")} />
                    <Button size={6} icon={mdiCheck} onClick={()=>{handleSave()}} color="#090" title={i18n("save")} />
                </Tags.ButtonGroup>
            </Tags.ButtonBar>
        </Card>
        <Tags.FileInput ref={refInput} onChange={handleFileChange} accept='.pem' multiple={false}/>
    </PageContent>)
}

export default PatientActivityForm;