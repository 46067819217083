import { useState } from "react";
import Icon from "@mdi/react";
import { useDispatch } from "react-redux";
import { mdiClose, mdiMagnify, mdiMagnifyMinusOutline } from "@mdi/js";

import { hideModal } from "../redux/modalReducer";
import { getPatients } from "../services/requests/patients";
import { setPaymentsFilter } from "../redux/filtersReducer";
import { DateFromUTCToView, DateFromViewToDate } from "@pilarterapeutico/util";
import { Row, Button, Column, MoneyInput, SelectPaged, Checkbox, DateInput, Translate, i18n, RowOrColumn } from "@pilarterapeutico/components";
import * as Tags from './styles/ModalFilterPatients.styles';

type ModalFilterPaymentsProps = {
    onConfirm: Function;
};

export type ModalFilterData = {
    statusPending?:boolean,
    statusProcessing?:boolean,
    statusPaid?:boolean,
    statusCancelled?:boolean,
    statusRefused?:boolean,
    dueDateMin?:Date,
    dueDateMax?:Date,
    methodPix?:boolean,
    methodCredit?:boolean,
    methodMoney?:boolean,
    valueMin?:number,
    valueMax?:number,
    patients_id?: {value:string, label:string}[],

    [i:string]:any,
};

const ModalFilterPayments = ({onConfirm}:ModalFilterPaymentsProps) => {

    const [dataValueMin, setDataValueMin] = useState<number>(0);
    const [dataValueMax, setDataValueMax] = useState<number>(0);
    const [dataMethodPix, setDataMethodPix] = useState<boolean>(false);
    const [dataMethodCredit, setDataMethodCredit] = useState<boolean>(false);
    const [dataMethodMoney, setDataMethodMoney] = useState<boolean>(false);
    const [dataDueDateMin, setDataDueDateMin] = useState<Date>();
    const [dataDueDateMax, setDataDueDateMax] = useState<Date>();
    const [dataStatusPending, setDataStatusPending] = useState<boolean>();
    const [dataStatusProcessing, setDataStatusProcessing] = useState<boolean>();
    const [dataStatusPaid, setDataStatusPaid] = useState<boolean>();
    const [dataStatusCancelled, setDataStatusCancelled] = useState<boolean>();
    const [dataStatusRefused, setDataStatusRefused] = useState<boolean>();
    const [dataPatientsId, setDataPatientsId] = useState<number>(Date.now());
    const [dataPatientsList, setDataPatientsList] = useState<any[]>([]);

    const dispatch = useDispatch();

    const handleClearFilter = () => {
        setDataValueMin(0);
        setDataValueMax(0);
        setDataMethodPix(false);
        setDataMethodCredit(false);
        setDataMethodMoney(false);
        setDataDueDateMin(undefined);
        setDataDueDateMax(undefined);
        setDataStatusPending(false);
        setDataStatusProcessing(false);
        setDataStatusPaid(false);
        setDataStatusCancelled(false);
        setDataStatusRefused(false);
        setDataPatientsId(Date.now());

        dispatch(hideModal());
        dispatch(setPaymentsFilter({filter: undefined}));
    };

    const handleSubmitFilter = () => {
        const data:ModalFilterData = {
            valueMin: dataValueMin,
            valueMax: dataValueMax,
            methodPix: dataMethodPix,
            methodCredit: dataMethodCredit,
            methodMoney: dataMethodMoney,
            dueDateMin: dataDueDateMin,
            dueDateMax: dataDueDateMax,
            statusPending: dataStatusPending,
            statusProcessing: dataStatusProcessing,
            statusPaid: dataStatusPaid,
            statusCancelled: dataStatusCancelled,
            statusRefused: dataStatusRefused,
            patients_id: dataPatientsList
        };

        onConfirm(data);
    };

    const handleAppendPatient = (option: {value:string, label:string}) => {
        setDataPatientsList(o => {
            if (o.some(i => i.value === option.value)) {
                return o;
            }
            return [...o, option]
        });
        setDataPatientsId(Date.now());
    }

    const handleRemovePatient = (value:string) => {
        setDataPatientsList(o => o.filter(i => i.value !== value));
    }

    return <Tags.Content>
        <Tags.Title><Translate path="payments.modalFilter.title" /></Tags.Title>
        <Tags.Form>
            <RowOrColumn rowAlign="stretch" columnAlign="stretch">
                <Column style={{flex:1, marginRight:'2rem'}} align="flex-start" justify="flex-start">
                    <Tags.Field>
                        <Tags.Label><Translate path="payments.modalFilter.patients" /></Tags.Label>
                        <SelectPaged 
                            uid="payments.modalFilter.patients_id" 
                            value={dataPatientsId} 
                            onChange={(option) => {handleAppendPatient(option)}}
                            loader={async (page:number, filter:string) => {return getPatients(page, {name:filter, active:'true', size:5})}}
                            transform={(item:any, index:number)=>({index, value:item.id, label:item.name})}
                        />
                        {dataPatientsList.map(a => <Tags.PatientsItemsRow>
                            <div>{a.label}</div>
                            <div className='btn' onClick={()=>{handleRemovePatient(a.value)}}>
                                <Icon path={mdiClose} size={0.8} />
                            </div>
                        </Tags.PatientsItemsRow>)}
                    </Tags.Field>
                </Column>
                <Column style={{flex:1, marginRight:'2rem'}} align="flex-start" justify="flex-start">
                    <Tags.Field>
                        <Tags.Label><Translate path="payments.modalFilter.status" /></Tags.Label>
                        <Checkbox className='compact' uid="payments.modalFilter.statusPending" label={i18n("payments.modalFilter.statusPending")} checked={dataStatusPending} onCheck={(e:boolean)=>setDataStatusPending(e)}/>
                        <Checkbox className='compact' uid="payments.modalFilter.statusProcessing" label={i18n("payments.modalFilter.statusProcessing")} checked={dataStatusProcessing} onCheck={(e:boolean)=>setDataStatusProcessing(e)}/>
                        <Checkbox className='compact' uid="payments.modalFilter.statusPaid" label={i18n("payments.modalFilter.statusPaid")} checked={dataStatusPaid} onCheck={(e:boolean)=>setDataStatusPaid(e)}/>
                        <Checkbox className='compact' uid="payments.modalFilter.statusRefused" label={i18n("payments.modalFilter.statusRefused")} checked={dataStatusRefused} onCheck={(e:boolean)=>setDataStatusRefused(e)}/>
                        <Checkbox className='compact' uid="payments.modalFilter.statusCancelled" label={i18n("payments.modalFilter.statusCancelled")} checked={dataStatusCancelled} onCheck={(e:boolean)=>setDataStatusCancelled(e)}/>
                    </Tags.Field>
                    <Tags.Field>
                        <Tags.LabelCheckbox><Translate path="payments.modalFilter.method" /></Tags.LabelCheckbox>
                        <Checkbox className='compact' uid="payments.modalFilter.methodPix" label={i18n("payments.modalFilter.methodPix")} checked={dataMethodPix} onCheck={(e:boolean)=>setDataMethodPix(e)}/>
                        <Checkbox className='compact' uid="payments.modalFilter.methodCreditCard" label={i18n("payments.modalFilter.methodCreditCard")} checked={dataMethodCredit} onCheck={(e:boolean)=>setDataMethodCredit(e)}/>
                        <Checkbox className='compact' uid="payments.modalFilter.methodMoney" label={i18n("payments.modalFilter.methodMoney")} checked={dataMethodMoney} onCheck={(e:boolean)=>setDataMethodMoney(e)}/>
                    </Tags.Field>
                </Column>
                <Column style={{flex:1}} justify="flex-start">
                    <Tags.Field>
                        <Tags.Label><Translate path="payments.modalFilter.dueDate" /></Tags.Label>
                        <Row>
                            <Tags.LabelInRow><Translate path="payments.modalFilter.dueDateMin" /></Tags.LabelInRow>
                            <Tags.LabelInRow><Translate path="payments.modalFilter.dueDateMax" /></Tags.LabelInRow>
                        </Row>
                        <Row>
                            <DateInput uid="payments.modalFilter.dueDateMin" style={{flex:2}} value={DateFromUTCToView(dataDueDateMin)} onChange={(e)=>setDataDueDateMin(DateFromViewToDate((e.target as HTMLInputElement).value))} />
                            <DateInput uid="payments.modalFilter.dueDateMax" style={{flex:2}} value={DateFromUTCToView(dataDueDateMax)} onChange={(e)=>setDataDueDateMax(DateFromViewToDate((e.target as HTMLInputElement).value))} />
                        </Row>
                    </Tags.Field>
                    <Tags.Field>
                        <Tags.Label><Translate path="payments.modalFilter.value" /></Tags.Label>
                        <Row>
                            <Tags.LabelInRow><Translate path="payments.modalFilter.valueMin" /></Tags.LabelInRow>
                            <Tags.LabelInRow><Translate path="payments.modalFilter.valueMax" /></Tags.LabelInRow>
                        </Row>
                        <Row>
                            <MoneyInput uid="payments.modalFilter.valueMin" style={{flex:1}} value={dataValueMin} onChange={(e)=>setDataValueMin(e ?? 0)} />
                            <MoneyInput uid="payments.modalFilter.valueMax" style={{flex:1}} value={dataValueMax} onChange={(e)=>setDataValueMax(e ?? 0)} />
                        </Row>
                    </Tags.Field>
                </Column>
            </RowOrColumn>
        </Tags.Form>
        <Tags.ButtonRow>
            <Button onClick={()=>{handleClearFilter()}} color={"var(--theme-color-font-weak)"} border={true} icon={mdiMagnifyMinusOutline} title={i18n('clear')} />
            <Button onClick={()=>{dispatch(hideModal())}} color={"var(--theme-color-font-weak)"} border={true} icon={mdiClose} title={i18n('close')} />
            <Button onClick={()=>handleSubmitFilter()} color={"#004BA3"} icon={mdiMagnify} title={i18n('filter')} />
        </Tags.ButtonRow>
    </Tags.Content>
}

export default ModalFilterPayments;
