import styled from "styled-components"

export const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding-top: 60px;

    @media (min-width: 1000px) {
        top: 0;
        left: 300px;
        right: 0;
        bottom: 0;
        padding-top: 100px;
    }
`;

export const ShowingState = styled.div`
    font-size: 1rem;
    color: var(--theme-color-font);
`;

export const LimitState = styled.div`
    font-size: 0.8rem;
    color: var(--theme-color-font-weak);
`;