import { Column } from "@pilarterapeutico/components";
import styled from "styled-components"

export const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

export const ButtonBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--theme-color-background-aux2, "#bbb");
    padding: 1.5rem;

    & > :not(:first-child) {
        margin-left: 1rem;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    & > :not(:first-child) {
        margin-left: 1rem;
    }
`;

export const Photo = styled.div`
    width: 15rem;
    margin: 0 auto;
    
    @media (min-width: 1000px){
        margin: 0 1rem 0 0;
    }
`;

export const ColumnFields = styled(Column)`
    padding-right: 0;
    
    @media (min-width: 1000px) {
        padding-right: 0.625rem;
        border-right: 1px solid var(--theme-color-background-aux2);
    }
`;

export const ColumnDefaults = styled(Column)`
    width: 100%;
    
    @media (min-width: 1000px) {
        width: 18.75rem;
    }
`;

export const Subtitle = styled.div`
    font-size: 1rem;
    opacity: 0.7;
    line-height: 1.4rem;
    margin-top: 0rem;
    margin-bottom: 2rem;

    @media (min-width:1000px) {
        margin-bottom: 0;
    }
`;

export const NoAppointmens = styled.div`
    font-size: 1rem;
    line-height: 2.4rem;
    background-color: var(--theme-color-background-aux2, #ddd);
    margin-top: 0rem;
    width: 100%;
    height: 2.4rem;
    color: var(--theme-color-font-weak);
    text-align: center;
    margin-bottom: 0.5rem;
`;

export const AppointmentsItemsRow = styled.div`
    font-size: 1rem;
    line-height: 2.4rem;
    background-color: var(--theme-color-background-aux2, #ddd);
    margin: 0 0 2px 0;
    width: 100%;
    height: 2.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    justify-content: space-between;

    &:last-child {
        margin-bottom: 0.5rem;
    }

    & .btn {
        height: 1rem;
        width: 1rem;
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &:hover .btn {
        display: flex;
    }
`;

export const NoContent = styled.div`
    color: var(--theme-color-font-empty, #999);
`;

export const ContentAnswer = styled.div`
    margin: 0.5rem 0;
    background-color: var(--theme-color-background-aux2, #999);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

export const AnswerHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const AnswerList = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1rem 0;
    margin-top: 1rem;
    border-top: 1px solid var(--theme-color-font-empty);

    & > p {
        width: 100%;
    }
`;


export const AnswerDate = styled.div`
    color: var(--theme-color-font, #000);
    flex: 1;
    `;

export const AnswerCript = styled.div`
    padding: 0 0.5rem;
    margin-right: 0.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 0.75rem;
    color: var(--theme-color-font-weak, #666);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    & *:last-child {
        margin-left: 0.1rem;
    }
`;

export const AnswerUnlock = styled.button`
    cursor:pointer; 
    padding: 0 0.5rem;
    border: 0;
    line-height: 1.5rem;
    height: 1.5rem;
    background-color: transparent;
    color: var(--theme-color-font);
    
    &:hover {
        background-color: var(--theme-color-font-empty, #999);
    }
`;

export const LabelArea = styled.div`
    font-size: 0.8rem;
    line-height: 2.4rem;
    width: 100%;
    height: 2.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    color: var(--theme-color-font-empty, #999);
    text-transform: uppercase;
    justify-content: stretch;

    :before {
        margin-right: 0.5rem;
        flex: 1;
        height: 0px;
        content: '';
        border-bottom: 1px solid var(--theme-color-background-aux2, #aaa);
    }

    :after {
        margin-left: 0.5rem;
        flex: 1;
        height: 0px;
        content: '';
        border-bottom: 1px solid var(--theme-color-background-aux2, #aaa);
    }
`;

export const FileInput = styled.input.attrs(()=>({
    type: "file"
}))`
    display: none;
    visibility: hidden;
    height: 0;
    position: fixed;
    top: -10000%;
    left: -10000%;
`;