import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import CardSchedule from "../components/cards/Schedule";
import CardPayments from "../components/cards/Payments";
import CardBirthdays from "../components/cards/birthdays";
import { AppState } from "../redux/stores";
import { getStatus } from "../services/requests/plans";
import { getBirthdates, getAppointments, getPayments } from "../services/requests/home";
import { AppointmentItem, BirthdateItem, PaymentsItem } from "../services/contracts/home";
import { Card, PageContent, Title, TitleBar, i18n, RowOrColumn, FilterBar, FilterRow, Button } from "@pilarterapeutico/components";
import * as Tags from './styles/Dashboard.styles';
import { mdiTransferUp } from "@mdi/js";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {

    const [birthdates, setBirthdates] = useState<BirthdateItem[]>([]);
    const [appointments, setAppointments] = useState<AppointmentItem[]>([]);
    const [payments, setPayments] = useState<PaymentsItem[]>([]);
    const [welcomeMessage, setWelcomeMessage] = useState<string>('');
    const [showIsBasic, setShowIsBasic] = useState<boolean>(false);
    const [showPayment, setShowPayment] = useState<boolean>(false);

    const userName = useSelector<AppState>(s => s.auth.name);
    const navigate = useNavigate();
    const refUpdate = useRef<boolean>(true);

    const handleGoToPlans = () => {
        navigate('/config/plans');
    }

    useEffect(()=>{
        const time = (new Date()).getHours();

        if (time < 6 || time >= 18) {
            setWelcomeMessage(i18n('home.night'));
        } else if (time < 12) {
            setWelcomeMessage(i18n('home.morning'));
        } else {
            setWelcomeMessage(i18n('home.afternoon'));
        }
    },[]);


    useEffect(()=>{
        getBirthdates().then((req) => {
            if (refUpdate.current && req.data) {
                setBirthdates(req.data.birthdates);
            }
        });

        getAppointments().then((req) => {
            if (refUpdate.current && req.data) {
                setAppointments(req.data.appointments);
            }
        });

        getPayments().then((req) => {
            if (refUpdate.current && req.data) {
                setPayments(req.data.payments);
            }
        });

        getStatus().then((req) => {
            if (refUpdate.current && req.data) {
                setShowIsBasic(!req.data?.payment?.payment_qr_code && (!req.data?.plan || (String(req.data.plan?.plan_type).toUpperCase() === 'BASIC')));
                setShowPayment(!!(req.data?.payment?.payment_qr_code));
            }
        });

        return () => {refUpdate.current = false;};
    }, []);

    return (<PageContent>
        <TitleBar>
            <Title>
                {welcomeMessage}
                <Tags.Username>{ String(userName) }</Tags.Username>
            </Title>
        </TitleBar>
        {showIsBasic ? <FilterBar onClick={handleGoToPlans} style={{cursor:"pointer", flexDirection:"row", alignItems:"center", justifyContent:"stretch"}}>
            <FilterRow>{i18n("home.isBasic")}</FilterRow>
            <Button color="var(--theme-color-button-orange)" size={6} icon={mdiTransferUp} style={{marginLeft:"1rem"}}>{i18n("config.plans.subscribe")}</Button>
        </FilterBar> : null}
        {showPayment ? <FilterBar onClick={handleGoToPlans} style={{backgroundColor:"var(--theme-color-yellow)", cursor:"pointer", flexDirection:"row", alignItems:"center", justifyContent:"stretch"}}>
            <FilterRow>{i18n("home.showPayment")}</FilterRow>
            <Button color="var(--theme-color-button-blue)" size={6} icon={mdiTransferUp} style={{marginLeft:"1rem"}}>{i18n("config.plans.subscribe")}</Button>
        </FilterBar> : null}
        <Tags.RowBirth>
            <Card title={i18n('home.nextBirthdates')}>
                <CardBirthdays birthdays={birthdates} />
            </Card>
        </Tags.RowBirth> 
        <RowOrColumn rowAlign="stretch" rowMargin="0.625rem" columnAlign="flex-start" columnMargin="0 0 1.5rem 0">
            <Card title={i18n('home.schedule')}>
                <CardSchedule schedules={appointments} />
            </Card>
            <Card title={i18n('home.payments')}>
                <CardPayments payments={payments} />
            </Card>
        </RowOrColumn>
    </PageContent>)
}

export default Dashboard;
