import axios from "axios";
import store from '../../redux/stores';

import URLs from '../../urls';
import { RequestHandler } from '../contracts';
import { startLoading, stopLoading} from '../../redux/loadingReducer';
import { GetAppointmentsDataResponse, GetCalendarMoveResponse, GetCalendarResponse, PostAppointmentsResponse } from "../contracts/appointments";
import AppointmentDataType from "../../types/AppointmentDataType";

export const getCalendar = async (start:Date, end:Date): Promise<RequestHandler<GetCalendarResponse>> => {
    const result = new RequestHandler<GetCalendarResponse>();
    try {
        store.dispatch(startLoading({key:'calendar.getCalendar'}));
        const data = {start: start.toISOString(), end:end.toISOString()};
        const res = await axios.get(URLs.calendar.index, {params: data});
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'calendar.getCalendar'}));
    }
    return result;
}


export const getCalendarData = async (appointmentId: string): Promise<RequestHandler<GetAppointmentsDataResponse>> => {
    const result = new RequestHandler<GetAppointmentsDataResponse>();
    try {
        store.dispatch(startLoading({key:'calendar.getCalendartData'}));
        const res = await axios.get(URLs.calendar.data(appointmentId));
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'calendar.getCalendartData'}));
    }
    return result;
}


export const createCalendar = async (data: AppointmentDataType): Promise<RequestHandler<PostAppointmentsResponse>> => {
    const result = new RequestHandler<PostAppointmentsResponse>();
    try {
        store.dispatch(startLoading({key:'calendar.createCalendar'}));
        const res = await axios.post(URLs.calendar.index, data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'calendar.createCalendar'}));
    }
    return result;
}

export const updateCalendar = async (data: AppointmentDataType): Promise<RequestHandler<PostAppointmentsResponse>> => {
    const result = new RequestHandler<PostAppointmentsResponse>();
    try {
        store.dispatch(startLoading({key:'calendar.updateAppointment'}));
        const res = await axios.put(URLs.calendar.data(data.id ?? '0'), data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'calendar.updateAppointment'}));
    }
    return result;
}

export const deleteCalendar = async (id: string): Promise<RequestHandler<PostAppointmentsResponse>> => {
    const result = new RequestHandler<PostAppointmentsResponse>();
    try {
        store.dispatch(startLoading({key:'appointments.deleteAppointment'}));
        const res = await axios.delete(URLs.calendar.data(id));
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'appointments.deleteAppointment'}));
    }
    return result;
}

export const moveCalendar = async (data: AppointmentDataType): Promise<RequestHandler<GetCalendarMoveResponse>> => {
    const result = new RequestHandler<GetCalendarMoveResponse>();
    try {
        store.dispatch(startLoading({key:'calendar.moveCalendar'}));
        const res = await axios.put(URLs.calendar.move(data.id ?? '0'), data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'calendar.moveCalendar'}));
    }
    return result;
}