import { useEffect, useMemo, useState } from "react";
import { mdiCheck } from "@mdi/js";
import { useDispatch } from "react-redux";

import { FormError } from "../services/contracts";
import { setMessage } from "../redux/messagesReducer";
import { updatePreferences } from "../redux/authReducer";
import { PreferencesType } from "../types/ProfileDataType";
import { saveProfileConfig } from "../services/requests/profile";
import { Column, Button, Label, Translate, i18n, Select } from "@pilarterapeutico/components";
import * as Tags from './styles/ConfigProfileForm.styles';

const ConfigGeneral = () => {
    const [dataLang, setDataLang] = useState<string>("");
    const [dataTheme, setDataTheme] = useState<string>("");
    const [dataErrors, setDataErrors] = useState<FormError[]>([]);

    const dispatch = useDispatch();

    const handleSave = async () => {
        const data:PreferencesType = {
            lang: dataLang || undefined,
            theme: dataTheme || undefined,
        };
    
        setDataErrors([]);
    
        let response = await saveProfileConfig(data);
    
        if (response.error) { 
            if (response.type === 'form') {
                setDataErrors((response.error ?? []) as FormError[]);
            } else {
                dispatch(setMessage({message: response.error ?? '', type: "error"}));
            }
        } else {
            dispatch(updatePreferences({theme: dataTheme, lang: dataLang}));
            dispatch(setMessage({message: i18n("config.savedPreferences"), type: "success"}));
        }
    }

    /*const getFistError = useMemo(() => {
        return (key:string) => dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
    }, [dataErrors]);*/
    
    useEffect(()=>{
        let preferences = JSON.parse(localStorage.getItem('config.auth') || `{}`);

        if (preferences && preferences.lang) {
            setDataLang(preferences.lang);
        }
        if (preferences && preferences.theme) {
            setDataTheme(preferences.theme);
        }

    }, []);

    return <>
        <div style={{padding:'1rem'}}>            
            <Column align="flex-start" style={{width: '10rem'}}>
                <Tags.Field style={{flex:1}}>
                    <Label>
                        <Translate path="config.labelLang"/>
                    </Label>
                    <Select 
                        uid="config.preference.lang" 
                        value={dataLang} 
                        className="fullWidthMobile"
                        onChange={(e)=>setDataLang((e.target as HTMLSelectElement).value)}
                        >
                        <option value="">{i18n("auto")}</option>
                        <option value="pt-BR">Português Brasileiro</option>
                        <option value="en-US">English US</option>
                    </Select>
                </Tags.Field>
                <Tags.Field>
                    <Label>
                        <Translate path="config.labelTheme" />
                    </Label>
                    <Select 
                        uid="config.preference.theme" 
                        value={dataTheme} 
                        onChange={(e)=>setDataTheme((e.target as HTMLSelectElement).value)}
                        className="fullWidthMobile"
                        >
                        <option value="">{i18n("auto")}</option>
                        <option value="dark">{i18n("config.themeDark")}</option>
                        <option value="light">{i18n("config.themeLight")}</option>
                    </Select>
                </Tags.Field>
            </Column>
        </div>
        <Tags.ButtonBar>
            <Button size={6} icon={mdiCheck} onClick={()=>{handleSave()}} color="#090" title={i18n("save")} />
        </Tags.ButtonBar>
    </>;
}

export default ConfigGeneral;
