import URLs from '../../urls';
import store from '../../redux/stores';
import axios from "./interceptors";
import { RequestHandler } from '../contracts';
import { ModalFilterData } from "../../modals/ModalFilterPatients";
import { startLoading, stopLoading} from '../../redux/loadingReducer';
import { GetPlansResponse, GetStatusPlanResponse, GetPixPaymentResponse, GetCardPaymentResponse, GetCancelPaymentResponse, GetActivePaymentResponse } from "../contracts/plans";

export const getPlans = async (page?: number, filter?: ModalFilterData): Promise<RequestHandler<GetPlansResponse>> => {
    const result = new RequestHandler<GetPlansResponse>();
    try {
        store.dispatch(startLoading({key:'plans.getPlans'}));
        const res = await axios.get(URLs.config.plans.index)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'plans.getPlans'}));
    }
    return result;
}

export const getStatus = async (): Promise<RequestHandler<GetStatusPlanResponse>> => {
    const result = new RequestHandler<GetStatusPlanResponse>();
    try {
        store.dispatch(startLoading({key:'plans.getStatus'}));
        const res = await axios.get(URLs.config.plans.status);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'plans.getStatus'}));
    }
    return result;
}

export const getActivePayment = async (): Promise<RequestHandler<GetActivePaymentResponse>> => {
    const result = new RequestHandler<GetActivePaymentResponse>();
    try {
        store.dispatch(startLoading({key:'plans.getActivePayment'}));
        const res = await axios.get(URLs.config.plans.active);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'plans.getActivePayment'}));
    }
    return result;
}

export const subscribeByPix = async (data: {plan?:string, cpf?:string, name?:string}): Promise<RequestHandler<GetPixPaymentResponse>> => {
    const result = new RequestHandler<GetPixPaymentResponse>();
    try {
        store.dispatch(startLoading({key:'plans.subscribeByPix'}));
        const res = await axios.post(URLs.config.plans.subscribePix, data)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'plans.subscribeByPix'}));
    }
    return result;
}

export const subscribeByCreditCard = async (data: {plan?:string, cpf?:string, name?:string, payData:any}): Promise<RequestHandler<GetCardPaymentResponse>> => {
    const result = new RequestHandler<GetCardPaymentResponse>();
    try {
        store.dispatch(startLoading({key:'plans.subscribeByCreditCard'}));
        data.payData = data.payData ? JSON.stringify(data.payData) : undefined;
        const res = await axios.post(URLs.config.plans.subscribeCard, data)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'plans.subscribeByCreditCard'}));
    }
    return result;
}

export const subscribeChange = async (data: {plan?:string, cpf?:string, name?:string, payData?:any}): Promise<RequestHandler<void>> => {
    const result = new RequestHandler<void>();
    try {
        store.dispatch(startLoading({key:'plans.subscribeChange'}));
        data.payData = data.payData ? JSON.stringify(data.payData) : undefined;
        const res = await axios.put(URLs.config.plans.subscribeChange, data);
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'plans.subscribeChange'}));
    }
    return result;
}

export const cancelPayment = async (): Promise<RequestHandler<GetCancelPaymentResponse>> => {
    const result = new RequestHandler<GetCancelPaymentResponse>();
    try {
        store.dispatch(startLoading({key:'plans.cancelPayment'}));
        const res = await axios.post(URLs.config.plans.cancel)
        result.setData(res);
    } catch (e) {
        result.setError(e);
    } finally {
        store.dispatch(stopLoading({key:'plans.cancelPayment'}));
    }
    return result;
}