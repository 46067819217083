import { AppointmentItem } from '../../services/contracts/home';
import { Column, EmptyState, i18n } from "@pilarterapeutico/components";
import { mdiCalendarCheck, mdiCheck, mdiClose, mdiHelp  } from '@mdi/js';
import * as Tags from './styles';
import { DateFromISOToWeekday, DaysUntil, FirstUppercase, TimeDiffAsText, TimeFromIntMinutesToTime } from '@pilarterapeutico/util';
import Icon from '@mdi/react';
import { useNavigate } from 'react-router-dom';

export type CardScheduleProps = {
    schedules: AppointmentItem[];
};

const CardSchedule: React.FC<CardScheduleProps> = ({schedules}:CardScheduleProps) => {

    const navigate = useNavigate();

    const getStatusColor = (status:string):string => {
        switch(status) {
            case 'WAITING': return "#999";
            case 'CONFIRMED': return "#090";
            case 'DONE': return "#27f";
            case 'CANCELLED': return "#f93";
            case 'LOST': return "#f33";
            default: return "#999";
        }
    }
    
    const getConfirmByPatientColor = (confirm?:number):string => {
        switch(confirm) {
            case 1: return "var(--theme-color-value-green)";
            case 0: return "var(--theme-color-value-red)";
            default: return "var(--theme-color-value-gray)";
        }
    }

    const handleGoToAppointment = (patientId:string, id:string) => {
        navigate(`/patients/${patientId}/appointment/${id}`);
    }

    let lastDate:string = "";

    return (<Tags.ContentRow style={{flex:1}}>
        {schedules && schedules.length > 0 ? 
            schedules.map((a,i) => {
                let result = [];
                if (lastDate !== a.date.substring(0,10)) {
                    lastDate = a.date.substring(0,10);
                    let dayDiff = DaysUntil(lastDate);
                    let label = DateFromISOToWeekday(a.date);
                    switch (dayDiff) {
                        case 0: label = i18n('time.today'); break;
                        case 1: label = i18n('time.tomorrow'); break;
                        default: label = FirstUppercase(label);
                    }
                    result.push(<Tags.RowHeader key={'h'+String(i)}>{label}</Tags.RowHeader>)
                }
                result.push(<Tags.ItemRow key={i} onClick={()=>handleGoToAppointment(a.patient_id,a.id)}>
                    <Column align='flex-start' style={{width:"4rem"}}>
                        <Tags.AppointmentTime>{TimeFromIntMinutesToTime(a.time,':')}</Tags.AppointmentTime>
                        <Tags.AppointmentDuration>{TimeDiffAsText(a.time, a.timeend)}</Tags.AppointmentDuration>
                    </Column>
                    <Tags.AppointmentPatient>{a.patient_name}</Tags.AppointmentPatient>
                    <Tags.AppointmentStatus color={getStatusColor(a.status ?? '')}>{i18n("patientView.scheduleStatus."+a.status.toLowerCase())}</Tags.AppointmentStatus>
                    <Tags.AppointmentConfirmByPatient color={getConfirmByPatientColor(a.confirm_by_patient ?? undefined)}>
                        {a.confirm_by_patient === 1 ? <Icon size={0.75} path={mdiCheck} /> : null}
                        {a.confirm_by_patient === 0 ? <Icon size={0.75} path={mdiClose} /> : null}
                        {a.confirm_by_patient === null ? <Icon size={0.75} path={mdiHelp} /> : null}
                    </Tags.AppointmentConfirmByPatient>
                </Tags.ItemRow>);

                return result;
            })
        :
            <div style={{flex:1,display:"flex",alignItems:"center",justifyContent:"center"}}>
                <EmptyState  title={i18n("home.emptySchedule")} icon={mdiCalendarCheck } size={2} />
            </div>
        }
    </Tags.ContentRow>)
}

export default CardSchedule;
