import { createSlice } from '@reduxjs/toolkit'

interface messagesProps {
  message: string,
  time: number,
  type: "success" | "error" | "warn" | "info",
};

const initialState: messagesProps = {
  message: "",
  time: 0,
  type: "success",
};

const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessage(state, action) {
        state.message = action.payload.message;
        state.type = action.payload.type;
        state.time = Date.now()
    }
  }
});

export const { setMessage } = messageSlice.actions;
export default messageSlice.reducer;