import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { mdiCashMultiple, mdiChevronLeft, mdiChevronRight, mdiFilter, mdiPlus } from "@mdi/js";
import { Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer, ComposedChart } from 'recharts';

import { LimitState } from "./styles/Home.styles";
import { AppState } from "../redux/stores";
import { RequestHandler } from "../services/contracts";
import { setPaymentsFilter } from "../redux/filtersReducer";
import { hideModal, setModalAndShow } from "../redux/modalReducer";
import { getPaymentsByType, getPaymentsCashflow, getPaymentsChart, getPaymentsRecomendations, getPaymentsResume } from "../services/requests/payments";
import { GetPaymentsCashflowResponse, GetPaymentsRecomendations, GetPaymentsResponse, GetPaymentsResumeResponse, PaymentsChartItem } from "../services/contracts/payments";
import { Row, Card, Column, FilterBar, Title, TitleBar, Paginator, EmptyState, Button, ButtonRow, PageContent, Translate as T, i18n, FilterRow, RowOrColumn } from '@pilarterapeutico/components';
import { capitalize, MoneyFromNumberToString, DateFromDateToMonthView, DateFromMonthAndYearToMonthView, DateFromUTCToView, DateStrFromUTCToView } from '@pilarterapeutico/util';
import * as Tags from './styles/PatientView.styles';
import ModalFilterPayments, { ModalFilterData as PaymentsFilterData } from "../modals/ModalFilterPayments";
import { CurrentPlanDataType } from "types/PlansDataTypes";

interface PaymentType {
    id: string;
    description: string;
    due_date: Date;
    value_total: number;
    related_to: string;
    appointment_id: {date:string; id:string}[];
    month?: string;
    patient_id?: {id:string, label:string};
    status: string;
};

interface TotalPaymentType {
    nextdue: number;
    delayed: number;
    paid: number;
    received: number;
    total: number;
};

interface CharItem {
    name: string;
    year: string;
    income: number;
    outcome: number;
    diff: number;
}

interface CashFlowItem {
    month: number;
    year: number;
    income: number;
    outcome: number;
    diff: number;
    balance: number;
}

interface RecomendationsType {
    grossValue: number;
    socialSecurity: number;
    incomeTax: number;
    vacactionReserve: number;
    vacationsThird: number;
    income13reserve: number;
    socialSecurity13: number;
    total: number;
}

const _processChartTicks = (data?: CharItem[]) => {
    let scale = 1;
    let value = data?.reduce((a,c) => Math.max(Math.abs(c.diff), a), 0) ?? 0;
    while(value > 10) {
        value = value/10;
        scale = scale*10;
    }

    let maxValue = Math.ceil(value) * scale;
    return [maxValue, maxValue/2, 0, -maxValue/2, -maxValue];
};

const _processCashflowData = (data: CashFlowItem[], balance: number) => {
    let cashflow = data.map(c => ({
        ...c, 
        diff:c.income-c.outcome, 
        balance: 0,
    })).reverse() ?? [];

    for(let i=0; i<cashflow.length; i++) {
        if (i === 0) {
            cashflow[i].balance = cashflow[i].income - cashflow[i].outcome + balance;
        } else {
            cashflow[i].balance = cashflow[i].income - cashflow[i].outcome + cashflow[i-1].balance;
        }
    }
    return cashflow.reverse();
}

const CustomTooltip = ({active, payload, label}:{active?:boolean, payload?:any[], label?:string}) => {
    if (active && payload && payload.length) {
        return (
            <Tags.TooltipWrapper>
                <Tags.TooltipLabel>{`${label}/${payload[0].payload.year}`}</Tags.TooltipLabel>
                {payload.map((p,i) => <Tags.TooltipValue key={i} style={{color:p.color}}>{`${p.name}: ${MoneyFromNumberToString(p.value)}`}</Tags.TooltipValue>)}
            </Tags.TooltipWrapper>
        );
    }
  
    return null;
  };

const Payments = () => {

    const [paymentsDateCurrent, setPaymentsDateCurrent] = useState<Date>(new Date());
    const [paymentsReceive, setPaymentsReceive] = useState<PaymentType[]>([]);
    const [paymentsPay, setPaymentsPay] = useState<PaymentType[]>([]);
    const [paymentsReceivePage, setPaymentsReceivePage] = useState<number>(1);
    const [paymentsReceiveTotal, setPaymentsReceiveTotal] = useState<number>(1);
    const [paymentsPayPage, setPaymentsPayPage] = useState<number>(1);
    const [paymentsPayTotal, setPaymentsPayTotal] = useState<number>(1);
    const [cashflowPage, setCashflowPage] = useState<number>(1);
    const [totalToPay, setTotalToPay] = useState<TotalPaymentType>({nextdue: 0, delayed: 0, paid: 0, received: 0, total: 0});
    const [totalToReceive, setTotalToReceive] = useState<TotalPaymentType>({nextdue: 0, paid: 0, received: 0, delayed: 0, total: 0});
    const [chartTicks, setChartTicks] = useState<number[]>([10,5,0,-5,-10]);
    const [chartData, setChartData] = useState<CharItem[]>([]);
    const [cashflowData, setCashflowData] = useState<CashFlowItem[]>([]);
    const [cashflowTotal, setCashflowTotal] = useState<number>(1);
    const [recomendationsData, setRecomendationsData] = useState<RecomendationsType>({grossValue: 0, socialSecurity: 0, incomeTax: 0, vacactionReserve: 0, vacationsThird: 0, income13reserve: 0, socialSecurity13: 0, total: 0});
    const [patientsFilterNames, setPatientsFilterNames] = useState<string[]>([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const update = useRef<boolean>(true);

    const filters = useSelector<AppState, PaymentsFilterData|undefined>(s => s.filters.paymentsFilters);
    const currentPlan = useSelector<AppState, CurrentPlanDataType|undefined>(s => s.auth.plan);

    const handleFilter = () => {
        dispatch(setModalAndShow({
            content: <ModalFilterPayments onConfirm={handleFilterList} />,
        }));
      };
    
    const handleFilterList = async (data:any) => {
        setPatientsFilterNames(data.patients_id?.map((p:any)=>p.label) ?? []);
        if (data.patients_id) {
            data.patients_id = data.patients_id.map((p:any)=>p.value);
        }
        dispatch(setPaymentsFilter({filter: data}));
        dispatch(hideModal());
    }
    
    const handleNewPatient = () => {
        navigate('/payments/new');
    };

    const handleGoToPaymentEdit = (id: string) => {
        navigate(`/payments/${id}`);
    };

    const handlePrevMonth = () => {
        setPaymentsDateCurrent(s => {
            let dt = new Date(s);
            dt.setDate(1);
            dt.setMonth(dt.getMonth()-1);
            return dt;
        });
    };

    const handleNextMonth = () => {
        setPaymentsDateCurrent(s => {
            let dt = new Date(s);
            dt.setDate(1);
            dt.setMonth(dt.getMonth()+1);
            return dt;
        });
    };


    const handleChangePaymentsToPayPage = (page: number) => {
        setPaymentsPayPage(page);

        (async () => {
            const req = await getPaymentsByType('O', page, paymentsDateCurrent, filters);

            if (update.current) {
                setPaymentsPay(req.data?.items.map(i=>({...i, due_date: new Date(i.due_date ?? '')})) ?? []);
                setPaymentsPayTotal(Math.ceil((req.data?.total ?? 0)/5));
            }
        })();
    };

    const handleChangePaymentsToReceivePage = (page: number) => {
        setPaymentsReceivePage(page);

        (async () => {
            const req = await getPaymentsByType('I', page, paymentsDateCurrent, filters);

            if (update.current) {
                setPaymentsReceive(req.data?.items.map(i=>({...i, due_date: new Date(i.due_date ?? '')})) ?? []);
                setPaymentsReceiveTotal(Math.ceil((req.data?.total ?? 0)/5));
            }
        })();
    };

    const handleChangeCashflowPage = (page: number) => {
        setCashflowPage(page);

        (async () => {
            const req = await getPaymentsCashflow(page);

            if (update.current) {
                let cashflow = _processCashflowData(req.data?.cashflow ?? [], req.data?.balance ?? 0);
        
                setCashflowData(cashflow);
                setCashflowTotal(Math.ceil((req.data?.total ?? 1)/5));
            }
        })();
    };

    const getPaymentDescription = (item:PaymentType):string => {
        switch(item.related_to) {
            case 'APPOINTMENT': return (item.appointment_id ?? []).length === 1 ? i18n("patientView.payments.appointmentItem").replace("$1", DateStrFromUTCToView(item.appointment_id[0].date)) : i18n(`patientView.payments.appointmentList`).replace("$1", String(item.appointment_id?.length ?? '0'));
            case 'MONTH': return DateFromDateToMonthView(new Date(item.month ?? ''));
            default: return item.description;
        }
    };

    const getFilterValue = (filterKey:string) => {
        if (!filters) return '';
        let v = filters[filterKey];
        switch (filterKey) {
            case 'dueDateMin':
            case 'dueDateMax': return DateFromUTCToView(v);
            case 'valueMin':
            case 'valueMax': return MoneyFromNumberToString(v);
            case 'patients_id': return patientsFilterNames.join(", ");
            case 'statusPending':
            case 'statusProcessing':
            case 'statusPaid':
            case 'statusCancelled':
            case 'statusRefused':
            case 'methodPix':
            case 'methodCredit':
            case 'methodMoney':return v ? i18n('yes') : i18n('no'); 
        }
    };

    useEffect(() => {
        (async () => {
            setPaymentsPayPage(1);
            setPaymentsReceivePage(1);

            let reqPaymentsIncome = getPaymentsByType('I', 1, paymentsDateCurrent, filters);
            let reqPaymentsOutcome = getPaymentsByType('O', 1, paymentsDateCurrent, filters);
            let reqPaymentsResume = getPaymentsResume(paymentsDateCurrent, filters);
            
            Promise.all([reqPaymentsIncome, reqPaymentsOutcome, reqPaymentsResume]).then((data) => {
                let reqIncome = data[0] as RequestHandler<GetPaymentsResponse>;
                let reqOutcome = data[1] as RequestHandler<GetPaymentsResponse>;
                let reqResume = data[2] as RequestHandler<GetPaymentsResumeResponse>;
                
                if (update.current) {
                    setPaymentsReceive(reqIncome.data?.items.map(i=>({...i, due_date: new Date(i.due_date ?? '')})) ?? []);
                    setPaymentsReceiveTotal(Math.ceil((reqIncome.data?.total ?? 0)/5));
                    
                    setPaymentsPay(reqOutcome.data?.items.map(i=>({...i, due_date: new Date(i.due_date ?? '')})) ?? []);
                    setPaymentsPayTotal(Math.ceil((reqOutcome.data?.total ?? 0)/5));

                    setTotalToPay(reqResume.data?.outcome ?? {total:0, delayed:0, paid: 0, received: 0, nextdue:0});
                    setTotalToReceive(reqResume.data?.income ?? {total:0, delayed:0, paid: 0, received: 0, nextdue:0});
                }
            });
        })();
    },[filters, paymentsDateCurrent]);

    useEffect(() => {
        (async () => {
            let reqPaymentsChart = getPaymentsChart();
            let reqPaymentsCashflow = getPaymentsCashflow();
            let reqRecomendations = getPaymentsRecomendations();

            Promise.all([reqPaymentsChart, reqPaymentsCashflow, reqRecomendations]).then((data) => {
                let reqChart = data[0] as RequestHandler<PaymentsChartItem[]>;
                let reqCashflow = data[1] as RequestHandler<GetPaymentsCashflowResponse>;
                let reqRecomendations = data[2] as RequestHandler<GetPaymentsRecomendations>;

                if (update.current) {
                    setChartData(reqChart.data?.map(c => ({
                        ...c,
                        outcome: -(c.outcome ?? 0),
                        name: i18n(`dates.monthShort2.${c.name}`)
                    })) ?? []);
                    setChartTicks(_processChartTicks(reqChart.data ?? []));

                    let cashflow = _processCashflowData(reqCashflow.data?.cashflow ?? [], reqCashflow.data?.balance ?? 0);        
                    setCashflowData(cashflow);
                    setCashflowTotal(Math.ceil((reqCashflow.data?.total ?? 1)/5));

                    setRecomendationsData({
                        grossValue: reqRecomendations.data?.grossValue ?? 0, 
                        socialSecurity: reqRecomendations.data?.socialSecurity ?? 0, 
                        incomeTax: reqRecomendations.data?.incomeTax ?? 0, 
                        vacactionReserve: reqRecomendations.data?.salaryPart ?? 0, 
                        vacationsThird: (reqRecomendations.data?.salaryPart ?? 0)/3, 
                        income13reserve: reqRecomendations.data?.salaryPart ?? 0, 
                        socialSecurity13: (reqRecomendations.data?.socialSecurity ?? 0)/11, 
                        total: reqRecomendations.data?.total ?? 0
                    });
                }
            });
        })();

        return () => {update.current = false;};
    },[]);

    const renderReciveAndPay = useMemo(()=>{
        return [
            <Column key="receive" align="stretch" justify="space-between" style={{flex:1}}>
                <Card title={[
                    <div key="title">{i18n("payments.titleToReceive")}</div>
                ]}>
                    {paymentsReceive.length ? <>
                        <Tags.TableHeader>
                            <Tags.TableColumn>
                                <Tags.TableHeaderLabel>
                                    <T path="payments.payments.description" />
                                </Tags.TableHeaderLabel>
                            </Tags.TableColumn>
                            <Tags.TableColumn width="7rem">
                                <Tags.TableHeaderLabel>
                                    <T path="payments.payments.dueDate" />
                                </Tags.TableHeaderLabel>
                            </Tags.TableColumn>
                            <Tags.TableColumn width="7rem">
                            <Tags.TableHeaderLabel>
                                <T path="payments.payments.value" />
                            </Tags.TableHeaderLabel>
                            </Tags.TableColumn>
                        </Tags.TableHeader>
                        { paymentsReceive.map((item:PaymentType, index:number) => <Tags.TableRow style={{height:"3rem"}} key={index} onClick={()=>{handleGoToPaymentEdit(item.id ?? '0')}}>
                            <Tags.TableColumn>
                                <Column justify="center">
                                    <Tags.TableLabel>{getPaymentDescription(item)}</Tags.TableLabel>
                                    {item.patient_id?.label ? <Tags.TableLabelDetail>{item.patient_id.label}</Tags.TableLabelDetail> : null}
                                </Column>
                            </Tags.TableColumn>
                            <Tags.TableColumn width="7rem">
                                <Column justify="center">
                                    <Tags.TableLabel>{DateFromUTCToView(item.due_date, false, {noValue: "-"})}</Tags.TableLabel>
                                    <Tags.TableLabelDetail className={item.status}>{i18n(`payments.modalFilter.status${capitalize(item.status)}`)}</Tags.TableLabelDetail>
                                </Column>
                            </Tags.TableColumn>
                            <Tags.TableColumn className="moneyValue" width="7rem" style={{color:"var(--theme-color-value-green)"}}>{MoneyFromNumberToString(item.value_total)}</Tags.TableColumn>
                        </Tags.TableRow>)}
                        <Tags.FillSpace />
                    </> : <EmptyState size={2} title={i18n("payments.paymentNoItemToReceive")} icon={mdiCashMultiple} />}
                    <Tags.TableFooter>
                        <Paginator page={paymentsReceivePage} total={paymentsReceiveTotal} onPageChange={handleChangePaymentsToReceivePage} />
                    </Tags.TableFooter>
                </Card>
                <Column style={{width: '100%'}} align="stretch">
                    <Tags.TotalTitle><T path="payments.total.toReceiveTitle" /></Tags.TotalTitle>
                    <Card key="receiveTotal">
                        <RowOrColumn style={{padding:"0.5rem 1rem"}} columnAlign="stretch">
                            <Row>
                                <Column style={{flex:2}} align="flex-start">
                                    <Tags.TotalLabel><T path="payments.total.due" /></Tags.TotalLabel>
                                    <Tags.TotalValue>{MoneyFromNumberToString(totalToReceive?.nextdue ?? 0)}</Tags.TotalValue>
                                </Column>
                                <Column style={{flex:2}} align="flex-start">
                                    <Tags.TotalLabel><T path="payments.total.delayed" /></Tags.TotalLabel>
                                    <Tags.TotalValue>{MoneyFromNumberToString(totalToReceive?.delayed ?? 0)}</Tags.TotalValue>
                                </Column>
                                <Column style={{flex:2}} align="flex-start">
                                    <Tags.TotalLabel><T path="payments.total.received" /></Tags.TotalLabel>
                                    <Tags.TotalValue>{MoneyFromNumberToString(totalToReceive?.received ?? 0)}</Tags.TotalValue>
                                </Column>
                            </Row>
                            <Column style={{flex:3, margin:"0.3rem 0"}} align="flex-start" justify="flex-start">
                                <Tags.TotalLabel><T path="payments.total.toReceiveTitle" /></Tags.TotalLabel>
                                <Tags.TotalValue className='totalReceive'>{MoneyFromNumberToString(totalToReceive?.total ?? 0)}</Tags.TotalValue>
                            </Column>
                        </RowOrColumn>
                    </Card>
                </Column>
            </Column>,
            <Column key="pay" align="stretch" justify="space-between" style={{flex:1}}>
                <Card title={[
                    <div key="title">{i18n("payments.titleToPay")}</div>,
                ]}>
                    {paymentsPay.length ? <>
                        <Tags.TableHeader>
                            <Tags.TableColumn>
                                <Tags.TableHeaderLabel>
                                    <T path="payments.payments.description" />
                                </Tags.TableHeaderLabel>
                            </Tags.TableColumn>
                            <Tags.TableColumn width="7rem">
                                <Tags.TableHeaderLabel>
                                    <T path="payments.payments.dueDate" />
                                </Tags.TableHeaderLabel>
                            </Tags.TableColumn>
                            <Tags.TableColumn width="7rem">
                            <Tags.TableHeaderLabel>
                                <T path="payments.payments.value" />
                            </Tags.TableHeaderLabel>
                            </Tags.TableColumn>
                        </Tags.TableHeader>
                        { paymentsPay.map((item:PaymentType, index:number) => <Tags.TableRow style={{height:"3rem"}} key={index} onClick={()=>{handleGoToPaymentEdit(item.id ?? '0')}}>
                            <Tags.TableColumn>{getPaymentDescription(item)}</Tags.TableColumn>
                            <Tags.TableColumn width="7rem">
                                <Column justify="center">
                                    <Tags.TableLabel>{DateFromUTCToView(item.due_date, false, {noValue: "-"})}</Tags.TableLabel>
                                    <Tags.TableLabelDetail>{i18n(`payments.modalFilter.status${capitalize(item.status)}`)}</Tags.TableLabelDetail>
                                </Column>
                            </Tags.TableColumn>
                            <Tags.TableColumn className="moneyValue" width="7rem" style={{color:"var(--theme-color-value-red)"}}>{MoneyFromNumberToString(item.value_total)}</Tags.TableColumn>
                        </Tags.TableRow>)}
                        <Tags.FillSpace />
                    </> : <EmptyState size={2} title={i18n("payments.paymentNoItemToPay")} icon={mdiCashMultiple} />}
                    <Tags.TableFooter>
                        <Paginator page={paymentsPayPage} total={paymentsPayTotal} onPageChange={handleChangePaymentsToPayPage} />
                    </Tags.TableFooter>
                </Card>
                <Column style={{width: '100%'}} align="stretch">
                    <Tags.TotalTitle><T path="payments.total.toPayTitle" /></Tags.TotalTitle>
                    <Card key="receiveTotal">
                        <RowOrColumn style={{padding:"0.5rem 1rem"}} columnAlign="stretch">
                            <Row>
                                <Column style={{flex:2}} align="flex-start">
                                    <Tags.TotalLabel><T path="payments.total.due" /></Tags.TotalLabel>
                                    <Tags.TotalValue>{MoneyFromNumberToString(totalToPay?.nextdue ?? 0)}</Tags.TotalValue>
                                </Column>
                                <Column style={{flex:2}} align="flex-start">
                                    <Tags.TotalLabel><T path="payments.total.delayed" /></Tags.TotalLabel>
                                    <Tags.TotalValue>{MoneyFromNumberToString(totalToPay?.delayed ?? 0)}</Tags.TotalValue>
                                </Column>
                                <Column style={{flex:2}} align="flex-start">
                                    <Tags.TotalLabel><T path="payments.total.paid" /></Tags.TotalLabel>
                                    <Tags.TotalValue>{MoneyFromNumberToString(totalToPay?.paid ?? 0)}</Tags.TotalValue>
                                </Column>
                            </Row>
                            <Column style={{flex:3, margin:"0.3rem 0"}} align="flex-start">
                                <Tags.TotalLabel><T path="payments.total.toPayTitle" /></Tags.TotalLabel>
                                <Tags.TotalValue className='totalPay'>{MoneyFromNumberToString(totalToPay?.total ?? 0)}</Tags.TotalValue>
                            </Column>
                        </RowOrColumn>
                    </Card>
                </Column>
            </Column>
        ];
    },[paymentsReceive, paymentsReceivePage, paymentsReceiveTotal, handleChangePaymentsToReceivePage, paymentsPay, paymentsPayPage, paymentsPayTotal, handleChangePaymentsToPayPage]);

    const renderChartAndCashflow = useMemo(()=>{
        return [
            <Card key={"chart"} title={i18n("payments.chartTitle")}>
                <div style={{width:"100%", height:"300px"}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart width={500} height={300} data={chartData} stackOffset="sign" margin={{top: 5, right: 0, left: 0, bottom: 5}} >
                            <CartesianGrid stroke="var(--theme-color-chart-lines)"vertical={false} strokeDasharray="3" />
                            <XAxis axisLine={false}  interval={0}  dataKey="name" tick={{fontSize:'0.75rem', fill:'var(--theme-color-font)'}}/>
                            <XAxis dataKey="year" hide={true} xAxisId="yearXAxis" />
                            <YAxis interval="preserveStartEnd"  tick={{fontSize:'0.75rem', fill:'var(--theme-color-font)'}} ticks={chartTicks}  tickFormatter={(v:any)=>MoneyFromNumberToString(Math.abs(v), false, true)}  domain={([dataMin, dataMax]:number[]) => [Math.min(dataMin, -dataMax), Math.max(-dataMin, dataMax)]} />
                            <Tooltip cursor={false} content={<CustomTooltip />} labelStyle={{color:'var(--theme-color-font)', padding:0, margin:0}}/>
                            <Legend />
                            <ReferenceLine y={0} stroke="var(--theme-color-chart-lines)" />
                            <Bar name={i18n("payments.chartReceived")} dataKey="income" fill="var(--theme-color-value-green)" stackId="stack" />
                            <Bar name={i18n("payments.chartPaid")} dataKey="outcome" fill="var(--theme-color-value-red)" stackId="stack" />
                            <Line name={i18n("payments.chartDiff")} dataKey="diff" dot={{ stroke: 'var(--theme-color-chart-blue)', strokeWidth: 3, fill: 'white', r:5 }} stroke="var(--theme-color-chart-blue)" strokeWidth={3} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </Card>,
            <Card key={"cashflow"} title={i18n("payments.cashflow.title")}>
                {cashflowData.length ? <>
                    <Tags.TableHeader>
                        <Tags.TableColumn>
                            <Tags.TableHeaderLabel>
                                <T path="payments.cashflow.month" />
                            </Tags.TableHeaderLabel>
                        </Tags.TableColumn>
                        <Tags.TableColumn width="7rem">
                            <Tags.TableHeaderLabel style={{display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center"}}>
                                <Tags.CashflowDetail><Tags.CashflowOp>(+)</Tags.CashflowOp><T path="payments.cashflow.received" /></Tags.CashflowDetail>
                                <Tags.CashflowDetail><Tags.CashflowOp>(-)</Tags.CashflowOp><T path="payments.cashflow.paid" /></Tags.CashflowDetail>
                            </Tags.TableHeaderLabel>
                        </Tags.TableColumn>
                        <Tags.DualTableColumn width={7.5}>
                            <Tags.TableColumn width="7.5rem" className="moneyHeader">
                                <Tags.TableHeaderLabel>
                                    <T path="payments.cashflow.diff" />
                                </Tags.TableHeaderLabel>
                            </Tags.TableColumn>
                            <Tags.TableColumn width="7.5rem" className="moneyHeader" style={{paddingLeft:0}}>
                                <Tags.TableHeaderLabel>
                                    <T path="payments.cashflow.balance" />
                                </Tags.TableHeaderLabel>
                            </Tags.TableColumn>
                        </Tags.DualTableColumn>
                    </Tags.TableHeader>
                    { cashflowData.map((item:CashFlowItem, index:number) => <Tags.TableRow key={index} style={{height:"auto"}}>
                        <Tags.TableColumn>{DateFromMonthAndYearToMonthView(item.month, item.year)}</Tags.TableColumn>
                        <Tags.TableColumn width="7rem" className="row">
                            <div style={{display:"flex", alignItems:"center",marginRight:"3px"}}>R$</div>
                            <Column style={{flex:1}} align="flex-start" justify="flex-start">
                                <Tags.CashflowValue className="top">
                                    <Tags.CashflowOp>+ </Tags.CashflowOp>
                                    <div style={{textAlign:"right", flex:1}}>{MoneyFromNumberToString(item.income,true)}</div>
                                </Tags.CashflowValue>
                                <Tags.CashflowValue className="bottom">
                                    <Tags.CashflowOp>- </Tags.CashflowOp>
                                    <div style={{textAlign:"right", flex:1}}>{MoneyFromNumberToString(item.outcome,true)}</div>
                                </Tags.CashflowValue>
                            </Column>
                        </Tags.TableColumn>
                        <Tags.DualTableColumn width={7.5}>
                            <Tags.TableColumn className="moneyValue" width="7.5rem" style={{color:`var(--theme-color-value-${item.diff > 0 ? 'green' : 'red'})`}}>{MoneyFromNumberToString(item.diff)}</Tags.TableColumn>
                            <Tags.TableColumn className="moneyValue" width="7.5rem">{MoneyFromNumberToString(item.balance)}</Tags.TableColumn>
                        </Tags.DualTableColumn>
                    </Tags.TableRow>)}
                    <Tags.FillSpace />
                </> : <EmptyState size={2} title={i18n("payments.paymentNoItemCashflow")} icon={mdiCashMultiple} />}
                <Tags.TableFooter>
                    <Paginator page={cashflowPage} total={cashflowTotal} onPageChange={handleChangeCashflowPage} />
                </Tags.TableFooter>
            </Card>,
        ]

    },[chartData, chartTicks, cashflowData, cashflowPage, cashflowTotal, handleChangeCashflowPage]);
    

    return <PageContent>
        <TitleBar>
            <Title>
                <T path="payments.title" />
            </Title>
            <ButtonRow>
                <div>{i18n("dates.monthLong."+(paymentsDateCurrent.getMonth()+1))}/{paymentsDateCurrent.getFullYear()}</div>
                <Button color="var(--theme-color-primary)" icon={mdiChevronLeft} onClick={handlePrevMonth} tooltip={i18n("payments.prevMonth")} ></Button>
                <Button color="var(--theme-color-primary)" icon={mdiChevronRight} onClick={handleNextMonth} tooltip={i18n("payments.nextMonth")} ></Button>
                <Button color="#0A35CE" icon={mdiFilter} onClick={handleFilter}><T path="payments.filter" /></Button>
                <Button color="#2B7100" icon={mdiPlus} onClick={handleNewPatient}><T path="payments.new" /></Button>
            </ButtonRow>
        </TitleBar>
        {filters ? <FilterBar>
            <FilterRow><T path="payments.hasFilter" /></FilterRow>
            {Object.keys(filters).filter(f=>Array.isArray(filters[f]) ? filters[f].length > 0 : filters[f]).map((f,i) => <FilterRow key={i}>{i18n(`payments.filterValues.${f}`)}: <span style={{paddingLeft:"0.3rem", fontWeight:600}}>{getFilterValue(f)}</span></FilterRow>)}
        </FilterBar> : null }
        <RowOrColumn rowAlign="stretch" columnAlign="stretch">
            {renderReciveAndPay}
        </RowOrColumn>
        <TitleBar style={{marginTop:'2rem'}}>
            <Title>
                <T path="payments.titleReports" />
            </Title>
            {[0,undefined].indexOf(currentPlan?.history) < 0 ? <LimitState>{i18n("payments.limit").replace("$1", String(currentPlan?.history))}</LimitState> : null}
        </TitleBar>
        <RowOrColumn rowAlign="stretch" columnAlign="stretch">
            {renderChartAndCashflow}
        </RowOrColumn>
        <Card title={i18n("payments.recomendations.title")}>
            <RowOrColumn rowAlign="stretch" columnAlign="stretch">
                <Column style={{flex: 1, padding:'1rem 0 1rem 1rem'}}>
                    <Tags.RecomendationRow>
                        <Tags.RecomendationItem><T path="payments.recomendations.grossValue" /></Tags.RecomendationItem>
                        <Tags.RecomendationItem className="big">{MoneyFromNumberToString(recomendationsData.grossValue)}</Tags.RecomendationItem>
                    </Tags.RecomendationRow>
                    <Tags.RecomendationRow>
                        <Tags.RecomendationItem><T path="payments.recomendations.socialSecurity" /></Tags.RecomendationItem>
                        <Tags.RecomendationItem>{MoneyFromNumberToString(recomendationsData.socialSecurity)}</Tags.RecomendationItem>
                    </Tags.RecomendationRow>
                    <Tags.RecomendationRow>
                        <Tags.RecomendationItem><T path="payments.recomendations.incomeTax" /></Tags.RecomendationItem>
                        <Tags.RecomendationItem>{MoneyFromNumberToString(recomendationsData.incomeTax)}</Tags.RecomendationItem>
                    </Tags.RecomendationRow>

                    <Tags.RecomendationRow>
                        <Tags.RecomendationItem><T path="payments.recomendations.vacations" /></Tags.RecomendationItem>
                        <Tags.RecomendationItem>{MoneyFromNumberToString(recomendationsData.vacactionReserve)}</Tags.RecomendationItem>
                    </Tags.RecomendationRow>
                    <Tags.RecomendationRow>
                        <Tags.RecomendationItem><T path="payments.recomendations.vacationsThird" /></Tags.RecomendationItem>
                        <Tags.RecomendationItem>{MoneyFromNumberToString(recomendationsData.vacationsThird)}</Tags.RecomendationItem>
                    </Tags.RecomendationRow>

                    <Tags.RecomendationRow>
                        <Tags.RecomendationItem><T path="payments.recomendations.income13" /></Tags.RecomendationItem>
                        <Tags.RecomendationItem>{MoneyFromNumberToString(recomendationsData.income13reserve)}</Tags.RecomendationItem>
                    </Tags.RecomendationRow>
                    <Tags.RecomendationRow>
                        <Tags.RecomendationItem><T path="payments.recomendations.socialSecurity13" /></Tags.RecomendationItem>
                        <Tags.RecomendationItem>{MoneyFromNumberToString(recomendationsData.socialSecurity13)}</Tags.RecomendationItem>
                    </Tags.RecomendationRow>
                    <div style={{flex: 1}}></div>
                    <Tags.RecomendationRow style={{borderTop: "1px solid var(--theme-color-line)"}}>
                        <Tags.RecomendationItem><T path="payments.recomendations.total" /></Tags.RecomendationItem>
                        <Tags.RecomendationItem className="big" style={{marginTop:"0.5rem"}}>{MoneyFromNumberToString(recomendationsData.total)}</Tags.RecomendationItem>
                    </Tags.RecomendationRow>
                </Column>
                <Column align="flex-start" style={{flex: 2, padding:"1rem 1rem 1rem 2rem"}}>
                    {i18n("payments.recomendations.text").split("\\n").map((t,i) => <p key={i} style={{marginTop:"0"}}>{t}</p>)}
                </Column>
            </RowOrColumn>
        </Card>
    </PageContent>
}

export default Payments;
