import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StripeCardNumberElementOptions } from "@stripe/stripe-js";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement, Elements } from "@stripe/react-stripe-js";

import { AppState } from "../../redux/stores";
import { setMessage } from '../../redux/messagesReducer';
import { CPFInput, Column, Input, Label, Row, RowOrColumn, i18n } from "@pilarterapeutico/components";
import * as Tags from '../../pages/styles/Plans.styles';

type CreditCardFormProps = {
    ref?: React.Ref<any>;
};

const CreditCardForm: React.FC<CreditCardFormProps> = forwardRef(({}, ref) => {

    const [dataPayerName, setDataPayerName] = useState<string>("");
    const [dataPayerCPF, setDataPayerCPF] = useState<string>("");
    const [messageError, setMessageError] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [focusOn, setFocusOn] = useState<string>();
    const [cardNumberElementOptions, setCardNumberElementOptions] = useState<StripeCardNumberElementOptions>({
        style: {
            base: {
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "14px",
            },
            invalid: {
                color: '#f00',
                fontFamily: 'Roboto, sans-serif',
                iconColor: '#f00'
            }
        }
    });
    
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const theme = useSelector<AppState, string>(s => s.auth.theme);

    const hasFocus = (key:string) => {
        return focusOn === key ? 'focus' : '';
    };

    const handleError = (error?:{message?:string}) => {
        setIsLoading(false);
        setMessageError(error?.message);
    };

    useImperativeHandle(ref, () => ({

        getPayerInfo: () => {
            return {
                name: dataPayerName,
                cpf: dataPayerCPF
            }
        },

        saveCreditCard: async () => {
            try {
                if (!stripe || !elements || isLoading) {
                    return false;
                }
            
                setIsLoading(true);
            
                const {error: submitError} = await elements.submit();
                if (submitError) {
                    handleError(submitError);
                    return;
                }
            
                const paymentMethod = await stripe.createPaymentMethod({
                    type: 'card',
                    card: (elements.getElement("cardNumber") as any),
                    billing_details: {
                        name: dataPayerName,
                    },
                });
            
                handleError();
                return {
                    success: true,
                    paymentMethod,
                };
            } catch (e:any) {
                console.error(e);
                dispatch(setMessage({message:e.message, type: "error"}));
                setIsLoading(false);
                return {
                    success: false
                }
            }
        },

        setLoading: (loading:boolean) => {
            setIsLoading(loading);
        }
    
    }));

    useEffect(() => {
        let isDarkMode = theme === 'dark' || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

        setCardNumberElementOptions({
            disabled: false,
            style: {
                base: {
                    ...cardNumberElementOptions?.style?.base,
                    color: isDarkMode ? '#fff' : '#000',
                },
                invalid: {
                    ...cardNumberElementOptions?.style?.invalid,
                    color: isDarkMode ? '#f66' : '#a00',
                    iconColor: isDarkMode ? '#f66' : '#a00',
                }
            }
        });
    }, []);


    useEffect(()=>{
        setCardNumberElementOptions(o => ({...o, disabled: isLoading}))
    }, [isLoading]);

    return <Column style={{width:"100%", position:'relative'}}>
        <RowOrColumn columnAlign='stretch' columnJustify='flex-start'>
            <Tags.Field style={{flex:1}}>
                <Label>
                    {i18n("config.plans.labelCardNumber")}
                </Label>
                <Tags.Element className={hasFocus('cardnumber')}>
                    <Tags.ElementContent>
                        <CardNumberElement onFocus={()=>setFocusOn('cardnumber')} onBlur={()=>setFocusOn(undefined)} options={{showIcon:true, iconStyle:'solid', ...cardNumberElementOptions}}  />
                    </Tags.ElementContent>
                </Tags.Element>
            </Tags.Field>
            <Tags.Field style={{flex:1}}>
                <Row align="stretch" justify="stretch">
                    <Tags.Field style={{flex:1}}>
                        <Label>
                            {i18n("config.plans.labelCardDue")}
                        </Label>
                        <Tags.Element className={hasFocus('expiry')}>
                            <Tags.ElementContent>
                                <CardExpiryElement onFocus={()=>setFocusOn('expiry')} onBlur={()=>setFocusOn(undefined)} options={cardNumberElementOptions} />
                            </Tags.ElementContent>
                        </Tags.Element>
                    </Tags.Field>
                    <Tags.Field style={{flex:1}}>
                        <Label>
                            {i18n("config.plans.labelCardCode")}
                        </Label>
                        <Tags.Element className={hasFocus('cvc')}>
                            <Tags.ElementContent>
                                <CardCvcElement onFocus={()=>setFocusOn('cvc')} onBlur={()=>setFocusOn(undefined)} options={cardNumberElementOptions} />
                            </Tags.ElementContent>
                        </Tags.Element>
                    </Tags.Field>
                </Row>
            </Tags.Field>
        </RowOrColumn>
        <RowOrColumn columnAlign='stretch' columnJustify='flex-start'>
            <Tags.Field style={{flex:1}}>
                <Label>
                    {i18n("config.plans.labelCardHolderName")}
                </Label>
                <Input uid='plans.cardHolderName' disabled={isLoading} value={dataPayerName}  onChange={(e)=>{setDataPayerName((e.target as HTMLInputElement).value)}} />
            </Tags.Field>
            <Tags.Field style={{flex:1}}>
                <Label>
                    {i18n("config.plans.labelPayerCPF")}
                </Label>
                <CPFInput uid='plans.cardDue' disabled={isLoading} value={dataPayerCPF}  onChange={(e)=>{setDataPayerCPF((e.target as HTMLSelectElement).value)}} />
            </Tags.Field>
        </RowOrColumn>
        {isLoading ? <Tags.Loading>{i18n("config.plans.savingCard")}</Tags.Loading> : null}
        {messageError && <Tags.MessageError>{messageError}</Tags.MessageError>}
    </Column>
});

export default CreditCardForm;
