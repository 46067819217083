import { RowOrColumn } from "@pilarterapeutico/components";
import styled from "styled-components"

export const Photo = styled.div`
    height: 12rem;
    width: 12rem;
    background-color: var(--theme-color-primary, #863BD1);
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    
    @media (min-width: 1000px) {
        margin: 0 0.75rem 0 0;
    }
`;

export const Image = styled.img`
    min-width: 100%;
    min-height: 100%;
`;

export const Name = styled.div`
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;

    @media (min-width: 1000px) {
        width: auto;
        margin-top: 0;
        justify-content: flex-start;
    }
`;

export const NameValue = styled.div`
    font-size: 1.5rem;
    margin-right: 1rem;
`;

export const ValueRow = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
`;

export const Label = styled.div`
    opacity: 0.5;
    width: 40%;
    margin-bottom: 0.6rem;

    @media (min-width: 1000px) {
        width: 7.5rem;
        min-width: 7.5rem;
        margin-bottom: 0rem;
    }
`;

export const Value = styled.div`
    word-break: break-all;
    margin-bottom: 0.6rem;
    
    @media (min-width: 1000px) {
        margin-bottom: 0;
    }
`;

export const WpLink = styled.a`
    color: var(--theme-color-font);
    
    &:hover {
        color: var(--theme-color-primary);
    }
`;

export const ContentGrid = styled.div`
    padding: 10px 10px 0 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    flex-wrap: wrap;
    flex: 1;
    
    @media (min-width: 1000px) {
        padding: 20px 20px 0 20px;
    }
`;

export const ContentGridTop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

export const AppointmentItem = styled.div`
    flex: 1;
    width: calc(50% - 5px);
    min-width: calc(50% - 5px);
    max-width: calc(50% - 5px);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    padding: 0.625rem;
    background-color: var(--theme-color-background-aux2, #bbb);
    margin: 0 10px 10px 0;
    cursor: pointer;

    &:nth-child(2n) {
        margin-right: 0;
    }

    &:hover {
        background-color: var(--theme-color-background-aux4);
    }

    @media (min-width: 1000px) {
        width: calc(50% - 10px);
        min-width: calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin: 0 20px 20px 0;
    }
`;

export const AppointmentDay = styled.div`
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
`;

export const AppointmentMonth = styled.div`
    font-size: 0.8rem;
    opacity: 0.6;
    text-transform: uppercase;
    font-weight: lighter;
    padding: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
`;

export const AppointmentDate = styled.div`
    font-size: 0.8rem;
    padding: 0;
    flex: 1;
    
    @media (min-width: 1000px) {
        line-height: 1rem;
    }
`;

export const AppointmentStatus = styled.div`
    font-size: 0.75rem;
    padding: 0 6px;
    height: 1.5rem;
    padding-top: 0.15rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: 1px solid #fff;
    background-color: ${(props:{color:string}) => props.color ?? '#999'};
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 0;
`;

export const AppointmentConfirmByPatient = styled.div`
    font-size: 0.75rem;
    height: 1.5rem;
    width: 1rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${(props:{color:string}) => props.color ?? '#999'};
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;


export const ActivityItem = styled.div`
    flex: 1;
    width: calc(50% - 5px);
    min-width: calc(50% - 5px);
    max-width: calc(50% - 5px);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    padding: 0.625rem;
    background-color: var(--theme-color-background-aux2, #bbb);
    margin: 0 0.625rem 0.625rem 0;
    cursor: pointer;
    overflow: hidden;
    
    &:nth-child(2n) {
        margin-right: 0;
    }
    
    &:hover {
        background-color: var(--theme-color-background-aux4);
    }
    
    @media (min-width: 1000px) {
        margin: 0 1rem 1rem 0;
        width: calc(50% - 0.5rem);
        min-width: calc(50% - 0.5rem);
        max-width: calc(50% - 0.5rem);

    }
`;

export const ActivityName = styled.div`
    font-size: 1rem;
    line-height: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ActivityDate = styled.div`
    font-size: 0.8rem;
    line-height: 1rem;
    opacity: 0.6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;


export const FillSpace = styled.div`
    width: 100%;
    min-height: 0;
    flex: 1;
`;

export const TableFooter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 2.5rem;
    min-height: 2.5rem;
    background-color: var(--theme-color-background-aux2, #bbb);
`;

export const TableHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    height: 2.5rem;
    min-height: 2.5rem;
    background-color: var(--theme-color-background-aux2, #bbb);
`;

export const TableHeaderLabel = styled.span`
    opacity: 0.6;
    line-height: 1rem;
`;

interface TableColumnProps {mWidth?:string, width?:string};
export const TableColumn = styled.div<TableColumnProps>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 0 0 0 10px;

    &.moneyHeader {
        padding: 0 10px 0 0;
        margin-left: 10px;
    }

    &.row {
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;
    }
    
    &.moneyValue {
        justify-content: flex-end;
        align-items: flex-end;
        padding: 0 10px 0 0;
    }

    @media (max-width: 1000px) {
        ${(props:TableColumnProps) => (props.mWidth ?? props.width) ? `width: ${props.mWidth ?? props.width}` : 'flex:1'};
    }

    @media (min-width: 1000px) {
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;
        ${(props:{width?:string}) => props.width ? `width: ${props.width}` : 'flex: 1'};

        &.moneyValue {
            justify-content: flex-end;
            align-items: center;
        }
    }
`;

interface DualTableColumnProps {width?:number};
export const DualTableColumn = styled(RowOrColumn)<DualTableColumnProps>`
    width: ${(p:DualTableColumnProps) => p.width ? (p.width+'rem') : 'auto'};

    @media (min-width: 1000px) {
        width: ${(p:DualTableColumnProps) => p.width ? ((2*p.width)+'rem') : 'auto'};
    }
`;

export const HideMobile = styled.div`
    @media (max-width: 1000px) {
        display: none;
    }
`;

export const TableRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    height: 2.5rem;
    min-height: 2.5rem;
    margin: 0 0 1rem 0;
    line-height: 1.25rem;
    cursor: pointer;

    &:hover {
        background-color: var(--theme-color-background-aux-hover, #cacaca);
    }
    
    @media (min-width: 1000px) {
        margin: 0;
    }

    &.cancelled {
        opacity: 0.5;
        text-decoration: line-through;
    }
`;

export const TableLabel = styled.div`
    width: 100%;
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 0;
`;

export const TableLabelDetail = styled.div`
    width: 100%;
    opacity: 0.5;
    font-size: 0.8rem;
    line-height: 1rem;

    &.PAID {
        opacity: 1;
        color: var(--theme-color-value-green);
    }

    &.CANCELLED,
    &.REFUSED {
        opacity: 1;
        color: var(--theme-color-value-red);
    }
`;

export const PaymentsStatus = styled.div`
    text-transform: uppercase;

    &.statusPAID {
        color: var(--theme-color-value-green);
    }

    &.statusREFUSED {
        color: var(--theme-color-value-red);
    }

    &.statusPENDING,
    &.statusPROCESSING {
        color: var(--theme-color-font-weak);
    }

    &.statusCANCELLED {
        color: var(--theme-color-font-weak);
        text-decoration: line-through;
    }
`;

export const RowButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    flex-wrap: nowrap;

    & > * {
        margin-left: 3px;
    }
`;

export const DateDetail = styled.span`
    font-size: 0.75rem;
    opacity: 0.5;
    margin-left: 0;
    
    @media (min-width: 1000px){
        margin-left: 0.5rem;
    }
`;

export const TotalLabel = styled.span`
    font-size: 0.75rem;
    opacity: 0.5;
`;

export const TotalValue = styled.span`
    font-size: 1rem;
    opacity: 1;
    color: var(--theme-color-font);

    &.totalReceive {
        color: var(--theme-color-value-green);
        font-size: 1.8rem;
    }
    &.totalPay {
        color: var(--theme-color-value-red);
        font-size: 1.8rem;
    }
`;

export const TotalTitle = styled.span`
    font-size: 1rem;
    margin: 1rem 0 0.5rem;
    color: var(--theme-color-font);
`;

export const CashflowValue = styled.div`
    font-size: 0.9rem;
    margin: 0;
    line-height: 0.9rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    
    &.received {
        color: var(--theme-color-value-green);
    }
    &.paid {
        color: var(--theme-color-value-red);
    }
    &.top {
        margin: 5px 0 0;
    }
    &.bottom {
        margin: 0 0 5px;
    }
`;

export const CashflowDetail = styled.div`
    font-size: 0.9rem;
    line-height: 1rem;
`;

export const CashflowOp = styled.span`
    font-family: monospace;
    font-size: 0.9rem;
`;

export const RecomendationRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    min-height: 1.2rem;
    padding-bottom: 5px;
`;

export const RecomendationItem = styled.div`
    font-size: 0.9rem;
    
    &.big {
        font-size: 1.5rem;
    }
`;

export const TooltipWrapper = styled.div`
    background-color: var(--theme-color-background);
    border: 1px solid var(--theme-color-line);
    padding: 0.5rem;
`;

export const TooltipLabel = styled.div`
    color: var(--theme-color-font);
`;
export const TooltipValue = styled.div`
    font-size: 0.75rem;
    line-height: 1.2rem;
`;