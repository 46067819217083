import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import { useDispatch } from 'react-redux';
import { mdiClose, mdiCheck, mdiTrashCan } from '@mdi/js';
import { useParams, useLocation, useNavigate } from 'react-router-dom';


import PatienDataType from '../types/PatienDataType';
import usePatientData from '../hooks/usePatientData';
import LocationDataType from '../types/LocationDataType';
import ScheduleFormSchema from '../forms/scheduleForm';
import ModalDeleteSchedule from '../modals/ModalDeleteSchedule';
import { setMessage } from '../redux/messagesReducer';
import { getLocations } from '../services/requests/locations';
import { ScheduleFormType } from '../types/ScheduleDataType';
import { getTherapistAgenda } from '../services/requests/appointments';
import { PostScheduleResponse } from '../services/contracts/schedules';
import { AppointmentsListItemsType } from '../services/contracts/appointments';
import { FormError, RequestHandler } from '../services/contracts';
import { hideModal, setModalAndShow } from '../redux/modalReducer';
import { createSchedule, updateSchedule, getScheduleData, deleteSchedule } from '../services/requests/schedules';
import { TimeFromIntMinutesToTime, TimeFromTimeToIntMinutes, DateFromDateToUTC, DateFromUTCToView, DateFromViewToUTC, DateIsSameDay } from '@pilarterapeutico/util';
import { Row, Card, Column, Button, Input, Label, Select, Radio, DateInput, TimeInput, TitleBar, Breadcrumb, PageContent, Timecheck, Translate, i18n, RowOrColumn } from '@pilarterapeutico/components';
import * as Tags from './styles/PatientForm.styles';

type ScheduleFormProps = {
  id?: string;
  edit?: boolean;
}


const PatientScheduleAdd = ({edit}:ScheduleFormProps) => {
  
  const [dataDate, setDataDate] = useState<Date>(new Date());
  const [dataStart, setDataStart] = useState<string>("");
  const [dataEnd, setDataEnd] = useState<string>("");
  const [dataRepeat, setDataRepeat] = useState<string>("");
  const [dataFinish, setDataFinish] = useState<string>('NEVER');
  const [dataRepeatDate, setDataRepeatDate] = useState<Date>();
  const [dataRepeatTimes, setDataRepeatTimes] = useState<string>('');
  const [dataLocation, setDataLocation] = useState<string>('');
  const [dataLocationList, setDataLocationList] = useState<LocationDataType[]>([]);
  const [dataAppointmentsModify, setdataAppointmentsModify] = useState<string>('MODIFY');
  const [dataAppointmentsCount, setdataAppointmentsCount] = useState<number>(0);
  const [dataErrors, setDataErrors] = useState<FormError[]>([]);
  const [patientData, setPatientData] = useState<PatienDataType>();
  const [agendaEvents, setAgendaEvents] = useState<AppointmentsListItemsType[]>([]);
  
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const patient = usePatientData();

  const validateAll = (data:ScheduleFormType) => {
      try {
          ScheduleFormSchema().validateSync(data, {abortEarly:false});
          return true;
      } catch (e:any) {
          if (e.inner && Array.isArray(e.inner)) {
              setDataErrors(e.inner.map((err:any) => ({message:err.message, type:err.type, path: err.path})));
          }
          return false;
      }
  };

  const handleCancel = () => {
    navigate(-1);
  }

  const handleDelete = () => {
    dispatch(setModalAndShow({
      content: <ModalDeleteSchedule count={dataAppointmentsCount} onConfirm={handleDeleteConfirm} />,
    }))
  }

  const handleDeleteConfirm = async (data: {appointments: 'REMOVE'|'KEEP'}) => {
    dispatch(hideModal());
    const response = await deleteSchedule(String(params.scheduleId), String(params.id), data);
    if (response.error) {
      dispatch(setMessage({
        message: i18n("scheduleform.unableToRemove"),
        type: "error",
      }));
    } else {
      dispatch(setMessage({
        message: i18n("scheduleform.deleted"),
        type: "success",
      }));
      navigate(`/patients/${params.id}`);
    }
  }

  const handleSave = async () => {
    const data:ScheduleFormType = {
      start_date: DateFromDateToUTC(dataDate) ?? '',
      start_time: TimeFromTimeToIntMinutes(dataStart),
      end_time: TimeFromTimeToIntMinutes(dataEnd),
      repeat: dataRepeat,
      end_at: dataFinish,
      location_id: dataLocation || undefined,
      end_at_date: DateFromDateToUTC(dataRepeatDate),
      end_at_times: Boolean(dataRepeatTimes) ? dataRepeatTimes : undefined,
      patient_id: String(params.id),
      appointments_modify: dataAppointmentsModify ?? '',
    };

    setDataErrors([]);

    const isValidForm = validateAll(data);
    if (!isValidForm) {
      return;
    }

    let response:RequestHandler<PostScheduleResponse>;
    if (edit) {
      data.id = params.scheduleId;
      response = await updateSchedule(data);
    } else {
      response = await createSchedule(data);
    }

    if (response.error) { 
      if (response.type === 'form') {
        setDataErrors((response.error ?? []) as FormError[]);
      } else {
        dispatch(setMessage({message: response.error ?? '', type: "error"}));
      }
    } else {
      dispatch(setMessage({message: i18n("patientform.saved"), type: "success"}));
      navigate(`/patients/${params.id}`,{replace:true});
    }
  }

  const handleChangeDate = (e: FormEvent<HTMLInputElement>) => {
    let dt = DateFromViewToUTC((e.target as HTMLInputElement).value);
    if (dt) {
      setDataDate(new Date(dt));
    }
  }

  const handleChangeEndDate = (e: FormEvent<HTMLInputElement>) => {
    let dt = DateFromViewToUTC((e.target as HTMLInputElement).value);
    if (dt) {
      setDataRepeatDate(new Date(dt));
    }
  }

  const seekNextAppointments = ({date,view}:{date:Date,view:string}) => {
    if (view === 'month') {
      if (datesNextAppointments.find((dDate:Date) => DateIsSameDay(dDate, date))) {
        return 'markedDate';
      }
    }
  }

  const getFistError = useCallback((key:string) => {
    return dataErrors.filter((e:FormError)=>e.path===key)[0] ?? null;
  }, [dataErrors]);

  const datesNextAppointments = useMemo(()=>{
    let nDate = new Date(dataDate);
    let maxDate = new Date(dataDate);
    let result:Date[] = [];

    if (isNaN(maxDate.getTime())) {
      return [];
    }

    maxDate.setUTCMonth(maxDate.getUTCMonth() + 2);

    switch(dataRepeat) {
      case 'WEEK':
        while(maxDate.getTime() > nDate.getTime()) {
          nDate = new Date(nDate);
          nDate.setUTCDate(nDate.getUTCDate() + 7);
          result.push(nDate);
        }
        break;
      case 'TWOWEEKS':
        while(maxDate.getTime() > nDate.getTime()) {
          nDate = new Date(nDate);
          nDate.setUTCDate(nDate.getUTCDate() + 14);
          result.push(nDate);
        }
        break;
      case 'TWICEAMONTH':
        let monthCount = 1;
        let lastMonth = nDate.getUTCMonth();

        while(maxDate.getTime() > nDate.getTime()) {
          nDate = new Date(nDate);
          nDate.setUTCDate(nDate.getUTCDate() + 14);

          if (lastMonth === nDate.getUTCMonth()) {
            monthCount++;
            if (monthCount > 2) {
              nDate.setUTCDate(nDate.getUTCDate() + 7);
              monthCount = 1;
              lastMonth = nDate.getUTCMonth();
            }
          }

          result.push(nDate);
        }
        break;
      case 'MONTH':
        while(maxDate.getTime() > nDate.getTime()) {
          nDate = new Date(nDate);
          nDate.setUTCMonth(nDate.getUTCMonth() + 1);
          result.push(nDate);
        }
        break;

      default:
        result = [];
    }

    return result;
  },[dataDate, dataRepeat]);

  const dateAsString = useMemo(()=>{
    return DateFromUTCToView(dataDate, false);
  },[dataDate]);

  const dateEndAsString = useMemo(()=>{
      return DateFromUTCToView(dataRepeatDate, false);
  },[dataRepeatDate]);



  useEffect(()=>{
    let update = true; 

    (async () => {
      if (!params.scheduleId || !params.id) {
        return;
      }
      
      try {
        let reqData = await getScheduleData(params.scheduleId, params.id);
        
        if(!reqData || !update) {
          return;
        }

        setDataDate(new Date(reqData.data?.start_date ?? '') ?? new Date());
        setDataStart(TimeFromIntMinutesToTime(parseInt(reqData.data?.start_time ?? ''), ':') ?? '');
        setDataEnd(TimeFromIntMinutesToTime(parseInt(reqData.data?.end_time ?? ''), ':') ?? '');
        setDataRepeat(reqData.data?.repeat ?? '');
        setDataFinish(reqData.data?.end_at ?? 'never');
        setDataRepeatDate(new Date(reqData.data?.end_at_date ?? '') ?? undefined);
        setDataRepeatTimes(reqData.data?.end_at_times ?? '');
        setDataLocation(reqData.data?.location_id ?? '');
        setdataAppointmentsCount(parseInt(reqData.data?.appointmentsCount ?? '0', 10));
      } catch (e) {
        console.log(e);
      }
    })();
    
    return () => {update = false;};
  }, [edit, params.scheduleId]);
  

  useEffect(()=>{
    let update = true; 

    (async () => {
      let reqLocations = await getLocations();

      if (update && reqLocations) {
        setDataLocationList(reqLocations.data?.items ?? []);
      }
    })();

    return () => {update = false;};
  }, []);

  useEffect(()=>{
    if (!dataStart || !dataEnd || !dataDate) {
        return;
    }

    let update = true;

    (async() => {
        let req = await getTherapistAgenda(params.id ?? '0', DateFromDateToUTC(dataDate));

        if(req && update) {
            setAgendaEvents(req.data?.items ?? []);
        }
    })();

    return ()=>{update = false;};
}, [dataStart, dataEnd, dataDate]);

  useEffect(()=>{
    (async () => {
      let data = await patient.getData(params.id);
      if (patient.update) {
        setPatientData(data);
      }
    })();

    return patient.fn;
  }, [location.state?.patient?.name]);
    
  const patientPath = `/patients/${params.id ?? ''}`;
  const breadcrumbPath:any = {};
  breadcrumbPath["/patients"] = i18n("patients.title");
  breadcrumbPath[patientPath] = patientData?.name ?? '';

  return (<PageContent>
    <TitleBar>
      <Breadcrumb items={breadcrumbPath} current={i18n("patientSchedule.path")} />
    </TitleBar>
    <Card title={i18n("patientSchedule.title"+(edit?'Edit':''))}>
      <div style={{padding:'1rem'}}>
        <RowOrColumn rowAlign='flex-start' rowMargin="2rem" columnAlign='stretch'>
          <Column align="stretch" justify='flex-start' style={{flex:1}}>
            <Tags.Field>
              <Label><Translate path="patientSchedule.date" /></Label>
              <DateInput uid="patientSchedule.date" value={dateAsString} onChange={(e)=>handleChangeDate(e)} calendar={false} formError={getFistError('start_date')}/>
            </Tags.Field>

            <Row>
              <Tags.Field>
                <Label><Translate path="patientSchedule.start" /></Label>
                <TimeInput uid="patientSchedule.start" value={dataStart} onChange={(v) => setDataStart((v.target as HTMLInputElement).value)} formError={getFistError('start_time')} />
              </Tags.Field>
              <Tags.Field>
                <Label><Translate path="patientSchedule.end" /></Label>
                <TimeInput uid="patientSchedule.end" value={dataEnd} onChange={(v) => setDataEnd((v.target as HTMLInputElement).value)} formError={getFistError('end_time')} />
              </Tags.Field>
            </Row>

            <Tags.Field>
              <Label><Translate path="patientSchedule.repeat" /></Label>
              <Select uid="patientSchedule.repeat" value={dataRepeat} onChange={(v) => setDataRepeat((v.target as HTMLSelectElement).value)} formError={getFistError('repeat')}>
                <option value=""></option>
                {/*<option value="ONCE">{i18n("patientSchedule.repeatValues.once")}</option>*/}
                <option value="WEEK">{i18n("patientSchedule.repeatValues.week")}</option>
                <option value="TWOWEEKS">{i18n("patientSchedule.repeatValues.twoweeks")}</option>
                <option value="TWICEAMONTH">{i18n("patientSchedule.repeatValues.twiceamonth")}</option>
                <option value="MONTH">{i18n("patientSchedule.repeatValues.month")}</option>
              </Select>
            </Tags.Field>

            {["ONCE",""].indexOf(dataRepeat) < 0 ? <Tags.Field>
              <Label><Translate path="patientSchedule.finish" /></Label>
              <Radio className={"spaced-left"} style={{minHeight:'50px'}} isVertical={false} labelContent={i18n("patientSchedule.never")} uid='patientSchedule.finish.never' checked={dataFinish === 'NEVER'} onCheck={() => {setDataFinish('NEVER')}} />
              <Radio className={"spaced-left"} style={{minHeight:'50px'}} isVertical={false} labelContent={([
                <div key="label" style={{width: '3rem'}}><Translate path="patientSchedule.atDate" /></div>, 
                <DateInput key="input" value={dateEndAsString} onChange={handleChangeEndDate} style={{maxWidth:'180px', width:'180px', minWidth:'180px', margin: "0 10px"}} uid="patientSchedule.finish.date.dt" formError={getFistError('end_at_date')}/>
              ])} uid='patientSchedule.finish.date' checked={dataFinish === 'DATE'} onCheck={() => {setDataFinish('DATE')}} />
              <Radio className={"spaced-left"} style={{minHeight:'50px'}} isVertical={false} labelContent={([
                <div key="label" style={{width: '3rem'}}><Translate path="patientSchedule.after" /></div>,
                <Input key="input" value={dataRepeatTimes} onChange={(e) => {setDataRepeatTimes((e.target as HTMLInputElement).value)}} style={{maxWidth:'70px', width:'60px', minWidth:'60px', margin: "0 10px"}} uid="patientSchedule.finish.times"  formError={getFistError('end_at_times')} />, 
                <div key="continue"><Translate path="patientSchedule.times" /></div>
              ])} uid='patientSchedule.finish.times' checked={dataFinish === 'TIMES'} onCheck={() => {setDataFinish('TIMES')}} />
            </Tags.Field> : null }

            <Tags.Field>
              <Label><Translate path="patientSchedule.location" /></Label>
              <Select uid="patientSchedule.location" value={dataLocation} onChange={(v) => setDataLocation((v.target as HTMLSelectElement).value)} formError={getFistError('location_id')}>
                <option value=""></option>
                {dataLocationList.map(location => <option value={location.id} key={location.id}>{location.name}</option>)}
              </Select>
            </Tags.Field>

          </Column>

          <Column align="stretch" style={{flex:1}}>
            <Label><Translate path="patientSchedule.calendar" /></Label>
            <Calendar 
              minDetail="year" 
              locale={i18n('locale')}
              next2Label={null}
              prev2Label={null}
              value={dataDate} 
              onChange={(v:Date)=>setDataDate(v)} 
              className='formSchedule'
              tileClassName={seekNextAppointments}
            />
            {edit ? <>
              <Tags.Field>
                <Label><Translate path="patientSchedule.appointments" /></Label>
                <Radio className={"spaced-left"} isVertical={false} labelContent={i18n("patientSchedule.appointmentsModify")} uid='patientSchedule.appointments.modify' checked={dataAppointmentsModify === 'MODIFY'} onCheck={() => {setdataAppointmentsModify('MODIFY')}} />
                <Radio className={"spaced-left"} isVertical={false} labelContent={i18n("patientSchedule.appointmentsKeep")} uid='patientSchedule.appointments.keep' checked={dataAppointmentsModify === 'KEEP'} onCheck={() => {setdataAppointmentsModify('KEEP')}} />
              </Tags.Field>
              <Tags.Subtitle style={{marginTop: '1rem'}}><Translate path={`patientSchedule.appointments${dataAppointmentsModify === 'MODIFY' ? 'Modify' : 'Keep'}Detail`} /></Tags.Subtitle>
            </> : null }
            {!edit && ["ONCE",""].indexOf(dataRepeat) < 0 ? <>
              <Tags.Subtitle><Translate path="patientSchedule.appointmentsInsert" /></Tags.Subtitle>
            </> : null}
          </Column>

          <Column align="stretch" style={{flex:1}}>
            <Label><Translate path="patientSchedule.agenda" /></Label>
            <Timecheck events={agendaEvents} start={TimeFromTimeToIntMinutes(dataStart)} end={TimeFromTimeToIntMinutes(dataEnd)} date={dataDate} patientName={patientData?.name} location={dataLocationList.filter(loc=>loc.id === dataLocation)[0]?.name ?? ''} locationList={dataLocationList} />
          </Column>
        </RowOrColumn>
      </div>
      <Tags.ButtonBar style={{justifyContent:'space-between'}}>
        <Tags.ButtonGroup>
          {edit ?
            <Button size={6} icon={mdiTrashCan} onClick={()=>{handleDelete()}} color="#ff4444" title={i18n("delete")} />
          : null}
        </Tags.ButtonGroup>
        <Tags.ButtonGroup>
          <Button size={6} icon={mdiClose} onClick={()=>{handleCancel()}} color="#999999" title={i18n("cancel")} />
          <Button size={6} icon={mdiCheck} onClick={()=>{handleSave()}} color="#090" title={i18n("save")} />
        </Tags.ButtonGroup>
      </Tags.ButtonBar>
    </Card>
  </PageContent>)
}

export default PatientScheduleAdd;