import { createSlice } from '@reduxjs/toolkit'

interface menuProps {
  show: boolean,
};

const initialState: menuProps = {
  show: false
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openMenu(state) {
        state.show = true;
    },

    closeMenu(state) {
        state.show = false;
    }
  }
});

export const { openMenu, closeMenu } = menuSlice.actions;
export default menuSlice.reducer;