import * as yup from 'yup';
import i18n from 'i18next';

const PaymentFormSchema = () => yup.object({
    related_to: yup.string().oneOf(['APPOINTMENT','MONTH','OTHER'], i18n.t('payments.invalidRelatedTo') ?? '').required(i18n.t('payments.invalidRelatedTo') ?? '').typeError(i18n.t('payments.invalidRelatedTo') ?? ''),
    appointment_id: yup.array().of(yup.string()).optional().typeError(i18n.t('payments.invalidRelatedToAppointment') ?? '').when("related_to", {
        is: 'APPOINTMENT',
        then: (schema:yup.AnySchema) => schema.required(i18n.t('payments.invalidRelatedToAppointment') ?? '')
    }),
    voucher_id: yup.string().optional().typeError(i18n.t('payments.invalidVoucher') ?? ''),
    month: yup.date().optional().typeError(i18n.t('payments.invalidMonth') ?? '').when("related_to", {
        is: 'MONTH',
        then: (schema:yup.AnySchema) => schema.required(i18n.t('payments.invalidMonth') ?? '')
    }),
    description: yup.string().optional().typeError(i18n.t('payments.invalidDescription') ?? '').when("related_to", {
        is: 'OTHER',
        then: (schema:yup.AnySchema) => schema.required(i18n.t('payments.invalidDescription') ?? '')
    }),
    type: yup.string().oneOf(['CREDITCARD','MONEY','PIX']).required(i18n.t('payments.invalidType') ?? '').typeError(i18n.t('payments.invalidType') ?? ''),
    value_total: yup.number().positive(i18n.t('payments.invalidValueTotal') ?? '').required(i18n.t('payments.invalidValueTotal') ?? '').typeError(i18n.t('payments.invalidValueTotal') ?? ''),
    value_paid: yup.number().min(0, i18n.t('payments.invalidValuePaid') ?? '').default(0).optional().typeError(i18n.t('payments.invalidValuePaid') ?? ''),
    status: yup.string().oneOf(['PENDING','REFUSED','PROCESSING','PAID','CANCELLED'], i18n.t('payments.invalidStatis') ?? '').required(i18n.t('payments.invalidStatis') ?? '').typeError(i18n.t('payments.invalidStatis') ?? ''),
    due_date: yup.date().optional().typeError(i18n.t('payments.invalidDueDate') ?? ''),
    paid_at: yup.date().optional().typeError(i18n.t('payments.invalidPaidAt') ?? ''),
    notes: yup.string().optional().typeError(i18n.t('payments.invalidNotes') ?? ''),
});

export const PaymentWithBillingTypeFormSchema = () => yup.object({
    billing_type: yup.string().oneOf(['INCOME','OUTCOME'], i18n.t('payments.invalidBillingType') ?? '').required(i18n.t('payments.invalidBillingType') ?? '').typeError(i18n.t('payments.invalidBillingType') ?? ''),
    patient_id: yup.string().optional(),
    related_to: yup.string().oneOf(['APPOINTMENT','MONTH','OTHER'], i18n.t('payments.invalidRelatedTo') ?? '').typeError(i18n.t('payments.invalidRelatedTo') ?? '').when("billing_type", {
        is: 'INCOME',
        then: (schema:yup.AnySchema) => schema.required(i18n.t('payments.invalidRelatedTo') ?? ''),
        otherwise: (schema:yup.AnySchema) => schema.optional()
    }),
    appointment_id: yup.array().of(yup.string()).optional().typeError(i18n.t('payments.invalidRelatedToAppointment') ?? '').when("billing_type", {
        is: 'INCOME',
        then: (schema:yup.AnySchema) => schema.when("related_to", {
                is: 'APPOINTMENT',
                then: (schema:yup.AnySchema) => schema.required(i18n.t('payments.invalidRelatedToAppointment') ?? '')
            }).required(i18n.t('payments.invalidRelatedToAppointment') ?? ''),
        otherwise: (schema:yup.AnySchema) => schema.optional()
    }),
    voucher_id: yup.string().optional().typeError(i18n.t('payments.invalidVoucher') ?? ''),
    month: yup.date().typeError(i18n.t('payments.invalidMonth') ?? '').when("billing_type", {
        is: 'INCOME',
        then: (schema:yup.AnySchema) => schema.when("related_to", {
            is: 'MONTH',
            then: (schema:yup.AnySchema) => schema.required(i18n.t('payments.invalidMonth') ?? ''),
            otherwise: (schema:yup.AnySchema) => schema.optional()
        }),
        otherwise: (schema:yup.AnySchema) => schema.optional()
    }),
    description: yup.string().optional().typeError(i18n.t('payments.invalidDescription') ?? '').when("related_to", {
        is: 'OTHER',
        then: (schema:yup.AnySchema) => schema.required(i18n.t('payments.invalidDescription') ?? '')
    }).when("billing_type", {
        is: 'OUTCOME',
        then: (schema:yup.AnySchema) => schema.required(i18n.t('payments.invalidDescription') ?? ''),
        otherwise: (schema:yup.AnySchema) => schema.optional()
    }),
    type: yup.string().oneOf(['CREDITCARD','MONEY','PIX']).required(i18n.t('payments.invalidType') ?? '').typeError(i18n.t('payments.invalidType') ?? ''),
    value_total: yup.number().positive(i18n.t('payments.invalidValueTotal') ?? '').required(i18n.t('payments.invalidValueTotal') ?? '').typeError(i18n.t('payments.invalidValueTotal') ?? ''),
    value_paid: yup.number().min(0, i18n.t('payments.invalidValuePaid') ?? '').default(0).optional().typeError(i18n.t('payments.invalidValuePaid') ?? ''),
    status: yup.string().oneOf(['PENDING','REFUSED','PROCESSING','PAID','CANCELLED'], i18n.t('payments.invalidStatis') ?? '').required(i18n.t('payments.invalidStatis') ?? '').typeError(i18n.t('payments.invalidStatis') ?? ''),
    due_date: yup.date().optional().typeError(i18n.t('payments.invalidDueDate') ?? ''),
    paid_at: yup.date().optional().typeError(i18n.t('payments.invalidPaidAt') ?? ''),
    notes: yup.string().optional().typeError(i18n.t('payments.invalidNotes') ?? ''),
});

export default PaymentFormSchema;