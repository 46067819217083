import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit'

const savedUser = JSON.parse(localStorage.getItem('config.auth') || `{}`);
axios.defaults.headers.common['x-access-token'] = savedUser.accessToken || null;

const authSlice = createSlice({
    name: 'auth',
    initialState: savedUser,
    reducers: {
        refreshProfile(state, action) {
            try {
                let storage = JSON.parse(localStorage.getItem("config.auth") ?? '{}')
                
                state.name = action.payload.name;
                state.photo = action.payload.photo;

                storage.name = action.payload.name;
                storage.photo = action.payload.photo;
                
                localStorage.setItem("config.auth", JSON.stringify(storage));
            } catch (e) {
                console.log('LocalStorage is broken', e);
            }
        },

        updatePreferences(state, action) {
            try {
                let storage = JSON.parse(localStorage.getItem("config.auth") ?? '{}')
                
                state.lang = action.payload.lang;
                state.theme = action.payload.theme;

                storage.lang = action.payload.lang;
                storage.theme = action.payload.theme;
                
                localStorage.setItem("config.auth", JSON.stringify(storage));
            } catch (e) {
                console.log('LocalStorage is broken', e);
            }
        },

        login(state, action) {
            let instance = action.payload.instance;

            localStorage.setItem('config.auth', JSON.stringify(action.payload.data));
            instance.defaults.headers.common['x-access-token'] = action.payload.data.accessToken;

            state.accessToken = action.payload.data.accessToken;
            state.refreshtoken = action.payload.data.refreshToken;
            state.id = action.payload.data.id;
            state.email = action.payload.data.email;
            state.name = action.payload.data.name;
            state.type = action.payload.data.type;
            state.photo = action.payload.data.photo;
            state.key = undefined;
            state.plan = action.payload.data.plan;
            state.lang = action.payload.data.lang;
            state.theme = action.payload.data.theme;
        },

        logout(state, action) {
            let instance = action.payload.instance;

            localStorage.removeItem('config.auth');
            instance.defaults.headers.common['x-access-token'] = null;

            state.accessToken = null;
            state.refreshToken = null;
            state.id = null;
            state.email = null;
            state.name = null;
            state.type = 'P';
            state.photo = null;
            state.key = undefined;
            state.plan = undefined;
            state.lang = undefined;
            state.theme = undefined;
        },

        storeKey(state, action) {
            state.key = action.payload.content;
        }
      }
});

let actions = authSlice.actions;
export const refreshProfile = actions.refreshProfile;
export const updatePreferences = actions.updatePreferences;
export const login = actions.login;
export const logout = actions.logout;
export const storeKey = actions.storeKey;

export default authSlice.reducer;