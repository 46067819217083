import { useState } from "react";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";

import { Button } from "@pilarterapeutico/components";
import { hideModal } from "../redux/modalReducer";
import { useDispatch } from "react-redux";
import { Translate, i18n } from "@pilarterapeutico/components";
import * as Tags from './styles/ModalDeleteSchedule.styles';

type ModalDeleteProps = {
  onConfirm: Function;
  icon?: string;
  color?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  i18nKey: string;
};

const ModalDelete = ({i18nKey, onConfirm, icon, color, confirmLabel, cancelLabel}:ModalDeleteProps) => {

  const dispatch = useDispatch();

  return <Tags.Content>
    <Icon path={icon ?? mdiTrashCan} size={4} color={color ?? '#f44'} style={{margin:'2rem 0'}} />
    <Tags.Title><Translate path={`${i18nKey}.modalDelete.title`} /></Tags.Title>
    <Tags.Description>
      <Translate path={`${i18nKey}.modalDelete.description`} /><br />
    </Tags.Description>
    <Tags.ButtonRow>
      <Button onClick={()=>onConfirm()} color={color ?? "#f44"} size={6} title={confirmLabel ?? i18n('delete')} />
      <Button onClick={()=>{dispatch(hideModal())}} color={"#999"} size={6} title={cancelLabel ?? i18n('cancel')} />
    </Tags.ButtonRow>
  </Tags.Content>
}

export default ModalDelete;
