import { createSlice } from '@reduxjs/toolkit'

interface loadingProps {
  loading: string[]
};

const initialState: loadingProps = {
  loading: []
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading(state, action) {
        state.loading = [...state.loading, action.payload.key];
    },

    stopLoading(state, action) {
        let first = true;
        state.loading = state.loading.filter(i => {
          let result = i === action.payload.key;
          if (result && first) {
            first = false;
            return false;
          }
          return true;
        });
    }
  }
});

export const { startLoading, stopLoading } = loadingSlice.actions;
export default loadingSlice.reducer;