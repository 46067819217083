import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import ptBr from './_pt-br'
import enUs from './_en-us'

const i18nConfig = {
    resources: {
        'pt-BR': ptBr,
        'en-US': enUs
    },
    fallbackLng: 'pt-BR',
    defaultNS: 'translations'
}

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig)

declare global {
    interface Window { i18n: any; }
}
window.i18n = i18n;

export default i18n;