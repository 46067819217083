import { useEffect, useMemo, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { PageContent, Tabs, Title, Translate as T, i18n, TitleBar } from "@pilarterapeutico/components";

const Config = () => {
	
	const [currentTab, setCurrentTab] = useState<string>("general");

	const navigate = useNavigate();
	const location = useLocation();
	const tabs = [
		{id:"general", label:i18n("config.tabGeneral")},
		{id:"profile", label:i18n("config.tabProfile")},
		{id:"plans", label:i18n("config.tabPlans")},
		{id:"security", label:i18n("config.tabSecurity")},
		{id:"activities", label:i18n("config.tabActivities")},
		//{id:"calendar", label:i18n("config.tabCalendar")}
	];

	const handleChangeTab = (item: string) => {
		setCurrentTab(item);
		navigate(`/config/${item}`)
	};

	useEffect(() => {
		const path = location.pathname.split('/');
		setCurrentTab(path[2] ?? '');

		if (["","config"].indexOf(path[path.length-1]) >= 0) {
			navigate(`/config/general`, {replace:true});
		}
    }, [location]);

    return <PageContent>
        <TitleBar>
            <Title>
                <T path="config.title" />
            </Title>
        </TitleBar>
        <Tabs items={tabs} onChange={(id) => {handleChangeTab(id);}} value={currentTab} />
		<div style={{backgroundColor:"var(--theme-color-background-aux)"}}>
			<Outlet />
		</div>
    </PageContent>
}

export default Config;
